import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ModalContainer } from 'modules/components'
import { FILTER_MODAL_NAME } from '../constants'

import STRINGS from 'strings'
const modalStrings = STRINGS.ViewBulkSharedStrings.StatusFilterModal
const statusStrings = STRINGS.ViewBulkSharedStrings.Statuses

const statuses = [{
  id: 'pending',
  name: statusStrings.pending
}, {
  id: 'delivery',
  name: statusStrings.delivered
}, {
  id: 'click',
  name: statusStrings.clicked
}, {
  id: 'bounce',
  name: statusStrings.bounced
}]

export class StatusFilterView extends Component {

  state = {
    selectedStatuses: []
  }

  handleFilter = () => {
    const { hideModal, onStatusUpdate } = this.props
    onStatusUpdate(this.state.selectedStatuses)
    hideModal()
  }

  selectStatus = (status) => {
    const oldStatuses = this.state.selectedStatuses
    const index = oldStatuses.findIndex(oldStatus => oldStatus === status.id)
    if (index > -1) {
      this.setState({ ...this.state, selectedStatuses: [
      ...oldStatuses.slice(0, index),
      ...oldStatuses.slice(index + 1)
      ]})
    } else {
      this.setState({ ...this.state, selectedStatuses: [
        ...oldStatuses,
        status.id
      ]})
    }
  }

  render () {
    const { selectedStatuses } = this.state
    return (
      <ModalContainer
        modalName={FILTER_MODAL_NAME}
        modalTitle={modalStrings.title}
        colorThemeClass={'primary'}
        showCancelButton
        actionEnabled
        actionButtonClicked={this.handleFilter}
        actionButtonElement={
          <span>{modalStrings.filterButton}</span>
        }>
        <p>{modalStrings.description}</p>
        <table className='table table-hover table-vcenter'>
          <tbody>
            {statuses.map((status, index) => {
              const selected = selectedStatuses.indexOf(status.id) >= 0
              return (
                <tr onClick={() => this.selectStatus(status)} key={index} className='selectable'>
                  <td className='text-center' style={{ width: '15%'}}>
                    <label className='css-input css-checkbox css-checkbox-primary'>
                      <input type='checkbox' disabled checked={selected} readOnly /><span />
                    </label>
                  </td>
                  <td>{status.name}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </ModalContainer>
    )
  }
}

StatusFilterView.propTypes = {
  onStatusUpdate: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

export default StatusFilterView
