// import * as actionTypes from './actionTypes'

const LINKS_ACTION_HANDLERS = {

}

const initialState = {

}

export default function (state = initialState, action) {
  const handler = LINKS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
