import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { GymLeadsLoader, ImageEmptyView } from 'modules/components'
import Funnel from 'images/Funnel.png'

import StepContainer from '../containers/StepContainer'
import STRINGS from 'strings'
const listingStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Listing

export class ListingView extends Component {

  componentDidMount () {
    const { getSteps, automationFunnel } = this.props
    getSteps(automationFunnel.id)
  }

  addNewStep = () => {
    const { createNewStep, automationFunnel } = this.props
    createNewStep(automationFunnel.id)
  }

  render () {
    const { loading, steps, loadingAdd } = this.props
    const stepRows = steps.map((step, index) => {
      return <StepContainer step={step} key={index} />
    })
    return (
      <div>
        {steps.length === 0 && loading &&
          <GymLeadsLoader text={listingStrings.loadingFunnelStep} active />
        }
        {steps.length === 0 && !loading &&
          <ImageEmptyView
            title={listingStrings.noSteps}
            image={Funnel}
            subtitle={listingStrings.addFirstStep}
            buttonText={listingStrings.createFirstStep}
            buttonSelected={this.addNewStep} />
        }
        {steps.length > 0 &&
          <div>
            {stepRows}
            <div className='row'>
              <div className='col-xs-12 col-sm-4 col-sm-offset-4'>
                <button className='btn btn-block btn-primary'
                  disabled={loadingAdd}
                  onClick={this.addNewStep}>
                  <i className={'fa fa-plus'} /> {listingStrings.addNewStep}
                </button>
              </div>
            </div>
          </div>
        }
        {loadingAdd &&
          <GymLeadsLoader text={listingStrings.addingStep} active />
        }
      </div>
    )
  }
}

ListingView.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  automationFunnel: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingAdd: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  getSteps: PropTypes.func.isRequired,
  createNewStep: PropTypes.func.isRequired
}

export default ListingView
