import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from "strings";

const triggersStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Triggers;
export class TriggerOption extends Component {

  selectOption = () => {
    const { triggerOption, onSelect } = this.props
    onSelect(triggerOption)
  }

  isDisabled = () => {
    const { triggerOption, stepNumber } = this.props
    if (triggerOption.first_step_only && stepNumber !== 1) { return true }
    if (triggerOption.never_first_step && stepNumber === 1) { return true }
    return false
  }

  render () {
    const { triggerOption, selected, stepNumber } = this.props
    const disabledClass = (this.isDisabled()) ? 'trigger-cell-disabled' : ''
    const selectedClass = (selected) ? 'selected' : 'selectable'
    return (
      <div className={'trigger-cell ' + selectedClass + ' ' + disabledClass}
        onClick={this.selectOption}>
        <h5>{triggerOption.title}</h5>
        <p>{triggerOption.description}</p>
        {triggerOption.first_step_only && stepNumber !== 1 &&
          <p><strong>{triggersStrings.firstStepOnly}</strong></p>
        }

        {triggerOption.never_first_step && stepNumber === 1 &&
          <p><strong>{triggersStrings.notFirstStepOnly}</strong></p>
        }
      </div>
    )
  }
}

TriggerOption.propTypes = {
  triggerOption: PropTypes.object.isRequired,
  stepNumber: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  isRight: PropTypes.bool.isRequired

}

export default TriggerOption
