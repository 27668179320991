import React, { Component } from 'react'
import STRINGS from 'strings'
const smsChecklistStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Checklist

export class FromRow extends Component {

  render () {
    // const { automationTextMessage } = this.props
    const icon = 'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row-top'>
        <i className={icon} />
        <h4>{smsChecklistStrings.from}</h4>
        <p>{smsChecklistStrings.sentFromLocationsPhonenumber}</p>
      </div>
    )
  }
}

FromRow.propTypes = {

}

export default FromRow
