import { NAME } from "./constants";

export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_STEPS_LEADS = NAME + "/SET_STEPS_LEADS";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const SET_SELECTED_FUNNEL_STEP_ID =
  NAME + "/SET_SELECTED_FUNNEL_STEP_ID";
export const RESET = NAME + "/RESET";
export const REMOVE_STEPS_LEAD_WITH_ID = NAME + "REMOVE_STEPS_LEAD_WITH_ID";

export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";
