import PropTypes from "prop-types";
import React, { Component } from "react";

import { MultiTagSelect } from "modules/components";
import Button from "@atlaskit/button";

const SELECT_TAGS_MODAL = "TAG_ENTRY/SELECT_TAGS_MODAL";

class TagsExcludeEntryView extends Component {

  constructor(props) {
    super(props);
    if (props.data) {
      switch (typeof props.data.value) {
        case "number":
          this.state = { value: [props.data.value] };
          break;
        case "object":
          this.state = { value: props.data.value };
          break;
        case "string":
          this.state = {
            value: props.data.value
              .toString()
              .split(",")
              .map(i => parseInt(i, 10))
          };
          break;
        default:
          this.state = { value: [] };
          break;
      }
    } else {
      this.state = {
        value: []
      };
    }
  }

  updateTagIds = selectedTagIds => {
    this.setState({ ...this.state, value: selectedTagIds });
    this.updateWithResult({ value: selectedTagIds });
  };

  updateWithResult = result => {
    const { id, ruleUpdated } = this.props;
    ruleUpdated(id, result);
  };

  showSelectTagsModal = () => {
    const { showModal } = this.props;
    showModal(SELECT_TAGS_MODAL);
  };

  buttonStringTitle = () => {
   
    const { excludedTags, data } = this.props;
    const selectedLength = data ? data.value.length : 0;

    if (selectedLength === 0) {
      return "Select tag";
    } else if (selectedLength === 1) {
      const index = excludedTags.findIndex(
        excludedTags => excludedTags.id === data.value[0]
      );
      if (index < 0) {
        return "A tag you don't have access to";
      }
      return excludedTags[index].name;
    } else {
      return selectedLength + " selected";
    }
  };

  render() {
    const { readonly, showClearSelectionButton } = this.props;
    const title = readonly ? "Tags Used" : "Select tag";
    return (
      <div style={{ display: "inline" }}>
        <span style={{ display: "inline-block" }}>
          <Button appearance="default" onClick={this.showSelectTagsModal}>
            {this.buttonStringTitle()}
          </Button>
        </span>
        <span style={{ display: "inline-block" }}>
          <MultiTagSelect.MultiTagSelectModalContainer
            readonly={readonly}
            title={title}
            modalName={SELECT_TAGS_MODAL}
            tagIds={this.state.value}
            onUpdate={this.updateTagIds}
            showClearSelectionButton={showClearSelectionButton}
          />
        </span>
      </div>
    );
  }
}

TagsExcludeEntryView.propTypes = {
  id: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  ruleUpdated: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  data: PropTypes.object,
  showClearSelectionButton: PropTypes.bool
};

TagsExcludeEntryView.defaultProps = {
  readonly: false,
  showClearSelectionButton: false
};

export default TagsExcludeEntryView;
