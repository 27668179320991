import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'
import { FormFields, ModalContainer } from 'modules/components'
import { NEW_BULK_EMAIL_MODAL } from '../constants'
const { TextField } = FormFields

const newBulkEmailStrings = STRINGS.BulkMessaging.NewBulkEmailModal

export class NewEmailTemplateModalView extends Component {

  state = {
    name: null
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    const { showSelectDesignView, setNewName, hideModal } = this.props
    setNewName(this.state.name)
    showSelectDesignView()
    hideModal()
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value })
  }

  isDisabled = () => {
    return !this.state.name
  }

  render () {
    const { loading } = this.props
    return (
      <ModalContainer
        modalName={NEW_BULK_EMAIL_MODAL}
        modalTitle={newBulkEmailStrings.createNewBulkEmail}
        colorThemeClass={'success'}
        loading={loading}
        loadingText={newBulkEmailStrings.creatingBulkEmail}
        showCancelButton
        actionEnabled={!this.isDisabled()}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={
          <span><i className='fa fa-plus' /> {newBulkEmailStrings.headline}</span>
        }>
        <p>{newBulkEmailStrings.subheadline}</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}
          style={{
            marginLeft: '10px',
            marginRight: '10px'
          }}>
          <TextField
            name='name'
            label=''
            placeholder={newBulkEmailStrings.textFieldPlaceholder}
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput} />
        </form>
      </ModalContainer>
    )
  }
}

NewEmailTemplateModalView.propTypes = {
  setNewName: PropTypes.func.isRequired,
  showSelectDesignView: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default NewEmailTemplateModalView
