import PropTypes from "prop-types";
import React, { Component } from "react";
import { ModalContainer } from "modules/components";

import {
  TextSearchView,
  GymLeadsLoader
} from "modules/components";

import LeadsTableView from "./LeadsTableView";
import { PagesView } from "modules/components";

import { SHOW_STEP_ADD_LEADS_MODAL_VIEW } from "../constants";
import STRINGS from "strings";

export class StepAddLeadsModal extends Component {
  state = {
    search: null
  };

  componentDidMount() {
    this.getLeadsWithPageNumber(1);
  }

  getLeadsWithPageNumber = pageNumber => {
    const {
      getLeadsForFunnelStep,
      automationFunnel,
      selectedFunnelStep
    } = this.props;
    getLeadsForFunnelStep(automationFunnel, selectedFunnelStep, pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadsWithPageNumber(pageNumber);
  };

  addLead = (lead) => {    
    const {
      addLeadToStep,
      automationFunnel,
      selectedFunnelStep
    } = this.props;

    addLeadToStep(
      automationFunnel,
      selectedFunnelStep,
      lead.id
    );
  };

  searchUpdated = searchTerm => {
    const {
      getLeadsForFunnelStep,
      automationFunnel,
      selectedFunnelStep
    } = this.props;
    getLeadsForFunnelStep(
      automationFunnel,
      selectedFunnelStep,
      1,
      null,
      searchTerm
    );
  };

  cleanUp = () => {
    this.props.reset();
  };

  render() {
    const { selectedFunnelStep, leads, pageDetails, loading } = this.props;
    const { search } = this.state;
    return (
      <ModalContainer
        modalName={SHOW_STEP_ADD_LEADS_MODAL_VIEW}
        width={"large"}
        modalTitle={
          STRINGS.Funnels.Steps.LeadsList.leadsIn +
          " " +
          selectedFunnelStep.name
        }
        colorThemeClass={"primary"}
        showCancelButton
        cleanUp={this.cleanUp}
        actionEnabled={false}>
        <div className="block-content">
        <div style={{ marginBottom: '20px' }}> {STRINGS.Funnels.Steps.LeadsList.addLeadDisclaimer} </div>
        <hr />
          <GymLeadsLoader active={loading} text="Loading" />
          {!loading && (
            <div>
              <TextSearchView
                onUpdate={this.searchUpdated}
                searchText={search || ""}
                sectionName={"search"}
              />
              {leads.length > 0 && (
                <div className="table-responsive">
                  <LeadsTableView
                    leads={leads}
                    addLead={this.addLead}
                  />
                  <PagesView
                    pageDetails={pageDetails}
                    pageClicked={this.pageClicked}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </ModalContainer>
    );
  }
}

StepAddLeadsModal.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  addLeadToStep: PropTypes.func.isRequired,
  automationFunnel: PropTypes.object.isRequired,
  selectedFunnelStep: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

export default StepAddLeadsModal;
