import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { LeadStatusTag } from 'modules/components'
import alert from 'modules/components/alert'
import STRINGS from 'strings'
export class CompletedLeadTableRow extends Component {

  showWarning = () => {
    const { showAlertWithConfig, joinRow } = this.props
    const lead = joinRow.lead
    const alertConfig = {
      ...alert.defaultConfig,
      children: STRINGS.formatString(STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.CompletedLeads.areYouSureAlert, lead.display_name),
      style: 'danger',
      onConfirm: this.deleteRecord
    }
    showAlertWithConfig(alertConfig)
  }

  deleteRecord = () => {
    const { deleteRecord, joinRow, automationFunnel } = this.props
    deleteRecord(automationFunnel.id, joinRow.id)
  }

  render () {
    const { joinRow } = this.props
    const lead = joinRow.lead
    return (
      <tr>
        <td>{lead.display_name}</td>
        <td><LeadStatusTag leadStatus={lead.status}/></td>
        <td>{lead.location_name}</td>
        <td>
          <button className='btn btn-sm btn-danger' onClick={this.showWarning} type='button'>
            <i className='fa fa-times' />
          </button>
        </td>
      </tr>
    )
  }
}

CompletedLeadTableRow.propTypes = {
  joinRow: PropTypes.object.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
}

export default CompletedLeadTableRow
