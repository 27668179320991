import { request, PUT } from 'utils/apiUtils'
import loadGroup from '../loadGroup'
import flags from 'modules/components/flags'

export const update = (groupId, updateBody) => {
  return (dispatch) => {
    const body = {
      marketing_group: updateBody
    }

    function onFailure (payload) {
    }

    function onSuccess (payload) {
      dispatch(loadGroup.actions.setGroup(payload.marketing_group))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: 'Group rules updated',
        index: 0
      }))
    }

    return request('marketing_groups/' + groupId, PUT, body, onSuccess, onFailure)
  }
}
