import { request, POST, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import session from 'modules/stores/session'

export const login = (username, password, rememberMe) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const body = {
      auth: {
        username: username,
        password: password,
        remember_me: rememberMe
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(session.actions.startSession(payload))
      dispatch(setLoading(false))
    }

    return request('sessions', POST, body, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

// Whitelabel profile
export const fetchWhitelabelProfileLogo = (productName) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setWhitelabelProfileLogo(payload))
      dispatch(setLoading(false))
    }

    const url = `whitelabel_profile_logo?client=${productName}`
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setWhitelabelProfileLogo (whitelabelProfileLogo) {
  return {
    type: actionTypes.WHITELABEL_PROFILE,
    whitelabelProfileLogo
  }
}