import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { MultiUserSelect } from 'modules/components'
import Button from '@atlaskit/button'

import './styles.css'

class UserEntryView extends Component {
  constructor(props) {
    super(props)
    if (props.data) {
      switch (typeof props.data.value) {
        case 'number':
        this.state = { value: [props.data.value] }
          break;
        case 'object':
          this.state = { value: props.data.value }
          break;
        case 'string':
          this.state = { value: props.data.value.toString().split(',').map( i => parseInt(i, 10)) }
          break;
        default:
        this.state = { value: [] }
          break;
      }
    } else {
      this.state = {
        value: []
      }
    }
  }

  updateUserIds = (selectedUserIds) => {
    this.setState({ ...this.state, value: selectedUserIds })
    this.updateWithResult({ value: selectedUserIds })
  }

  updateWithResult = (result) => {
    const { id, ruleUpdated } = this.props
    ruleUpdated(id, result)
  }

  showSelectUsersModal = () => {
    const { showModal, userSelectModalName, setModalUserIds } = this.props
    const { value } = this.state

    setModalUserIds(value)
    showModal(userSelectModalName)
  }

  buttonStringTitle = () => {
    const { data } = this.props
    const selectedLength = (data) ? data.value.length : 0
    if (selectedLength === 0) {
      return 'Select a user'
    } else {
      return selectedLength + ' selected'
    }
  }

  render () {
    const { readonly } = this.props
    return (
      <div style={{ display: 'inline' }}>
        <span style={{ display: 'inline-block' }}>
          <Button appearance='default' onClick={this.showSelectUsersModal}>
            {this.buttonStringTitle()}
          </Button>
        </span>
        <span style={{ display: 'inline-block' }}>
          <MultiUserSelect.MultiUserSelectContainer
            readonly={readonly}
            onUpdate={this.updateUserIds}
          />
        </span>
      </div>
    )
  }
}

UserEntryView.propTypes = {
  id: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  userSelectModalName: PropTypes.string.isRequired,
  setModalUserIds: PropTypes.func.isRequired,
  ruleUpdated: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  data: PropTypes.object
}

UserEntryView.defaultProps = {
  readonly: false
}

export default UserEntryView
