import PropTypes from 'prop-types'
import React, { Component } from 'react'

import STRINGS from "strings";
const loadBulkEmailStrings = STRINGS.BulkMessaging.BulkEmail.Header;
export class LoadTemplateView extends Component {

  componentDidMount () {
    const { getBulkEmail, match } = this.props
    getBulkEmail(match.params.id)
  }

  render () {
    return (
      <div className='row'>
        <div className='col-sm-6 col-sm-offset-3 text-center'>
          <h2 className='push'>
            {loadBulkEmailStrings.loadingText}
          </h2>
          <i className='fa fa-4x fa-cog fa-spin text-warning' />
        </div>
      </div>
    )
  }
}

LoadTemplateView.propTypes = {
  getBulkEmail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default LoadTemplateView
