import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import settings from '../settings'
import audience from '../audience'

export const get = (automationFunnelId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      if (payload.status === 404) {
          dispatch(setNotFound(true))
          return
      }
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setAutomationFunnel(payload.automation_funnel))
      dispatch(settings.actions.prefillDataForAutomationFunnel(payload.automation_funnel))
      dispatch(audience.actions.prefillDataForAutomationFunnel(payload.automation_funnel))
      dispatch(setNotFound(false))
      dispatch(setLoading(false))
    }

    return request('automation_funnels/' + automationFunnelId, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setAutomationFunnel (automationFunnel) {
  return {
    type: actionTypes.SET_AUTOMATION_FUNNEL,
    automationFunnel
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function setNotFound (notFound) {
  return {
    type: actionTypes.SET_NOT_FOUND,
    notFound
  }
}

export function setChecklist (checklist) {
  return {
    type: actionTypes.SET_CHECKLIST,
    checklist
  }
}
