import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ListingView from '../components/ListingView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import loadAutomationFunnel from '../../../../loadAutomationFunnel'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    loadingAdd: selectors.getLoadingAdd(state),
    errors: selectors.getErrors(state),
    steps: selectors.getSteps(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getSteps: actions.getSteps,
    createNewStep: actions.createNewStep,
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingView))
