import { NAME } from "./constants";

export const getRecords = state => state.viewBulkEmail[NAME].records;
export const getSelectedReply = state =>
  state.viewBulkEmail[NAME].selectedReply;
export const getSelectedRecordId = state =>
  state.viewBulkEmail[NAME].selectedRecordId;
export const getLoading = state => state.viewBulkEmail[NAME].loading;
export const getLoadingReply = state => state.viewBulkEmail[NAME].loadingReply;
export const getErrors = state => state.viewBulkEmail[NAME].errors;

export const getPageDetails = state => state.viewBulkEmail[NAME].pageDetails;
