import SortOptions from "./sortOptions";

const initialState = {
  loading: false,
  errors: null,
  funnels: [],
  searchTerm: null,
  sortOrder: SortOptions[3].id,
  pageDetails: {
    currentPage: 1,
    pageCount: 1,
    totalCount: 0
  }
};

export default initialState;
