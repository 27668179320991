import PropTypes from "prop-types";
import React, { Component } from "react";
import LeadsEmpty from "images/LeadsEmpty.jpg";
import styled from "styled-components";

import { SimpleImageEmptyView, GymLeadsLoader } from "modules/components";

import STRINGS from "strings";
const audienceStrings = STRINGS.ViewBulkSharedStrings.Audience;

class ShowAudienceView extends Component {
  componentDidMount() {
    const {
      getAudienceRecordsForBulkEmail,
      bulkEmail,
      audienceRecords
    } = this.props;
    if (audienceRecords) {
      return;
    }
    getAudienceRecordsForBulkEmail(bulkEmail);
  }

  render() {
    const { audienceRecords, loading } = this.props;
    return (
      <div>
        <GymLeadsLoader active={loading} />
        {!audienceRecords && !loading && (
          <SimpleImageEmptyView
            image={LeadsEmpty}
            height={100}
            title={audienceStrings.noRecordsTitle}
            subtitle={audienceStrings.noRecordsDestription}
          />
        )}
        {audienceRecords && !loading && (
          <div className="audience">
            <Headline>
              {STRINGS.formatString(
                audienceStrings.breakdownHeadline,
                audienceRecords.total
              )}
              :{" "}
            </Headline>
            <LineListing>
              <Badge className="badge badge-success">
                {audienceRecords.sent}
              </Badge>
              {audienceStrings.breakdownDescriptionSuccess}
            </LineListing>
            {audienceRecords.opted_out > 0 && (
              <LineListing>
                <Badge className="badge badge-danger">
                  {audienceRecords.opted_out}
                </Badge>
                {audienceStrings.breakdownDescriptionOptedOut}
              </LineListing>
            )}
            {audienceRecords.previously_bounced > 0 && (
              <LineListing>
                <Badge className="badge badge-danger">
                  {audienceRecords.previously_bounced}
                </Badge>
                {audienceStrings.breakdownDescriptionBounced}
              </LineListing>
            )}
            {audienceRecords.not_opted_in > 0 && (
              <LineListing>
                <Badge className="badge badge-danger">
                  {audienceRecords.not_opted_in}
                </Badge>
                {audienceStrings.breakdownDescriptionEnhancedPrivacy}
              </LineListing>
            )}
            {audienceRecords.missing_send_field > 0 && (
              <LineListing>
                <Badge className="badge badge-warning">
                  {audienceRecords.missing_send_field}
                </Badge>
                {audienceStrings.breakdownDescriptionNoEmail}
              </LineListing>
            )}
          </div>
        )}
      </div>
    );
  }
}

ShowAudienceView.propTypes = {
  bulkEmail: PropTypes.object.isRequired,
  audienceRecords: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  getAudienceRecordsForBulkEmail: PropTypes.func.isRequired
};

export default ShowAudienceView;

const Headline = styled.h5`
  margin-bottom: 15px;
`;
const LineListing = styled.p``;

const Badge = styled.span`
  margin-right: 5px;
`;
