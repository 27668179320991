import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AvatarImage from 'images/avatar.jpg'

export class UserAvatar extends Component {

  urlParam = () => {
    switch (this.props.type) {
      case 'thumb':
        return 'thumb_url'
      case 'medium':
        return 'medium_url'
      default:
        return 'url'
    }
  }

  render () {
    const { avatarImage, className, style } = this.props

    const avatarString = (avatarImage && avatarImage[this.urlParam()]) ? avatarImage[this.urlParam()] : AvatarImage

    return (
      <img alt={'profile-pic'} src={avatarString} className={'img-avatar ' + className} style={style} />
    )
  }
}

UserAvatar.propTypes = {
  avatarImage: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(['thumb', 'medium'])
}

export default UserAvatar
