import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { errorPanelForErrors } from 'utils/formHelpers'

import { ContentBlockView, ImageEmptyView } from 'modules/components'

import DraftSendContainer from '../containers/DraftSendContainer'
import ScheduledSendContainer from '../containers/ScheduledSendContainer'

import SmsNotReady from 'images/SmsNotReady.jpg'

export class SendView extends Component {

  render () {
    const { bulkSms, loading, errors } = this.props
    const checklist = bulkSms.checklist
    return (
      <ContentBlockView bordered loading={loading}>
        {errorPanelForErrors(errors)}
        {checklist.ready_to_send && bulkSms.status === 'draft' &&
          <DraftSendContainer />
        }
        {checklist.ready_to_send && bulkSms.status === 'scheduled' &&
          <ScheduledSendContainer />
        }
        {!checklist.ready_to_send &&
          <ImageEmptyView
            title={'You\'re Almost There!'}
            subtitle={'Make sure you\'ve completed all of the above before sending the sms.'}
            image={SmsNotReady} />
        }
      </ContentBlockView>
    )
  }
}

SendView.SubjectRow = {
  bulkSms: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
}

export default SendView
