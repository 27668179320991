import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class SsoView extends Component {

  componentDidMount () {
    const { loadSsoSession, match } = this.props
    loadSsoSession(match.params.token)
  }

  render () {
    return (
      <div id='page-container' className='header-navbar-fixed'>
        <div id='page-loader' style={{ display: 'block' }} />
      </div>
    )
  }
}

SsoView.propTypes = {
  loadSsoSession: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
}

export default SsoView
