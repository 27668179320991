import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import styled from "styled-components";

import { SelectedRuleItem } from "modules/rules";
import "./styles.css";

import STRINGS from "strings";
const conditionsStrings = STRINGS.ViewBulkSms.ConditionsView;

class ConditionsView extends Component {
  render() {
    const { rules, condition, selectedGroup } = this.props;
    return (
      <Fragment>
        {selectedGroup && (
          <Fragment>
            <Headline>{conditionsStrings.usedMarketingGroupTitle}:</Headline>
            <Headline>{selectedGroup.name}</Headline>
          </Fragment>
        )}
        {!selectedGroup && (
          <Fragment>
            <Headline>{conditionsStrings.usedCustomFiltersTitle}:</Headline>
            <Body>
              {STRINGS.formatString(
                conditionsStrings.matchedLeads,
                <strong>{condition}</strong>
              )}
            </Body>
            {rules.map((rule, i) => (
              <SelectedRuleItem key={i} rule={rule} index={i} readonly />
            ))}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

ConditionsView.propTypes = {
  selectedGroup: PropTypes.object,
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  condition: PropTypes.string.isRequired
};

export default ConditionsView;

const Headline = styled.h4`
  margin-bottom: 15px;
`;

const Body = styled.div`
  margin-bottom: 15px;
`;
