import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'

export class LinkItem extends Component {

  render () {
    const { link } = this.props
    return (
      <ContentBlockView>
        <div className='row'>
          <div className='col-xs-12'>
            <i className='si si-link fa-2x pull-left push-10-r' />
            <h5 style={{
              marginTop: '5px'
            }}>
            <a href={link.long_url_string} target='__blank'>{link.long_url_string}</a></h5>
          </div>
        </div>
      </ContentBlockView>
    )
  }
}

LinkItem.propTypes = {
  link: PropTypes.object.isRequired
}

export default LinkItem
