import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditDealTriggerValueView from "../components/EditDealTriggerValueView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import deals from "modules/stores/deals";
import * as apiActions from "modules/stores/deals/apiActions";

function mapStateToProps(state) {
  return {
    data: selectors.getData(state),
    triggerValueText: selectors.triggerValueText(state),
    deals: deals.selectors.getDeals(state),
    loading: deals.selectors.getLoading(state),
    pageDetails: deals.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDeals: apiActions.getDeals,
      updateData: actions.updateData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDealTriggerValueView);
