import { createSelector } from 'reselect'
import loadBulkEmail from '../loadBulkEmail'
import { NAME } from './constants'

export const getErrors = state => state.viewBulkEmail[NAME].errors
export const getLoading = state => state.viewBulkEmail[NAME].loading

export const formattedStatistics = createSelector(
  [ loadBulkEmail.selectors.getBulkEmail ],
  (bulkEmail) => {
    return bulkEmail.full_results
  }
)
