import config from 'config'
import store from 'store/store'

import session from 'modules/stores/session'
import userData from 'modules/stores/userData'
import { newBugsnagClient } from 'modules/helpers/BugsnagClient'

// START USER SESSIONS
export function startUserSession () {
  const state = store.getState()
  const user = session.selectors.getUser(state)
  const company = userData.selectors.getCompany(state)

  let intercomhash = session.selectors.getIntercomHash(state)
  if (intercomhash) {
    intercomhash = intercomhash.replace(/['"]+/g, '')
  }

  if (config.debug) {
    console.log('ANALYTIC START', user, company, intercomhash)
    return
  }

  const options = {
    app_id: 'f8f1vqq3',
    email: user.email,
    user_id: user.id,
    username: user.username,
    name: user.display_name,
    role: user.user_role.name,
    created_at: user.created_at,
    user_hash: intercomhash,
    app: 'Marketing',
    app_version: config.version,
  }

  try {
    if (company && company.id) {
      options['company'] = {
        id: company.id,
        name: company.title,
        website: company.url,
        created_at: company.created_at
      }
    }

    if (window.Intercom) {
      window.Intercom('boot', options)
    }
  } catch (exception) {
    const bugsnagClient = newBugsnagClient()
    bugsnagClient.notify(exception)
  }
}

// STOP USER SESSIONS
export function stopUserSession () {
  if (config.debug) {
    console.log('ANALYTIC STOP')
    return
  }
  if (window.Intercom) {
    window.Intercom('shutdown')
  }
  trackEvent('Log Out')
}

// EVENT TRACKING
export function trackEvent (event, data) {
  if (config.debug) {
    console.log('ANALYTIC EVENT', event, data)
    return
  }
  sendIntercomEvent(event, data)
}

function sendIntercomEvent (event, data) {
  if (!window.Intercom) { return }
  if (data) {
    window.Intercom('trackEvent', event, data)
  } else {
    window.Intercom('trackEvent', event)
  }
}
