import { NAME } from "./constants";

export const getErrors = state => state.automations.funnels[NAME].errors;
export const getLoading = state => state.automations.funnels[NAME].loading;
export const getLegacyRecipesLoading = state =>
  state.automations.funnels[NAME].legacyRecipesLoading;
export const getPageDetails = state =>
  state.automations.funnels[NAME].pageDetails;
export const getRecipes = state => state.automations.funnels[NAME].recipes;
export const getLegacyRecipes = state =>
  state.automations.funnels[NAME].legacyRecipes;
export const getPrivateRecipe = state =>
  state.automations.funnels[NAME].privateRecipe;
export const getSearchTerm = state =>
  state.automations.funnels[NAME].searchTerm;
export const getSortOrder = state => state.automations.funnels[NAME].sortOrder;
