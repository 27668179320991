import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'

import image from 'images/html_email.png'

import { RowContainer, PreviewImageContainer } from "./styles";

export class SystemTemplateRow extends Component {

  rowSelected = (e) => {
    const { id, onSelect } = this.props
    onSelect(id)
  }

  render () {
    const { title, detail } = this.props
    return (
      <ContentBlockView bordered>
        <RowContainer
          onClick={this.rowSelected}>
          <PreviewImageContainer>
            <img src={image}
              style={{
              width: '70px',
              maxHeight: '88px',
              marginBottom: '20px'
            }}
            alt='email preview' />
          </PreviewImageContainer>
          <div className='col-xs-10'>
            <h5>{title}</h5>
            <p>{detail}</p>
          </div>
        </RowContainer>
      </ContentBlockView>
    )
  }
}

SystemTemplateRow.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default SystemTemplateRow
