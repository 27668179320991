import config from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { readableMobileNumber } from "modules/helpers/FormatHelpers";

export class LeadRow extends Component {
  removeSelected = () => {
    const { onRemoveSelected, stepsLead } = this.props;
    onRemoveSelected(stepsLead);
  };

  goToLead = () => {
    const { stepsLead } = this.props;
    window.open(
      config.mainHost + "/Secure/manageLeads?lead_id=" + stepsLead.lead.id,
      "_blank"
    );
  };

  render() {
    const { stepsLead } = this.props;
    const lead = stepsLead.lead;
    return (
      <tr>
        <td>{lead.display_name}</td>
        <td>{lead.email}</td>
        <td>{readableMobileNumber(lead.mobile_number)}</td>
        <td>
          <button
            className="btn btn-sm btn-danger"
            onClick={this.removeSelected}
            type="button">
            <i className="fa fa-remove" />
          </button>
        </td>
        <td>
          <button
            className="btn btn-sm btn-primary"
            onClick={this.goToLead}
            type="button">
            <i className="fa fa-user" />
          </button>
        </td>
      </tr>
    );
  }
}

LeadRow.propTypes = {
  stepsLead: PropTypes.PropTypes.object.isRequired,
  onRemoveSelected: PropTypes.func.isRequired
};

export default LeadRow;
