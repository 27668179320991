import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MultiLeadSourceSelectModal from "../components/MultiLeadSourceSelectModal";

import leadSources from "modules/stores/leadSources";
import * as apiActions from "modules/stores/leadSources/apiActions";
import modal from "modules/stores/modal";

function mapStateToProps(state) {
  return {
    leadSources: leadSources.selectors.getLeadSources(state),
    loading: leadSources.selectors.getLoading(state),
    errors: leadSources.selectors.getErrors(state),
    pageDetails: leadSources.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadSources: apiActions.getLeadSources,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiLeadSourceSelectModal);
