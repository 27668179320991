import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import TextInputView from '../components/TextInputView'
import loadBulkSms from '../../loadBulkSms'

import userData from 'modules/stores/userData'
import mergeTags from 'modules/stores/mergeTags'
import modal from 'modules/stores/modal'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    bulkSms: loadBulkSms.selectors.getBulkSms(state),
    mergeTags: mergeTags.selectors.mergeTagsForSelect(state),
    text: selectors.getText(state),
    company: userData.selectors.getCompany(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setText: actions.setText,
    showModal: modal.actions.showModal,
    updateSmsContent: actions.updateSmsContent
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextInputView))
