import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PrivateRouteContainer, HeaderContainer } from 'modules/components'
import MenuContainer from '../containers/MenuContainer'
import './CoreLayout.css'
import 'styles/core.css'
import 'styles/oneui.css'
import 'styles/cells.css'
import IntercomWrapper from './IntercomWrapper'

import { Switch, Route } from 'react-router-dom'
import login from 'containers/login'
import articles from 'containers/articles'
import sso from 'containers/sso'
import secure from 'containers/secure'

export class AppView extends Component {

  render () {
    const { user, shouldShowMenu } = this.props

    return (
      <div id='page-container' className='header-navbar-fixed'>
        {user &&
          <HeaderContainer />
        }
        <main id='main-container' style={{ minHeight: '921px' }}>
          {user && shouldShowMenu &&
            <MenuContainer />
          }
          <Switch>
            <Route exact path="/" component={login.LoginContainer} />
            <Route exact path="/login" component={login.LoginContainer} />
            <Route exact path={"/" + articles.constants.NAME} component={articles.ArticlesContainer} />
            <Route exact path={'/' + sso.constants.NAME + '/:token'} component={sso.SsoContainer} />
            <PrivateRouteContainer path="/secure" component={secure.SecureContainer} />
          </Switch>
        </main>
        <IntercomWrapper />
      </div>
    )
  }
}

AppView.propTypes = {
  user: PropTypes.object,
  shouldShowMenu: PropTypes.bool.isRequired
}

AppView.defaultProps = {
  shouldShowMenu: true
}

export default AppView
