import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'
import STRINGS from 'strings'

import moment from 'moment-timezone'

export class FunnelRow extends Component {

  rowSelected = (e) => {
    const { funnel, onSelect } = this.props
    onSelect(funnel)
  }

  render () {
    const { funnel, timezone } = this.props
    return (
      <ContentBlockView>
        <div className='row selectable'
          onClick={this.rowSelected}>
          <div className='col-xs-4' style={{width: 'auto'}}>
            <h5>
              {funnel.name}
             </h5>
            <p>{STRINGS.Global.lastUpdated} {moment(funnel.updated_at).tz(timezone).format('Do MMM YYYY, h:mm a')}</p>
          </div>
        </div>
      </ContentBlockView>
    )
  }
}

FunnelRow.propTypes = {
  funnel: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default FunnelRow
