// import PropTypes from 'prop-types'
import React, { Component } from "react";

import DesignsContainer from "../containers/DesignsContainer";
import TemplatesContainer from "../containers/TemplatesContainer";
import STRINGS from "strings";
const selectDesignStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.SelectDesign;

const SYSTEM_DESIGNS = "SYSTEM_DESIGNS";
const USER_TEMPLATES = "USER_TEMPLATES";

const menuItems = [
  {
    id: SYSTEM_DESIGNS,
    name: selectDesignStrings.menuDesigns,
  },
  {
    id: USER_TEMPLATES,
    name: selectDesignStrings.menuYourDesigns,
  },
];

export class SelectDesignView extends Component {
  state = {
    view: SYSTEM_DESIGNS,
  };

  setView = (view) => {
    this.setState({ ...this.state, view: view });
  };

  render() {
    const { view } = this.state;
    return (
      <div>
        <h3 className="push-10">{selectDesignStrings.headline}</h3>
        <div className="block">
          <ul className="nav nav-tabs nav-tabs-alt">
            {menuItems.map((menuItem, index) => {
              const className = menuItem.id === view ? "active" : "selectable";
              return (
                <li
                  className={className}
                  key={index}
                  onClick={() => this.setView(menuItem.id)}
                >
                  <div className="topSubButton">{menuItem.name}</div>
                </li>
              );
            })}
          </ul>
        </div>
        {view === SYSTEM_DESIGNS && <DesignsContainer />}
        {view === USER_TEMPLATES && <TemplatesContainer />}
      </div>
    );
  }
}

SelectDesignView.propTypes = {};

export default SelectDesignView;
