import { request, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import flags from 'modules/components/flags'
import loadBulkEmail from '../loadBulkEmail'

import STRINGS from "strings";
const contentStrings = STRINGS.BulkMessaging.BulkEmail.Content;

export const updateEmailContent = (bulkEmail, html, json) => {
  return dispatch => {
    dispatch(setLoading(true))
    const templateBody = {
      mass_lead_email: {
        html: html,
        design_json: json
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setHasChanges(false))
      dispatch(loadBulkEmail.actions.setBulkEmail(payload.mass_lead_email))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: contentStrings.saveSuccessNotification,
        index: 0
      }))
    }

    const url = 'mass_lead_emails/' + bulkEmail.id
    return request(url, PUT, templateBody, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}
