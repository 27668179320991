import PropTypes from "prop-types";
import React, { Component } from "react";
import "../Header.css";
import logo from "resources/logo.png";

import AppSelectContainer from "../containers/AppSelectContainer";

export class LoggedInHeader extends Component {
  updateClicked = () => {
    window.location.reload();
  };

  setLogo() {
    const { whitelabelProfile } = this.props;
    if (whitelabelProfile && whitelabelProfile.whitelabel_profile_logo) {
      return whitelabelProfile.logo_url;
    } else {
      return logo;
    }
  }

  handleLogoutClick = () => {
    const { logout, whitelabelProfile } = this.props;
    const safeName =
      whitelabelProfile && whitelabelProfile.safe_name
        ? whitelabelProfile.safe_name
        : null;
    logout(safeName);
  };

  render() {
    const { user, updateAvailable } = this.props;
    return (
      <header id="header-navbar">
        <div className="content-mini content-mini-full content-boxed">
          {user && (
            <ul className="nav-header pull-right">
              <li>
                <button
                  className="linkButton"
                  onClick={this.handleLogoutClick}
                  style={{
                    padding: "0 12px",
                    display: "block",
                    height: "34px",
                    lineHeight: "34px",
                    fontWeight: "600",
                    color: "#5c90d2",
                  }}
                >
                  Log Out
                </button>
              </li>
            </ul>
          )}
          <ul className="nav-header pull-left">
            <li>
              <img src={this.setLogo()} alt="logo" style={{ width: "120px" }} />
            </li>
            <li className="push-5-t">
              <AppSelectContainer />
            </li>
            {user && (
              <li className="push-5-t">Logged in as {user.display_name}</li>
            )}
            {updateAvailable && (
              <li className="push-5-t">
                <button
                  className="btn btn-sm btn-info push-5-r push-10"
                  onClick={this.updateClicked}
                  type="button"
                >
                  <i className="fa fa-star"></i> Update Available
                </button>
              </li>
            )}
          </ul>
        </div>
      </header>
    );
  }
}

LoggedInHeader.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  whitelabelProfile: PropTypes.object,
  updateAvailable: PropTypes.bool.isRequired,
};

export default LoggedInHeader;
