import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { FormFields } from 'modules/components'

import { EventOptionSelection, SelectedEventItem } from 'modules/events'
import './styles.css';
const { Switch } = FormFields

class ExitRulesView extends Component {

  handleToggle = (name, value) => {
    this.props.setExitIsAutomatic(value)
  }

  render () {
    const { event, setEvent, exitIsAutomatic } = this.props
    return (
      <div>
        <h6 className='push-10'>Leads exit the group when:</h6>
        {!event &&
          <EventOptionSelection eventSelected={setEvent} />
        }
        {event &&
          <SelectedEventItem
            event={event}
            setEvent={setEvent} />
        }
        <Switch
          name='automaticExit'
          label='Remove leads from the group when they no longer match these filters automatically'
          onChange={this.handleToggle}
          switchStyle='switch-sm switch-primary'
          value={exitIsAutomatic} />
      </div>
    )
  }
}

ExitRulesView.propTypes = {
  event: PropTypes.object,
  setEvent: PropTypes.func.isRequired,
  exitIsAutomatic: PropTypes.bool.isRequired,
  setExitIsAutomatic: PropTypes.func.isRequired
}

export default ExitRulesView
