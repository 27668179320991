import PropTypes from 'prop-types'
import React, { Component } from 'react'

import alert from 'modules/components/alert'
import editTemplate from '../../editTemplate'
import settings from '../../settings'
import links from '../../links'

import { ContentBlockView, NavLink, InfoView } from 'modules/components'

export class HeaderView extends Component {

  backSelected = () => {
    const { showWithConfig, hasUnsavedChanges } = this.props
    if (hasUnsavedChanges) {
      showWithConfig({
        ...alert.defaultConfig,
        children: 'You will lose any unsaved changes',
        style: 'warning',
        onConfirm: this.goBack
      })
    } else {
      this.goBack()
    }
  }

  goBack = () => {
    this.props.history.push('/secure/templates')
  }

  helperText = () => {
    const { template } = this.props
    if (!template.system_template_name) { return }
    let detail
    switch (template.system_template_name) {
      case 'OptInTextMessage':
        detail = "This template is used for opting leads in."
        break
      default:
        detail = "Unkown template."
        break
    }
    return detail + '\n System templates cannot have their settings changed.'
  }

  render () {
    const { template, currentPath } = this.props
    const baseLink = '/secure/templates/sms/' + template.id
    const helperText = this.helperText()
    return (
      <ContentBlockView>
        <div className='row'>
          <div className='col-md-6'>
            <i className='si si-arrow-left fa-2x pull-left selectable'
              style={{ marginTop: '5px', marginRight: '20px' }}
              onClick={this.backSelected} />
            <h2 className='push'>
              {template.title}
            </h2>
          </div>
        </div>
        {helperText &&
          <InfoView colorThemeClass={'info'} title={'This is a system template'} detail={helperText} />
        }
        {template.design_type === 'user' &&
        <div className='row'>
          <div className='col-xs-12'>
            <div className='block'>
              <ul className='nav nav-tabs nav-tabs-alt'>
                <NavLink
                  to={baseLink + '/' + editTemplate.constants.NAME}
                  currentPath={currentPath}>
                  <i className='si si-pencil' /> Edit
                </NavLink>
                <NavLink
                  to={baseLink + '/' + links.constants.NAME}
                  currentPath={currentPath}>
                  <i className='si si-link' /> Links
                </NavLink>
                <NavLink
                  to={baseLink + '/' + settings.constants.NAME}
                  currentPath={currentPath}>
                  <i className='si si-settings' /> Settings
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
        }
      </ContentBlockView>
    )
  }
}

HeaderView.propTypes = {
  template: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  showWithConfig: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
}

export default HeaderView
