import * as actionTypes from './actionTypes'

const STEPS_ACTION_HANDLERS = {
  [actionTypes.SET_STEPS]: (state, action) => {
    return { ...state, steps: action.steps }
  },
  [actionTypes.ADD_STEP]: (state, action) => {
    const updatedSteps = [
      ...state.steps,
      action.step
    ]
    return { ...state, steps: updatedSteps }
  },
  [actionTypes.REMOVE_STEP]: (state, action) => {
    const index = state.steps
    .findIndex(step => parseInt((step.id), 10) === parseInt((action.step), 10))
    const updatedSteps = [
      ...state.steps.slice(0, index),
      ...state.steps.slice(index + 1)
    ]
    return { ...state, steps: updatedSteps }
  },
  [actionTypes.SET_STEP_TRIGGER]: (state, action) => {
    const index = state.steps
    .findIndex(step => parseInt((step.id), 10) === parseInt((action.stepId), 10))
    let step = state.steps[index]
    const updatedStep = {
      ...step,
      trigger: action.trigger
    }
    const updatedSteps = [
      ...state.steps.slice(0, index),
      updatedStep,
      ...state.steps.slice(index + 1)
    ]
    return { ...state, steps: updatedSteps }
  },
  [actionTypes.SET_SCHEDULE_FOR_STEP_ID]: (state, action) => {
    const index = state.steps
    .findIndex(step => parseInt((step.id), 10) === parseInt((action.stepId), 10))
    let step = state.steps[index]
    const updatedStep = {
      ...step,
      automation_funnel_step_schedule: action.schedule
    }
    const updatedSteps = [
      ...state.steps.slice(0, index),
      updatedStep,
      ...state.steps.slice(index + 1)
    ]
    return { ...state, steps: updatedSteps }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_SELECTED_STEP_ID]: (state, action) => {
    return { ...state, selectedStepId: action.selectedStepId }
  },
  [actionTypes.SET_LOADING_ADD]: (state, action) => {
    return { ...state, loadingAdd: action.loadingAdd }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialStateForAutomationFunnel(null)
  },
}

function initialStateForAutomationFunnel (automationFunnel) {
  return {
    loading: false,
    loadingAdd: false,
    errors: null,
    selectedStepId: null,
    steps: []
  }
}

export default function (state = initialStateForAutomationFunnel(null), action) {
  const handler = STEPS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
