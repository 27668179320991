import conditions from './modules/conditions'
import audienceRecords from './modules/audienceRecords'

export const prefillDataForBulkEmail = (bulkEmail) => {
  return (dispatch) => {
    dispatch(conditions.actions.prefillDataForBulkEmail(bulkEmail))
  }
}

export const reset = () => {
  return (dispatch) => {
    dispatch(conditions.actions.reset())
    dispatch(audienceRecords.actions.reset())
  }
}
