import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import SmsView from '../components/SmsView'
import loadAutomationTextMessage from '../modules/loadAutomationTextMessage'

import * as actions from '../actions'
import header from '../modules/header'

function mapStateToProps (state) {
  return {
    loadingAutomationTextMessage: loadAutomationTextMessage.selectors.getLoading(state),
    automationTextMessage: loadAutomationTextMessage.selectors.getAutomationTextMessage(state),
    currentView: header.selectors.getView(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    reset: actions.reset
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SmsView))
