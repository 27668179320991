import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ContentBlockView, GymLeadsLoader } from 'modules/components'

import SelectDesignCell from './SelectDesignCell'

export class DesignsView extends Component {

  componentDidMount () {
    const { getDesigns } = this.props
    getDesigns()
  }

  designSelected = (design) => {
    const { createTemplate, automationFunnel, automationFunnelStep } = this.props
    createTemplate(automationFunnel.id, automationFunnelStep.id, design.html, design.design_json)
  }

  render () {
    const { basicDesigns, loading } = this.props

    const basicDesignCells = basicDesigns.map((design, index) => {
      return (
        <SelectDesignCell
          key={index}
          design={design}
          onSelect={this.designSelected} />
      )
    })

    return (
      <ContentBlockView title={'System Designs'} bordered>
        <GymLeadsLoader title='Loading Designs' active={loading} />
        <div className='designs-list'>
          {basicDesigns.length > 0 &&
            basicDesignCells
          }
        </div>
      </ContentBlockView>
    )
  }
}

DesignsView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  getDesigns: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  basicDesigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  featuredDesigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTemplate: PropTypes.func.isRequired,
}

export default DesignsView
