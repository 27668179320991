import * as actionTypes from "./actionTypes";
import * as userApi from "modules/api/user"

export const fetchUsers = (page, search) => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      const response = await userApi.fetchUsers(page, null, search)
      dispatch(setUsers(response.users));
      dispatch(setPageDetails(response.meta));
      dispatch(setLoading(false));
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(setErrors(error.response.data.errors));
      }
      dispatch(setLoading(false));
    }
  };
};

function setUsers(users) {
  return {
    type: actionTypes.SET_USERS,
    users
  };
}

export function setSelectedUserIds(selectedUserIds) {
  return {
    type: actionTypes.SET_SELECTED_USER_IDS,
    selectedUserIds
  };
}

export function addUserId(userId) {
  return {
    type: actionTypes.ADD_USER_ID,
    userId
  };
}

export function removeUserId(userId) {
  return {
    type: actionTypes.REMOVE_USER_ID,
    userId
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}
