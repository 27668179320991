import { request, GET, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { push } from 'react-router-redux'
import loadAutomationFunnel from '../loadAutomationFunnel'
import flags from 'modules/components/flags'
import STRINGS from 'strings'

export const getChecklist = (automationFunnel) => {
  return dispatch => {
    dispatch(setLoading(true))
    dispatch(setErrors(null))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadAutomationFunnel.actions.setChecklist(payload.automation_funnel_checklist))
    }
    const url = 'automation_funnels/' + automationFunnel.id + '/checklist'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const startAutomation = (automationFunnel) => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadAutomationFunnel.actions.setAutomationFunnel(payload.automation_funnel))
      dispatch(push('/secure/automation/funnel/' + automationFunnel.id + '/members'))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: STRINGS.Funnels.EditAutomationFunnel.Checklist.funnelStarted,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnel.id + '/start'
    return request(url, POST, null, onSuccess, onFailure)
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
