import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.editEmailTemplate[NAME].errors
export const getLoading = state => state.editEmailTemplate[NAME].loading
export const getMergeTags = state => state.editEmailTemplate[NAME].mergeTags

export const mergeTagsForSelect = createSelector(
  [ getMergeTags ],
  (mergeTags) => {
    return mergeTags.map((mergeTag) => {
      return {
        id: mergeTag.value,
        title: mergeTag.name
      }
    })
  }
)
