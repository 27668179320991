export const sortOptions = [
  {
    id: "replied_at_asc",
    name: "Date (Ascending)",
    icon: "fa fa-sort-numeric-asc"
  },
  {
    id: "replied_at_desc",
    name: "Date (Descending)",
    icon: "fa fa-sort-numeric-desc"
  }
];

export default sortOptions;
