import PropTypes from 'prop-types'
import React, { Component } from 'react'

import content from '../../content'

import STRINGS from 'strings'
const bulkEmailChecklistStrings = STRINGS.BulkMessaging.BulkEmail.Checklist

export class ContentRow extends Component {

  goToContent = () => {
    this.props.history.push(content.constants.NAME)
  }

  render () {
    const { bulkEmail } = this.props
    const checklist = bulkEmail.checklist
    const icon = (!checklist.content_ok) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        {bulkEmail.status !== 'scheduled' &&
          <button
            className='pull-right btn btn-primary'
            onClick={this.goToContent}>
            {bulkEmailChecklistStrings.editContentButton}
          </button>
        }
        <i className={icon} />
        <h4>{bulkEmailChecklistStrings.emailContentHeader}</h4>
        {!checklist.compliance.is_valid &&
          <div>{bulkEmailChecklistStrings.complianceInvalidMessage}</div>
        }
        {checklist.compliance.is_valid &&
          <div>{bulkEmailChecklistStrings.complianceValidMessage}</div>
        }
        {!checklist.correct_merge_tags &&
          <div>
            {bulkEmailChecklistStrings.mergetagsInvalidMessage} [
              {checklist.incorrect_merge_tags.map((tag, index) => {
                if (index === checklist.incorrect_merge_tags.length - 1) {
                  return tag
                }
                return tag + ', '
              })}
            ]
          </div>
        }
        {checklist.correct_merge_tags &&
          <div>{bulkEmailChecklistStrings.mergetagsValidMessage}</div>
        }
      </div>
    )
  }
}

ContentRow.SubjectRow = {
  bulkEmail: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default ContentRow
