import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";

import email from "../modules/email";
import sms from "../modules/sms";
import selectionModule from "../modules/selection";
import STRINGS from "strings";

const menuOptions = [
  {
    title: "Email",
    option: selectionModule.constants.EMAIL,
    icon: "fa fa-envelope-o",
  },
  {
    title: "SMS",
    option: selectionModule.constants.SMS,
    icon: "fa fa-mobile",
  },
];

export class TemplatesView extends Component {
  newTemplateClicked = () => {
    const { selection } = this.props;
    switch (selection) {
      case selectionModule.constants.EMAIL:
        this.props.showModal(email.constants.NEW_EMAIL_TEMPLATE_MODAL);
        break;
      case selectionModule.constants.SMS:
        this.props.showModal(sms.constants.SELECT_TEMPLATE_OPTION);
        break;
      default:
        break;
    }
  };

  title = () => {
    const { selection } = this.props;
    switch (selection) {
      case selectionModule.constants.EMAIL:
        return "Email Templates";
      case selectionModule.constants.SMS:
        return STRINGS.Templates.TextMessage.List["title"];
      default:
        return "Templates";
    }
  };

  buttonTitle = () => {
    const { selection, selectedEmailType } = this.props;
    switch (selection) {
      case selectionModule.constants.EMAIL:
        return selectedEmailType.newButtonTitle;
      case selectionModule.constants.SMS:
        return STRINGS.Templates.TextMessage.List["newSmsTemplate"];
      default:
        return "New Template";
    }
  };

  render() {
    const { selection, setSelection, shouldHideHeader } = this.props;
    const menuItems = menuOptions.map((menuItem, index) => {
      const isActive = menuItem.option === selection;
      const className = isActive ? "active" : "selectable";
      return (
        <li className={className} key={index}>
          <div
            className="topSubButton"
            onClick={() => setSelection(menuItem.option)}
          >
            <i className={menuItem.icon} /> {menuItem.title}
          </div>
        </li>
      );
    });
    return (
      <div className="content content-boxed">
        {!shouldHideHeader && (
          <ContentBlockView>
            <div className="row">
              <div className="col-md-6">
                <h2>{this.title()}</h2>
                <p>{STRINGS.Templates["manageYourTemplates"]}</p>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-success pull-right"
                  style={{ marginTop: "5px" }}
                  onClick={this.newTemplateClicked}
                >
                  <i className="fa fa-plus" /> {this.buttonTitle()}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="block">
                  <ul className="nav nav-tabs nav-tabs-alt">{menuItems}</ul>
                </div>
              </div>
            </div>
          </ContentBlockView>
        )}
        <div className="row">
          <div className="col-sm-12">
            {selection === selectionModule.constants.EMAIL && (
              <div>
                <email.EmailTemplatesContainer />
                <email.NewEmailTemplateModalContainer />
              </div>
            )}
            {selection === selectionModule.constants.SMS && (
              <div>
                <sms.SmsTemplatesContainer />
                <sms.NewSmsTemplateModalContainer />
                <sms.SelectNewSmsTypeModalContainer />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

TemplatesView.propTypes = {
  showModal: PropTypes.func.isRequired,
  setSelection: PropTypes.func.isRequired,
  selectedEmailType: PropTypes.object.isRequired,
  shouldHideHeader: PropTypes.bool.isRequired,
  selection: PropTypes.string.isRequired,
};

export default TemplatesView;
