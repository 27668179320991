import { apiClient, buildIndexUrl } from "utils/apiClient";

export const fetchUsers = async (page = 1, sort = null, search = null) => {
  let url = buildIndexUrl("users", page, sort, search)
  try {
    const response = await apiClient().get(url);
    return response.data
  } catch (error) {
    return error.data
  }
}