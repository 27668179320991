import PropTypes from "prop-types";
import React, { Component } from "react";

import SelectViewMenu from "modules/components/selectViewMenu";

import DesignsContainer from "../containers/DesignsContainer";
import TemplatesContainer from "../containers/TemplatesContainer";
import ShareCodeContainer from "../containers/ShareCodeContainer";
import STRINGS from "strings/";

const SYSTEM_DESIGNS = "SYSTEM_DESIGNS";
const USER_TEMPLATES = "USER_TEMPLATES";
const SHARE_CODE = "SHARE_CODE";

const menuItems = [
  {
    id: SYSTEM_DESIGNS,
    name: STRINGS.Templates.Email.Designs.systemDesigns
  },
  {
    id: USER_TEMPLATES,
    name: STRINGS.Templates.Email.Designs.yourTemplates
  },
  {
    id: SHARE_CODE,
    name: STRINGS.Templates.shareTitle
  }
];

export class SelectDesignView extends Component {
  state = {
    view: SYSTEM_DESIGNS
  };

  goBack = () => {
    this.props.showListingView();
  };

  setView = view => {
    this.setState({ ...this.state, view: view });
  };

  render() {
    const { view } = this.state;
    return (
      <div>
        <h3 className="push-10">{STRINGS.Templates.Email.Designs.title}</h3>
        <SelectViewMenu
          menuItems={menuItems}
          currentView={view}
          setView={this.setView}
        />
        {view === SYSTEM_DESIGNS && <DesignsContainer />}
        {view === USER_TEMPLATES && <TemplatesContainer />}
        {view === SHARE_CODE && <ShareCodeContainer />}
        <button
          className="btn btn-primary push-20-t push-20"
          onClick={this.goBack}>
          <i className="fa fa-angle-left" />{" "}
          {STRINGS.Templates.Email.Designs.backToTemplates}
        </button>
      </div>
    );
  }
}

SelectDesignView.propTypes = {
  showListingView: PropTypes.func.isRequired
};

export default SelectDesignView;
