import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields, ModalContainer } from 'modules/components'
import { NEW_SMS_TEMPLATE_MODAL } from '../constants'
import STRINGS from 'strings'

const { TextField } = FormFields

export class NewSmsTemplateModalView extends Component {

  state = {
    name: null,
    smsType: null
  }

  handleSubmit = (event) => {
    const { createTemplate, company } = this.props
    event && event.preventDefault()
    createTemplate(this.state.name, company)
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value })
  }

  isDisabled = () => {
    return !this.state.name
  }

  render () {
    const { loading } = this.props
    return (
      <ModalContainer
        modalName={NEW_SMS_TEMPLATE_MODAL}
        modalTitle={STRINGS.Templates.TextMessage.List['createTitle']}
        colorThemeClass={'success'}
        loading={loading}
        loadingText={STRINGS.Templates.TextMessage.List['createPlaceholder']}
        showCancelButton
        actionEnabled={!this.isDisabled()}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={
          <span><i className='fa fa-plus' /> {STRINGS.Templates.TextMessage.List['createButton']}</span>
        }>
        <p>{STRINGS.Templates.TextMessage.List['createSubtitle']}</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}
          style={{
            marginLeft: '10px',
            marginRight: '10px'
          }}>
          <TextField
            name='name'
            label=''
            placeholder={STRINGS.Templates.TextMessage.List['fromScratchTitle']}
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput} />
        </form>
      </ModalContainer>
    )
  }
}

NewSmsTemplateModalView.propTypes = {
  createTemplate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired
}

export default NewSmsTemplateModalView
