import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditTriggerValueView from "../components/EditTriggerValueView";
import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    data: selectors.getData(state),
    triggerValueText: selectors.triggerValueText(state),
    options: selectors.triggerValueOptions(state),
    type: selectors.triggerValueType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateData: actions.updateData,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTriggerValueView);
