import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields, ModalContainer, LeadStatusTag } from 'modules/components'
import { ADD_LEAD_MANUAL_MODAL } from '../constants'

const { TextField } = FormFields

export class AddMemberModalView extends Component {

  state = {
    search: null,
    selectedId: null
  }

  reset = () => {
    this.setState({ search: null, selectedId: null })
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    const { selectedId } = this.state
    if (!selectedId) { return }
    const { addLeadIds, group } = this.props
    addLeadIds(group.id, [selectedId])
  }

  handleSearch = () => {
    const { search } = this.state
    const { searchForLeads } = this.props
    searchForLeads(search)
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value })
  }

  isDisabled = () => {
    return !this.state.selectedId
  }

  selectLead = (lead) => {
    this.setState({ ...this.state, selectedId: lead.id })
  }

  render () {
    const { selectedId } = this.state
    const { searchLeads } = this.props
    return (
      <ModalContainer
        modalName={ADD_LEAD_MANUAL_MODAL}
        modalTitle={'Add a lead manually'}
        colorThemeClass={'success'}
        showCancelButton
        actionEnabled={!this.isDisabled()}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={
          <span><i className='fa fa-plus' /> Add lead</span>
        }>
        <p>Search for a lead using their name, email or mobile number.</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}
          style={{
            marginLeft: '15px',
            marginRight: '15px'
          }}>
          <div className='row'>
            <div className='col-md-8'>
              <TextField
                name='search'
                label=''
                placeholder='name, email or mobile number'
                formFieldStyle='form-material form-material-primary'
                onChange={this.handleInput} />
            </div>
            <div className='col-md-4'>
              <button className={'btn btn-primary'}
                style={{ marginTop: '10px' }}
                type='button'
                disabled={!this.state.search}
                onClick={this.handleSearch}>
                <span><i className='fa fa-search' /> Search</span>
              </button>
            </div>
          </div>
        </form>
        {searchLeads &&
          <table className='table table-hover table-vcenter'>
            <tbody>
              {searchLeads.map((lead, index) => {
                const selected = lead.id === selectedId
                const className = (selected) ? 'selected' : 'selectable'
                return (
                  <tr onClick={() => this.selectLead(lead)} key={index} className={className}>
                    <td>{lead.display_name}</td>
                    <td><LeadStatusTag leadStatus={lead.status}/></td>
                    <td>{lead.location_name}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        }
      </ModalContainer>
    )
  }
}

AddMemberModalView.propTypes = {
  group: PropTypes.object.isRequired,
  searchForLeads: PropTypes.func.isRequired,
  addLeadIds: PropTypes.func.isRequired,
  searchLeads: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default AddMemberModalView
