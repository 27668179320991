import { push } from 'react-router-redux'
import { request, POST, DELETE } from 'utils/apiUtils'
import moment from 'moment'
import * as analyticActions from 'modules/helpers/Analytics'

import * as actionTypes from './actionTypes'

export function startSession (payload) {
  const user = payload.user
  const tokenExpiry = payload.token_expiry_date
  const sessionExpiry = payload.session_expiry_date
  const intercomHash = payload.intercom_user_hash

  localStorage.setItem('user', JSON.stringify(user))
  localStorage.setItem('intercomHash', intercomHash)
  localStorage.setItem('tokenExpiry', JSON.stringify(tokenExpiry))
  localStorage.setItem('sessionExpiry', JSON.stringify(sessionExpiry))
  localStorage.setItem('realtimeAuth', JSON.stringify(payload.realtime_auth))

  return (dispatch) => {
    dispatch(setRealtimeAuth(payload.realtime_auth))
    dispatch(setIntercomHash(intercomHash))
    dispatch(setSessionExpiry(sessionExpiry))
    dispatch(createSession(user))
    dispatch(push('/secure/automation'))
  }
}

const refreshSession = (renewSuccess, renewFailure) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      if (renewFailure) { renewFailure(payload) }
    }

    function onSuccess (payload, version) {
      dispatch(setLoading(false))
      if (renewSuccess) { renewSuccess(payload) }
    }

    return request('renew_session', POST, null, onSuccess, onFailure)
  }
}

export const logout = (clientID = null) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload, version) {
      dispatch(endSession('Logged Out'))
      dispatch(setLoading(false))
      if (clientID) {
        dispatch(push(`/login?client=${clientID}`))
      }
    }

    return request('logout', DELETE, null, onSuccess, onFailure)
  }
}


export function expireSession () {
  localStorage.clear()
  return (dispatch: Function) => {
    dispatch(endSession('Your session timed out'))
    push('/')
  }
}

function createSession (user) {
  return {
    type    : actionTypes.SESSION_CREATE,
    user    : user,
    isMobile: (isMobile.any() !== null)
  }
}

function setSessionExpiry (sessionExpiry) {
  return {
    type: actionTypes.SET_SESSION_EXPIRY,
    sessionExpiry
  }
}

function setRealtimeAuth (realtimeAuth) {
  return {
    type: actionTypes.SET_REALTIME_AUTH,
    realtimeAuth
  }
}

export function restoreSession () {
  return (dispatch) => {
    if (!localStorage['user']) {
      return
    }
    const user = localStorage['user'] ? JSON.parse(localStorage['user']) : null
    const sessionExpiry = localStorage['sessionExpiry']

    // Has the session expired?
    const sessionExpired = (sessionExpiry) ? moment(sessionExpiry, 'YYYY-MM-DDTHH:mm:ssZ').isBefore(moment()) : true
    if (user && sessionExpired) {
      dispatch(expireSession())
      return
    }

    function onRefreshSuccess (payload) {
      dispatch(startSession(payload))
    }
    function onRefreshFailure () { dispatch(expireSession()) }

    dispatch(refreshSession(onRefreshSuccess, onRefreshFailure))
  }
}

export function endSession (reason) {
  analyticActions.stopUserSession()
  localStorage.clear()
  return {
    type: actionTypes.SESSION_END,
    sessionEndReason: reason
  }
}

export const goToApp = (app) => {
  return (dispatch) => {
    const body = { to: app }

    function onFailure (payload) {

    }

    function onSuccess (payload) {
      window.open(payload.url, '_self')
    }

    return request('sso_tokens/', POST, body, onSuccess, onFailure)
  }
}

export function attemptedUrl (attemptedUrl) {
  return {
    type: actionTypes.ATTEMPED_URL,
    attemptedUrl
  }
}

export function clearAttemptedUrl () {
  return {
    type: actionTypes.ATTEMPED_URL
  }
}

export function setAppVersion (appVersion) {
  return {
    type: actionTypes.SET_APP_VERSION,
    appVersion
  }
}

function setIntercomHash (intercomHash) {
  return {
    type: actionTypes.SET_INTERCOM_HASH,
    intercomHash
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

const isMobile = {
  Android: function () {
    return (navigator.userAgent.match(/Android/i))
  },
  BlackBerry: function () {
    return (navigator.userAgent.match(/BlackBerry/i))
  },
  iOS: function () {
    return (navigator.userAgent.match(/iPhone|iPad|iPod/i))
  },
  Opera: function () {
    return (navigator.userAgent.match(/Opera Mini/i))
  },
  Windows: function () {
    return (navigator.userAgent.match(/IEMobile/i))
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
  }
}
