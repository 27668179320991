import { request, GET, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import { push } from "react-router-redux";

import modal from "modules/stores/modal";
import listing from "../listing";
import create from "../create";

export const getDesigns = () => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setDesigns(payload.email_template_designs));
    }

    return request("email_template_designs", GET, null, onSuccess, onFailure);
  };
};

export const getTemplates = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setTemplates(payload.email_templates));
    }
    let url =
      "email_templates?template_type=html&page[number]=" +
      pageNumber +
      "&viewable=true&detail=true";
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const sortSearchUpdated = (sort, search) => {
  return async dispatch => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getTemplates(1, sort, search));
  };
};

export const createMassEmail = (name, designJson, html) => {
  return dispatch => {
    dispatch(setLoading(true));
    const body = {
      mass_lead_email: {
        title: name,
        html: html,
        design_json: designJson,
        is_legacy: false
      }
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      const massLeadEmail = payload.mass_lead_email;
      dispatch(listing.actions.addBulkMessage(massLeadEmail));
      dispatch(create.actions.reset());
      const id = massLeadEmail.id;
      const url = "/secure/bulkMessaging/editBulkEmail/" + id + "/checklist";
      dispatch(push(url));
    }

    return request("mass_lead_emails", POST, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setDesigns(designs) {
  return {
    type: actionTypes.SET_DESIGNS,
    designs
  };
}

function setTemplates(templates) {
  return {
    type: actionTypes.SET_TEMPLATES,
    templates
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder
  };
}
