import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SweetAlert from 'react-bootstrap-sweetalert'

export class AlertView extends Component {

  onCancel = () => {
    const { config } = this.props
    this.hide()
    if (config.onCancel) {
      config.onCancel()
    }
  }

  onConfirm = () => {
    const { config } = this.props
    this.hide()
    if (config.onConfirm) {
      config.onConfirm()
    }
  }

  hide = () => {
    this.props.setShowing(false)
  }

  render () {
    const { showing, config } = this.props
    return (
    <div>
      {showing &&
        <SweetAlert
            danger={config.style === 'danger'}
            warning={config.style === 'warning'}
            success={config.style === 'success'}
            showCancel={config.showCancel}
            cancelBtnBsStyle={config.cancelButtonStyle}
            confirmBtnBsStyle={config.style}
            confirmBtnText={config.confirmButtonText}
            title={config.title}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}>
            {config.children &&
              config.children
            }
          </SweetAlert>
      }
    </div>
    )
  }
}

AlertView.propTypes = {
  showing: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  setShowing: PropTypes.func.isRequired
}

AlertView.defaultProps = {
  showing: false,
  config: {
    showCancel: true,
    cancelButtonStyle: 'default',
    confirmButtonText: 'I\'m sure',
    style: 'danger',
    title: 'Are you sure?'
  }
}

export default AlertView
