import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import EditGroupView from '../components/EditGroupView'

import loadGroup from '../modules/loadGroup'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    group: loadGroup.selectors.getGroup(state),
    notFound: loadGroup.selectors.getNotFound(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    reset: actions.reset
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditGroupView))
