import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import TemplateListingView from "../components/TemplateListingView";
import session from "modules/stores/session";
import * as selectors from "../selectors";
import * as actions from "../actions";
import filters from "../../filters";

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    templates: selectors.getTemplates(state),
    templateType: filters.selectors.getSelection(state),
    pageDetails: selectors.getPageDetails(state),
    searchTerm: selectors.getSearchTerm(state),
    sortOrder: selectors.getSortOrder(state),
    userPermissionRuleSet: session.selectors.getUserPermissions(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTemplates: actions.getTemplates,
      sortSearchUpdated: actions.sortSearchUpdated
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemplateListingView)
);
