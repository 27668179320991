import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

export class PrivateRoute extends Component {

  render () {
    const { component, user } = this.props
    if (user) {
      return <Route {...this.props} component={component} />
    } else {
      return <Redirect to={{ pathname: '/login' }} />
    }
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  user: PropTypes.object
}

export default PrivateRoute
