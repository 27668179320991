import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {  } from 'react-transition-group'

import * as EventEntries from 'modules/events/eventEntries'

class SelectedEventItem extends Component {

  removeRule = () => {
    this.props.setEvent(null)
  }

  eventUpdated = (id, eventData) => {
    const { setEvent, event } = this.props
    const eventWithData = {
      ...event,
      data: eventData
    }
    setEvent(eventWithData)
  }

  render () {
    const { event } = this.props
    let EntryComponent = null
    if (event.entry) {
      EntryComponent = EventEntries[event.entry]
    }

    return (
      <div className='push-5'>
        <i className={event.icon} />{' '}
        {event.name}{' '}
        {EntryComponent &&
          <EntryComponent id={event.id} eventUpdated={this.eventUpdated} data={event.data} />
        }{' '}
        <i className='fa fa-times-circle selectable' onClick={this.removeRule} />
      </div>
    )
  }
}

SelectedEventItem.propTypes = {
  event: PropTypes.object.isRequired,
  setEvent: PropTypes.func.isRequired
}

export default SelectedEventItem
