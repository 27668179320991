import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import SelectDesignView from '../components/SelectDesignView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import create from '../../create'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state),
    legacyRecipesLoading: selectors.getLegacyRecipesLoading(state),
    errors: selectors.getErrors(state),
    legacyRecipies: selectors.getLegacyRecipes(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getLegacyRecipes: actions.getLegacyRecipes,
    createFunnel: actions.createFunnel,
    showListingView: create.actions.reset
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectDesignView))
