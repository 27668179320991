export function userRoleHasManagerPermissions (userRole) {
  return userRole.id < 4
}

export function userRoleHasOwnerPermissions (userRole) {
  return userRole.id <= 2
}

export function userRoleHasReceptionPermissions (userRole) {
  return userRole.id === 5
}

export function userCanAssign (user) {
  return user.user_permission_rule_set.allow_lead_assign === true
}

export function userCanReAssign (user) {
  return user.user_permission_rule_set.allow_lead_reassign === true
}

export function userCanSeeAllCalenders (user) {
  return user.user_permission_rule_set.allow_other_calendar === true
}

export function userHasOpenComms (user) {
  return user.user_permission_rule_set.allow_open_comms === true
}

export function userCanUseMarketing (user) {
  return user.user_permission_rule_set.allow_marketing === true
}

export function userCanAccessBilling (user) {
  switch (user.user_role.name) {
    case 'Owner':
    case 'Admin':
      return true
    default:
      return false
  }
}

export function canSwitchDashboard (user, permissions) {
  const hasManagerPermissions = userRoleHasManagerPermissions(user.user_role)
  const multiUser = permissions.multi_user
  return (hasManagerPermissions && multiUser)
}
