import conditions from './modules/conditions'
import showAudience from './modules/showAudience'

export const prefillDataForBulkSms = (bulkSms) => {
  return (dispatch) => {
    dispatch(conditions.actions.prefillDataForBulkSms(bulkSms))
  }
}

export const reset = () => {
  return (dispatch) => {
    dispatch(conditions.actions.reset())
    dispatch(showAudience.actions.reset())
  }
}
