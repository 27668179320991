import PropTypes from "prop-types";
import React, { Component } from "react";
import STRINGS from "strings";
import { errorPanelForErrors } from "utils/formHelpers";

import Modal from "@atlaskit/modal-dialog";
import "styles/modalStyles.css";

export class ModalView extends Component {
  cleanUpAndHide = () => {
    const { cleanUp, hideModal } = this.props;
    if (cleanUp) {
      cleanUp();
    }
    hideModal();
  };

  render() {
    const {
      currentModal,
      modalName,
      loading,
      loadingText,
      width,
      showCancelButton,
      actionButtonElement,
      secondaryActionButtonElement,
      secondaryActionEnabled,
      secondaryActionButtonClicked,
      modalTitle,
      readonly,
      errors
    } = this.props;
    const theme = this.props.colorThemeClass || "primary";

    const footer = () => (
      <div className="modal-footer">
        {showCancelButton && !readonly && (
          <button
            className="btn btn-default push-5-r push-10"
            type="button"
            onClick={this.cleanUpAndHide}
          >
            {STRINGS.Global["cancel"]}
          </button>
        )}
        {secondaryActionButtonElement && !readonly && (
          <button
            className="btn btn-default push-5-r push-10"
            type="button"
            disabled={!secondaryActionEnabled || loading}
            onClick={
              secondaryActionButtonClicked && secondaryActionButtonClicked
            }
          >
            {secondaryActionButtonElement}
          </button>
        )}
        {actionButtonElement && !readonly && (
          <button
            className={"btn btn-" + theme + " push-5-r push-10"}
            type="button"
            disabled={!this.props.actionEnabled || loading}
            onClick={this.props.actionButtonClicked}
          >
            {loading && (
              <span>
                <i className="fa fa-circle-o-notch fa-spin" />{" "}
                {loadingText || "Loading"}
              </span>
            )}
            {!loading && actionButtonElement}
          </button>
        )}
      </div>
    );

    return (
      <div>
        {currentModal === modalName && (
          <Modal
            onClose={this.cleanUpAndHide}
            footer={footer}
            header={null}
            width={width}
            heading={modalTitle}
          >
            <div>
              {errorPanelForErrors(errors)}
              {this.props.children}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

ModalView.propTypes = {
  colorThemeClass: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  cleanUp: PropTypes.func,
  modalTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  actionEnabled: PropTypes.bool,
  actionButtonElement: PropTypes.element,
  actionButtonClicked: PropTypes.func,
  secondaryActionEnabled: PropTypes.bool,
  secondaryActionButtonElement: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  secondaryActionButtonClicked: PropTypes.func,
  showCancelButton: PropTypes.bool,
  width: PropTypes.string.isRequired,
  loadingText: PropTypes.string,
  errors: PropTypes.object,
  readonly: PropTypes.bool.isRequired
};

ModalView.defaultProps = {
  loading: false,
  width: "medium",
  readonly: false
};

export default ModalView;
