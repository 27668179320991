import * as actionTypes from "./actionTypes";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.SET_LOADING:
        return { ...state, loading: action.loading };
      case actionTypes.SET_ERRORS:
        return { ...state, errors: action.errors };
      case actionTypes.PAGE_DETAILS_UPDATED:
        return {
          ...state,
          pageDetails: {
            pageCount: action.pageDetails.total_pages,
            currentPage: action.pageDetails.current_page,
            totalCount: action.pageDetails.total_count
          }
        };
      case actionTypes.SET_INITIAL_LOAD_COMPLETE:
        return { ...state, initialLoadComplete: action.initialLoadComplete };
      case actionTypes.SET_IS_MULTI_LOCATION_ACCOUNT:
        return {
          ...state,
          isMultiLocationAccount: action.isMultiLocationAccount
        };
      case actionTypes.SET_DEFAULT_LOCATION:
        return { ...state, defaultLocation: action.defaultLocation };
      case actionTypes.SET_LOCATIONS:
        return { ...state, locations: action.locations };
      case actionTypes.ADD_LOCATION:
        const addLocationindex = indexForLocation(state.locations, action.location.id);
        if (addLocationindex !== -1) {
          return state;
        }
        const newLocationsAfterAdd = [...state.locations, action.location];
        return { ...state, locations: newLocationsAfterAdd };
      case actionTypes.REMOVE_LOCATION:
        const removeLocationIndex = indexForLocation(state.locations, action.locationId);

        const newLocationsAfterRemove = [
          ...state.locations.slice(0, removeLocationIndex),
          ...state.locations.slice(removeLocationIndex + 1)
        ];

        return { ...state, locations: newLocationsAfterRemove };
      case actionTypes.EDIT_LOCATION:
        const index = indexForLocation(state.locations, action.location.id);

        const newLocationsAfterEdit = [
          ...state.locations.slice(0, index),
          action.location,
          ...state.locations.slice(index + 1)
        ];

        return { ...state, locations: newLocationsAfterEdit };
      case actionTypes.RESET:
        return initialState;
      default:
        return state;
    }
  }
  return state;
};
export default reducer;

function indexForLocation (locations, locationId) {
  return locations.findIndex(location => location.id === locationId)
}