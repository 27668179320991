import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  ImageEmptyView,
  SortSearchView,
  GymLeadsLoader
} from "modules/components";

import TemplateRowContainer from "../containers/TemplateRowContainer";
import PlainTextTemplateRowContainer from "../containers/PlainTextTemplateRowContainer";
import SystemTemplateRow from "./SystemTemplateRow";
import TemplatesEmpty from "images/TemplatesEmpty.jpg";

import editHtmlTemplate from "containers/editEmailTemplate/modules/editHtmlTemplate";
import editPlainTextEmailTemplate from "containers/editEmailTemplate/modules/editPlainTextTemplate";
import PagesContainer from "../containers/PagesContainer";

import filters from "../../filters";

import sortOptions from "../sortOptions";

export class TemplateListingView extends Component {
  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getTemplatesWithPageNumber(currentPage ? currentPage : 1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.templateType !== this.props.templateType) {
      const { getTemplates, templateType, sortOrder, searchTerm } = nextProps;
      getTemplates(templateType, 1, sortOrder, searchTerm);
    }
  }

  templateSelected = template => {
    let url;
    switch (template.template_type) {
      case "html":
        url =
          "/secure/templates/email/" +
          template.id +
          "/" +
          editHtmlTemplate.constants.NAME;
        break;
      case "quick_send":
        url =
          "/secure/templates/email/" +
          template.id +
          "/" +
          editPlainTextEmailTemplate.constants.NAME;
        break;
      default:
        break;
    }

    this.props.history.push(url);
  };

  getTemplatesWithPageNumber = pageNumber => {
    const { getTemplates, templateType, sortOrder, searchTerm } = this.props;
    getTemplates(templateType, pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getTemplatesWithPageNumber(pageNumber);
  };

  sortSearchUpdated = (sort, search) => {
    const { sortSearchUpdated, templateType } = this.props;
    sortSearchUpdated(templateType, sort, search);
  };

  editSystemTemplate = () => {
    const { selectedSystemTemplate } = this.props;
    const url =
      "/secure/templates/email/" +
      selectedSystemTemplate.id +
      "/" +
      editHtmlTemplate.constants.NAME;
    this.props.history.push(url);
  };

  render() {
    const {
      templates,
      templateType,
      selectedSystemTemplate,
      loading,
      sortOrder,
      searchTerm,
      userPermissionRuleSet
    } = this.props;

    const showCreatedBy =
      !!(userPermissionRuleSet && userPermissionRuleSet.view_all_marketing_assets);

    let templateRows = [];
    if (templateType === filters.constants.HTML_TEMPLATES) {
      templateRows = templates.map((template, index) => {
        return (
          <TemplateRowContainer
            key={index}
            template={template}
            onSelect={this.templateSelected}
            showCreatedBy={showCreatedBy}
          />
        );
      });
    }

    if (templateType === filters.constants.QUICK_SEND_TEMPLATES) {
      templateRows = templates.map((template, index) => {
        return (
          <PlainTextTemplateRowContainer
            key={index}
            template={template}
            onSelect={this.templateSelected}
            showCreatedBy={showCreatedBy}
          />
        );
      });
    }

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={this.sortSearchUpdated}
          sortColumnWidth={"4"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <div className="row">
          <div className="col-xs-12">
            {selectedSystemTemplate && (
              <div>
                <SystemTemplateRow
                  title={selectedSystemTemplate.title}
                  detail={selectedSystemTemplate.detail}
                  onSelect={this.editSystemTemplate}
                />
              </div>
            )}
            <GymLeadsLoader active={loading} text="Loading Templates" />
            {templates.length === 0 && !loading && (
              <ImageEmptyView
                image={TemplatesEmpty}
                title={"No Templates"}
                subtitle="Create email templates for common emails sent by your team"
              />
            )}
            {templates.length > 0 && !loading && (
              <div>
                {templateRows}
                <PagesContainer pageClicked={this.pageClicked} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

TemplateListingView.propTypes = {
  getTemplates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  templateType: PropTypes.string.isRequired,
  pageDetails: PropTypes.object.isRequired,
  selectedSystemTemplate: PropTypes.object,
  errors: PropTypes.object,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  userPermissionRuleSet: PropTypes.object.isRequired
};

export default TemplateListingView;
