import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import TagsEntryView from './TagsEntryView'

import tags from 'modules/stores/tags'

function mapStateToProps (state) {
  return {
    tags: tags.selectors.getOptions(state)
  }
}

export default withRouter(connect(mapStateToProps)(TagsEntryView))