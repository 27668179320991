import PropTypes from "prop-types";
import React, { Component } from "react";
import { numberWithCommas } from "modules/helpers/FormatHelpers";

export class CountView extends Component {
  render() {
    const { title, count, percentage, textClass } = this.props;
    return (
      <div className="block text-center block-bordered">
        <div className="font-w600 count-type">{title}</div>
        <div
          className="block-content block-content-full"
          style={{ paddingTop: "10px" }}
        >
          <h4 className={"text-" + textClass}>
            {(percentage * 100).toFixed(2)}%
          </h4>
          <p className="email-count">{numberWithCommas(count)}</p>
        </div>
      </div>
    );
  }
}

CountView.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  textClass: PropTypes.string.isRequired,
};

export default CountView;
