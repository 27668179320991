import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";
import styled from "styled-components";

import conditions from "../modules/conditions";
import audienceRecords from "../modules/audienceRecords";

import STRINGS from "strings";
const audienceStrings = STRINGS.ViewBulkSharedStrings.Audience;

export class AudienceView extends Component {
  render() {
    const { bulkSms } = this.props;
    return (
      <ContentBlockView title={audienceStrings.title} bordered>
        <Row className="row">
          <Column className="col-xs-4 options-container-left">
            <conditions.ConditionsContainer bulkSms={bulkSms} />
          </Column>
          <Column className="col-xs-8">
            <audienceRecords.AudienceRecordsContainer bulkSms={bulkSms} />
          </Column>
        </Row>
      </ContentBlockView>
    );
  }
}

AudienceView.propTypes = {
  bulkSms: PropTypes.object.isRequired
};

export default AudienceView;

const Row = styled.div`
  margin-left: -20px;
  margin-right: -20px;
`;

const Column = styled.div``;
