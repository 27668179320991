import PropTypes from "prop-types";
import React, { Component } from "react";
import STRINGS from "strings";

import listing from "../modules/listing";
import sms from "../modules/sms";
import email from "../modules/email";
import triggers from "../modules/triggers";
import schedule from "../modules/schedule";
import stepLeads from "../modules/stepLeads";
import stepAddLeads from "../modules/stepAddLeads";

import viewModule from "../modules/view";
const funnelStepsStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps;

export class StepsView extends Component {
  componentDidMount() {
    this.props.getTriggerOptions();
  }

  currentView = () => {
    const { currentView } = this.props;
    switch (currentView) {
      case viewModule.constants.LIST_STEP_VIEW:
        return <listing.ListingContainer />;
      case viewModule.constants.STEP_SMS_VIEW:
        return <sms.SmsContainer />;
      case viewModule.constants.STEP_EMAIL_VIEW:
        return <email.EmailContainer />;
      default:
        return <div />;
    }
  };

  render() {
    const {
      automationFunnel,
      selectedStepLeadsId,
      selectedStepAddLeadsId,
    } = this.props;
    return (
      <div>
        {automationFunnel.automation_state === "active" && (
          <div className="alert alert-warning">
            <p>{funnelStepsStrings.activeFunnelMessage}</p>
          </div>
        )}
        {this.currentView()}
        <triggers.TriggersContainer />
        <schedule.ScheduleContainer />
        {selectedStepLeadsId && <stepLeads.StepLeadsContainer />}
        {selectedStepAddLeadsId && <stepAddLeads.StepAddLeadsContainer />}
      </div>
    );
  }
}

StepsView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  selectedStepLeadsId: PropTypes.number,
  selectedStepAddLeadsId: PropTypes.number,
  getTriggerOptions: PropTypes.func.isRequired,
  currentView: PropTypes.string.isRequired,
};

export default StepsView;
