import PropTypes from "prop-types";
import React, { Component } from "react";
import { ModalContainer } from "modules/components";

import DealRow from "./DealRow";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class SelectDealModalView extends Component {
  componentWillMount() {
    const { deals } = this.props;
    if (deals.length > 0) {
      return;
    }
    this.getDealsWithPageNumber(1);
  }

  getDealsWithPageNumber = pageNumber => {
    const { getDeals } = this.props;
    getDeals(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getDealsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getDeals } = this.props;
    getDeals(1, searchTerm);
  };

  dealSelected = deal => {
    const { setSelectedDeal, hideModal } = this.props;
    setSelectedDeal(deal);
    hideModal();
  };

  render() {
    const {
      deals,
      selectedDealId,
      modalName,
      loading,
      pageDetails
    } = this.props;
    const pageCount = pageDetails.pageCount;

    const rows = deals.map((deal, index) => {
      const selected = selectedDealId ? selectedDealId === deal.id : false;
      return (
        <DealRow
          key={index}
          selected={selected}
          deal={deal}
          selectDeal={this.dealSelected}
        />
      );
    });

    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={"Select A Deal"}
        colorThemeClass={"primary"}
        showCancelButton
      >
        <ContentBlockView loading={loading}>
          <div className="row" style={{ marginTop: "-20px" }}>
            <TextSearchView
              sectionName={"dealSearch"}
              onUpdate={this.searchUpdated}
            />
          </div>
          {deals.length > 0 && (
            <div>
              <div className="table-responsive">
                <table className="table table-vcenter table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          )}
          {deals.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="si si-magnifier"
              title="No results"
              subtitle="Check the search field"
            />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesView
                pageDetails={pageDetails}
                pageClicked={this.pageClicked}
              />
            </div>
          )}
        </ContentBlockView>
      </ModalContainer>
    );
  }
}

SelectDealModalView.propTypes = {
  getDeals: PropTypes.func.isRequired,
  pageDetails: PropTypes.object,
  selectedDealId: PropTypes.number,
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedDeal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default SelectDealModalView;
