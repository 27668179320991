import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  ImageEmptyView,
  SortSearchView,
  GymLeadsLoader
} from "modules/components";

import FunnelRowContainer from "../containers/FunnelRowContainer";
import FunnelsEmpty from "images/AutomationsEmpty.jpg";
import PagesContainer from "../containers/PagesContainer";

import sortOptions from "../sortOptions";
import STRINGS from "strings/";

export class FunnelListingView extends Component {
  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getFunnelsWithPageNumber(currentPage ? currentPage : 1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.funnelState !== this.props.funnelState) {
      const { getFunnels, funnelState, sortOrder, searchTerm } = nextProps;
      getFunnels(funnelState, 1, sortOrder, searchTerm);
    }
  }

  funnelSelected = funnel => {
    let url = "/secure/automation/funnel/" + funnel.id + "/checklist";
    if (funnel.automation_state === "active") {
      url = "/secure/automation/funnel/" + funnel.id + "/members";
    }
    this.props.history.push(url);
  };

  getFunnelsWithPageNumber = pageNumber => {
    const { getFunnels, funnelState, sortOrder, searchTerm } = this.props;
    getFunnels(funnelState, pageNumber, sortOrder, searchTerm);
  };

  sortSearchUpdated = (sort, search) => {
    const { sortSearchUpdated, funnelState } = this.props;
    sortSearchUpdated(funnelState, sort, search);
  };

  render() {
    const { funnels, loading, sortOrder, searchTerm } = this.props;

    const funnelRows = funnels.map((funnel, index) => {
      return (
        <FunnelRowContainer
          key={index}
          funnel={funnel}
          onSelect={this.funnelSelected}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={this.sortSearchUpdated}
          sortColumnWidth={"4"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <div className="row">
          <div className="col-xs-12">
            <GymLeadsLoader
              active={loading}
              text={STRINGS.Automations.Listing.loading}
            />
            {funnels.length === 0 && !loading && (
              <ImageEmptyView
                image={FunnelsEmpty}
                title={STRINGS.Automations.Listing.emptyTitle}
                subtitle={STRINGS.Automations.Listing.emptyDetail}
              />
            )}
            {funnels.length > 0 && !loading && (
              <div>
                {funnelRows}
                <PagesContainer pageClicked={this.getFunnelsWithPageNumber} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FunnelListingView.propTypes = {
  getFunnels: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  funnels: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  funnelState: PropTypes.string.isRequired,
  pageDetails: PropTypes.object.isRequired,
  errors: PropTypes.object,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string
};

export default FunnelListingView;
