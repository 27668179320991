import * as actionTypes from './actionTypes'
import { ALL } from 'modules/rules/rules'

const SELECT_RULES_ACTION_HANDLERS = {
  [actionTypes.SET_RULES]: (state, action) => {
    return { ...state, rules: action.rules }
  },
  [actionTypes.SET_CONDITION]: (state, action) => {
    return { ...state, condition: action.condition, hasChanges: true }
  },
  [actionTypes.ADD_RULE]: (state, action) => {
    const rule = { ...action.rule.rule }
    const newRules = [
      ...state.rules,
      rule
    ]
    return { ...state, rules: newRules, hasChanges: true }
  },
  [actionTypes.UPDATE_RULE]: (state, action) => {
    const oldRules = state.rules
    const rule = oldRules[action.index]
    const newRule = {...rule, data: action.ruleData}
    const newRules = [
      ...oldRules.slice(0, action.index),
      newRule,
      ...oldRules.slice(action.index + 1)
    ]
    return { ...state, rules: newRules, hasChanges: true }
  },
  [actionTypes.REMOVE_RULE]: (state, action) => {
    let newRules = state.rules.slice();
    newRules.splice(action.index, 1);
    return { ...state, rules: newRules, hasChanges: true }
  },
  [actionTypes.SET_EVENT]: (state, action) => {
    return { ...state, event: action.event, hasChanges: true }
  },
  [actionTypes.SET_ENTRY_IS_AUTOMATIC]: (state, action) => {
    return { ...state, entryIsAutomatic: action.entryIsAutomatic, hasChanges: true }
  },
  [actionTypes.SET_HAS_CHANGES]: (state, action) => {
    return { ...state, hasChanges: action.hasChanges }
  },
  [actionTypes.RESET]: (state, action) => {
      return initialState
  }
}

const initialState = {
  condition: ALL,
  rules: [],
  event: null,
  hasChanges: false,
  entryIsAutomatic: false
}

export default function reducer (state = initialState, action) {
  const handler = SELECT_RULES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
