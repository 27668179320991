import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import HeaderView from '../components/HeaderView'

import loadBulkEmail from '../../loadBulkEmail'
import alert from 'modules/components/alert'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  const bulkEmail = loadBulkEmail.selectors.getBulkEmail(state)
  return {
    bulkEmail: bulkEmail,
    hasUnsavedChanges: selectors.hasUnsavedChanges(state),
    currentPath: state.routing.location.pathname,
    isLegacy: (bulkEmail && !bulkEmail.checklist)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showWithConfig: alert.actions.showWithConfig
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderView))
