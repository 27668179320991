import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ModalContainer, TimeSelectView } from 'modules/components'

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css'

import moment from 'moment-timezone'

import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import STRINGS from 'strings'
const scheduleSendStrings = STRINGS.BulkMessaging.BulkSms.Checklist.ScheduleSend

export class ScheduleModalView extends Component {
  state = {
    day: undefined,
    disabledDateSelected: false,
    time: {
      hour: undefined,
      adjustedHour: undefined,
      minute: undefined,
      period: undefined
    }
  }

  componentDidMount() {
    this.setInitialState()
  }

  setInitialState() {
    const { bulkSms, timezone } = this.props
    const timeMoment = bulkSms.schedule_send_time ? moment(bulkSms.schedule_send_time).tz(timezone) : moment().tz(timezone)
    const day = timeMoment.toDate()
    const time = this.timeObjectFromMoment(timeMoment)
    this.setState({ ...this.state, day: day, time: time })
  }

  timeObjectFromMoment (momentValue) {
    if (!momentValue) {
      return {
        hour: '1',
        adjustedHour: '13',
        minute: '00',
        period: 'pm'
      }
    }
    const adjustedHour = momentValue.hour()
    const hourValue = (adjustedHour < 13) ? adjustedHour : adjustedHour - 12
    const periodValue = (adjustedHour < 13) ? 'am' : 'pm'
    const minuteValue = momentValue.minute()
    return {
      hour: hourValue,
      adjustedHour: adjustedHour,
      minute: minuteValue,
      period: periodValue
    }
  }

  handleTimeChange = (timeObject) => {
    this.setState({ ...this.state, time: timeObject })
  }

  handleDayChange = (selectedDay, modifers) => {
    this.setState({ ...this.state, day: selectedDay, disabledDateSelected: modifers.disabled })
  }
  
  handleSubmit = (event) => {
    event && event.preventDefault()
    const { scheduleSend, bulkSms } = this.props
    const date = this.generateDateWithState(this.state).format()
    scheduleSend(bulkSms, date)
  }

  submitReady = () => {
    const { day, time, disabledDateSelected } = this.state
    if (!day) { return false }
    if (disabledDateSelected === true) { return false }
    if (!time) { return false }
    return true
  }
  
  generateDateWithState (state) {
    const { day, time } = this.state
    const { timezone } = this.props
    if (!day || !time) { return null }
    const { adjustedHour, minute } = time

    return moment(day).set({'hour': adjustedHour, 'minute': minute}).tz(timezone).utc()
  }

  render () {
    const { disabledDateSelected, day, time } = this.state
    const { timezone, modalName, title, buttonTitle, color } = this.props

    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={title}
        colorThemeClass={color}
        showCancelButton
        actionEnabled={this.submitReady()}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={
          <span>{buttonTitle}</span>
        }>
        <p><strong>{scheduleSendStrings.whenToSend}</strong></p>
        {disabledDateSelected &&
          <div className='alert alert-danger' >
            {scheduleSendStrings.oldDate}
          </div>
        }
        <DayPickerInput
          value={day}
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={this.handleDayChange}
          placeholder={scheduleSendStrings.selectDateButton}
          format='dddd, MMMM Do YYYY'
          dayPickerProps={{ disabledDays: {before: new Date()} }}
        />
        <p className='push-10-t'><strong>
          {scheduleSendStrings.whatTimeToSend}
        </strong></p>
        <TimeSelectView onTimeChange={this.handleTimeChange} time={time} />
        <p>{`${scheduleSendStrings.timezone} ${timezone}`}</p>
        <div className='push-100' />
      </ModalContainer>
    )
  }
}

ScheduleModalView.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  modalName: PropTypes.string.isRequired,
  scheduleSend: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  bulkSms: PropTypes.object.isRequired
}

export default ScheduleModalView
