import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import MembersView from '../components/MembersView'
import loadAutomationFunnel from '../../loadAutomationFunnel'

function mapStateToProps (state) {
  return {
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersView))
