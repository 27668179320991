import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class ContentBlockView extends Component {

  render () {
    const { title, children, header, loading, bordered, minHeight, style, blockOptions, disabled } = this.props
    const blockStyle = (bordered) ? 'block block-bordered' : 'block'
    const className = (loading) ? blockStyle + ' block-opt-refresh' : blockStyle
    const heightStyle = (minHeight) ? { minHeight: minHeight } : {}
    const pointerEventsValue = (disabled) ? 'none' : 'auto'
    return (
      <div className='row' style={{ pointerEvents: pointerEventsValue }}>
        <div className='col-xs-12'>
          <div className={className} style={heightStyle}>
            {header &&
              header
            }
            {title &&
              <div className='block-header'>
                {blockOptions &&
                  blockOptions
                }
                <h3 className='block-title pull-left'>{title}</h3>
              </div>
            }
            <div className='block-content' style={style}>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ContentBlockView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  title: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.element,
  bordered: PropTypes.bool,
  minHeight: PropTypes.number,
  blockOptions: PropTypes.element,
  style: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
}

ContentBlockView.defaultProps = {
  loading: false,
  bordered: false,
  disabled: false
}

export default ContentBlockView
