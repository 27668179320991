import { combineReducers } from 'redux'
import rules from './modules/rules'
import loadGroup from './modules/loadGroup'
import settings from './modules/settings'
import members from './modules/members'
import stats from './modules/stats'

export const reducer = combineReducers({
  [stats.constants.NAME]: stats.reducer,
  [members.constants.NAME]: members.reducer,
  [loadGroup.constants.NAME]: loadGroup.reducer,
  [rules.constants.NAME]: rules.reducer,
  [settings.constants.NAME]: settings.reducer
})
