import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { GymLeadsLoader } from 'modules/components'
import { ImageEmptyView } from 'modules/components'
import emptyImage from 'images/TemplatesEmpty.jpg'
import STRINGS from 'strings'

export class LoadTemplateView extends Component {

  componentDidMount () {
    const { getAutomationEmail, automationFunnel, automationFunnelStep } = this.props
    if (!automationFunnel || !automationFunnelStep) { return }
    getAutomationEmail(automationFunnel.id, automationFunnelStep.id)
  }

  render () {
    const loadEmailStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.LoadAutomationEmail
    const { automationFunnel, automationFunnelStep } = this.props
    if (!automationFunnel || !automationFunnelStep) {
      return (
        <ImageEmptyView
          image={emptyImage}
          title={loadEmailStrings.cantLoadEmail}
          subtitle={loadEmailStrings.cantLoadEmailMessage} />
      )
    }

    return <GymLeadsLoader text={loadEmailStrings.buttonLoadingState} active />
  }
}

LoadTemplateView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  getAutomationEmail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default LoadTemplateView
