import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import { EDIT_TRIGGER_VIEW } from "../constants";
import TriggerOption from "./TriggerOption";
import "./styles.css";

export class SelectTriggerView extends Component {
  state = {
    selectedCategory: this.props.categories[0],
  };

  setSelectedCategory = (category) => {
    this.setState({ ...this.state, selectedCategory: category });
  };

  readableCategoryName(category) {
    function titleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ");
    }
    return titleCase(category.replace("_", " "));
  }

  filteredTriggers = () => {
    const { triggerOptions } = this.props;
    const { selectedCategory } = this.state;

    return triggerOptions.filter(
      (trigger) => trigger.trigger_category === selectedCategory
    );
  };

  onTriggerSelect = (trigger) => {
    const { data, updateData, setView } = this.props;
    const { timeBefore } = data.fields;
    let newData = generateNewFormState(
      data,
      "triggerCondition",
      trigger.trigger_condition,
      true
    );

    // If we select a trigger that doesn't allow time before, switch it
    if (trigger.allow_before_time === false && timeBefore.value !== null) {
      newData = generateNewFormState(
        data,
        "triggerCondition",
        trigger.trigger_condition,
        true
      );
      newData = generateNewFormState(newData, "timeBefore", null, true);
      newData = generateNewFormState(
        newData,
        "timeToWait",
        timeBefore.value,
        true
      );
      newData = generateNewFormState(newData, "valueId", null, true);
    }
    updateData(newData);
    setView(EDIT_TRIGGER_VIEW);
  };

  render() {
    const { categories, currentTrigger, stepNumber } = this.props;

    const categoryHeadings = categories.map((category, index) => {
      const className =
        category === this.state.selectedCategory ? "active" : "selectable";
      return (
        <li className={className} key={index}>
          <div
            className="topSubButton"
            onClick={() => this.setSelectedCategory(category)}
          >
            {this.readableCategoryName(category)}
          </div>
        </li>
      );
    });

    const triggerCells = this.filteredTriggers().map((trigger, index) => {
      const selected =
        trigger.trigger_condition === currentTrigger.trigger_condition;
      const isRight = [3, 6, 9, 12, 15, 18].indexOf(index + 1) >= 0;
      return (
        <TriggerOption
          triggerOption={trigger}
          stepNumber={stepNumber}
          selected={selected}
          onSelect={this.onTriggerSelect}
          isRight={isRight}
          key={index}
        />
      );
    });

    return (
      <div className="block" style={{ maxHeight: "55vh" }}>
        <div className="nav nav-tabs nav-tabs-alt">{categoryHeadings}</div>
        <div className="triggers-list">{triggerCells}</div>
      </div>
    );
  }
}

SelectTriggerView.propTypes = {
  currentTrigger: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  setView: PropTypes.func.isRequired,
  triggerOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SelectTriggerView;
