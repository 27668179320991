import * as actionTypes from './actionTypes'

export function showModal (modalName) {
  return {
    type: actionTypes.SHOW_MODAL,
    modalName: modalName
  }
}

export function hideModal () {
  return {
    type: actionTypes.HIDE_MODAL
  }
}

export function startLoading () {
  return {
    type: actionTypes.START_LOADING,
  }
}

export function stopLoading () {
  return {
    type: actionTypes.STOP_LOADING
  }
}

export function showErrors (errors) {
  return {
    type: actionTypes.SHOW_ERRORS,
    errors
  }
}
