import { NAME } from "./constants";

export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_LEGACY_RECIPES_LOADING = NAME + "/SET_LEGACY_RECIPES_LOADING";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const SET_RECIPES = NAME + "/SET_RECIPES";
export const SET_LEGACY_RECIPES = NAME + "/SET_LEGACY_RECIPES";
export const SET_PRIVATE_RECIPE = NAME + "/SET_PRIVATE_RECIPE";
export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";
export const SET_SORT_ORDER = NAME + "/SET_SORT_ORDER";
export const SET_SEARCH_TERM = NAME + "/SET_SEARCH_TERM";
