import config from 'config'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Avatar, LeadToolTip } from 'modules/components'
import './styles.css'

import ToolTip from 'react-portal-tooltip'

export class LeadSquareView extends Component {

  state = {
    tooltipShowing: false
  }

  showToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: true })
  }

  hideToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: false })
  }

  openLead = () => {
    const { lead } = this.props
    window.open(config.mainHost + '/Secure/manageLeads?lead_id=' + lead.id, "_blank")
  }

  render () {
    const { lead } = this.props
    const { tooltipShowing } = this.state
    return (
      <span>
        <span className='group-user selectable'
          id={`lead-${lead.id}`}
          onClick={this.openLead}
          onMouseEnter={this.showToolTip}
          onMouseLeave={this.hideToolTip}>
          <Avatar size={40} name={lead.display_name} />
        </span>
        <ToolTip active={tooltipShowing} parent={`#lead-${lead.id}`} position={'top'} arrow={'center'}>
          <LeadToolTip lead={lead} />
        </ToolTip>
      </span>
    )
  }
}

LeadSquareView.propTypes = {
  lead: PropTypes.object.isRequired
}

export default LeadSquareView
