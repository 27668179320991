import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import DesignsView from "../components/DesignsView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import create from "../../create";
import userData from "modules/stores/userData";

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    designs: selectors.getDesigns(state),
    company: userData.selectors.getCompany(state),
    pageDetails: selectors.getPageDetails(state),
    searchTerm: selectors.getDesignsSearchTerm(state),
    sortOrder: selectors.getDesignsSortOrder(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDesigns: actions.getDesigns,
      createTemplateFromDesign: actions.createTemplateFromDesign,
      showListingView: create.actions.reset,
      sortSearchUpdated: actions.designsSortSearchUpdated,
      resetSortSearch: actions.resetSortSearch
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DesignsView)
);
