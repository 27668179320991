import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import AutomationsView from '../components/AutomationsView'

import modal from 'modules/stores/modal'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    shouldHideHeader: selectors.shouldHideHeader(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutomationsView))
