import * as actionTypes from './actionTypes'

const SHOW_AUDIENCE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_PREVIEW_LEADS]: (state, action) => {
    return { ...state, previewLeads: action.previewLeads }
  },
  [actionTypes.SET_TOTAL_COUNT]: (state, action) => {
    return { ...state, totalCount: action.totalCount }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  loading: false,
  previewLeads: [],
  totalCount: 0
}

export default function reducer (state = initialState, action) {
  const handler = SHOW_AUDIENCE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
