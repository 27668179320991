import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import alert from 'modules/components/alert'

import SettingsView from '../components/SettingsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import loadAutomationTextMessage from '../../loadAutomationTextMessage'
import loadAutomationFunnel from 'containers/editAutomationFunnel/modules/loadAutomationFunnel'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state),
    loading: selectors.getLoading(state),
    automationTextMessage: loadAutomationTextMessage.selectors.getAutomationTextMessage(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: loadAutomationTextMessage.selectors.currentFunnelStep(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateData: actions.updateData,
    update: actions.update,
    showAlertWithConfig: alert.actions.showWithConfig,
    deleteTextMessage: actions.deleteTextMessage
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsView))
