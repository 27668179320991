import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FlagsView from '../components/FlagsView'

import * as selectors from '../selectors'
import * as actions from '../actions'

const mapStateToProps = (state) => {
  return {
    flags: selectors.getFlags(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    removeFlagAtIndex: actions.removeFlagAtIndex
  },
    dispatch)
}

export default connect(mapStateToProps ,mapDispatchToProps)(FlagsView)
