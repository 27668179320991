import { NAME } from "./constants";

export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const SET_TEMPLATES = NAME + "/SET_TEMPLATES";
export const SET_SORT_ORDER = NAME + "/SET_SORT_ORDER";
export const SET_SEARCH_TERM = NAME + "/SET_SEARCH_TERM";

export const ADD_TEMPLATE = NAME + "/ADD_TEMPLATE";
export const REMOVE_TEMPLATE = NAME + "/REMOVE_TEMPLATE";

export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";
