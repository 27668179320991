import config from 'config'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import JourneyCell from './JourneyCell'

export class RecordRow extends Component {

  goToLead = () => {
    const { record } = this.props
    window.open(config.mainHost + '/Secure/manageLeads?lead_id=' + record.lead.id, "_blank")
  }

  render () {
    const { record } = this.props
    const lead = record.lead
    return (
      <tr className='selectable' onClick={this.goToLead}>
        <td>{lead.display_name}</td>
        <td>{lead.email}</td>
        <JourneyCell record={record} />
      </tr>
    )
  }
}

RecordRow.propTypes = {
  record: PropTypes.PropTypes.object.isRequired
}

export default RecordRow
