import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './daySelectStyles.css'

const days = [{
  id: 'mon',
  display: 'Monday'
}, {
  id: 'tue',
  display: 'Tuesday'
}, {
  id: 'wed',
  display: 'Wednesday'
}, {
  id: 'thu',
  display: 'Thursday'
}, {
  id: 'fri',
  display: 'Friday'
}, {
  id: 'sat',
  display: 'Saturday'
}, {
  id: 'sun',
  display: 'Sunday'
}]

export class DaySelect extends Component {

  handleChange = (event) => {
    const value = event.target.checked
    const fieldName = event.target.id

    const values = this.translatedData()
    if (value === true) {
      const dayString = [...values, fieldName].join(',')
      this.props.daysUpdated(dayString)
    } else {
      const index = values.indexOf(fieldName)
      const dayString = [
        ...values.slice(0, index),
        ...values.slice(index + 1)
      ].join(',')
      this.props.daysUpdated(dayString)
    }
  }

  translatedData = () => {
    const { dayString } = this.props
    if (dayString) {
      return dayString.split(',')
    }
    return []
  }

  render () {
    const values = this.translatedData()
    const headers = days.map(d => d.display).map((dayName, index) => {
      return <td key={'h-' + index} className={'day-select-header'}>{dayName}</td>
    })
    const cells = days.map(d => d.id).map((dayId, index) => {
      const checked = values.indexOf(dayId) >= 0
      return (
        <td className='text-center' key={'c-' + index}>
          <label className={'css-input css-checkbox css-checkbox-primary day-select-checkbox-label'}
            htmlFor={dayId}>
            <input
              type='checkbox'
              id={dayId}
              name={dayId}
              onChange={this.handleChange}
              checked={checked}
              style={{ display: 'table-cell' }} />
            <span />
          </label>
        </td>
      )
    })
    return (
      <div>
        <table>
          <tbody>
            <tr>
              {headers}
            </tr>
            <tr>
              {cells}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

DaySelect.propTypes = {
  dayString: PropTypes.string,
  daysUpdated: PropTypes.func.isRequired
}

export default DaySelect
