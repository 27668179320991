import { createSelector } from 'reselect'
import { NAME } from './constants'
import content from '../content'

export const getView = state => state.editAutomationFunnel.steps.stepSms[NAME].view

export const hasUnsavedChanges = createSelector(
  [ content.selectors.getHasChanges ],
  (contentHasChanges ) => {
    if (contentHasChanges) { return true }
    return false
  }
)
