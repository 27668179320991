import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import MemberRow from '../components/MemberRow'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  const isSelectedSelector = selectors.memberIdIsSelected(props.member.id)
  return {
    selected: isSelectedSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    select: actions.selectMember,
    deSelect: actions.deSelectMember,
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberRow))
