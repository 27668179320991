import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getAudienceRecordsForBulkEmail = (bulkEmail) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setAudienceRecords(payload.audience_records))
      dispatch(setLoading(false))
    }

    const url = 'mass_lead_emails/' + bulkEmail.id + '/audience_records'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setAudienceRecords (audienceRecords) {
  return {
    type: actionTypes.SET_AUDIENCE_RECORDS,
    audienceRecords
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
