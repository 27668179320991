import STRINGS from "strings/";

export function linksForUser(user) {
  const standardLinks = [
    {
      to: "/secure/bulkMessaging",
      style: "fa-envelope",
      title: STRINGS.Menu.bulkMessaging
    },
    {
      to: "/secure/templates",
      style: "fa-edit",
      title: STRINGS.Menu.templates
    },
    {
      to: "/secure/groups",
      style: "fa-group",
      title: STRINGS.Menu.groups
    },
    {
      to: "/secure/automation",
      style: "fa-cogs",
      title: STRINGS.Menu.funnels
    }
  ];
  return standardLinks;
}
