import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectLeadSourceTableView from "./TableView/SelectLeadSourceTableView";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class MultiLeadSourceSelectView extends Component {
  componentWillMount() {
    const { leadSources } = this.props;
    if (leadSources.length > 0) {
      return;
    }
    this.getLeadSourcesWithPageNumber(1);
  }

  getLeadSourcesWithPageNumber = pageNumber => {
    const { getLeadSources } = this.props;
    getLeadSources(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadSourcesWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getLeadSources } = this.props;
    getLeadSources(1, searchTerm);
  };

  render() {
    const {
      leadSources,
      pageDetails,
      loading,
      selectedLeadSourceIds,
      readonly
    } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView loading={loading}>
        <div className="row" style={{ marginTop: "-20px" }}>
          <TextSearchView
            sectionName={"leadSourceSearch"}
            onUpdate={this.searchUpdated}
          />
        </div>
        {leadSources.length > 0 && (
          <div>
            <SelectLeadSourceTableView
              leadSources={leadSources}
              selectedLeadSourceIds={selectedLeadSourceIds}
              selectLeadSource={this.props.selectLeadSource}
              deselectLeadSource={this.props.deselectLeadSource}
              readonly={readonly}
            />
          </div>
        )}
        {leadSources.length === 0 && (
          <EmptyView
            colorThemeClass="primary"
            iconClass="si si-magnifier"
            title="No results"
            subtitle="Check the search field"
          />
        )}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesView
              pageDetails={pageDetails}
              pageClicked={this.pageClicked}
            />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

MultiLeadSourceSelectView.propTypes = {
  getLeadSources: PropTypes.func.isRequired,
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedLeadSourceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectLeadSource: PropTypes.func.isRequired,
  deselectLeadSource: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired
};

MultiLeadSourceSelectView.defaultProps = {
  buttonTitle: "Filter by selected",
  readonly: false
};

export default MultiLeadSourceSelectView;
