import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {  } from 'react-transition-group'

import * as RuleEntries from 'modules/rules/ruleEntries'

class SelectedRuleItem extends Component {

  removeRule = () => {
    const { removeRuleAtIndex, index } = this.props
    removeRuleAtIndex(index)
  }

  ruleUpdated = (id, ruleData) => {
    const { ruleUpdatedWithData, index } = this.props
    ruleUpdatedWithData(index, ruleData)
  }

  render () {
    const { rule, readonly } = this.props
    let EntryComponent = null
    if (rule.entry) {
      EntryComponent = RuleEntries[rule.entry]
    }

    return (
      <div className='push-5'>
        <i className={rule.icon} />{' '}
        {rule.name}{' '}
        {EntryComponent &&
          <EntryComponent id={rule.id} ruleUpdated={this.ruleUpdated} data={rule.data} readonly={readonly} />
        }{' '}
        {!readonly &&
          <i className='fa fa-times-circle selectable' onClick={this.removeRule} />
        }
      </div>
    )
  }
}

SelectedRuleItem.propTypes = {
  rule: PropTypes.object.isRequired,
  removeRuleAtIndex: PropTypes.func,
  ruleUpdatedWithData: PropTypes.func,
  readonly: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired
}

SelectedRuleItem.defaultProps = {
  readonly: false
}

export default SelectedRuleItem
