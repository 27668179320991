import loadAutomationTextMessage from './modules/loadAutomationTextMessage'
import content from './modules/content'
import createNewTemplate from './modules/createNewTemplate'

export const reset = () => {
  return (dispatch) => {
    dispatch(loadAutomationTextMessage.actions.reset())
    dispatch(content.actions.reset())
    dispatch(createNewTemplate.actions.reset())
  }
}

export function setSelectedFunnelStepId (selectedFunnelStepId) {
  return (dispatch) => {
    dispatch(loadAutomationTextMessage.actions.setSelectedFunnelStepId(selectedFunnelStepId))
  }
}
