import { createSelector } from 'reselect'

import entryRules from './modules/entryRules'
import exitRules from './modules/exitRules'

export const getUpdateBody = createSelector(
  [ entryRules.selectors.getRules,
    entryRules.selectors.getCondition,
    entryRules.selectors.getEvent,
    entryRules.selectors.getEntryIsAutomatic,
    exitRules.selectors.getEvent,
    exitRules.selectors.getExitIsAutomatic ],
  (entryRules, entryCondition, entryEvent, entryIsAutomatic, exitEvent, exitIsAutomatic) => {
    let body = {
      entry_rules: {
        condition: entryCondition,
        rules: entryRules,
        event: entryEvent
      },
      exit_rules: {
        event: exitEvent
      },
      entry_is_automatic: entryIsAutomatic,
      exit_is_automatic: exitIsAutomatic
    }
    if (entryEvent) {
      body = { ...body, entry_event: entryEvent.id }
      if (entryEvent.data) {
        body = { ...body, entry_event_option: entryEvent.data.value }
      }
    } else {
      body = { ...body, entry_event: null }
    }

    if (exitEvent) {
      body = { ...body, exit_event: exitEvent.id }
      if (exitEvent.data) {
        body = { ...body, exit_event_option: exitEvent.data.value }
      }
    } else {
      body = { ...body, exit_event: null }
    }

    return body
  }
)
