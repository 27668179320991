export const SECONDS = 'seconds'
export const MINUTES = 'minutes'
export const HOURS = 'hours'
export const DAYS = 'days'
export const WEEKS = 'weeks'
export const MONTHS = 'months'
export const YEARS = 'years'
export const timeTypes = [SECONDS, MINUTES, HOURS, DAYS, WEEKS, MONTHS, YEARS]

export function secondsFormattedForTimeType (time, timeType) {
  switch (timeType) {
    case SECONDS:
      return time
    case MINUTES:
      return time * 60
    case HOURS:
      return time * 60 * 60
    case DAYS:
      return time * 60 * 60 * 24
    case WEEKS:
      return time * 60 * 60 * 24 * 7
    default:
      return time
  }
}

export function calculatedTimeTypeSelection (time) {
  const usePlural = time !== 1
  return timeTypes.map((timeType) => {
    const capitalized = timeType.charAt(0).toUpperCase() + timeType.slice(1)
    return {
      id: timeType,
      display: (usePlural) ? capitalized : capitalized.slice(0, -1)
    }
  })
}

export function secondsToTimeObject (seconds) {
  if (seconds % 604800 === 0) {
    return { time: seconds / 604800, selectedTimeType: WEEKS }
  } else if (seconds % 86400 === 0) {
    return { time: seconds / 86400, selectedTimeType: DAYS }
  } else if (seconds % 3600 === 0) {
    return { time: seconds / 3600, selectedTimeType: HOURS }
  } else if (seconds % 60 === 0) {
    return { time: seconds / 60, selectedTimeType: MINUTES }
  }
}
