import PropTypes from "prop-types";
import React, { Component } from "react";
import STRINGS from "strings";
import {
  ImageEmptyView,
  SortSearchView,
  GymLeadsLoader,
} from "modules/components";

import BulkMessageRowContainer from "../containers/BulkMessageRowContainer";
import emptyImage from "images/TemplatesEmpty.jpg";
import { toTitleCase } from "modules/helpers/StringHelpers";

import PagesContainer from "../containers/PagesContainer";
import sortOptions from "../sortOptions";

const listingStrings =
  STRINGS.BulkMessaging.Email.Listing.BulkMessageListingView;

export class BulkMessageListingView extends Component {
  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getBulkEmailsWithPageNumber(currentPage ? currentPage : 1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status.id !== this.props.status.id) {
      const {
        getBulkEmails,
        status,
        sortOrder,
        searchTerm,
        selectedLocations,
      } = nextProps;
      getBulkEmails(status.value, 1, sortOrder, searchTerm, selectedLocations);
    }
  }

  bulkEmailSelected = (bulkEmail) => {
    if (bulkEmail.status === "draft" || bulkEmail.status === "scheduled") {
      const url =
        "/secure/bulkMessaging/editBulkEmail/" + bulkEmail.id + "/checklist";
      this.props.history.push(url);
    } else {
      const url =
        "/secure/bulkMessaging/viewBulkEmail/" + bulkEmail.id + "/results";
      this.props.history.push(url);
    }
  };

  getBulkEmailsWithPageNumber = (pageNumber) => {
    const {
      getBulkEmails,
      status,
      searchTerm,
      sortOrder,
      selectedLocations,
    } = this.props;
    getBulkEmails(
      status.value,
      pageNumber,
      sortOrder,
      searchTerm,
      selectedLocations
    );
  };

  pageClicked = (pageNumber) => {
    this.getBulkEmailsWithPageNumber(pageNumber);
  };

  sortSearchUpdated = (sort, search) => {
    const { sortSearchUpdated, status, selectedLocations } = this.props;
    sortSearchUpdated(status.value, sort, search, selectedLocations);
  };

  handledLocationsUpdated = (locations) => {
    const {
      selectedLocationsUpdated,
      status,
      sortOrder,
      searchTerm,
    } = this.props;
    selectedLocationsUpdated(status.value, sortOrder, searchTerm, locations);
  };

  emptyTitleString = () => {
    const titleStatus = toTitleCase(this.props.status.title);
    return (
      STRINGS.Global.no +
      " " +
      titleStatus +
      " " +
      STRINGS.BulkMessaging.bulkEmails
    );
  };

  render() {
    const {
      bulkEmails,
      loading,
      sortOrder,
      searchTerm,
      selectedLocations,
      userPermissionRuleSet,
    } = this.props;

    const { view_all_marketing_assets } = userPermissionRuleSet;
    const locationFilterEnabled = !!view_all_marketing_assets;

    const rows = bulkEmails.map((bulkEmail, index) => {
      return (
        <BulkMessageRowContainer
          key={index}
          bulkEmail={bulkEmail}
          onSelect={this.bulkEmailSelected}
          showCreatedBy={locationFilterEnabled}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={this.sortSearchUpdated}
          sortColumnWidth={"4"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
          showLocationFilter={locationFilterEnabled}
          locationsUpdated={this.handledLocationsUpdated}
          initialSelectedLocations={selectedLocations}
        />
        <div className="row">
          <div className="col-xs-12">
            {bulkEmails.length === 0 && !loading && (
              <ImageEmptyView
                image={emptyImage}
                title={this.emptyTitleString()}
                subtitle={listingStrings.subtitle}
              />
            )}
            <GymLeadsLoader
              active={loading}
              text={listingStrings.loadingEmails}
            />
            {bulkEmails.length > 0 && !loading && (
              <div>
                {rows}
                <PagesContainer pageClicked={this.pageClicked} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BulkMessageListingView.propTypes = {
  getBulkEmails: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  bulkEmails: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  pageDetails: PropTypes.object.isRequired,
  errors: PropTypes.object,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  selectedLocationsUpdated: PropTypes.func,
};

export default BulkMessageListingView;
