import { request, GET, paramStringWithName } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

export const getBulkEmails = (status, pageNumber, sort, search, locations) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setBulkMessages(payload.mass_lead_emails));
    }

    let statusString;
    if (Array.isArray(status)) statusString = status.join("&status[]=");
    else statusString = status;

    let url =
      "mass_lead_emails?status[]=" +
      statusString +
      "&page[number]=" +
      pageNumber;
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    const locationsString = paramStringWithName("location_ids", locations);
    if (locationsString) {
      url = url + "&" + locationsString;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const sortSearchUpdated = (status, sort, search, locations) => {
  return async (dispatch) => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getBulkEmails(status, 1, sort, search, locations));
  };
};

export const selectedLocationsUpdated = (status, sort, search, locations) => {
  return async (dispatch) => {
    dispatch(setSelectedLocations(locations));
    dispatch(getBulkEmails(status, 1, sort, search, locations));
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setBulkMessages(bulkEmails) {
  return {
    type: actionTypes.SET_BULK_EMAILS,
    bulkEmails,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function addBulkMessage(bulkEmail) {
  return {
    type: actionTypes.ADD_BULK_EMAIL,
    bulkEmail,
  };
}

export function removeBulkMessage(bulkEmail) {
  return {
    type: actionTypes.REMOVE_BULK_EMAIL,
    bulkEmail,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm,
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder,
  };
}

function setSelectedLocations(selectedLocations) {
  return {
    type: actionTypes.SET_SELECTED_LOCATIONS,
    selectedLocations,
  };
}
