import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import HomeView from '../components/HomeView'

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    changePage: () => push('/about-us')
  }, dispatch)
}

export default withRouter(connect(null, mapDispatchToProps)(HomeView))
