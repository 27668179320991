import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class LeadLostRow extends Component {

  rowSelected = (event) => {
    const { leadLostReason, selectLeadLostReason } = this.props
    selectLeadLostReason(leadLostReason)
  }

  render () {
    const { leadLostReason, selected } = this.props
    const className = (selected) ? 'selected' : 'selectable'
    return (
      <tr className={className} onClick={() => this.rowSelected(leadLostReason)}>
        <td>{leadLostReason.lost_reason_title}</td>
      </tr>
    )
  }
}

LeadLostRow.propTypes = {
  leadLostReason: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectLeadLostReason: PropTypes.func.isRequired
}


export default LeadLostRow
