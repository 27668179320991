import { generateStore } from "utils/reduxHelpers";

const name = "deals";

const initialState = {
  deals: [],
  loading: false,
  errors: null,
  pageDetails: {}
};

export default generateStore(name, initialState);
