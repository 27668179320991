import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import TemplatesView from '../components/TemplatesView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import create from '../../create'
import userData from 'modules/stores/userData'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    templates: selectors.getTemplates(state),
    newName: create.selectors.getNewName(state),
    company: userData.selectors.getCompany(state),
    pageDetails: selectors.getTemplatePageDetails(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getTemplates: actions.getTemplates,
    createTemplate: actions.createTemplate
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplatesView))
