import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ImageEmptyView, TextSearchView } from 'modules/components'
import LeadsEmpty from 'images/LeadsEmpty.jpg'
import alert from 'modules/components/alert'

import { ADD_LEAD_MANUAL_MODAL } from '../constants'

import MembersTableView from './MembersTableView'
import PagesContainer from '../containers/PagesContainer'
import AddMemberModalContainer from '../containers/AddMemberModalContainer'

export class MembersView extends Component {

  state = {
    showingSearch: false,
    search: null
  }

  componentDidMount () {
    const { setAlertConfig } = this.props
    const alertConfig = {
      ...alert.defaultConfig,
      children: 'Are you sure you want to remove the selected leads from this group?',
      style: 'danger',
      onConfirm: this.removeLeadIds
    }
    setAlertConfig(alertConfig)
    this.getMembersWithPageNumber(1)
  }

  getMembersWithPageNumber = (pageNumber)  => {
    const { get, group } = this.props
    get(group.id, pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getMembersWithPageNumber(pageNumber)
  }

  setupRulesClicked = () => {
    const { history, group } = this.props
    const url = '/secure/groups/' + group.id + '/member_rules'
    history.push(url)
  }

  showWarning = () => {
    this.props.setAlertShowing(true)
  }

  removeLeadIds = () => {
    const { group, removeLeadIds, selectedIds } = this.props
    removeLeadIds(group.id, selectedIds)
  }

  showAddLeadPopup = () => {
    const { showModal } = this.props
    showModal(ADD_LEAD_MANUAL_MODAL)
  }

  searchUpdated = (searchTerm, fieldName) => {
    const { get, group } = this.props
    get(group.id, 1, searchTerm)
  }

  toggleSearch = () => {
    if (this.state.showingSearch === true) {
      const { pageDetails } = this.props
      this.setState({ ...this.state, search: null })
      this.getMembersWithPageNumber(pageDetails.currentPage)
    }
    this.setState({ ...this.state, showingSearch: !this.state.showingSearch })
  }

  render () {
    const { members, loading, selectedIds } = this.props
    const { search, showingSearch } = this.state
    const className = (loading) ? 'block block-bordered block-opt-refresh' : 'block block-bordered'
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className={className}>
            <div className='block-header'>
              <div className='block-options-simple'>
                <button type='button' className='pull-right btn btn-xs btn-primary'
                  onClick={this.toggleSearch}>
                  <i className='fa fa-search' />
                </button>
              </div>
              <h3 className='block-title pull-left'
                style={{
                  marginRight: '5px'
                }}>Members</h3>
                <button className='btn btn-xs btn-primary' onClick={this.showAddLeadPopup}
                  style={{ marginTop: '-5px', marginRight: '5px', marginLeft: '7px', paddingLeft: '7px', paddingRight: '7px' }}>
                  <i className='fa fa-plus' /> Add manually
                </button>
                {selectedIds.length > 0 &&
                  <button className='btn btn-xs btn-danger' onClick={this.showWarning}
                    style={{ marginTop: '-5px', marginBottom: '-3px' }}>
                    <i className='fa fa-times' /> Remove from Group
                  </button>
                }
            </div>
            <div className='block-content'>
              {showingSearch &&
                <TextSearchView
                  onUpdate={this.searchUpdated}
                  searchText={search || ''}
                  sectionName={'search'} />
              }
              {members.length > 0 &&
                <div className='table-responsive'>
                  <MembersTableView members={members} />
                  <PagesContainer pageClicked={this.pageClicked} />
                </div>
                }
                {members.length === 0 &&
                  <ImageEmptyView
                    image={LeadsEmpty}
                    height={100}
                    title={'No leads in this group yet'}
                    subtitle={'Select some rules on how leads should enter this group'}
                    buttonText='Setup rules'
                    buttonSelected={this.setupRulesClicked} />
                }
          </div>
        </div>
      </div>
      <AddMemberModalContainer />
    </div>
    )
  }
}

MembersView.propTypes = {
  group: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setAlertShowing: PropTypes.func.isRequired,
  setAlertConfig: PropTypes.func.isRequired,
  removeLeadIds: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
}

export default MembersView
