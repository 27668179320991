import PropTypes from 'prop-types'
import React, { Component } from 'react'

import GroupGrowthView from './GroupGrowthView'
import NewestMembersView from './NewestMembersView'

export class StatsView extends Component {

  componentDidMount () {
    const { getStats, group } = this.props
    getStats(group.id)
  }

  render () {
    const { newestUsers, loading, growthData } = this.props
    return (
      <div>
        <div className='row'>
          <div className='col-md-8'>
            <GroupGrowthView loading={loading} data={growthData} />
          </div>
          <div className='col-md-4'>
            <NewestMembersView loading={loading} newestUsers={newestUsers} />
          </div>
        </div>
      </div>
    )
  }
}

StatsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  growthData: PropTypes.object,
  newestUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  getStats: PropTypes.func.isRequired,
}

export default StatsView
