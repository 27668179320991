import { apiClient } from "utils/apiClient";

export const fetchAutomationFunnels = async (funnelState = 'draft', page = 1, sort = null, search = null) => {
  // Requires custom URL build
  let url = 'automation_funnels?funnel_state=' + funnelState + '&page[number]=' + page + '&design_type=user'
  if (sort) { url = url + '&sort=' + sort }
  if (search) { url = url + '&search=' + search }

  try {
    const response = await apiClient().get(url);
    return response.data
  } catch (error) {
    return error.data
  }
}