import { NAME } from './constants'

export const SET_SEARCH_TEXT = NAME + '/SET_SEARCH_TEXT'
export const SET_SEARCH_RESULTS = NAME + '/SET_SEARCH_RESULTS'
export const SET_PREVIEW_RESULT = NAME + '/SET_PREVIEW_RESULT'
export const SET_PREVIEW_HTML = NAME + '/SET_PREVIEW_HTML'
export const SET_PREVIEW_SUBJECT = NAME + '/SET_PREVIEW_SUBJECT'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const RESET = NAME + '/RESET'
