export const ANY = "any";
export const ALL = "all";

export const personalRules = [
  {
    id: "display_name",
    name: "Name",
    icon: "si si-user",
    entry: "TextEntry"
  },
  {
    id: "location",
    name: "Location is",
    icon: "fa fa-building-o",
    entry: "LocationEntry"
  },
  {
    id: "lead_source",
    name: "Lead Source is",
    icon: "si si-login",
    entry: "LeadSourceEntry"
  },
  {
    id: "user",
    name: "Assigned to",
    icon: "si si-users",
    entry: "UserEntry"
  },
  {
    id: "age",
    name: "Age",
    icon: "fa fa-birthday-cake",
    entry: "NumericEntry"
  },
  {
    id: "gender",
    name: "Gender",
    icon: "fa fa-transgender",
    entry: "GenderEntry"
  },
  {
    id: "tag",
    name: "Has a tag",
    icon: "si si-tag",
    entry: "TagsEntry"
  },
  {
    id: "dont_have_tag",
    name: "Doesn't have tag",
    icon: "si si-tag",
    entry: "TagsExcludeEntry"
  },
  {
    id: "email_bounced",
    name: "Email bounced",
    icon: "fa fa-envelope-o",
    entry: "YesNoEntry"
  },
  {
    id: "sms_bounced",
    name: "SMS bounced",
    icon: "si si-screen-smartphone",
    entry: "YesNoEntry"
  }
];

export const dateRules = [
  {
    id: "enquired",
    name: "Enquired",
    icon: "si si-calendar",
    entry: "DaysEntry"
  },
  {
    id: "last_contacted",
    name: "Last contacted",
    icon: "si si-call-end",
    entry: "DaysEntry"
  },
  {
    id: "trial_expires_at",
    name: "Trial ending in",
    icon: "fa fa-clock-o",
    entry: "TrialTimeEntry"
  },
  {
    id: "trial_starts_at",
    name: "Trial starting in",
    icon: "fa fa-clock-o",
    entry: "TrialTimeEntry"
  }
];

export const statusRules = [
  {
    id: "sales_funnel_step",
    name: "Sales funnel of",
    icon: "fa fa-filter",
    entry: "SalesFunnelStepEntry"
  },
  {
    id: "status",
    name: "Status of",
    icon: "fa fa-arrow-circle-right",
    entry: "StatusEntry"
  },
  {
    id: "trial",
    name: "Trial of",
    icon: "fa fa-clock-o",
    entry: "TrialEntry"
  },
  {
    id: "contact_call_count",
    name: "Total Calls Created",
    icon: "si si-call-end",
    entry: "NumericEntry"
  },
  {
    id: "successful_contact_call_count",
    name: "Calls Successfully Completed",
    icon: "si si-call-in",
    entry: "NumericEntry"
  },
  {
    id: "appointment_count",
    name: "Total Appointments Created",
    icon: "si si-calendar",
    entry: "NumericEntry"
  },
  {
    id: "presentation_count",
    name: "Presentations Successfully Completed",
    icon: "si si-calendar",
    entry: "NumericEntry"
  },
  {
    id: "visit_count",
    name: "Total Visitations",
    icon: "si si-pointer",
    entry: "NumericEntry"
  }
];
