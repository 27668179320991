import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'
import { validator } from '../validator'
import alert from 'modules/components/alert'

import STRINGS from 'strings'
import { FormFields, ContentBlockView } from 'modules/components'

const settingsStrings = STRINGS.BulkMessaging.BulkSms.Settings
const { TextField } = FormFields

export class ChecklistView extends Component {

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.updateData(newData)
  }

  saveTemplateClicked = () => {
    const { bulkSms, update, data } = this.props
    update(bulkSms, data)
  }

  deleteClicked = () => {
    const { showAlertWithConfig } = this.props
    const alertConfig = {
      ...alert.defaultConfig,
      children: settingsStrings.deleteAreYouSureAlert,
      style: 'danger',
      onConfirm: this.deleteBulkSms
    }
    showAlertWithConfig(alertConfig)
  }

  deleteBulkSms = () => {
    const { bulkSms, deleteBulkSms, company } = this.props
    deleteBulkSms(bulkSms, company)
  }

  render () {
    const { loading, data, bulkSms } = this.props
    const { title } = data.fields
    return (
      <div>
        <ContentBlockView title={settingsStrings.contentTitle} loading={loading} bordered disabled={bulkSms.status === 'scheduled'}>
          <div className='row'>
            <div className='col-md-6'>
              <TextField
                name='title'
                label={settingsStrings.titleLabel}
                placeholder={settingsStrings.titlePlaceholder}
                onChange={this.handleInput}
                value={title.value}
                validator={validator} />
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-4 push-20'>
              <button className='btn btn-primary' onClick={this.saveTemplateClicked}>
                <i className='fa fa-save' /> {settingsStrings.titleSaveButton}
              </button>
            </div>
          </div>
        </ContentBlockView>
        <ContentBlockView title={settingsStrings.deleteTitle} bordered>
          <div className='row'>
            <div className='col-xs-12 push-20'>
              <p>{settingsStrings.deleteDescription}</p>
              <button className='btn btn-danger' onClick={this.deleteClicked}>
                <i className='fa fa-cross' /> {settingsStrings.deleteButton}
              </button>
            </div>
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

ChecklistView.propTypes = {
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  bulkSms: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  deleteBulkSms: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default ChecklistView
