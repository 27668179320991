import { createSelector } from 'reselect'

import settings from '../settings'
import audienceConditions from '../audience/modules/conditions'

export const hasUnsavedChanges = createSelector(
  [ settings.selectors.getHasChanges,
    audienceConditions.selectors.getHasChanges],
  (settingsHaveChanges, audienceHasChanges ) => {
    if (settingsHaveChanges) { return true }
    if (audienceHasChanges) { return true }
    return false
  }
)
