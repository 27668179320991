import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import HeaderView from '../components/HeaderView'

import loadTemplate from '../../loadTemplate'
import editHtmlTemplate from '../../editHtmlTemplate'
import alert from 'modules/components/alert'
import userData from 'modules/stores/userData'

function mapStateToProps (state) {
  return {
    template: loadTemplate.selectors.getTemplate(state),
    currentPath: state.routing.location.pathname,
    company: userData.selectors.getCompany(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showWithConfig: alert.actions.showWithConfig,
    updateTemplate: editHtmlTemplate.actions.updateTemplate
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderView))
