import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";

import DealRow from "modules/components/selectDealModal/DealRow";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class EditDealTriggerValueView extends Component {
  componentWillMount() {
    const { deals, data } = this.props;
    generateNewFormState(data, "valueId", this.getInitialValue(), true);
    if (deals.length > 0) {
      return;
    }
    this.getDealsWithPageNumber(1);
  }

  getDealsWithPageNumber = pageNumber => {
    const { getDeals } = this.props;
    getDeals(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getDealsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getDeals } = this.props;
    getDeals(1, searchTerm);
  };

  handleChange = value => {
    const newData = generateNewFormState(
      this.props.data,
      "valueId",
      value.id,
      true
    );
    this.props.updateData(newData);
  };

  getInitialValue = () => {
    const { data } = this.props;
    if (data.fields.valueId.value) {
      return String(data.fields.valueId.value);
    } else {
      return null;
    }
  };

  render() {
    const { data, deals, triggerValueText, loading, pageDetails } = this.props;
    const { valueId } = data.fields;

    const rows = deals.map((deal, index) => {
      const selected = valueId
        ? parseInt(valueId.value, 10) === deal.id
        : false;
      return (
        <DealRow
          key={index}
          selected={selected}
          deal={deal}
          selectDeal={this.handleChange}
        />
      );
    });

    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView loading={loading} title={triggerValueText}>
        <div className="row" style={{ marginTop: "-20px" }}>
          <TextSearchView
            sectionName={"search"}
            onUpdate={this.searchUpdated}
          />
        </div>
        {deals.length > 0 && (
          <div>
            <div className="table-responsive">
              <table className="table table-vcenter table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        )}
        {deals.length === 0 && (
          <EmptyView
            colorThemeClass="primary"
            iconClass="si si-magnifier"
            title="No results"
            subtitle="Check the search field"
          />
        )}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesView
              pageDetails={pageDetails}
              pageClicked={this.pageClicked}
            />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

EditDealTriggerValueView.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  triggerValueText: PropTypes.string.isRequired,
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object
};

EditDealTriggerValueView.defaultProps = {
  deals: []
};

export default EditDealTriggerValueView;
