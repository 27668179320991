import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import alert from "modules/components/alert";
import ToolTip from "react-portal-tooltip";
import STRINGS from "strings";
import { FormFields, ContentBlockView } from "modules/components";
const { Checkbox } = FormFields;
const smsSettingsStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Settings;

export class ChecklistView extends Component {
  state = {
    tooltipShowing: false
  };

  handleInput = (fieldName, value, isValid) => {
    const { updateData, data } = this.props;
    const newData = generateNewFormState(data, fieldName, value, isValid);
    updateData(newData);
  };

  saveTemplateClicked = () => {
    const {
      automationTextMessage,
      automationFunnel,
      automationFunnelStep,
      update,
      data
    } = this.props;
    update(
      automationFunnel.id,
      automationFunnelStep.id,
      automationTextMessage.id,
      data
    );
  };

  handleToggle = (fieldName, value) => {
    const { updateData, data } = this.props;
    const communicationType = value ? "transactional" : "marketing";
    const newData = generateNewFormState(
      data,
      fieldName,
      communicationType,
      true
    );
    updateData(newData);
  };

  deleteClicked = () => {
    const { showAlertWithConfig } = this.props;
    const alertConfig = {
      ...alert.defaultConfig,
      children: smsSettingsStrings.deleteWarning,
      style: "danger",
      onConfirm: this.deleteBulkTextMessage
    };
    showAlertWithConfig(alertConfig);
  };

  deleteBulkTextMessage = () => {
    const {
      automationTextMessage,
      automationFunnel,
      automationFunnelStep,
      deleteTextMessage
    } = this.props;
    deleteTextMessage(
      automationFunnel.id,
      automationFunnelStep.id,
      automationTextMessage.id
    );
  };

  showToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: true });
  };

  hideToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: false });
  };

  render() {
    const { loading, data } = this.props;
    const { communicationType } = data.fields;
    const { tooltipShowing } = this.state;
    return (
      <div>
        <ContentBlockView title={"Send Settings"} loading={loading} bordered>
          <div className="row push">
            <div className="col-md-6">
              <Checkbox
                name="communicationType"
                label={smsSettingsStrings.transactionalCheckboxLabel}
                onChange={this.handleToggle}
                switchStyle="primary"
                display={"inline"}
                checked={communicationType.value === "transactional"}
              />
              <i
                className="fa fa-question-circle selectable"
                style={{ marginLeft: "10px" }}
                id={"tooltip"}
                onMouseEnter={this.showToolTip}
                onMouseLeave={this.hideToolTip}
              >
                <ToolTip
                  active={tooltipShowing}
                  parent={"#tooltip"}
                  position={"top"}
                  arrow={"center"}
                >
                  <div style={{ width: "300px" }}>
                    <p>
                      {smsSettingsStrings.transactionalCheckboxMessage}
                      <a href="http://support.gymleads.net/gymleads-marketing-app/transactional-vs-marketing-communication">
                        {smsSettingsStrings.transactionalCheckboxLearnMore}
                      </a>
                    </p>
                  </div>
                </ToolTip>
              </i>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4 push-20">
              <button
                className="btn btn-primary"
                onClick={this.saveTemplateClicked}
              >
                <i className="fa fa-save" /> {smsSettingsStrings.saveButton}
              </button>
            </div>
          </div>
        </ContentBlockView>
        <ContentBlockView title={smsSettingsStrings.deleteTitle} bordered>
          <div className="row">
            <div className="col-xs-12 push-20">
              <p>{smsSettingsStrings.deleteButtonDescription}</p>
              <button className="btn btn-danger" onClick={this.deleteClicked}>
                <i className="fa fa-cross" /> {smsSettingsStrings.deleteButton}
              </button>
            </div>
          </div>
        </ContentBlockView>
      </div>
    );
  }
}

ChecklistView.propTypes = {
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  deleteTextMessage: PropTypes.func.isRequired,
  automationTextMessage: PropTypes.object.isRequired,
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  errors: PropTypes.object
};

export default ChecklistView;
