import { request, PUT, DELETE } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { fieldNames } from './constants'
import { push } from 'react-router-redux'
import flags from 'modules/components/flags'

import { formDataFromData } from 'utils/formHelpers'

import loadBulkSms from '../loadBulkSms'

import STRINGS from 'strings'
const settingsStrings = STRINGS.BulkMessaging.BulkSms.Settings

export function prefillDataForBulkSms (sms) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_SMS,
    sms
  }
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}

export const update = (bulkSms, formData) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const body = {
      mass_lead_text_message: formDataFromData(formData, fieldNames)
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(loadBulkSms.actions.setBulkSms(payload.mass_lead_text_message))
      dispatch(setLoading(false))
      dispatch(setHasChanges(false))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: settingsStrings.updatedSmsNotification,
        index: 0
      }))
    }

    const url = 'mass_lead_text_messages/' + bulkSms.id
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

export const deleteBulkSms = (bulkSms) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(push('/secure/bulkMessaging'))
      dispatch(setLoading(false))
      dispatch(setHasChanges(false))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: settingsStrings.deletedSmsNotification,
        index: 0
      }))
    }

    const url = 'mass_lead_text_messages/' + bulkSms.id
    return request(url, DELETE, null, onSuccess, onFailure)
  }
}
