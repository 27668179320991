import { request, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

export const fetchRecords = (bulkEmailId, pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    dispatch(setSelectedRecordId(null));
    dispatch(setSelectedReply(null));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setRecords(payload.leads_mass_lead_emails));
    }

    let url =
      "mass_lead_emails/" +
      bulkEmailId +
      "/leads_mass_lead_emails?lead_replied=true&page[number]=" +
      pageNumber;
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const fetchLeadEmail = (leadId, leadEmailId) => {
  return dispatch => {
    dispatch(setLoadingReply(true));
    function onFailure(payload) {
      dispatch(setLoadingReply(false));
      dispatch(setSelectedRecordId(null));
      dispatch(setSelectedReply(null));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoadingReply(false));
      dispatch(setSelectedReply(payload.email));
    }

    let url = `leads/${leadId}/lead_emails/${leadEmailId}`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setRecords(records) {
  return {
    type: actionTypes.SET_RECORDS,
    records
  };
}

export function setSelectedRecordId(id) {
  return {
    type: actionTypes.SET_SELECTED_RECORD_ID,
    id
  };
}

export function setSelectedReply(selectedReply) {
  return {
    type: actionTypes.SET_SELECTED_REPLY,
    selectedReply
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setLoadingReply(loadingReply) {
  return {
    type: actionTypes.SET_LOADING_REPLY,
    loadingReply
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}
