import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView, SimpleImageEmptyView } from 'modules/components'

import EmptyGraph from 'images/EmptyGraph.jpg'
import { Line } from 'react-chartjs-2';

export class GroupGrowthView extends Component {

  render () {
    const { data } = this.props
    return (
      <ContentBlockView title={'Group Growth'} bordered minHeight={400}>
        {data &&
          <Line data={data} />
        }
        {!data &&
          <SimpleImageEmptyView
            image={EmptyGraph}
            title={'Looks like this is empty'}
            subtitle={'We\'ll update this when we have some data to show you'}
            height={200}
           />
        }
      </ContentBlockView>
    )
  }
}

GroupGrowthView.propTypes = {
  data: PropTypes.object
}

export default GroupGrowthView
