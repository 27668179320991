import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const RESET = NAME + '/RESET'

export const SET_LOCATIONS = NAME + '/SET_LOCATIONS'

export const ADD_LOCATION = NAME + '/ADD_LOCATION'
export const REMOVE_LOCATION = NAME + '/REMOVE_LOCATION'
export const EDIT_LOCATION = NAME + '/EDIT_LOCATION'

export const SET_DEFAULT_LOCATION = NAME + '/SET_DEFAULT_LOCATION'
export const SET_IS_MULTI_LOCATION_ACCOUNT = NAME + '/SET_IS_MULTI_LOCATION_ACCOUNT'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
export const SET_INITIAL_LOAD_COMPLETE = NAME + '/SET_INITIAL_LOAD_COMPLETE'
