import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { LeadStatusTag } from 'modules/components'

export class ActiveLeadTableRow extends Component {

  render () {
    const { lead } = this.props
    return (
      <tr>
        <td>{lead.display_name}</td>
        <td><LeadStatusTag leadStatus={lead.status}/></td>
        <td>{lead.location_name}</td>
      </tr>
    )
  }
}

ActiveLeadTableRow.propTypes = {
  lead: PropTypes.PropTypes.object.isRequired
}

export default ActiveLeadTableRow
