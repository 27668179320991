import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { GymLeadsLoader } from 'modules/components'
import STRINGS from 'strings'

export class LoadAutomationFunnelView extends Component {

  componentDidMount () {
    const { getAutomationFunnel, match } = this.props
    getAutomationFunnel(match.params.id)
  }

  render () {
    return <GymLeadsLoader text={STRINGS.Funnels.EditAutomationFunnel.loading} active />
  }
}

LoadAutomationFunnelView.propTypes = {
  getAutomationFunnel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default LoadAutomationFunnelView
