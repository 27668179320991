import PropTypes from "prop-types";
import React, { Component } from "react";
import alert from "modules/components/alert";
import "./styles.css";
import STRINGS from "strings";

import StepAudiencePreviewView from "./StepAudiencePreviewView";

import { ContentBlockView } from "modules/components";
import { UPDATE_TRIGGER_MODAL_NAME } from "../../triggers/constants";
import { UPDATE_SCHEDULE_MODAL_VIEW } from "../../schedule/constants";

import view from "../../view";

export class StepRow extends Component {
  deleteSelected = () => {
    const { showAlertWithConfig } = this.props;
    const alertConfig = {
      ...alert.defaultConfig,
      children: STRINGS.Funnels.Steps.Checklist.deleteStepModalConfirmation,
      style: "danger",
      onConfirm: this.deleteStep,
    };
    showAlertWithConfig(alertConfig);
  };

  deleteStep = () => {
    const { deleteStep, step, automationFunnel } = this.props;
    deleteStep(automationFunnel.id, step.id);
  };

  editTriggerClicked = () => {
    const {
      setSelectedTriggerStepId,
      step,
      prefillDataForTrigger,
      showModal,
    } = this.props;
    setSelectedTriggerStepId(step.id);
    prefillDataForTrigger(step.trigger);
    showModal(UPDATE_TRIGGER_MODAL_NAME);
  };

  editScheduleClicked = () => {
    const {
      setSelectedScheduleStepId,
      step,
      prefillDataForSchedule,
      showModal,
    } = this.props;
    setSelectedScheduleStepId(step.id);
    prefillDataForSchedule(step.automation_funnel_step_schedule);
    showModal(UPDATE_SCHEDULE_MODAL_VIEW);
  };

  editEmailSelected = () => {
    const { setSelectedEmailStepId, step, setView } = this.props;
    setSelectedEmailStepId(step.id);
    setView(view.constants.STEP_EMAIL_VIEW);
  };

  editSmsSelected = () => {
    const { setSelectedSmsStepId, step, setView } = this.props;
    setSelectedSmsStepId(step.id);
    setView(view.constants.STEP_SMS_VIEW);
  };

  timeString = () => {
    const trigger = this.props.step.trigger;
    const { time_type, time_to_wait, time_before } = trigger;
    if (time_before) {
      const adjustedTimeType =
        time_before > 1 ? time_type : time_type.slice(0, -1);
      return time_before + " " + adjustedTimeType + " before " + trigger.title;
    } else {
      const adjustedTimeType =
        time_to_wait > 1 ? time_type : time_type.slice(0, -1);
      return time_to_wait + " " + adjustedTimeType + " after " + trigger.title;
    }
  };

  render() {
    const {
      step,
      automationFunnel,
      showModal,
      setSelectedStepLeadsStepId,
      setSelectedStepAddLeadsStepId,
    } = this.props;
    const { checklist } = step;
    const blockOptions =
      automationFunnel.automation_state !== "active" ? (
        <button
          type="button"
          className="pull-right btn btn-xs btn-danger"
          onClick={this.deleteSelected}
        >
          <i className="fa fa-times" />
        </button>
      ) : null;
    return (
      <ContentBlockView title={step.name} blockOptions={blockOptions}>
        <div className="step-row">
          <p>
            <strong>{STRINGS.Funnels.Steps.triggers}: </strong>
            {checklist.trigger_ready && (
              <span className="text-success">
                {STRINGS.Funnels.Steps.Checklist.setAndReady}:{" "}
              </span>
            )}
            {!checklist.trigger_ready && (
              <span className="text-warning">
                {STRINGS.Funnels.Steps.Checklist.noFunnelGroupAssigned}:{" "}
              </span>
            )}
            {this.timeString()}
            <button
              className="linkButton"
              onClick={this.editTriggerClicked}
              style={{ paddingLeft: "5px" }}
            >
              {" "}
              <i className="fa fa-bolt" /> {STRINGS.Global.edit}
            </button>
          </p>
          <p>
            <strong>{STRINGS.Global.email}:</strong>{" "}
            {step.automation_email_template_id && checklist.email_ready && (
              <span className="text-success">
                {STRINGS.Funnels.Steps.Checklist.setAndReady}:{" "}
              </span>
            )}
            {step.automation_email_template_id && !checklist.email_ready && (
              <span className="text-warning">
                {STRINGS.Funnels.Steps.Checklist.setButIncomplete}
              </span>
            )}
            {!step.automation_email_template_id && (
              <span>{STRINGS.Funnels.Steps.Checklist.noneSet}</span>
            )}{" "}
            <button
              className="linkButton"
              onClick={this.editEmailSelected}
              style={{ paddingLeft: "5px" }}
            >
              {" "}
              <i className="fa fa-envelope" /> {STRINGS.Global.edit}
            </button>
          </p>
          <p>
            <strong>{STRINGS.Global.SMS}:</strong>{" "}
            {step.automation_text_message_template_id &&
              checklist.sms_ready && (
                <span className="text-success">
                  {STRINGS.Funnels.Steps.Checklist.setAndReady}
                </span>
              )}
            {step.automation_text_message_template_id &&
              !checklist.sms_ready && (
                <span className="text-warning">
                  {STRINGS.Funnels.Steps.Checklist.setButIncomplete}
                </span>
              )}
            {!step.automation_text_message_template_id && (
              <span>{STRINGS.Funnels.Steps.Checklist.noneSet}</span>
            )}{" "}
            <button
              className="linkButton"
              onClick={this.editSmsSelected}
              style={{ paddingLeft: "5px" }}
            >
              {" "}
              <i className="fa fa-mobile" /> {STRINGS.Global.edit}
            </button>
          </p>
          <p>
            <strong>{STRINGS.Funnels.Steps.schedule}:</strong>{" "}
            {step.automation_funnel_step_schedule && (
              <span>
                {STRINGS.Funnels.Steps.Checklist.sendOnlyAtSpecificTimes}
              </span>
            )}
            {!step.automation_funnel_step_schedule && (
              <span>{STRINGS.Funnels.Steps.Checklist.sendAtAnyTime}</span>
            )}{" "}
            <button
              className="linkButton"
              onClick={this.editScheduleClicked}
              style={{ paddingLeft: "5px" }}
            >
              {" "}
              <i className="fa fa-clock-o" /> {STRINGS.Global.edit}
            </button>
          </p>
          {automationFunnel.automation_state === "active" && (
            <StepAudiencePreviewView
              automationFunnelStep={step}
              setSelectedStepLeadsStepId={setSelectedStepLeadsStepId}
              setSelectedStepAddLeadsStepId={setSelectedStepAddLeadsStepId}
              showModal={showModal}
            />
          )}
        </div>
      </ContentBlockView>
    );
  }
}

StepRow.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  prefillDataForTrigger: PropTypes.func.isRequired,
  deleteStep: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  setSelectedTriggerStepId: PropTypes.func.isRequired,
  setSelectedSmsStepId: PropTypes.func.isRequired,
  setSelectedEmailStepId: PropTypes.func.isRequired,
  setSelectedStepLeadsStepId: PropTypes.func.isRequired,
  setSelectedStepAddLeadsStepId: PropTypes.func.isRequired,
  setSelectedScheduleStepId: PropTypes.func.isRequired,
  prefillDataForSchedule: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
};

export default StepRow;
