import PropTypes from "prop-types";
import React, { Component } from "react";
import { ImageEmptyView, SortSearchView } from "modules/components";
import LeadsEmpty from "images/LeadsEmpty.jpg";

import RecordsTableView from "./RecordsTableView";
import PagesContainer from "../containers/PagesContainer";
import EmailReplyPreview from "./EmailReplyPreview";
import sortOptions from "../sortOptions";

import STRINGS from "strings";
const repliesStrings = STRINGS.ViewBulkSharedStrings.RepliesView;

export class RepliesView extends Component {
  state = {
    sortOrder: sortOptions[1].id,
    searchTerm: null
  };

  componentDidMount() {
    this.getRecordsWithPageNumber(1);
  }

  sortSearchUpdated = (sort, search) => {
    const { bulkEmail, fetchRecords } = this.props;
    this.setState(
      { sortOrder: sort, searchTerm: search },
      fetchRecords(bulkEmail.id, 1, sort, search)
    );
  };

  getRecordsWithPageNumber = pageNumber => {
    const { sortOrder, searchTerm } = this.state;
    const { bulkEmail, fetchRecords } = this.props;
    fetchRecords(bulkEmail.id, pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getRecordsWithPageNumber(pageNumber);
  };

  recordSelected = record => {
    const { fetchReply, setSelectedRecordId } = this.props;
    if (!record.lead) return;
    setSelectedRecordId(record.id);
    fetchReply(record.lead.id, record.latest_reply_lead_email_id);
  };

  render() {
    const { sortOrder, searchTerm } = this.state;
    const {
      records,
      loading,
      loadingReply,
      selectedReply,
      selectedRecordId,
      timezone
    } = this.props;

    const classNameRepliesList = loading
      ? "block block-bordered block-opt-refresh"
      : "block block-bordered";

    const classNameReplyPreview = loadingReply
      ? "block block-bordered block-opt-refresh"
      : "block block-bordered";

    const selectedRecord =
      selectedRecordId &&
      records &&
      records.find(record => record.id === selectedRecordId);

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={this.sortSearchUpdated}
          sortColumnWidth={"4"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className={classNameRepliesList}>
              <div className="block-header">
                <h3
                  className="block-title pull-left"
                  style={{
                    marginRight: "5px"
                  }}
                >
                  {repliesStrings.title}
                </h3>
              </div>
              <div className="block-content">
                {records.length > 0 && (
                  <div className="table-responsive">
                    <RecordsTableView
                      records={records}
                      onRecordSelected={this.recordSelected}
                      selectedRecordId={selectedRecordId}
                    />
                    <PagesContainer pageClicked={this.pageClicked} />
                  </div>
                )}
                {records.length === 0 && (
                  <ImageEmptyView
                    image={LeadsEmpty}
                    height={100}
                    title={repliesStrings.noResultsTitle}
                    subtitle={repliesStrings.noResultsSubtitle}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className={classNameReplyPreview}>
              <div className="block-header">
                <h3
                  className="block-title pull-left"
                  style={{
                    marginRight: "5px"
                  }}
                >
                  {repliesStrings.RepliesDetailView.title}
                </h3>
              </div>
              <div className="block-content">
                {selectedReply && (
                  <EmailReplyPreview
                    reply={selectedReply}
                    leadName={
                      selectedRecord && selectedRecord.lead.display_name
                    }
                    leadId={selectedRecord && selectedRecord.lead.id}
                    timezone={timezone}
                  />
                )}
                {!selectedReply && (
                  <ImageEmptyView
                    image={LeadsEmpty}
                    height={100}
                    title={repliesStrings.RepliesDetailView.noResultsTitle}
                    subtitle={
                      repliesStrings.RepliesDetailView.noResultsSubtitle
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RepliesView.propTypes = {
  bulkEmail: PropTypes.object.isRequired,
  fetchRecords: PropTypes.func.isRequired,
  setSelectedRecordId: PropTypes.func.isRequired,
  fetchReply: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRecordId: PropTypes.number,
  selectedReply: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired
};

export default RepliesView;
