import { NAME } from "./constants";

export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const SET_FUNNELS = NAME + "/SET_FUNNELS";
export const SET_SORT_ORDER = NAME + "/SET_SORT_ORDER";
export const SET_SEARCH_TERM = NAME + "/SET_SEARCH_TERM";
export const RESET = NAME + "/RESET";

export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";

export const ADD_FUNNEL = NAME + "/ADD_FUNNEL";
export const REMOVE_FUNNEL = NAME + "/REMOVE_FUNNEL";
