import PropTypes from "prop-types";
import React, { Component } from "react";

export class DealRow extends Component {
  rowSelected = event => {
    const { deal, selectDeal } = this.props;
    selectDeal(deal);
  };

  render() {
    const { deal, selected } = this.props;
    const className = selected ? "selected" : "selectable";
    return (
      <tr className={className} onClick={() => this.rowSelected(deal)}>
        <td>{deal.title}</td>
      </tr>
    );
  }
}

DealRow.propTypes = {
  deal: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectDeal: PropTypes.func.isRequired
};

export default DealRow;
