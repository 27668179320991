import STRINGS from 'strings'

export const NAME = 'funnelState'
export const DRAFT = 'draft'
export const ACTIVE = 'active'

export const selectOptions = [{
  id: DRAFT,
  title: STRINGS.Global.draft,
  icon: 'fa fa-edit'
}, {
  id: ACTIVE,
  title: STRINGS.Global.active,
  icon: 'fa fa-rocket'
}]
