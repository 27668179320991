import * as actionTypes from './actionTypes'

const EDIT_TEMPLATE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  },
  [actionTypes.PREFIL_FOR_TEMPLATE]: (state, action) => {
    return { ...state, text: action.template.text, subject: action.template.subject }
  },
  [actionTypes.SET_MERGE_TAGS]: (state, action) => {
    return { ...state, mergeTags: action.mergeTags }
  }
}

const initialState = {
  loading: false,
  errors: null,
  mergeTags: [],
  previewText: null
}

export default function (state = initialState, action) {
  const handler = EDIT_TEMPLATE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
