import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class TextArea extends Component {

  state = {
    errorMessage: null,
    style: '',
    valid: (this.props.validator !== null)
  }

  handleChange = (event) => {
    const textValue = event.target.value
    let style = ''
    const errorMessage = this.errorMessageForValue(textValue)
    const newIsValidValue = (errorMessage === null)

    if (!newIsValidValue) {
      style = 'has-error'
    }

    this.setState({ ...this.state, errorMessage: errorMessage, style: style, valid: newIsValidValue })
    this.props.onChange(this.props.name, textValue, newIsValidValue)
    if (this.props.autoResize === true) { this.autoResize(event.target) }
  }

  autoResize = (target) => {
    target.style.height = 'inherit'
    target.style.height = target.scrollHeight + 'px'
  }

  handleOnFocus = (event) => {
    const { onFocus, name } = this.props
    if (onFocus) {
      onFocus(name)
    }
  }

  handleOnBlur = (event) => {
    const { onBlur, name } = this.props
    if (onBlur) {
      onBlur(name)
    }
  }

  onSelect = (event) => {
    const { name, cursorUpdated } = this.props
    if (cursorUpdated) {
      cursorUpdated(name, event.target.selectionStart)
    }
  }

  errorMessageForValue = (value) => {
    const { name, validator } = this.props
    if (!validator) return null

    let errorMessage = null
    const validations = validator[name]
    validations.forEach(function (validation) {
      const isValid = validation.rule(value)
      if (!isValid) {
        errorMessage = validation.errorMessage
      }
    })
    return errorMessage
  }

  render () {
    const { name, disabled, formFieldStyle, label, placeholder, value, needsFormGroup, rows, maxLength } = this.props
    const { style, errorMessage } = this.state
    const input = (value !== null) ? value : ''
    const showFormGroup = needsFormGroup !== false
    const isDisabled = disabled === true
    const formStyle = (formFieldStyle) || ''

    const textField = () => {
      return (
        <div className={formStyle}>
          {label && label.length > 0 &&
            <label htmlFor={name}>{label}</label>
          }
          <textarea
            className='form-control'
            id={name}
            maxLength={maxLength}
            placeholder={placeholder}
            onChange={this.handleChange}
            ref={name}
            rows={rows}
            disabled={isDisabled}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            onSelect={this.onSelect}
            value={input}/>
          <span id={name + '-error-message'} className='help-block'>{errorMessage}</span>
        </div>
      )
    }
    return (
      <div>
        {showFormGroup &&
          <div className={'form-group ' + style}>
            <div className='col-xs-12'>
              {textField()}
            </div>
          </div>
        }
        {showFormGroup === false &&
          textField()
        }
      </div>
    )
  }
}

TextArea.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number.isRequired,
  formFieldStyle: PropTypes.string,
  disabled: PropTypes.bool,
  validator: PropTypes.object,
  value: PropTypes.string,
  needsFormGroup: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  cursorUpdated: PropTypes.func,
  autoResize: PropTypes.bool
}

TextArea.defaultProps = {
  autoResize: false
}

export default TextArea
