import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { errorPanelForErrors } from 'utils/formHelpers'
import { ContentBlockView } from 'modules/components'

import conditions from '../modules/conditions'
import showAudience from '../modules/showAudience'

import STRINGS from 'strings'
const bulkEmailAudienceStrings = STRINGS.SetAudience
export class AudienceView extends Component {

  saveAudienceClicked = () => {
    const { update, bulkEmail, selectedGroup, entryRulesJson } = this.props
    const selectedGroupId = (selectedGroup) ? selectedGroup.id : null
    update(bulkEmail, selectedGroupId, entryRulesJson.entry_rules)
  }

  render () {
    const { bulkEmail, errors } = this.props
    return (
      <div>
        <ContentBlockView title={'Set Audience'} bordered disabled={bulkEmail.status === 'scheduled'}>
          {errorPanelForErrors(errors)}
          <div className='row' style={{ marginLeft: '-20px', marginRight: '-20px' }}>
            <div className='col-xs-4 options-container-left'>
              <conditions.ConditionsContainer bulkEmail={bulkEmail} />
            </div>
            <div className='col-xs-8'>
              <showAudience.ShowAudienceContainer />
            </div>
          </div>
        </ContentBlockView>
        <button className='btn btn-primary '
          onClick={this.saveAudienceClicked}>
          <i className='fa fa-save' />  {bulkEmailAudienceStrings.saveAudienceButton}
        </button>
      </div>
    )
  }
}

AudienceView.propTypes = {
  bulkEmail: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  entryRulesJson: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default AudienceView
