import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import login from "containers/login";
import dashboard from "containers/dashboard";
import articles from "containers/articles";

import automations from "containers/automations";
import editAutomationFunnel from "containers/editAutomationFunnel";

import groups from "containers/groups";
import editGroup from "containers/editGroup";

import templates from "containers/templates";
import editEmailTemplate from "containers/editEmailTemplate";
import editSmsTemplate from "containers/editSmsTemplate";

import bulkMessaging from "containers/bulkMessaging";
import editBulkSms from "containers/editBulkSms";
import editBulkEmail from "containers/editBulkEmail";
import viewBulkEmail from "containers/viewBulkEmail";
import viewBulkSms from "containers/viewBulkSms";

import alert from "modules/components/alert";
import flags from "modules/components/flags";
import multiUserSelect from "modules/components/multiUserSelect";

import stores from "modules/stores";

const reducers = {
  [alert.constants.NAME]: alert.reducer,
  [flags.constants.NAME]: flags.reducer,
  [login.constants.NAME]: login.reducer,
  [articles.constants.NAME]: articles.reducer,
  [automations.constants.NAME]: automations.reducer,
  [editAutomationFunnel.constants.NAME]: editAutomationFunnel.reducer,
  [dashboard.constants.NAME]: dashboard.reducer,
  [groups.constants.NAME]: groups.reducer,
  [editGroup.constants.NAME]: editGroup.reducer,
  [templates.constants.NAME]: templates.reducer,
  [editEmailTemplate.constants.NAME]: editEmailTemplate.reducer,
  [editSmsTemplate.constants.NAME]: editSmsTemplate.reducer,
  [bulkMessaging.constants.NAME]: bulkMessaging.reducer,
  [editBulkSms.constants.NAME]: editBulkSms.reducer,
  [editBulkEmail.constants.NAME]: editBulkEmail.reducer,
  [viewBulkEmail.constants.NAME]: viewBulkEmail.reducer,
  [viewBulkSms.constants.NAME]: viewBulkSms.reducer,
  [multiUserSelect.constants.NAME]: multiUserSelect.reducer
};

Object.keys(stores).forEach(key => {
  const store = stores[key];
  reducers[store.constants.NAME] = store.reducer;
});

export default function createReducer(asyncReducers) {
  return combineReducers({
    routing: routerReducer,
    ...reducers,
    ...asyncReducers
  });
}
