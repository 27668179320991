import * as actionTypes from './actionTypes'

export const showFlag = (flag, timeout) => {
  return (dispatch) => {
    dispatch(addFlag(flag))
    setTimeout(() => {
      dispatch(removeFlagAtIndex(flag.index))
    }, 2500)
  }
}

function addFlag (flag) {
  return {
    type: actionTypes.ADD_FLAG,
    flag
  }
}

export function removeFlagAtIndex (index) {
  return {
    type: actionTypes.REMOVE_FLAG_AT_INDEX,
    index
  }
}

export function clearAllFlags () {
  return {
    type: actionTypes.CLEAR_ALL_FLAGS
  }
}
