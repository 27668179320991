import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
import { ContentBlockView } from 'modules/components'
import STRINGS from 'strings'
const statusStrings = STRINGS.ViewBulkSharedStrings.Statuses
const graphStrings = STRINGS.ViewBulkSms.Results.Graph

export class GraphView extends Component {

  formattedChartData = () => {
    const { formattedStatistics } = this.props
    const { pending, delivered, bounce, click } = formattedStatistics
    return [pending.count, delivered.count, click.count, bounce.count]
  }

  render () {
    const chartData = {
      labels: [statusStrings.pending, statusStrings.delivered, statusStrings.clicked, statusStrings.bounced],
      datasets: [
        {
          label: graphStrings.graphTitle,
          backgroundColor: [
            'rgba(101, 176, 232, 0.5)',
            'rgba(92, 144, 210, 0.5)',
            'rgba(68, 180, 166, 0.5)',
            'rgba(210, 106, 92, 0.5)',
          ],
          borderColor: [
            '#70b9eb',
            '#5281BC',
            '#44b4a6',
            '#BE6053',
          ],
          borderWidth: 1,
          data: this.formattedChartData()
        }
      ]
    }
    const options = {
      legend: { display: false },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }

    return (
      <ContentBlockView title={graphStrings.panelTitle} bordered>
        <Bar data={chartData} options={options} />
      </ContentBlockView>
    )
  }
}

GraphView.propTypes = {
  formattedStatistics: PropTypes.object.isRequired
}

export default GraphView
