import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import PreviewModalView from '../components/PreviewModalView'
import * as actions from '../actions'

import loadAutomationTextMessage from '../../loadAutomationTextMessage'
import loadAutomationFunnel from 'containers/editAutomationFunnel/modules/loadAutomationFunnel'
import session from 'modules/stores/session'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state),
    automationTextMessage: loadAutomationTextMessage.selectors.getAutomationTextMessage(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: loadAutomationTextMessage.selectors.currentFunnelStep(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    sendPreview: actions.sendPreview
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewModalView))
