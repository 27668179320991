import * as actionTypes from './actionTypes'
import defaultConfig from './defaultConfig'

const ALERT_ACTION_HANDLERS = {
  [actionTypes.SET_SHOWING]: (state, action) => {
    return { ...state, showing: action.showing }
  },
  [actionTypes.SET_CONFIG]: (state, action) => {
    return { ...state, config: action.config }
  }
}

const initialState = {
  config: defaultConfig,
  showing: false
}

export default function reducer (state = initialState, action) {
  const handler = ALERT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
