import { createSelector } from 'reselect'
import emailCreate from './modules/email/modules/create'
import select from './modules/selection'

export const shouldHideHeader = createSelector(
  [ select.selectors.getSelection, emailCreate.selectors.isSettingUpNewTemplate ],
  (selection, isSettingUpNewTemplate) => {
    if (selection === select.constants.SMS) { return false }
    return isSettingUpNewTemplate
  }
)
