import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'
import SideMenuItem from './SideMenuItem'

import { selectOptions } from '../constants'

export class FunnelStateView extends Component {

  optionSelected = (option) => {
    this.props.setSelection(option)
  }

  render () {
    const { selection } = this.props
    const options = selectOptions.map((option, index) => {
      const isSelected = option.id === selection
      return (
        <SideMenuItem
          key={index}
          id={option.id}
          icon={option.icon}
          title={option.title}
          isSelected={isSelected}
          onSelect={this.optionSelected}/>
      )
    })

    return (
      <ContentBlockView>
        <ul className='nav nav-pills nav-stacked push'>
          {options}
        </ul>
      </ContentBlockView>
    )
  }
}

FunnelStateView.propTypes = {
  selection: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired
}

export default FunnelStateView
