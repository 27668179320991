import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'

import './styles.css'

class SalesFunnelStepEntryView extends Component {
  constructor(props) {
    super(props)
    if (props.data) {
      const optionIndex = props.salesFunnelSteps.findIndex(option => option.id === props.data.value)
      this.state = { option: props.salesFunnelSteps[optionIndex] }
    } else {
      this.state = {
        option: null
      }
    }
  }

  handleOptionSelection = (option) => {
    this.setState({ ...this.state, option: option })
    this.updateWithResult({ value: option.id })
  }

  updateWithResult = (result) => {
    const { id, eventUpdated } = this.props
    eventUpdated(id, result)
  }

  render () {
    const { salesFunnelSteps } = this.props
    const { option } = this.state
    const dropdownDisplay = (option) ? option.name : 'Select one'
    return (
      <div style={{ display: 'inline' }}>
        <span style={{ display: 'inline-block' }}>
          <DropdownMenu
            trigger={dropdownDisplay}
            triggerType="button">
            <DropdownItemGroup>
              {salesFunnelSteps.map((salesFunnelStep, i) => (
                <DropdownItem key={i} onClick={()=> this.handleOptionSelection(salesFunnelStep)}>
                  {salesFunnelStep.name}
                </DropdownItem>
              ))}
            </DropdownItemGroup>
          </DropdownMenu>
        </span>
      </div>
    )
  }
}

SalesFunnelStepEntryView.propTypes = {
  id: PropTypes.string.isRequired,
  salesFunnelSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventUpdated: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default SalesFunnelStepEntryView
