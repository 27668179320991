import PropTypes from 'prop-types'
import React, { Component } from 'react'

import listing from '../modules/listing'
import selectDesign from '../modules/selectDesign'
import filters from '../modules/filters'

import create from '../modules/create'

export class BulkEmailsView extends Component {

  render () {
    const { view } = this.props
    return (
      <div>
        {view === create.constants.LIST_VIEW &&
          <div className='row'>
            <div className='col-md-3'>
              <filters.BulkEmailFiltersContainer />
            </div>
            <div className='col-md-9'>
              <listing.BulkMessageListingContainer />
            </div>
          </div>
        }
        {view === create.constants.SELECT_DESIGN_VIEW &&
          <selectDesign.SelectDesignContainer />
        }
      </div>
    )
  }
}

BulkEmailsView.propTypes = {
  view: PropTypes.string.isRequired
}

export default BulkEmailsView
