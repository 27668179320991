import PropTypes from "prop-types";
import React, { Component } from "react";

export class PageSelection extends Component {
  previousPageClicked = (event) => {
    event.preventDefault();
    const { currentPage } = this.props;
    if (currentPage === 1) return;

    const previousPage = currentPage - 1;
    this.pageClicked(previousPage);
  };

  nextPageClicked = (event) => {
    event.preventDefault();
    const { pageCount, currentPage } = this.props;
    if (currentPage === pageCount) return;

    const nextPage = currentPage + 1;
    this.pageClicked(nextPage);
  };

  pageClicked = (pageNumber) => {
    const { currentPage, pageClicked } = this.props;
    if (pageNumber === currentPage) return;

    pageClicked(pageNumber);
  };

  render() {
    const { pageCount, currentPage, pageLimit } = this.props;
    const pageLinks = () => {
      const rows = [];
      if (pageLimit && pageCount > pageLimit) {
        const firstHalf = pageLimit / 2 - 1;
        for (let i = 1; i <= firstHalf; i++) {
          const className = i === currentPage ? "active" : "selectable";
          rows.push(
            <li key={i} className={className}>
              <span onClick={() => this.pageClicked(i)}>{i}</span>
            </li>
          );
        }
        rows.push(
          <li key={"dot"}>
            <span>...</span>
          </li>
        );
        const startingPoint = pageCount - firstHalf + 1;
        for (let i = startingPoint; i <= pageCount; i++) {
          const className = i === currentPage ? "active" : "selectable";
          rows.push(
            <li key={i} className={className}>
              <span onClick={() => this.pageClicked(i)}>{i}</span>
            </li>
          );
        }
      } else {
        for (let i = 1; i <= pageCount; i++) {
          const className = i === currentPage ? "active" : "selectable";
          rows.push(
            <li key={i} className={className}>
              <span onClick={() => this.pageClicked(i)}>{i}</span>
            </li>
          );
        }
      }
      return rows;
    };

    const renderNextLink = () => {
      const className = currentPage === pageCount ? "disabled" : "selectable";
      return (
        <li className={className}>
          <span onClick={this.nextPageClicked}>
            <i className="fa fa-angle-double-right" />
          </span>
        </li>
      );
    };

    const renderPreviousLink = () => {
      const className = currentPage === 1 ? "disabled" : "selectable";
      return (
        <li className={className}>
          <span onClick={this.previousPageClicked}>
            <i className="fa fa-angle-double-left" />
          </span>
        </li>
      );
    };

    return (
      <nav>
        <ul className="pagination">
          {renderPreviousLink()}
          {pageLinks()}
          {renderNextLink()}
        </ul>
      </nav>
    );
  }
}

PageSelection.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageClicked: PropTypes.func.isRequired,
  pageLimit: PropTypes.number,
};

export default PageSelection;
