import conditions from './modules/conditions'
import audienceRecords from './modules/audienceRecords'

export const prefillDataForBulkSms = (bulkSms) => {
  return (dispatch) => {
    dispatch(conditions.actions.prefillDataForBulkSms(bulkSms))
  }
}

export const reset = () => {
  return (dispatch) => {
    dispatch(conditions.actions.reset())
    dispatch(audienceRecords.actions.reset())
  }
}
