import * as actionTypes from "./actionTypes";
import SortOptions from "./sortOptions";

const SELECT_FUNNEL_DESIGN_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_RECIPES]: (state, action) => {
    return { ...state, recipes: action.recipes };
  },
  [actionTypes.SET_SORT_ORDER]: (state, action) => {
    return { ...state, sortOrder: action.sortOrder };
  },
  [actionTypes.SET_SEARCH_TERM]: (state, action) => {
    return { ...state, searchTerm: action.searchTerm };
  },
  [actionTypes.SET_LEGACY_RECIPES]: (state, action) => {
    return { ...state, legacyRecipes: action.legacyRecipes };
  },
  [actionTypes.SET_PRIVATE_RECIPE]: (state, action) => {
    return { ...state, privateRecipe: action.privateRecipe };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        total: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  legacyRecipesLoading: false,
  loading: false,
  errors: null,
  privateRecipe: null,
  legacyRecipes: [],
  recipes: [],
  searchTerm: null,
  sortOrder: SortOptions[3].id,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

export default function(state = initialState, action) {
  const handler = SELECT_FUNNEL_DESIGN_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
