import * as actionTypes from "./actionTypes";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.SET_LOADING:
        return { ...state, loading: action.loading };
      case actionTypes.SET_ERRORS:
        return { ...state, errors: action.errors };
      case actionTypes.PAGE_DETAILS_UPDATED:
        return {
          ...state,
          pageDetails: {
            pageCount: action.pageDetails.total_pages,
            currentPage: action.pageDetails.current_page,
            totalLeads: action.pageDetails.total_count
          }
        };
      case actionTypes.SET_USERS:
        return { ...state, users: action.users }
      case actionTypes.SET_SELECTED_USER_IDS:
        return { ...state, selectedUserIds: action.selectedUserIds }
      case actionTypes.ADD_USER_ID:
        return { ...state, selectedUserIds: [
          ...state.selectedUserIds,
          action.userId
        ]}
      case actionTypes.REMOVE_USER_ID:
        const index = state.selectedUserIds
        .findIndex(selectedUserId => parseInt((action.userId), 10) === parseInt((selectedUserId), 10))
        const newSelectedIds = [
          ...state.selectedUserIds.slice(0, index),
          ...state.selectedUserIds.slice(index + 1)
        ]
        return { ...state, selectedUserIds: newSelectedIds };
      case actionTypes.RESET:
        return initialState;
      default:
        return state;
    }
  }
  return state;
};
export default reducer;
