import { request, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import modal from "modules/stores/modal";
import { push } from "react-router-redux";
import create from "../create";
import * as automationFunnelApi from "modules/api/automationFunnel";

export const getFunnels = (funnelState, page, sort, search) => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      const response = await automationFunnelApi.fetchAutomationFunnels(
        funnelState,
        page,
        sort,
        search
      );
      dispatch(setLoading(false));
      dispatch(setPageDetails(response.meta));
      dispatch(setFunnels(response.automation_funnels));
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(setErrors(error.response.data.errors));
      }
      dispatch(setLoading(false));
    }
  };
};

export const createEmptyFunnel = name => {
  return dispatch => {
    dispatch(modal.actions.startLoading());
    const automationBody = {
      automation_funnel: {
        name: name
      },
      recipe: "custom"
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      const funnel = payload.automation_funnel;
      const id = funnel.id;
      dispatch(push("automation/funnel/" + id + "/checklist"));
      dispatch(create.actions.reset());
    }

    return request(
      "automation_funnels",
      POST,
      automationBody,
      onSuccess,
      onFailure
    );
  };
};

export const sortSearchUpdated = (funnelState, sort, search) => {
  return async dispatch => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getFunnels(funnelState, 1, sort, search));
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setFunnels(funnels) {
  return {
    type: actionTypes.SET_FUNNELS,
    funnels
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

export function addFunnel(funnel) {
  return {
    type: actionTypes.ADD_FUNNEL,
    funnel
  };
}

export function removeFunnel(funnel) {
  return {
    type: actionTypes.REMOVE_FUNNEL,
    funnel
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder
  };
}
