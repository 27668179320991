import PropTypes from "prop-types";
import React, { Component } from "react";
import { PrivateRouteContainer, ImageEmptyView } from "modules/components";

import loadBulkEmail from "../modules/loadBulkEmail";
import content from "../modules/content";
import replies from "../modules/replies";
import resultsBreakdown from "../modules/resultsBreakdown";
import results from "../modules/results";
import header from "../modules/header";
import audience from "../modules/audience";
import STRINGS from "strings";

import EmptyImage from "images/TemplatesEmpty.jpg";

const bulkEmailStrings = STRINGS.ViewBulkEmail;

export class EditTemplateView extends Component {
  componentWillUnmount() {
    this.props.reset();
  }

  backSelected = () => {
    this.props.history.push("/secure/bulkMessaging");
  };

  render() {
    const { bulkEmail, notFound } = this.props;
    return (
      <div className="content">
        {bulkEmail && (
          <div>
            <header.HeaderContainer />
            <PrivateRouteContainer
              exact
              path={
                "/secure/bulkMessaging/viewBulkEmail/:id/" +
                results.constants.NAME
              }
              component={results.ResultsContainer}
            />
            <PrivateRouteContainer
              exact
              path={
                "/secure/bulkMessaging/viewBulkEmail/:id/" +
                content.constants.NAME
              }
              component={content.ContentContainer}
            />
            <PrivateRouteContainer
              exact
              path={
                "/secure/bulkMessaging/viewBulkEmail/:id/" +
                resultsBreakdown.constants.NAME
              }
              component={resultsBreakdown.ResultsBreakdownContainer}
            />
            <PrivateRouteContainer
              exact
              path={
                "/secure/bulkMessaging/viewBulkEmail/:id/" +
                replies.constants.NAME
              }
              component={replies.RepliesContainer}
            />
            <PrivateRouteContainer
              exact
              path={
                "/secure/bulkMessaging/viewBulkEmail/:id/" +
                audience.constants.NAME
              }
              component={audience.AudienceContainer}
            />
          </div>
        )}
        {!bulkEmail && !notFound && <loadBulkEmail.LoadBulkEmailContainer />}
        {!bulkEmail && notFound && (
          <ImageEmptyView
            image={EmptyImage}
            title={bulkEmailStrings.notFoundTitle}
            subtitle={bulkEmailStrings.notFoundSubtitle}
            buttonText={bulkEmailStrings.notFoundButtonText}
            buttonSelected={this.backSelected}
          />
        )}
      </div>
    );
  }
}

EditTemplateView.propTypes = {
  bulkEmail: PropTypes.object,
  reset: PropTypes.func.isRequired,
  notFound: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
};

export default EditTemplateView;
