export const sortOptions = [
  {
    id: "name_asc",
    name: "Name (Ascending)",
    icon: "fa fa-sort-alpha-asc"
  },
  {
    id: "name_desc",
    name: "Name (Descending)",
    icon: "fa fa-sort-alpha-desc"
  },
  {
    id: "date_asc",
    name: "Date (Ascending)",
    icon: "fa fa-sort-numeric-asc"
  },
  {
    id: "date_desc",
    name: "Date (Descending)",
    icon: "fa fa-sort-numeric-desc"
  }
];

export default sortOptions;
