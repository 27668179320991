import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TextInputContainer from '../containers/TextInputContainer'
import TemplateDetailsView from './TemplateDetailsView'

export class EditTemplateView extends Component {

  componentDidMount () {
    this.props.fetchMergeTags()
  }

  render () {
    return (
      <div className='row'>
        <div className='col-md-6'>
          <TextInputContainer />
        </div>
        <div className='col-md-6'>
          <TemplateDetailsView />
        </div>
      </div>
    )
  }
}

EditTemplateView.propTypes = {
  fetchMergeTags: PropTypes.func.isRequired
}

export default EditTemplateView
