import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PrivateRouteContainer, ImageEmptyView } from 'modules/components'

import loadTemplate from '../modules/loadTemplate'
import editHtmlTemplate from '../modules/editHtmlTemplate'
import editPlainTextTemplate from '../modules/editPlainTextTemplate'
import settings from '../modules/settings'
import links from '../modules/links'
import header from '../modules/header'
import compliance from '../modules/compliance'

import EmptyImage from 'images/TemplatesEmpty.jpg'

export class EditTemplateView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  backSelected = () => {
    this.props.history.push('/secure/templates')
  }

  render () {
    const { template, showCompliance, notFound } = this.props
    return (
      <div className='content'>
        {template &&
          <div>
            <header.HeaderContainer />
            {showCompliance &&
              <compliance.ComplianceContainer />
            }
            <div className='row'>
              <div className='col-sm-12'>
                <PrivateRouteContainer exact path={"/secure/templates/email/:id/" + editHtmlTemplate.constants.NAME} component={editHtmlTemplate.EditTemplateContainer} />
                <PrivateRouteContainer exact
                  path={"/secure/templates/email/:id/" + editPlainTextTemplate.constants.NAME}
                  component={editPlainTextTemplate.EditPlainTextTemplateContainer}  />
                {template.design_type === 'user' &&
                <div>
                  <PrivateRouteContainer exact path={"/secure/templates/email/:id/" + links.constants.NAME} component={links.LinksContainer}  />
                  <PrivateRouteContainer exact path={"/secure/templates/email/:id/" + settings.constants.NAME} component={settings.SettingsContainer}  />
                </div>
                }
              </div>
            </div>
          </div>
        }
        {!template && !notFound &&
          <loadTemplate.LoadTemplateContainer />
        }
        {!template && notFound &&
          <ImageEmptyView
            image={EmptyImage}
            title={"Template Not Found"}
            subtitle="It looks like this email template has been deleted."
            buttonText='Go Back'
            buttonSelected={this.backSelected} />
        }
      </div>
    )
  }
}

EditTemplateView.propTypes = {
  template: PropTypes.object,
  showCompliance: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired
}

export default EditTemplateView
