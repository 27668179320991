import PropTypes from "prop-types";
import React, { Component } from "react";

export class SideMenuItem extends Component {
  onSelect = () => {
    const { onSelect, item } = this.props;
    onSelect(item);
  };

  render() {
    const { item, isSelected } = this.props;
    const style = isSelected ? "active" : "selectable";
    const textStyle = isSelected ? "text-white-op" : "";
    return (
      <li className={style} onClick={this.onSelect}>
        <div className="sideNav">
          <i
            className={item.icon + " " + textStyle}
            style={{ marginRight: "5px" }}
          />
          {item.title}
        </div>
      </li>
    );
  }
}

SideMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SideMenuItem;
