import { connect } from 'react-redux'
import { AppView } from '../components/AppView'
import { withRouter } from 'react-router'

import session from 'modules/stores/session'
import * as selectors from '../selectors'

function mapStateToProps (state, props) {
  return {
    user: session.selectors.getUser(state),
    shouldShowMenu: selectors.shouldShowMenu(state)
  }
}

export default withRouter(connect(mapStateToProps)(AppView))
