import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import { validator } from "../validator";
import alert from "modules/components/alert";
import STRINGS from 'strings'

import { calculatedTimeTypeSelection } from "modules/helpers/TimeHelpers";
import ShareContainer from "../containers/ShareContainer";

import { FormFields, ContentBlockView } from "modules/components";
const { TextField, SelectField } = FormFields;
const activeFunnelSettingsStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Settings

export class SettingsView extends Component {
  state = {
    showTime: "1"
  };

  componentDidMount() {
    this.setTimeState(this.props.data);
  }

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.updateData(newData);
  };

  saveAutomationFunnelClicked = () => {
    const { automationFunnel, update, data } = this.props;
    update(data, automationFunnel.id);
  };

  setTimeState = data => {
    if (data.fields.timeType.value === null) {
      this.setState({ showTime: "1" });
    } else {
      this.setState({ showTime: "2" });
    }
  };

  handleShowTime = (internalFieldName, value, isValid) => {
    this.setState({ ...this.sate, showTime: value });
    if (value === "1") {
      let newData = generateNewFormState(
        this.props.data,
        "expireAfterTime",
        null,
        isValid
      );
      newData = generateNewFormState(newData, "timeType", null, isValid);
      this.props.updateData(newData);
    }
  };

  deleteAutomationFunnelClicked = () => {
    const { showAlertWithConfig } = this.props;
    const alertConfig = {
      ...alert.defaultConfig,
      children: activeFunnelSettingsStrings.deleteFunnelWarning,
      style: "danger",
      onConfirm: this.deleteAutomationFunnel
    };
    showAlertWithConfig(alertConfig);
  };

  stopAutomationFunnelClicked = () => {
    const { showAlertWithConfig } = this.props;
    const alertConfig = {
      ...alert.defaultConfig,
      children: activeFunnelSettingsStrings.stopFunnelWarning,
      style: "warning",
      onConfirm: this.stopAutomationFunnel
    };
    showAlertWithConfig(alertConfig);
  };

  deleteAutomationFunnel = () => {
    const { automationFunnel, deleteAutomationFunnel } = this.props;
    deleteAutomationFunnel(automationFunnel.id);
  };

  stopAutomationFunnel = () => {
    const { automationFunnel, stopAutomationFunnel } = this.props;
    stopAutomationFunnel(automationFunnel.id);
  };

  render() {
    const { name, expireAfterTime, timeType } = this.props.data.fields;
    const { loading, automationFunnel } = this.props;
    const { showTime } = this.state;

    return (
      <div>
        {automationFunnel.automation_state === "active" && (
          <div className="alert alert-warning">
            <p>{activeFunnelSettingsStrings.activeStateMessage}</p>
          </div>
        )}
        <ContentBlockView title={"Funnel Settings"} loading={loading} bordered>
          <div className="row">
            <div className="col-md-6">
              <TextField
                name="name"
                label={activeFunnelSettingsStrings.funnelName}
                placeholder={activeFunnelSettingsStrings.funnelNameQuestion}
                onChange={this.handleInput}
                value={name.value}
                validator={validator}
              />
            </div>
            <div className="col-sm-2">
              <SelectField
                name="showTime"
                label={activeFunnelSettingsStrings.repeatFunnelHeadline}
                possibleOptions={[
                  { id: "1", display: activeFunnelSettingsStrings.never },
                  { id: "2", display: activeFunnelSettingsStrings.after }
                ]}
                initialValue={showTime}
                displayFieldName={"display"}
                onChange={this.handleShowTime}
              />
            </div>
            {showTime === "2" && (
              <div>
                <div
                  className="col-sm-1"
                  style={{
                    marginTop: "23px",
                    paddingLeft: "0px",
                    marginLeft: "-15px"
                  }}>
                  <TextField
                    name="expireAfterTime"
                    placeholder="6"
                    onChange={this.handleInput}
                    value={expireAfterTime.value}
                  />
                </div>
                <div
                  className="col-sm-1"
                  style={{
                    marginTop: "23px",
                    paddingLeft: "0px",
                    marginLeft: "-15px"
                  }}>
                  <SelectField
                    name="timeType"
                    possibleOptions={calculatedTimeTypeSelection(
                      expireAfterTime.value
                    )}
                    displayFieldName={"display"}
                    onChange={this.handleInput}
                    initialValue={timeType.value}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xs-4 push-20">
              <button
                className="btn btn-primary"
                onClick={this.saveAutomationFunnelClicked}>
                <i className="fa fa-save" /> {activeFunnelSettingsStrings.saveFunnel}
              </button>
            </div>
          </div>
        </ContentBlockView>
        <ShareContainer />
        <ContentBlockView title={"Delete Funnel"} bordered>
          <div className="row">
            <div className="col-xs-6 push-20">
              <p>{activeFunnelSettingsStrings.deleteFunnelMessage}</p>
              <button
                className="btn btn-danger"
                onClick={this.deleteAutomationFunnelClicked}>
                <i className="fa fa-cross" /> {activeFunnelSettingsStrings.deleteFunnel}
              </button>
            </div>
            {automationFunnel.automation_state === "active" && (
              <div className="col-xs-6 push-20">
                <p>{activeFunnelSettingsStrings.stopFunnelMessage}</p>
                <button
                  className="btn btn-warning"
                  onClick={this.stopAutomationFunnelClicked}>
                  <i className="fa fa-stop" /> {activeFunnelSettingsStrings.stopFunnel}
                </button>
              </div>
            )}
          </div>
        </ContentBlockView>
      </div>
    );
  }
}

SettingsView.propTypes = {
  data: PropTypes.object.isRequired,
  automationFunnel: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  deleteAutomationFunnel: PropTypes.func.isRequired,
  stopAutomationFunnel: PropTypes.func.isRequired
};

export default SettingsView;
