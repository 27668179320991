import { apiClient, buildIndexUrl } from "utils/apiClient";

export const fetchLeadsForFunnelStep = async (funnel = null, funnelStep = null, page = 1, sort = null, search = null) => {
  let url = `automation_funnels/${funnel.id}/automation_funnel_steps/${funnelStep.id}/automation_funnel_steps_leads/available_leads`;
  url = buildIndexUrl(url, page, sort, search);
  try {
    const response = await apiClient().get(url);
    return response.data
  } catch (error) {
    return error.data
  }
}

export const addLeadToStep = async (funnel = null, funnelStep = null, leadId = null) => {
  let url = `automation_funnels/${funnel.id}/automation_funnel_steps/${funnelStep.id}/automation_funnel_steps_leads/`;
  const body  ={
    lead_id: leadId
  }
  try {
    const response = await apiClient().post(url, body);
    return response.data
  } catch (error) {
    return error.data
  }
}