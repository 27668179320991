import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import SendView from '../components/SendView'
import * as selectors from '../selectors'
import loadBulkSms from '../../loadBulkSms'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    bulkSms: loadBulkSms.selectors.getBulkSms(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendView))
