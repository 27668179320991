import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getGrowthData = state => state.editGroup[NAME].growthData
export const getNewestUsers = state => state.editGroup[NAME].newestUsers
export const getLoading = state => state.editGroup[NAME].loading

export const graphGrowthData = createSelector(
  [ getGrowthData ],
  (growthData) => {
    const data = {
      labels: ['Thu 30', 'Dec 1', 'Sat 02', 'Sun 03', 'Mon 04', 'Tue 05', 'Wed 06'],
      datasets: [
        {
          label: 'Last week',
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(141, 176, 223, 0.1)',
          borderColor: 'rgba(92, 144, 210, 0.5)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(92, 144, 210, 0.3)',
          pointBackgroundColor: 'rgba(92, 144, 210, 0.3)',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(92, 144, 210, 0.3)',
          pointHoverBorderColor: 'rgba(92, 144, 210, 0.3)',
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: [6, 6, 7, 10, 5, 6, 3]
        },
        {
          label: 'This week',
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(141, 176, 223, 0.4)',
          borderColor: 'rgb(92, 144, 210)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(92, 144, 210)',
          pointBackgroundColor: 'rgb(92, 144, 210)',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(92, 144, 210)',
          pointHoverBorderColor: 'rgb(92, 144, 210)',
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: [8, 9, 10, 9, 7, 12, 14]
        }
      ]
    }
    return data
  }
)
