import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ContentBlockView } from 'modules/components'

import DesignCell from './DesignCell'

import STRINGS from 'strings'
const selectDesignStrings = STRINGS.BulkMessaging.SelectDesign

export class DesignsView extends Component {

  componentDidMount () {
    const { getDesigns } = this.props
    getDesigns()
  }

  designSelected = (design) => {
    const { createMassEmail, newName } = this.props
    createMassEmail(newName, design.design_json, design.html)
  }

  goBack = () => {
    this.props.showListingView()
  }

  render () {
    const { basicDesigns, loading } = this.props

    const basicDesignCells = basicDesigns.map((design, index) => {
      return (
        <DesignCell
          key={index}
          design={design}
          onSelect={this.designSelected} />
      )
    })

    return (
      <ContentBlockView title={selectDesignStrings.title} bordered loading={loading}>
        <div className='designs-list'>
          {basicDesigns.length === 0 &&
            <p>{STRINGS.Global.loading}</p>
          }
          {basicDesigns.length > 0 &&
            basicDesignCells
          }
        </div>
      </ContentBlockView>
    )
  }
}

DesignsView.propTypes = {
  getDesigns: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  basicDesigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  featuredDesigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  createMassEmail: PropTypes.func.isRequired,
  newName: PropTypes.string.isRequired,
  errors: PropTypes.object
}

export default DesignsView
