import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import settings from '../settings'
import editPlainTextTemplate from '../editPlainTextTemplate'
import editHtmlTemplate from '../editHtmlTemplate'

export const get = (templateId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      if (payload.status === 404) {
          dispatch(setNotFound(true))
          return
      }
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setTemplate(payload.email_template))
      dispatch(setCompliance(payload.email_template.compliance))
      dispatch(settings.actions.prefillDataForTemplate(payload.email_template))
      if (payload.email_template.template_type === 'quick_send') {
        dispatch(editPlainTextTemplate.actions.prefillForTemplate(payload.email_template))
      } else if (payload.email_template.template_type === 'html') {
        dispatch(editHtmlTemplate.actions.prefillForTemplate(payload.email_template))
      }
      dispatch(setNotFound(false))
      dispatch(setLoading(false))
    }

    const idAsInt = parseInt(templateId, 10)
    if (Number.isInteger(idAsInt)) {
      return request('email_templates/' + templateId, GET, null, onSuccess, onFailure)
    } else {
      return request('system_email_template/' + templateId, GET, null, onSuccess, onFailure)
    }
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setTemplate (template) {
  return {
    type: actionTypes.SET_TEMPLATE,
    template
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setCompliance (compliance) {
  return {
    type: actionTypes.SET_COMPLIANCE,
    compliance
  }
}

function setNotFound (notFound) {
  return {
    type: actionTypes.SET_NOT_FOUND,
    notFound
  }
}
