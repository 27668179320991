import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import EditAutomationFunnelView from '../components/EditAutomationFunnelView'

import loadAutomationFunnel from '../modules/loadAutomationFunnel'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    notFound: loadAutomationFunnel.selectors.getNotFound(state),
    header: selectors.currentHeader(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    reset: actions.reset
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAutomationFunnelView))
