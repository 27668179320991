import PropTypes from 'prop-types'
import React, { Component } from 'react'

import STRINGS from 'strings'
const statusStrings = STRINGS.ViewBulkSharedStrings.Statuses

const journeySteps = [{
  id: 'accept',
  name: statusStrings.accepted
}, {
  id: 'delivery',
  name: statusStrings.delivered
}, {
  id: 'open',
  name: statusStrings.opened
}, {
  id: 'click',
  name: statusStrings.clicked
}]

export class JourneyCell extends Component {

  nameForEventStep (eventStep) {
    switch (eventStep) {
      case 'accept':
      case 'pending':
        return statusStrings.accepted
      case 'delivery':
      case 'delivered':
        return statusStrings.delivered
      case 'open':
      case 'opened':
        return statusStrings.opened
      case 'click':
      case 'link_clicked':
        return statusStrings.clicked
      case 'bounce':
      case 'drop':
      case 'dropped':
      case 'hard_bounced':
      case 'bounced':
        return statusStrings.bounced
      case 'complaint':
      case 'complained':
        return statusStrings.spamComplaint
      case 'unsubscribe':
        return statusStrings.unsubscribed
      default:
        return statusStrings.unknown

    }
  }

  normalSteps = () => {
    const { record } = this.props
    const lastMailEvent = record.mail_events.slice(-1)[0]
    return journeySteps.map((status, index) => {
      const found = record.mail_events.findIndex(event => status.id === event.event) !== -1
      let completeClass = (found) ? 'complete' : 'disabled'
      completeClass = (lastMailEvent && lastMailEvent.event === status.id) ? 'active' : completeClass
      if (status.id === 'click' && found) {
        completeClass = 'clicked'
      }
      return (
        <div className={'col-xs-2 journey-step ' + completeClass} key={index}>
          <div className='progress'>
            <div className='progress-bar' />
          </div>
            <span className='selectable label label-primary journey-label'>
              {status.name}
            </span>
        </div>
      )
    })
  }

  blockedSteps = () => {
    const { record } = this.props
    const count = record.mail_events.length
    return record.mail_events.map((event, index) => {
      const completeClass = (index === count -1) ? 'failed' : 'complete'
      return (
        <div className={'col-xs-2 journey-step ' + completeClass} key={index}>
          <div className='progress'>
            <div className='progress-bar' />
          </div>
            <span className='selectable label label-primary journey-label'>
              {this.nameForEventStep(event.event)}
            </span>
        </div>
      )
    })
  }

  render () {
    const { record } = this.props
    let steps
    switch (record.status) {
      case 'dropped':
      case 'hard_bounced':
      case 'complained':
      case 'bounced':
      case 'unsubscribed':
        steps = this.blockedSteps()
        break
      default:
        steps = this.normalSteps()
        break
    }

    return (
      <td>
        <div className='hidden-xs journey row'>
          {steps}
        </div>
        <div className='visible-xs'>
          <span>{this.nameForEventStep(record.status)}</span>
        </div>
      </td>
    )
  }
}

JourneyCell.propTypes = {
  record: PropTypes.object.isRequired
}

export default JourneyCell
