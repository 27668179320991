import { isPresent } from 'utils/validation'

export const validator = {
  title: [{
    rule: isPresent,
    errorMessage: 'The template name needs a value'
  }],
  subject: [{
    rule: isPresent,
    errorMessage: 'The template must have a subject (You can use merge fields here as well)'
  }],
  text: [{
    rule: isPresent,
    errorMessage: 'The template is missing text content.'
  }]
}
