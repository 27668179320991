import * as actionTypes from './actionTypes'

const EDIT_SMS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  },
  [actionTypes.PREFIL_FOR_BULK_SMS]: (state, action) => {
    return { ...state, text: action.bulkSms.text, previewText: action.bulkSms.preview, hasChanges: false }
  },
  [actionTypes.SET_TEXT]: (state, action) => {
    return { ...state, text: action.text, hasChanges: true }
  },
  [actionTypes.SET_PREVIEW_TEXT]: (state, action) => {
    return { ...state, previewText: action.previewText }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  },
  [actionTypes.SET_TEXT_MESSAGE_TEMPLATES]: (state, action) => {
    return { ...state, textMessageTemplates: action.textMessageTemplates }
  },
  [actionTypes.SET_HAS_CHANGES]: (state, action) => {
    return { ...state, hasChanges: action.hasChanges }
  }
}

const initialState = {
  loading: false,
  errors: null,
  previewText: null,
  text: '',
  textMessageTemplates: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function (state = initialState, action) {
  const handler = EDIT_SMS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
