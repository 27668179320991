import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { validator } from './validator'
import { EDIT_TRIGGER_VIEW } from './constants'

const TRIGGERS_ACTION_HANDLERS = {
  [actionTypes.PREFIL_DATA_FOR_TRIGGER]: (state, action) => {
    const fields = generateFieldsForKeys(action.trigger, fieldNames, payloadKeys, validator)
    return {
      ...state,
      data: {
        fields: fields,
        isValid: fieldsAreValid(fields)
      }
    }
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_VIEW]: (state, action) => {
    return { ...state, view: action.view }
  },
  [actionTypes.SET_SELECTED_FUNNEL_STEP_ID]: (state, action) => {
    return { ...state, selectedFunnelStepId: action.selectedFunnelStepId }
  },
  [actionTypes.SET_TRIGGER_OPTIONS]: (state, action) => {
    return { ...state, triggerOptions: action.triggerOptions }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  }
}

const payloadKeys = {
  timeType: 'time_type',
  timeToWait: 'time_to_wait',
  timeBefore: 'time_before',
  triggerCondition: 'trigger_condition',
  valueId: 'value_id'
}

function initialState () {
  const fields = generateFieldsForKeys(null, fieldNames, payloadKeys, validator)
  return {
    loading: false,
    view: EDIT_TRIGGER_VIEW,
    selectedFunnelStepId: null,
    errors: null,
    triggerOptions: [],
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function (state = initialState(), action) {
  const handler = TRIGGERS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
