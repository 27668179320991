import * as actionTypes from './actionTypes'

export const prefilForRuleSet = (ruleSet) => {
  return (dispatch) => {
    if (!ruleSet) { return }
    dispatch(setEvent(ruleSet.event))
    dispatch(setHasChanges(false))
  }
}

export function setEvent (event) {
  return {
    type: actionTypes.SET_EVENT,
    event
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function prefilExitIsAutomatic (exitIsAutomatic) {
  return (dispatch) => {
    if (!exitIsAutomatic) { return }
    dispatch(setExitIsAutomatic(exitIsAutomatic))
    dispatch(setHasChanges(false))
  }
}

export function setExitIsAutomatic (exitIsAutomatic) {
  return {
    type: actionTypes.SET_EXIT_IS_AUTOMATIC,
    exitIsAutomatic
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}
