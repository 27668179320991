import { request, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { push } from 'react-router-redux'

import modal from 'modules/stores/modal'
import listing from '../listing'
import editPlainTextEmailTemplate from 'containers/editEmailTemplate/modules/editPlainTextTemplate'

import * as constants from './constants'

export function showSelectDesignView () {
  return {
    type: actionTypes.SET_VIEW,
    view: constants.SELECT_DESIGN_VIEW
  }
}

export function showListingView () {
  return {
    type: actionTypes.SET_VIEW,
    view: constants.LIST_VIEW
  }
}

export function setNewName (newName) {
  return {
    type: actionTypes.SET_NEW_NAME,
    newName
  }
}

export function reset (reset) {
  return {
    type: actionTypes.RESET
  }
}

export const createQuickSendTemplate = (name, companyId) => {
  return dispatch => {
    dispatch(modal.actions.startLoading())
    const templateBody = {
      email_template: {
        title: name,
        template_type: 'quick_send',
        location_ids: []
      }
    }

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.hideModal())
      const template = payload.email_template
      dispatch(listing.actions.addTemplate(template))
      const id = template.id
      dispatch(push('templates/email/' + id + '/' + editPlainTextEmailTemplate.constants.NAME))
    }

    const url = 'companies/' + companyId + '/email_templates'
    return request(url, POST, templateBody, onSuccess, onFailure)
  }
}
