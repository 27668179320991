import { combineReducers } from "redux";
import listing from "./modules/listing";
import triggers from "./modules/triggers";
import sms from "./modules/sms";
import email from "./modules/email";
import view from "./modules/view";
import schedule from "./modules/schedule";
import stepLeads from "./modules/stepLeads";
import stepAddLeads from "./modules/stepAddLeads";

export const reducer = combineReducers({
  [listing.constants.NAME]: listing.reducer,
  [triggers.constants.NAME]: triggers.reducer,
  [sms.constants.NAME]: sms.reducer,
  [email.constants.NAME]: email.reducer,
  [view.constants.NAME]: view.reducer,
  [schedule.constants.NAME]: schedule.reducer,
  [stepLeads.constants.NAME]: stepLeads.reducer,
  [stepAddLeads.constants.NAME]: stepAddLeads.reducer,
});
