import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MultiTagSelectModal from "../components/MultiTagSelectModal";

import excludedTags from "modules/stores/excludedTags";
import * as actions from "modules/stores/excludedTags/actions";
import modal from "modules/stores/modal";

function mapStateToProps(state) {
  return {
    excludedTags: excludedTags.selectors.getOptions(state),
    loading: excludedTags.selectors.getLoading(state),
    errors: excludedTags.selectors.getErrors(state),
    pageDetails: excludedTags.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTags: actions.getTags,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiTagSelectModal);
