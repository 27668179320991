import { createSelector } from 'reselect'
import loadBulkEmail from '../loadBulkEmail'
import { NAME } from './constants'

export const getSearchText = state => state.viewBulkEmail[NAME].searchText
export const getSearchResults = state => state.viewBulkEmail[NAME].searchResults
export const getPreviewHtml = state => state.viewBulkEmail[NAME].previewHtml
export const getPreviewSubject = state => state.viewBulkEmail[NAME].previewSubject
export const getPreviewResult = state => state.viewBulkEmail[NAME].previewResult

export const getErrors = state => state.viewBulkEmail[NAME].errors
export const getLoading = state => state.viewBulkEmail[NAME].loading

export const validPreviewHtml = createSelector(
  [ getPreviewHtml, loadBulkEmail.selectors.getBulkEmail ],
  (previewHtml, bulkEmail) => {
    if (!previewHtml) { return bulkEmail.html }
    return previewHtml
  }
)

export const validPreviewSubject = createSelector(
  [ getPreviewSubject, loadBulkEmail.selectors.getBulkEmail ],
  (getPreviewSubject, bulkEmail) => {
    if (!getPreviewSubject) { return bulkEmail.subject }
    return getPreviewSubject
  }
)
