import PropTypes from "prop-types";
import React, { Component } from "react";

import {
  ContentBlockView,
  GymLeadsLoader,
  ImageEmptyView,
  SortSearchView
} from "modules/components";
import TemplatesEmpty from "images/TemplatesEmpty.jpg";

import SelectTemplateCell from "./SelectTemplateCell";
import sortOptions from "../sortOptions";
import STRINGS from "strings/";

import TemplatePagesContainer from "../containers/TemplatePagesContainer";

export class TemplatesView extends Component {
  state = {
    searchTerm: null,
    sortOrder: sortOptions[3].id
  };

  componentDidMount() {
    this.getTemplatesWithPageNumber(1);
  }

  getTemplatesWithPageNumber = pageNumber => {
    const { getTemplates } = this.props;
    const { sortOrder, searchTerm } = this.state;
    getTemplates(pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getTemplatesWithPageNumber(pageNumber);
  };

  sortSearchUpdated = (sort, search) => {
    const { getTemplates } = this.props;
    this.setState(
      { searchTerm: search, sortOrder: sort },
      getTemplates(1, sort, search)
    );
  };

  templateSelected = template => {
    const { createTemplate, company, newName } = this.props;
    createTemplate(newName, company.id, template.html, template.design_json);
  };

  render() {
    const { templates, loading } = this.props;
    const { sortOrder, searchTerm } = this.state;

    const templateCells = templates.map((template, index) => {
      return (
        <SelectTemplateCell
          key={index}
          template={template}
          onSelect={this.templateSelected}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={this.sortSearchUpdated}
          sortColumnWidth={"3"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <ContentBlockView
          title={STRINGS.Templates.Email.Designs.yourTemplates}
          bordered
        >
          <GymLeadsLoader
            title={STRINGS.Templates.Email.Designs.loadingTemplates}
            active={loading}
          />
          <div className="designs-list">
            {templates.length > 0 && !loading && templateCells}
            {templates.length === 0 && (
              <ImageEmptyView
                image={TemplatesEmpty}
                title={STRINGS.Templates.Email.Designs.noTemplates}
                subtitle={STRINGS.Templates.Email.Designs.noTemplatesDetail}
              />
            )}
          </div>
        </ContentBlockView>
        {templates.length > 0 && !loading && (
          <TemplatePagesContainer pageClicked={this.pageClicked} />
        )}
      </div>
    );
  }
}

TemplatesView.propTypes = {
  getTemplates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTemplate: PropTypes.func.isRequired,
  newName: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  pageDetails: PropTypes.object.isRequired
};

export default TemplatesView;
