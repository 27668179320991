import * as actionTypes from './actionTypes'

export function setShowing (showing) {
  return {
    type: actionTypes.SET_SHOWING,
    showing
  }
}

export function setConfig (config) {
  return {
    type: actionTypes.SET_CONFIG,
    config
  }
}

export const showWithConfig = (config) => {
  return (dispatch) => {
    dispatch(setConfig(config))
    dispatch(setShowing(true))
  }
}
