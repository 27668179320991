import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PrivateRouteContainer, ImageEmptyView } from 'modules/components'

import loadBulkSms from '../modules/loadBulkSms'
import content from '../modules/content'
import resultsBreakdown from '../modules/resultsBreakdown'
import results from '../modules/results'
import header from '../modules/header'
import audience from '../modules/audience'

import EmptyImage from 'images/EmptyGraph.jpg'

import STRINGS from 'strings'
const viewBulkSmsStrings = STRINGS.ViewBulkSms

export class EditTemplateView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  backSelected = () => {
    this.props.history.push('/secure/bulkMessaging')
  }

  render () {
    const { bulkSms, notFound } = this.props
    return (
      <div className='content'>
        {bulkSms &&
          <div>
            <header.HeaderContainer />
            <PrivateRouteContainer exact path={"/secure/bulkMessaging/viewBulkSms/:id/" + results.constants.NAME} component={results.ResultsContainer} />
            <PrivateRouteContainer exact path={"/secure/bulkMessaging/viewBulkSms/:id/" + content.constants.NAME} component={content.ContentContainer} />
            <PrivateRouteContainer exact path={"/secure/bulkMessaging/viewBulkSms/:id/" + resultsBreakdown.constants.NAME} component={resultsBreakdown.ResultsBreakdownContainer} />
            <PrivateRouteContainer exact path={"/secure/bulkMessaging/viewBulkSms/:id/" + audience.constants.NAME} component={audience.AudienceContainer} />
          </div>
        }
        {!bulkSms && !notFound &&
          <loadBulkSms.LoadBulkSmsContainer />
        }
        {!bulkSms && notFound &&
          <ImageEmptyView
            image={EmptyImage}
            title={viewBulkSmsStrings.notFoundTitle}
            subtitle={viewBulkSmsStrings.notFoundSubtitle}
            buttonText={viewBulkSmsStrings.notFoundButtonText}
            buttonSelected={this.backSelected} />
        }
      </div>
    )
  }
}

EditTemplateView.propTypes = {
  bulkSms: PropTypes.object,
  notFound: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired
}

export default EditTemplateView
