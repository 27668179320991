import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ContentBlockView } from 'modules/components'

import conditions from '../modules/conditions'
import showAudience from '../modules/showAudience'

import STRINGS from 'strings'
const setAudienceStrings = STRINGS.SetAudience

export class AudienceView extends Component {

  saveAudienceClicked = () => {
    const { update, bulkSms, selectedGroup, entryRulesJson } = this.props
    const selectedGroupId = (selectedGroup) ? selectedGroup.id : null
    update(bulkSms, selectedGroupId, entryRulesJson.entry_rules)
  }

  render () {
    const { bulkSms } = this.props
    return (
      <div>
        <ContentBlockView title={'Set Audience'} bordered disabled={bulkSms.status === 'scheduled'}>
          <div className='row' style={{ marginLeft: '-20px', marginRight: '-20px' }}>
            <div className='col-xs-4 options-container-left'>
              <conditions.ConditionsContainer bulkSms={bulkSms} />
            </div>
            <div className='col-xs-8'>
              <showAudience.ShowAudienceContainer />
            </div>
          </div>
        </ContentBlockView>
        <button className='btn btn-primary '
          onClick={this.saveAudienceClicked}>
          <i className='fa fa-save' /> {setAudienceStrings.saveAudienceButton}
        </button>
      </div>
    )
  }
}

AudienceView.propTypes = {
  bulkSms: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  entryRulesJson: PropTypes.object.isRequired
}

export default AudienceView
