const initialState = {
  users: [],
  selectedUserIds: [],
  loading: false,
  errors: null,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    totalLeads: 0
  }
}

export default initialState;
