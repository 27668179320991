import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditLeadLostReasonTriggerValueView from "../components/EditLeadLostReasonTriggerValueView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import leadLostReasons from "modules/stores/leadLostReasons";
import * as apiActions from "modules/stores/leadLostReasons/apiActions";

function mapStateToProps(state) {
  return {
    data: selectors.getData(state),
    triggerValueText: selectors.triggerValueText(state),
    leadLostReasons: leadLostReasons.selectors.getLeadLostReasons(state),
    loading: leadLostReasons.selectors.getLoading(state),
    pageDetails: leadLostReasons.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLeadLostReasons: apiActions.getLeadLostReasons,
      updateData: actions.updateData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLeadLostReasonTriggerValueView);
