import { request, GET } from 'utils/apiUtils'
import session from 'modules/stores/session'

export const loadSsoSession = (token) => {
  return (dispatch) => {

    function onFailure (payload) {

    }

    function onSuccess (payload) {
      if (payload) {
        dispatch(session.actions.startSession(payload, true))
      }
    }

    return request('sso?token=' + token, GET, null, onSuccess, onFailure)
  }
}
