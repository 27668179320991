import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import EmailHeaderView from '../components/EmailHeaderView'

import alert from 'modules/components/alert'
import loadAutomationEmail from '../../loadAutomationEmail'
import stepsView from '../../../../view'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    view: selectors.getView(state),
    hasUnsavedChanges: selectors.hasUnsavedChanges(state),
    funnelStep: loadAutomationEmail.selectors.currentFunnelStep(state),
    automationEmail: loadAutomationEmail.selectors.getAutomationEmail(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showWithConfig: alert.actions.showWithConfig,
    setStepView: stepsView.actions.setView,
    setView: actions.setView
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailHeaderView))
