import PropTypes from "prop-types";
import React, { Component } from "react";

export class SideMenuItem extends Component {
  onSelect = () => {
    const { onSelect, id } = this.props;
    onSelect(id);
  };

  render() {
    const { icon, title, isSelected } = this.props;
    const style = isSelected ? "active" : "selectable";
    const textStyle = isSelected ? "text-white-op" : "";
    return (
      <li className={style} onClick={this.onSelect}>
        <div className="sideNav">
          <i
            className={icon + " " + textStyle}
            style={{ marginRight: "5px" }}
          />
          {title}
        </div>
      </li>
    );
  }
}

SideMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SideMenuItem;
