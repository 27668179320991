import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  ImageEmptyView,
  ContentBlockView,
  SortSearchView,
  GymLeadsLoader,
} from "modules/components";
import GroupsEmpty from "images/GroupsEmpty.jpg";
import { SELECT_RECIPE_MODAL } from "../constants";
import sortOptions from "../sortOptions";
import STRINGS from "strings/";

import GroupItemContainer from "../containers/GroupItemContainer";
import NewGroupModalContainer from "../containers/NewGroupModalContainer";
import PagesContainer from "../containers/PagesContainer";
import RecipeOrNewSelectionContainer from "../containers/RecipeOrNewSelectionContainer";

export class ListingView extends Component {
  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getGroupsWithPageNumber(currentPage ? currentPage : 1);
  }

  getGroupsWithPageNumber = (pageNumber) => {
    const { getGroups, sortOrder, searchTerm } = this.props;
    getGroups(pageNumber, sortOrder, searchTerm);
  };

  groupSelected = (group) => {
    const url = "/secure/groups/" + group.id + "/members";
    this.props.history.push(url);
  };

  newGroupClicked = () => {
    this.props.showModal(SELECT_RECIPE_MODAL);
  };

  pageClicked = (pageNumber) => {
    this.getGroupsWithPageNumber(pageNumber);
  };

  render() {
    const {
      groups,
      loading,
      sortSearchUpdated,
      sortOrder,
      searchTerm,
    } = this.props;

    const groupRows = groups.map((group, index) => {
      return (
        <GroupItemContainer
          key={index}
          group={group}
          onSelect={this.groupSelected}
        />
      );
    });
    return (
      <div>
        <ContentBlockView loading={loading}>
          <h2>{STRINGS.Groups.Listing.title}</h2>
          <p>{STRINGS.Groups.Listing.description}</p>
          <div
            className="pull-right"
            style={{ position: "relative", top: "-65px" }}
          >
            <button className="btn btn-success" onClick={this.newGroupClicked}>
              <i className="fa fa-plus" />{" "}
              {STRINGS.Groups.Listing.createANewGroup}
            </button>
          </div>
        </ContentBlockView>
        <SortSearchView
          sortSearchUpdated={sortSearchUpdated}
          sortColumnWidth={"4"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <div className="row">
          <div className="col-xs-12">
            {groups.length === 0 && !loading && (
              <ImageEmptyView
                image={GroupsEmpty}
                title={STRINGS.Groups.Listing.emptyTitle}
                subtitle={STRINGS.Groups.Listing.emptyDetail}
                buttonText={STRINGS.Groups.Listing.emptyNew}
                buttonSelected={this.newGroupClicked}
              />
            )}
            <GymLeadsLoader
              active={loading}
              text={STRINGS.Groups.Listing.loading}
            />
            {groups.length > 0 && !loading && (
              <div>
                {groupRows}
                <PagesContainer pageClicked={this.pageClicked} />
              </div>
            )}
          </div>
        </div>
        <NewGroupModalContainer />
        <RecipeOrNewSelectionContainer />
      </div>
    );
  }
}

ListingView.propTypes = {
  getGroups: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
};

export default ListingView;
