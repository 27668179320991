import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TriggersModal from "../components/TriggersModal";

import * as actions from "../actions";
import * as selectors from "../selectors";
import modal from "modules/stores/modal";

import loadAutomationFunnel from "../../../../loadAutomationFunnel";

function mapStateToProps(state) {
  const selectedTrigger = selectors.selectedTrigger(state);
  return {
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    selectedFunnelStep: selectors.selectedFunnelStep(state),
    selectedTrigger: selectedTrigger,
    triggerOptions: selectors.getTriggerOptions(state),
    data: selectors.getData(state),
    view: selectors.getView(state),
    loading: selectors.getLoading(state),
    TriggerSelectionContainer: selectors.triggerSelectionContainer(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      update: actions.update,
      hideModal: modal.actions.hideModal,
      setView: actions.setView
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TriggersModal);
