import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";

import LeadLostReasonRow from "modules/components/selectLeadLostReasonModal/LeadLostRow";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class EditLeadLostReasonTriggerValueView extends Component {
  componentWillMount() {
    const { leadLostReasons, data } = this.props;
    generateNewFormState(data, "valueId", this.getInitialValue(), true);
    if (leadLostReasons.length > 0) {
      return;
    }
    this.getLeadLostReasonsWithPageNumber(1);
  }

  getLeadLostReasonsWithPageNumber = pageNumber => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadLostReasonsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(1, searchTerm);
  };

  handleChange = value => {
    const newData = generateNewFormState(
      this.props.data,
      "valueId",
      value.id,
      true
    );
    this.props.updateData(newData);
  };

  getInitialValue = () => {
    const { data } = this.props;
    if (data.fields.valueId.value) {
      return String(data.fields.valueId.value);
    } else {
      return null;
    }
  };

  render() {
    const {
      data,
      leadLostReasons,
      triggerValueText,
      loading,
      pageDetails
    } = this.props;
    const { valueId } = data.fields;

    const rows = leadLostReasons.map((leadLostReason, index) => {
      const selected = valueId
        ? parseInt(valueId.value, 10) === leadLostReason.id
        : false;
      return (
        <LeadLostReasonRow
          key={index}
          selected={selected}
          leadLostReason={leadLostReason}
          selectLeadLostReason={this.handleChange}
        />
      );
    });

    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView loading={loading} title={triggerValueText}>
        <div className="row" style={{ marginTop: "-20px" }}>
          <TextSearchView
            sectionName={"search"}
            onUpdate={this.searchUpdated}
          />
        </div>
        {leadLostReasons.length > 0 && (
          <div>
            <div className="table-responsive">
              <table className="table table-vcenter table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        )}
        {leadLostReasons.length === 0 && (
          <EmptyView
            colorThemeClass="primary"
            iconClass="si si-magnifier"
            title="No results"
            subtitle="Check the search field"
          />
        )}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesView
              pageDetails={pageDetails}
              pageClicked={this.pageClicked}
            />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

EditLeadLostReasonTriggerValueView.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  triggerValueText: PropTypes.string.isRequired,
  leadLostReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object
};

EditLeadLostReasonTriggerValueView.defaultProps = {
  leadLostReasons: []
};

export default EditLeadLostReasonTriggerValueView;
