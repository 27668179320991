import { request, PUT, DELETE } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { fieldNames } from './constants'

import flags from 'modules/components/flags'
import view from '../../../view'

import { formDataFromData } from 'utils/formHelpers'

import loadAutomationTextMessage from '../loadAutomationTextMessage'
import STRINGS from "strings";

const smsSettingsStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Settings;

export function prefillDataForTextMessage (textMessage) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_TEXT_MESSAGE,
    textMessage
  }
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}

export const update = (automationFunnelId, automationFunnelStepId, automationTextMessageId, formData) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const body = {
      automation_text_message_template: formDataFromData(formData, fieldNames)
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(loadAutomationTextMessage.actions.setAutomationTextMessage(payload.automation_text_message_template))
      dispatch(setLoading(false))
      dispatch(setHasChanges(false))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: smsSettingsStrings.settingsUpdatedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_text_message_templates/' + automationTextMessageId
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

export const deleteTextMessage = (automationFunnelId, automationFunnelStepId, automationTextMessageId) => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(view.actions.setView(view.constants.LIST_STEP_VIEW))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: smsSettingsStrings.deletedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_text_message_templates/' + automationTextMessageId
    return request(url, DELETE, null, onSuccess, onFailure)
  }
}
