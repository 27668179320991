import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import modal from "modules/stores/modal";
import ListingView from "../components/ListingView";
import * as selectors from "../selectors";
import * as actions from "../actions";

function mapStateToProps(state) {
  return {
    groups: selectors.getGroups(state),
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    pageDetails: selectors.getPageDetails(state),
    searchTerm: selectors.getSearchTerm(state),
    sortOrder: selectors.getSortOrder(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getGroups: actions.getGroups,
      showModal: modal.actions.showModal,
      sortSearchUpdated: actions.sortSearchUpdated
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListingView)
);
