import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.viewBulkSms[NAME].errors
export const getLoading = state => state.viewBulkSms[NAME].loading
export const getBulkSms = state => state.viewBulkSms[NAME].bulkSms
export const getNotFound = state => state.viewBulkSms[NAME].notFound

export const getChecklist = createSelector(
  [ getBulkSms ],
  (bulkSms) => {
    return bulkSms.checklist
  }
)

export const getDesignJson = createSelector(
  [ getBulkSms ],
  (bulkSms) => {
    return bulkSms.design_json
  }
)
