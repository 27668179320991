import { combineReducers } from 'redux'
import listing from './modules/listing'
import filters from './modules/filters'
import create from './modules/create'
import selectDesign from './modules/selectDesign'

export const reducer = combineReducers({
  [listing.constants.NAME]: listing.reducer,
  [filters.constants.NAME]: filters.reducer,
  [create.constants.NAME]: create.reducer,
  [selectDesign.constants.NAME]: selectDesign.reducer
})
