import * as actionTypes from './actionTypes'

const ACTIVE_LEADS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_LEADS]: (state, action) => {
    return { ...state, leads: action.leads }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  },
  [actionTypes.RESET]: (state, action) => {
      return initialState
  }
}

const initialState = {
  loading: false,
  errors: null,
  leads: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = ACTIVE_LEADS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
