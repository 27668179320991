import { combineReducers } from 'redux'
import listing from './modules/listing'
import create from './modules/create'
import selectDesign from './modules/selectDesign'

export const reducer = combineReducers({
  [listing.constants.NAME]: listing.reducer,
  [create.constants.NAME]: create.reducer,
  [selectDesign.constants.NAME]: selectDesign.reducer,
})
