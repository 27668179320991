import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { GymLeadsLoader } from 'modules/components'
import { ImageEmptyView } from 'modules/components'
import emptyImage from 'images/SmsNotReady.jpg'
import STRINGS from 'strings'
const smsLoadingTextMessageStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.LoadAutomationTextMessage

export class LoadTemplateView extends Component {

  componentDidMount () {
    const { getAutomationTextMessage, automationFunnel, automationFunnelStep } = this.props
    if (!automationFunnel || !automationFunnelStep) { return }
    getAutomationTextMessage(automationFunnel.id, automationFunnelStep.id)
  }

  render () {
    const { automationFunnel, automationFunnelStep } = this.props
    if (!automationFunnel || !automationFunnelStep) {
      return (
        <ImageEmptyView
          image={emptyImage}
          title={smsLoadingTextMessageStrings.title}
          subtitle={smsLoadingTextMessageStrings.subtitle} />
      )
    }
    return <GymLeadsLoader text={smsLoadingTextMessageStrings.loading} active />
  }
}

LoadTemplateView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  getAutomationTextMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default LoadTemplateView
