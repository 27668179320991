import * as actionTypes from './actionTypes'

const MEMBERS_ACTION_HANDLERS = {
  [actionTypes.SET_RECORDS]: (state, action) => {
    return { ...state, records: action.records }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  }
}

const initialState = {
  records: [],
  loading: false,
  errors: null,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function (state = initialState, action) {
  const handler = MEMBERS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
