import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SystemTemplateRow from './SystemTemplateRow'

import editSmsTemplate from 'containers/editSmsTemplate/modules/editTemplate'

export class SystemTemplateListingView extends Component {

  templateSelected = (id) => {
    const url = '/secure/templates/sms/' + id + '/' + editSmsTemplate.constants.NAME
    this.props.history.push(url)
  }

  render () {
    const { templates } = this.props

    const templateRows = templates.map((template, index) => {
      return (
        <SystemTemplateRow
          key={template.id}
          id={template.id}
          title={template.title}
          detail={template.detail}
          onSelect={this.templateSelected} />
      )
    })

    return (
      <div className='row'>
        <div className='col-xs-12'>
              {templateRows}
        </div>
      </div>
    )
  }
}

SystemTemplateListingView.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default SystemTemplateListingView
