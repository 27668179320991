import PropTypes from "prop-types";
import React, { Component } from "react";
import { ModalContainer } from "modules/components";
import * as arrayUtils from "utils/arrayUtils";
import STRINGS from "strings";

import MultiLocationSelectView from "./MultiLocationSelectView";

export class MultiLocationSelectModal extends Component {
  state = {
    selectedLocationIds: this.props.locationIds
  };

  componentWillReceiveProps(nextProps) {
    const oldLocationIds = this.props.locationIds;
    const newLocationIds = nextProps.locationIds;

    if (!oldLocationIds || !newLocationIds) {
      return;
    }

    if (arrayUtils.arraysEqual(oldLocationIds, newLocationIds)) {
      return;
    }
    this.setState({ selectedLocationIds: newLocationIds });
  }

  selectLocation = location => {
    const newSelectedLocationIds = [
      ...this.state.selectedLocationIds,
      location.id
    ];
    this.setState({
      ...this.state,
      selectedLocationIds: newSelectedLocationIds
    });
  };

  deselectLocation = location => {
    const index = this.state.selectedLocationIds.findIndex(
      locationId => parseInt(locationId, 10) === parseInt(location.id, 10)
    );
    const newSelectedLocationIds = [
      ...this.state.selectedLocationIds.slice(0, index),
      ...this.state.selectedLocationIds.slice(index + 1)
    ];
    this.setState({
      ...this.state,
      selectedLocationIds: newSelectedLocationIds
    });
  };

  updateClubIds = () => {
    this.props.onUpdate(this.state.selectedLocationIds);
    this.cleanAndHide();
  };

  cleanAndHide = () => {
    this.clearLocationSelection();
    this.props.hideModal();
  };

  clearLocationSelection = () => {
    this.setState({ selectedLocationIds: [] });
  };

  render() {
    const {
      modalName,
      readonly,
      buttonTitle,
      showClearSelectionButton
    } = this.props;
    const disabled = showClearSelectionButton
      ? false
      : this.state.selectedLocationIds.length === 0;

    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={"Select locations"}
        colorThemeClass={"primary"}
        showCancelButton
        readonly={readonly}
        actionEnabled={!disabled}
        actionButtonClicked={this.updateClubIds}
        actionButtonElement={
          <span>
            <i className="fa fa-building-o" /> {buttonTitle}
          </span>
        }
        secondaryActionEnabled={showClearSelectionButton}
        secondaryActionButtonClicked={this.clearLocationSelection}
        // only set the secondaryActionButtonElement if it should be shown
        {...(showClearSelectionButton
          ? { secondaryActionButtonElement: STRINGS.Global["clearSelection"] }
          : {})}
      >
        <MultiLocationSelectView
          {...this.props}
          readonly={readonly}
          selectedLocationIds={this.state.selectedLocationIds}
          selectLocation={this.selectLocation}
          deselectLocation={this.deselectLocation}
        />
      </ModalContainer>
    );
  }
}

MultiLocationSelectModal.propTypes = {
  getLocations: PropTypes.func.isRequired,
  locationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onUpdate: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalName: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  buttonTitle: PropTypes.string,
  showClearSelectionButton: PropTypes.bool
};

MultiLocationSelectModal.defaultProps = {
  buttonTitle: "Filter by selected",
  readonly: false,
  showClearSelectionButton: false
};

export default MultiLocationSelectModal;
