import { createSelector } from 'reselect'

export const getUser = state => state.session.user
export const getUserTimezone = state => state.session.user.timezone
export const getUserRole = state => state.session.user.user_role
export const getAttemptedUrl = state => state.session.attemptedUrl
export const getToken = state => state.session.token
export const getIsMobile = state => state.session.isMobile
export const getUserPermissions = state => state.session.user.user_permission_rule_set
export const getCurrentAppVersion = state => state.session.currentAppVersion
export const getSessionExpiry = state => state.session.sessionExpiry
export const getRefreshToken = state => state.session.refreshToken
export const getSessionEndReason = state => state.session.sessionEndReason
export const getIntercomHash = state => state.session.intercomHash
export const getLoading = state => state.session.loading

export const isSalesperson = createSelector(
  [ getUserRole ],
  (userRole) => {
    return (userRole.id === 4)
  }
)

export const canSendEmail = createSelector(
  [ getUser ],
  (user) => {
    return (user.user_email && user.user_email.usable === true)
  }
)
