import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  ImageEmptyView,
  SortSearchView,
  GymLeadsLoader,
} from "modules/components";
import STRINGS from "strings";
import BulkSmsRowContainer from "../containers/BulkSmsRowContainer";
import Empty from "images/EmptyGraph.jpg";

import PagesContainer from "../containers/PagesContainer";
import { toTitleCase } from "modules/helpers/StringHelpers";

import sortOptions from "../sortOptions";

const listingStrings = STRINGS.BulkMessaging.BulkSmsListing;

export class BulkSmsListingView extends Component {
  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getBulkSmssWithPageNumber(currentPage ? currentPage : 1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status.id !== this.props.status.id) {
      const {
        getBulkSms,
        status,
        sortOrder,
        searchTerm,
        selectedLocations,
      } = nextProps;
      getBulkSms(status.value, 1, sortOrder, searchTerm, selectedLocations);
    }
  }

  bulkSmsSelected = (bulkSms) => {
    if (bulkSms.status === "draft" || bulkSms.status === "scheduled") {
      const url =
        "/secure/bulkMessaging/editBulkSms/" + bulkSms.id + "/checklist";
      this.props.history.push(url);
    } else {
      const url =
        "/secure/bulkMessaging/viewBulkSms/" + bulkSms.id + "/results";
      this.props.history.push(url);
    }
  };

  getBulkSmssWithPageNumber = (pageNumber) => {
    const {
      getBulkSms,
      status,
      sortOrder,
      searchTerm,
      selectedLocations,
    } = this.props;
    getBulkSms(
      status.value,
      pageNumber,
      sortOrder,
      searchTerm,
      selectedLocations
    );
  };

  pageClicked = (pageNumber) => {
    this.getBulkSmssWithPageNumber(pageNumber);
  };

  sortSearchUpdated = (sort, search) => {
    const { sortSearchUpdated, status, selectedLocations } = this.props;
    sortSearchUpdated(status.value, sort, search, selectedLocations);
  };

  handledLocationsUpdated = (locations) => {
    const {
      selectedLocationsUpdated,
      status,
      sortOrder,
      searchTerm,
    } = this.props;
    selectedLocationsUpdated(status.value, sortOrder, searchTerm, locations);
  };

  emptyTitleString = () => {
    const titleStatus = toTitleCase(this.props.status.title);
    return `${STRINGS.Global.no} ${titleStatus} ${STRINGS.BulkMessaging.bulkSmss}`;
  };

  render() {
    const {
      bulkSmss,
      loading,
      sortOrder,
      searchTerm,
      selectedLocations,
      userPermissionRuleSet,
    } = this.props;

    const { view_all_marketing_assets } = userPermissionRuleSet;
    const locationFilterEnabled = !!view_all_marketing_assets;

    const rows = bulkSmss.map((bulkSms, index) => {
      return (
        <BulkSmsRowContainer
          key={index}
          bulkSms={bulkSms}
          onSelect={this.bulkSmsSelected}
          showCreatedBy={locationFilterEnabled}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={this.sortSearchUpdated}
          sortColumnWidth={"4"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
          showLocationFilter={locationFilterEnabled}
          locationsUpdated={this.handledLocationsUpdated}
          initialSelectedLocations={selectedLocations}
        />
        <div className="row">
          <div className="col-xs-12">
            {bulkSmss.length === 0 && !loading && (
              <ImageEmptyView
                image={Empty}
                title={this.emptyTitleString()}
                subtitle={listingStrings.emptyViewSubtitle}
              />
            )}
            <GymLeadsLoader
              active={loading}
              text={listingStrings.loadingMessages}
            />
            {bulkSmss.length > 0 && !loading && (
              <div>
                {rows}
                <PagesContainer pageClicked={this.pageClicked} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BulkSmsListingView.propTypes = {
  getBulkSms: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  bulkSmss: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  pageDetails: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  errors: PropTypes.object,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  selectedLocationsUpdated: PropTypes.func,
};

export default BulkSmsListingView;
