import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SelectDealModalView from "./SelectDealModalView";

import deals from "modules/stores/deals";
import * as apiActions from "modules/stores/deals/apiActions";
import modal from "modules/stores/modal";

function mapStateToProps(state) {
  return {
    deals: deals.selectors.getDeals(state),
    loading: deals.selectors.getLoading(state),
    errors: deals.selectors.getErrors(state),
    pageDetails: deals.selectors.getPageDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDeals: apiActions.getDeals,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectDealModalView);
