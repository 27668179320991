import { request, PUT, DELETE, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import { fieldNames } from "./constants";
import { push } from "react-router-redux";
import flags from "modules/components/flags";

import { formDataFromData } from "utils/formHelpers";

import loadGroup from "../loadGroup";

export function prefillDataForGroup(group) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_GROUP,
    group
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}

export function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setHasChanges(hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  };
}

export const update = (formData, groupId) => {
  return dispatch => {
    dispatch(setLoading(true));
    const body = {
      marketing_group: formDataFromData(formData, fieldNames)
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(loadGroup.actions.setGroup(payload.marketing_group));
      dispatch(setLoading(false));
      dispatch(setHasChanges(false));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: "Group settings updated",
          index: 0
        })
      );
    }

    return request(
      "marketing_groups/" + groupId,
      PUT,
      body,
      onSuccess,
      onFailure
    );
  };
};

export const deleteGroup = groupId => {
  return dispatch => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(push("/secure/groups"));
      dispatch(setLoading(false));
      dispatch(setHasChanges(false));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: "Group deleted",
          index: 0
        })
      );
    }

    return request(
      "marketing_groups/" + groupId,
      DELETE,
      null,
      onSuccess,
      onFailure
    );
  };
};

export const generateShareCode = group => {
  return dispatch => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      const updatedGroup = {
        ...group,
        share_code: payload.marketing_group_recipe.share_code
      };
      dispatch(loadGroup.actions.setGroup(updatedGroup));
      dispatch(setLoading(false));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: "Share Code Generated",
          index: 0
        })
      );
    }

    const url = `marketing_group/${group.id}/create_private_recipe`;
    return request(url, POST, null, onSuccess, onFailure);
  };
};
