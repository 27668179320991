import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SelectGroupModalView } from 'modules/components'

import * as actions from '../actions'
import * as selectors from '../selectors'
import { MODAL_NAME } from '../constants'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    groups: selectors.getGroups(state),
    selectedGroup: selectors.getSelectedGroup(state),
    pageDetails: selectors.getPageDetails(state),
    modalName: MODAL_NAME
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getGroups: actions.getGroups,
    setSelectedGroup: actions.setSelectedGroup,
    hideModal: modal.actions.hideModal,
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectGroupModalView)
