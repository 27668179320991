import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { pluralizationHelper } from 'modules/helpers/PluralizationHelpers'

import { ContentBlockView, InfoView } from 'modules/components'

export class TextDetailsView extends Component {

  render () {
    const { bulkSms, messageCost, pricingDetails, productName } = this.props

    return (
      <div>
        <ContentBlockView title={'Character limits'} bordered disabled={bulkSms.status === 'scheduled'}>
          <p><span className='text-muted'>{pricingDetails.remainingCharsLength} characters remaining until it's a new message</span><br />
          {pricingDetails.totalCharsRemaining} total characters remaining<br />
          {pluralizationHelper('messages', pricingDetails.messageCount)} {' '} is ${parseFloat(pricingDetails.currentCost).toFixed(2)} in total,  {' '}
          each SMS is charged at ${messageCost.toFixed(2)}</p>      
          {bulkSms.total_leads_count > 0 &&
            <p>With {pluralizationHelper('leads', bulkSms.total_leads_count)}, this bulk sms is estimated to cost ${(bulkSms.total_leads_count * pricingDetails.currentCost).toFixed(2)}</p>
          }

          {pricingDetails.emojiDetected &&
            <InfoView colorThemeClass='info'>
              <p>
                It looks like you're using emojis! <span role='img' aria-label='smile'>😀</span> <br />Emoijs are a great way to make your message stand out,{' '}
                but they do make the message more expensive because of the way carriers need to enocde the message.{' '}
                See our <a style={{ fontWeight: 'bold' }} href='http://support.gymleads.net/how-it-works/communication/sending-an-sms' target='__blank'>
                  support article</a> for more details
              </p>
            </InfoView>
          }
          <p className='text-muted'>
            *The above is an estimate only. Actual numbers may differ based on the actual value of merge fields.<br /><br />
            {productName} also adds an opt out message and link at the end of every message to identify you as the sender and help them opt out if they wish.{' '}
            This is required by law in all countries {productName} operates in.
          </p>
        </ContentBlockView>
      </div>
    )
  }
}

TextDetailsView.propTypes = {
  messageCost: PropTypes.number.isRequired,
  bulkSms: PropTypes.object.isRequired,
  productName: PropTypes.string,
  pricingDetails: PropTypes.object.isRequired
}

export default TextDetailsView
