import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import settings from '../settings'

export const get = (automationFunnelId, automationFunnelStepId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      if (payload) {
        dispatch(settings.actions.prefillDataForEmail(payload.automation_email_template))
        dispatch(setAutomationEmail(payload.automation_email_template))
      }
      dispatch(setLoading(false))
    }
    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_email_templates'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export function setSelectedFunnelStepId (selectedFunnelStepId) {
  return {
    type: actionTypes.SET_SELECTED_FUNNEL_STEP_ID,
    selectedFunnelStepId
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setAutomationEmail (automationEmail) {
  return {
    type: actionTypes.SET_AUTOMATION_EMAIL,
    automationEmail
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
