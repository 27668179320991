import * as actionTypes from './actionTypes'

import { LIST_VIEW } from './constants'

const CREATE_ACTION_HANDLERS = {
  [actionTypes.SET_VIEW]: (state, action) => {
    return { ...state, view: action.view }
  },
  [actionTypes.SET_NEW_NAME]: (state, action) => {
    return { ...state, newName: action.newName }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  view: LIST_VIEW,
  newName: null
}

export default function (state = initialState, action) {
  const handler = CREATE_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
