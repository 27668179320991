import PropTypes from 'prop-types'
import React, { Component } from 'react'

import activeLeads from '../modules/activeLeads'
import completedLeads from '../modules/completedLeads'
import SummaryView from './SummaryView'

export class MembersView extends Component {

  render () {
    const { automationFunnel } = this.props
    return (
      <div>
        <SummaryView automationFunnel={automationFunnel} />
        <div className='row'>
          <div className='col-sm-6'>
            <activeLeads.ActiveLeadsContainer automationFunnel={automationFunnel} />
          </div>
          <div className='col-sm-6'>
            <completedLeads.CompletedLeadsContainer automationFunnel={automationFunnel} />
          </div>
        </div>
      </div>
    )
  }
}

MembersView.propTypes = {
  automationFunnel: PropTypes.object.isRequired
}

export default MembersView
