import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import * as constants from '../constants'
import STRINGS from 'strings'

import { RecipeOrNewSelectionModal } from 'modules/components/recipeOrNewSelectionModal/RecipeOrNewSelectionModal'
import create from '../modules/create'

import modal from 'modules/stores/modal'

function mapStateToProps(state) {
  return {
    title: STRINGS.Automations.Listing['createTitle'],
    recipeDetail: STRINGS.Automations.Listing['recipeSubtitle'],
    scratchDetail: STRINGS.Automations.Listing['fromScratchSubtitle'],
    modalName: constants.SELECT_RECIPE_MODAL,
    newModalName: constants.NEW_FUNNEL_MODAL,
    loading: modal.selectors.getModalLoading(state)
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    hideModal: modal.actions.hideModal,
    goToRecipes: create.actions.showSelectDesignView
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeOrNewSelectionModal))



