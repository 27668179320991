import { combineReducers } from 'redux'
import loadTemplate from './modules/loadTemplate'
import editTemplate from './modules/editTemplate'
import links from './modules/links'
import settings from './modules/settings'

export const reducer = combineReducers({
  [loadTemplate.constants.NAME]: loadTemplate.reducer,
  [links.constants.NAME]: links.reducer,
  [editTemplate.constants.NAME]: editTemplate.reducer,
  [settings.constants.NAME]: settings.reducer
})
