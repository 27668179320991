import { NAME } from "./constants";

export const getErrors = state => state.templates.emailTemplates[NAME].errors;
export const getLoading = state => state.templates.emailTemplates[NAME].loading;
export const getTemplates = state =>
  state.templates.emailTemplates[NAME].templates;
export const getPageDetails = state =>
  state.templates.emailTemplates[NAME].pageDetails;
export const getSearchTerm = state =>
  state.templates.emailTemplates[NAME].searchTerm;
export const getSortOrder = state =>
  state.templates.emailTemplates[NAME].sortOrder;

export const systemTemplates = [
  {
    id: "QuickEmail",
    title: "Edit The Quick Send Email Template",
    detail:
      "Allows users to quickly send emails in a simple editor from the main app."
  },
  {
    id: "OptInEmail",
    title: "Edit The Opt In Email Template",
    detail: "A system defined email to send users to opt them in."
  }
];
