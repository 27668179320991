import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ShowAudienceView from '../components/ShowAudienceView'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    previewLeads: selectors.getPreviewLeads(state),
    loading: selectors.getLoading(state),
    entryRulesJson: selectors.getEntryRulesJson(state),
    totalCount: selectors.getTotalCount(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getPreviewLeads: actions.getPreviewLeads
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowAudienceView))
