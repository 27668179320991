const OPT_OUT_CHAR_LENGTH = 35;
const TEXT_MESSAGE_CHAR_LENGTH = 160;
const UTF_TEXT_MESSAGE_CHAR_LENGTH = 70;
const TOTAL_UTF8_TEXT_MESSAGE_CHAR_LENGTH = 280;
const TOTAL_NORMAL_TEXT_MESSAGE_CHAR_LENGTH = 612;

const gsm7bitChars =
  "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";
const gsm7bitExChar = "\\^{}\\\\\\[~\\]|€";
const gsm7bitRegExp = new RegExp("^[" + gsm7bitChars + "]*$");
const gsm7bitExRegExp = new RegExp("^[" + gsm7bitChars + gsm7bitExChar + "]*$");
// const gsm7bitExOnlyRegExp = new RegExp("^[\\" + gsm7bitExChar + "]*$");

const GSM_7BIT = "GSM_7BIT";
const GSM_7BIT_EX = "GSM_7BIT_EX";
const UTF16 = "UTF16";

function detectEncoding(text) {
  if (!text) {
    return GSM_7BIT;
  }
  switch (false) {
    case text.match(gsm7bitRegExp) == null:
      return GSM_7BIT;
    case text.match(gsm7bitExRegExp) == null:
      return GSM_7BIT_EX;
    default:
      return UTF16;
  }
}

export function getPricingDetailsFromText(text, messageCost = 0.1) {
  const messageLength = text ? text.length : 0;
  const encoding = detectEncoding(text);

  const utfEncoding = encoding === UTF16;

  let singleMessageLength = utfEncoding
    ? UTF_TEXT_MESSAGE_CHAR_LENGTH
    : TEXT_MESSAGE_CHAR_LENGTH;

  let maxLength = utfEncoding
    ? TOTAL_UTF8_TEXT_MESSAGE_CHAR_LENGTH
    : TOTAL_NORMAL_TEXT_MESSAGE_CHAR_LENGTH;
  maxLength = maxLength - OPT_OUT_CHAR_LENGTH;

  let charsRemaining =
    singleMessageLength - OPT_OUT_CHAR_LENGTH - messageLength;
  let totalCharsRemaining = maxLength - messageLength;
  if (charsRemaining >= 0) {
    return {
      remainingCharsLength: charsRemaining,
      totalCharsRemaining: totalCharsRemaining,
      currentCost: messageCost.toString(),
      emojiDetected: utfEncoding,
      maxLength: maxLength,
      messageCount: 1,
    };
  }

  const messageCount =
    parseInt(
      ((OPT_OUT_CHAR_LENGTH + messageLength) / singleMessageLength).toFixed(0),
      10
    ) + 1;
  const remainder =
    singleMessageLength -
    ((OPT_OUT_CHAR_LENGTH + messageLength) % singleMessageLength);

  const cost = (messageCount * messageCost).toFixed(2);
  return {
    remainingCharsLength: remainder,
    emojiDetected: utfEncoding,
    maxLength: maxLength,
    totalCharsRemaining: totalCharsRemaining,
    currentCost: cost,
    messageCount: messageCount,
  };
}
