import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AppSelect from '../components/AppSelect'

import session from 'modules/stores/session'

const mapStateToProps = (state) => {
  return {
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    goToApp: session.actions.goToApp
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSelect)
