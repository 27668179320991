import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TemplateDetailsContainer from '../containers/TemplateDetailsContainer'
import TextInputContainer from '../containers/TextInputContainer'
import TemplatePreviewContainer from '../containers/TemplatePreviewContainer'

import { getPricingDetailsFromText } from 'modules/helpers/SmsHelpers'

export class EditTemplateView extends Component {

  render () {
    const { previewText, text, messageCost } = this.props
    const pricingDetails = getPricingDetailsFromText(text, messageCost)

    return (
      <div className='row'>
        <div className='col-md-6'>
          <TextInputContainer maxLength={pricingDetails.maxLength} />
          {previewText &&
            <TemplatePreviewContainer />
          }
        </div>
        <div className='col-md-6'>
          <TemplateDetailsContainer pricingDetails={pricingDetails} />
        </div>
      </div>
    )
  }
}

EditTemplateView.propTypes = {
  previewText: PropTypes.string,
  text: PropTypes.string.isRequired,
  messageCost: PropTypes.number.isRequired
}

export default EditTemplateView
