import { request, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import flags from 'modules/components/flags'
import loadAutomationEmail from '../loadAutomationEmail'
import STRINGS from 'strings'

export const sendPreview = (automationFunnelId, automationFunnelStepId, automationEmailId) => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadAutomationEmail.actions.setAutomationEmail(payload.automation_email_template))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Checklist.Preview.sendingPreviewNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_email_templates/' + automationEmailId +
    '/preview'
    return request(url, POST, null, onSuccess, onFailure)
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
