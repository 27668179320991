import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import modal from 'modules/stores/modal'

import NewFunnelModalView from '../components/NewFunnelModalView'
import listing from '../modules/listing'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createEmptyFunnel: listing.actions.createEmptyFunnel
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewFunnelModalView))
