import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";

import email from "../modules/email";
import sms from "../modules/sms";
import selectionModule from "../modules/selection";
import STRINGS from "strings";

const bulkMessagingStrings = STRINGS.BulkMessaging;

const menuOptions = [
  {
    title: STRINGS.Global.email,
    option: selectionModule.constants.EMAIL,
    icon: "fa fa-envelope-o",
  },
  {
    title: STRINGS.Global.SMS,
    option: selectionModule.constants.SMS,
    icon: "fa fa-mobile",
  },
];

export class BulkMessagingView extends Component {
  newClicked = () => {
    const { selection } = this.props;
    switch (selection) {
      case selectionModule.constants.EMAIL:
        this.props.showModal(email.constants.NEW_BULK_EMAIL_MODAL);
        break;
      case selectionModule.constants.SMS:
        this.props.showModal(sms.constants.NEW_BULK_SMS_MODAL);
        break;
      default:
        break;
    }
  };

  title = () => {
    const { selection } = this.props;
    switch (selection) {
      case selectionModule.constants.EMAIL:
        return bulkMessagingStrings.bulkEmail;
      case selectionModule.constants.SMS:
        return bulkMessagingStrings.bulkSms;
      default:
        return bulkMessagingStrings.bulk;
    }
  };

  buttonTitle = () => {
    const { selection } = this.props;
    switch (selection) {
      case selectionModule.constants.EMAIL:
        return bulkMessagingStrings.Header.button.newBulkEmail;
      case selectionModule.constants.SMS:
        return bulkMessagingStrings.Header.button.newBulkSMS;
      default:
        return bulkMessagingStrings.Header.button.newBulkMessage;
    }
  };

  render() {
    const { selection, setSelection, shouldHideHeader } = this.props;
    const menuItems = menuOptions.map((menuItem, index) => {
      const isActive = menuItem.option === selection;
      const className = isActive ? "active" : "selectable";
      return (
        <li className={className} key={index}>
          <div
            className="topSubButton"
            onClick={() => setSelection(menuItem.option)}
          >
            <i className={menuItem.icon} /> {menuItem.title}
          </div>
        </li>
      );
    });
    return (
      <div className="content content-boxed">
        {!shouldHideHeader && (
          <ContentBlockView>
            <div className="row">
              <div className="col-md-6">
                <h2>{this.title()}</h2>
                <p>{STRINGS.BulkMessaging.Header.subheadline}</p>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-success pull-right"
                  style={{ marginTop: "5px" }}
                  onClick={this.newClicked}
                >
                  <i className="fa fa-plus" /> {this.buttonTitle()}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="block">
                  <ul className="nav nav-tabs nav-tabs-alt">{menuItems}</ul>
                </div>
              </div>
            </div>
          </ContentBlockView>
        )}
        <div className="row">
          <div className="col-sm-12">
            {selection === selectionModule.constants.EMAIL && (
              <div>
                <email.BulkEmailsContainer />
                <email.NewBulkEmailModalContainer />
              </div>
            )}
            {selection === selectionModule.constants.SMS && (
              <div>
                <sms.BulkSmsContainer />
                <sms.NewBulkSmsModalContainer />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BulkMessagingView.propTypes = {
  showModal: PropTypes.func.isRequired,
  setSelection: PropTypes.func.isRequired,
  shouldHideHeader: PropTypes.bool.isRequired,
  selection: PropTypes.string.isRequired,
};

export default BulkMessagingView;
