import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import BulkEmailsView from '../components/BulkEmailsView'

import create from '../modules/create'

function mapStateToProps (state) {
  return {
    view: create.selectors.getView(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkEmailsView))
