import PropTypes from "prop-types";
import React, { Component } from "react";
import { ModalContainer } from "modules/components";
import * as arrayUtils from "utils/arrayUtils";

import MutliLeadSourceSelectView from "./MultiLeadSourceSelectView";

export class MultiLeadSourceSelectModal extends Component {
  state = {
    selectedLeadSourceIds: this.props.leadSourceIds
  };

  componentWillReceiveProps(nextProps) {
    const oldLeadSourceIds = this.props.leadSourceIds;
    const newLeadSourceIds = nextProps.leadSourceIds;

    if (!oldLeadSourceIds || !newLeadSourceIds) {
      return;
    }

    if (arrayUtils.arraysEqual(oldLeadSourceIds, newLeadSourceIds)) {
      return;
    }
    this.setState({ selectedLeadSourceIds: newLeadSourceIds });
  }

  selectLeadSource = leadSource => {
    const newSelectedLeadSourceIds = [
      ...this.state.selectedLeadSourceIds,
      leadSource.id
    ];
    this.setState({
      ...this.state,
      selectedLeadSourceIds: newSelectedLeadSourceIds
    });
  };

  deselectLeadSource = leadSource => {
    const index = this.state.selectedLeadSourceIds.findIndex(
      leadSourceId => parseInt(leadSourceId, 10) === parseInt(leadSource.id, 10)
    );
    const newSelectedLeadSourceIds = [
      ...this.state.selectedLeadSourceIds.slice(0, index),
      ...this.state.selectedLeadSourceIds.slice(index + 1)
    ];
    this.setState({
      ...this.state,
      selectedLeadSourceIds: newSelectedLeadSourceIds
    });
  };

  updateClubIds = () => {
    this.props.onUpdate(this.state.selectedLeadSourceIds);
    this.cleanAndHide();
  };

  cleanAndHide = () => {
    this.setState({ selectedLeadSourceIds: [] });
    this.props.hideModal();
  };

  render() {
    const { modalName, readonly, buttonTitle } = this.props;
    const disabled = this.state.selectedLeadSourceIds.length === 0;

    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={"Select leadSources"}
        colorThemeClass={"primary"}
        showCancelButton
        readonly={readonly}
        actionEnabled={!disabled}
        actionButtonClicked={this.updateClubIds}
        actionButtonElement={
          <span>
            <i className="fa fa-building-o" /> {buttonTitle}
          </span>
        }
      >
        <MutliLeadSourceSelectView
          {...this.props}
          readonly={readonly}
          selectedLeadSourceIds={this.state.selectedLeadSourceIds}
          selectLeadSource={this.selectLeadSource}
          deselectLeadSource={this.deselectLeadSource}
        />
      </ModalContainer>
    );
  }
}

MultiLeadSourceSelectModal.propTypes = {
  getLeadSources: PropTypes.func.isRequired,
  leadSourceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onUpdate: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalName: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  buttonTitle: PropTypes.string
};

MultiLeadSourceSelectModal.defaultProps = {
  buttonTitle: "Filter by selected",
  readonly: false
};

export default MultiLeadSourceSelectModal;
