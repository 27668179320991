import PropTypes from "prop-types";
import React, { Component } from "react";
import alert from "modules/components/alert";

import { ContentBlockView, InfoView } from "modules/components";
import stepsView from "../../../../view";

import { CHECKLIST_VIEW, EDIT_VIEW, SETTINGS_VIEW } from "../constants";
import STRINGS from "strings";
const smsHeaderStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Header;

const menuItems = [
  {
    id: CHECKLIST_VIEW,
    name: smsHeaderStrings.MenuItems.checklist,
    icon: "fa fa-list-ol",
  },
  {
    id: EDIT_VIEW,
    name: smsHeaderStrings.MenuItems.editSms,
    icon: "si si-pencil",
  },
  {
    id: SETTINGS_VIEW,
    name: smsHeaderStrings.MenuItems.sendSettings,
    icon: "si si-settings",
  },
];

export class SmsHeaderView extends Component {
  backSelected = () => {
    const { showWithConfig, hasUnsavedChanges } = this.props;
    if (hasUnsavedChanges) {
      showWithConfig({
        ...alert.defaultConfig,
        children: smsHeaderStrings.unsavedChangesWarning,
        style: "warning",
        onConfirm: this.goBack,
      });
    } else {
      this.goBack();
    }
  };

  goBack = () => {
    this.props.setStepView(stepsView.constants.LIST_STEP_VIEW);
  };

  menuItemClicked = (view) => {
    this.props.setView(view);
  };

  render() {
    const { automationFunnelStep, automationTextMessage, view } = this.props;
    const name = automationFunnelStep
      ? automationFunnelStep.name
      : smsHeaderStrings.unknownStep;
    return (
      <div>
        <ContentBlockView>
          <div className="row">
            <div className="col-md-6">
              <i
                className="si si-arrow-left fa-2x pull-left selectable"
                style={{ marginTop: "5px", marginRight: "20px" }}
                onClick={this.backSelected}
              />
              <h2 className="push">
                {STRINGS.formatString(smsHeaderStrings.smsFor, <b>{name}</b>)}
              </h2>
            </div>
            {automationTextMessage &&
              automationTextMessage.communication_type === "transactional" && (
                <div className="col-md-6">
                  <InfoView colorThemeClass={"info"}>
                    <p>{smsHeaderStrings.transactionalMessagePopover}</p>
                    <a href="http://support.gymleads.net/gymleads-marketing-app/transactional-vs-marketing-communication">
                      {smsHeaderStrings.learnMore}
                    </a>
                  </InfoView>
                </div>
              )}
          </div>
          {automationTextMessage && (
            <div className="row">
              <div className="col-xs-12">
                <div className="block">
                  <ul className="nav nav-tabs nav-tabs-alt">
                    {menuItems.map((menuItem, index) => {
                      const className =
                        menuItem.id === view ? "active" : "selectable";
                      return (
                        <li
                          className={className}
                          key={index}
                          onClick={() => this.menuItemClicked(menuItem.id)}
                        >
                          <div className="topSubButton">
                            <i className={menuItem.icon} /> {menuItem.name}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </ContentBlockView>
      </div>
    );
  }
}

SmsHeaderView.propTypes = {
  automationFunnelStep: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  setStepView: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  automationTextMessage: PropTypes.object,
  showWithConfig: PropTypes.func.isRequired,
};

export default SmsHeaderView;
