import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ContentBlockView, ImageEmptyView } from 'modules/components'

import SmsReady from 'images/SmsReady.jpg'
import SmsNotReady from 'images/SmsNotReady.jpg'
import STRINGS from 'strings'

const smsChecklistStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Checklist
export class SendView extends Component {

  render () {
    const { automationTextMessage, loading } = this.props
    const checklist = automationTextMessage.checklist
    return (
      <ContentBlockView bordered loading={loading}>
        {checklist.ready_to_send &&
          <ImageEmptyView
            title={smsChecklistStrings.smsValidHeadline}
            subtitle={smsChecklistStrings.smsValidDescription}
            image={SmsReady} />
        }
        {!checklist.ready_to_send &&
          <ImageEmptyView
            title={smsChecklistStrings.smsInvalidHeadline}
            subtitle={smsChecklistStrings.smsInvalidDescription}
            image={SmsNotReady} />
        }
      </ContentBlockView>
    )
  }
}

SendView.SubjectRow = {
  automationTextMessage: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

export default SendView
