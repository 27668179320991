import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LostEntryView from "./LostEntryView";

import modal from "modules/stores/modal";

function mapStateToProps(state) {
  return {
    lostReasonSelectModalName: "SELECT_LOST_REASON_MODAL"
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LostEntryView)
);
