import { isPresent } from 'utils/validation'
import STRINGS from 'strings'

const settingsStrings = STRINGS.BulkMessaging.BulkSms.Settings

export const validator = {
  title: [{
    rule: isPresent,
    errorMessage: settingsStrings.Validator.emptyTitleField
  }]
}
