import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import modal from 'modules/stores/modal'

import NewEmailTemplateModalView from '../components/NewEmailTemplateModalView'
import create from '../modules/create'
import userData from 'modules/stores/userData'
import filters from '../modules/filters'

function mapStateToProps (state) {
  return {
    company: userData.selectors.getCompany(state),
    loading: modal.selectors.getModalLoading(state),
    templateType: filters.selectors.getSelection(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setNewName: create.actions.setNewName,
    showSelectDesignView: create.actions.showSelectDesignView,
    hideModal: modal.actions.hideModal,
    createQuickSendTemplate: create.actions.createQuickSendTemplate
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewEmailTemplateModalView))
