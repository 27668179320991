import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView, TextSearchView } from 'modules/components'

import StatusFilterView from './StatusFilterView'
import { FILTER_MODAL_NAME } from '../constants'

import STRINGS from 'strings'
const searchStrings = STRINGS.ViewBulkSharedStrings.SearchView

export class SearchView extends Component {

  showFilterOptions = () => {
    this.props.showModal(FILTER_MODAL_NAME)
  }

  render () {
    const { searchColumnWidth, hideModal, onSearchUpdate, onStatusUpdate, search } = this.props
    return (
      <ContentBlockView>
        <div className='row'>
          <div className={'col-md-' + searchColumnWidth + ' col-xs-12'}>
            <TextSearchView onUpdate={onSearchUpdate} searchText={search || ''} sectionName={'search'} />
          </div>
          <div className='col-md-2'>
            <button type='button'
              className='btn btn-block btn-primary'
              onClick={this.showFilterOptions}>
              {searchStrings.filterButton}
            </button>
          </div>
        </div>
        <StatusFilterView onStatusUpdate={onStatusUpdate} hideModal={hideModal} />
      </ContentBlockView>
    )
  }
}

SearchView.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSearchUpdate: PropTypes.func.isRequired,
  onStatusUpdate: PropTypes.func.isRequired,
  search: PropTypes.string,
  searchColumnWidth: PropTypes.string,
}

SearchView.defaultProps = {
  searchColumnWidth: '4'
}

export default SearchView
