import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'

import moment from 'moment-timezone'

export class TemplateRow extends Component {

  rowSelected = (e) => {
    const { template, onSelect } = this.props
    onSelect(template)
  }

  render () {
    const { template, timezone, showCreatedBy } = this.props
    const createdBy = (template.user && template.user.display_name) || null;

    return (
      <ContentBlockView>
        <div className='row selectable'
          onClick={this.rowSelected}>
          <div className='col-xs-5'>
            <h5>{template.title}</h5>
            <p>Last updated {moment(template.updated_at).tz(timezone).format('Do MMM YYYY, h:mm a')}
              <br />
              {showCreatedBy && createdBy && `Created by ${createdBy}`}
            </p>
          </div>
        </div>
      </ContentBlockView>
    )
  }
}

TemplateRow.propTypes = {
  template: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  showCreatedBy: PropTypes.bool.isRequired
}

export default TemplateRow
