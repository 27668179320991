import * as actionTypes from "./actionTypes";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.SET_LOADING:
        return { ...state, loading: action.loading };
      case actionTypes.SET_ERRORS:
        return { ...state, errors: action.errors };
      case actionTypes.PAGE_DETAILS_UPDATED:
        return {
          ...state,
          pageDetails: {
            pageCount: action.pageDetails.total_pages,
            currentPage: action.pageDetails.current_page,
            totalCount: action.pageDetails.total_count
          }
        };
      case actionTypes.SET_FUNNELS:
        return { ...state, funnels: action.funnels };
      case actionTypes.SET_SEARCH_TERM:
        return { ...state, searchTerm: action.searchTerm };
      case actionTypes.SET_SORT_ORDER:
        return { ...state, sortOrder: action.sortOrder };
      case actionTypes.ADD_FUNNEL:
        const updatedFunnelsAdd = [...state.funnels, action.funnel];
        return { ...state, funnels: updatedFunnelsAdd };
      case actionTypes.REMOVE_FUNNEL:
        const indexforFunnelToRemove = state.funnels.findIndex(
          funnel => parseInt(funnel.id, 10) === parseInt(action.funnelId, 10)
        );
        const updatedFunnelsRemove = [
          ...state.funnels.slice(0, indexforFunnelToRemove),
          ...state.funnels.slice(indexforFunnelToRemove + 1)
        ];
        return { ...state, funnels: updatedFunnelsRemove };
      case actionTypes.RESET:
        return initialState;
      default:
        return state;
    }
  }
  return state;
};
export default reducer;
