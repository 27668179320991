import { combineReducers } from 'redux'
import loadAutomationEmail from './modules/loadAutomationEmail'
import header from './modules/header'
import selectDesign from './modules/selectDesign'
import settings from './modules/settings'
import content from './modules/content'
import checklist from './modules/checklist'

export const reducer = combineReducers({
  [loadAutomationEmail.constants.NAME]: loadAutomationEmail.reducer,
  [header.constants.NAME]: header.reducer,
  [selectDesign.constants.NAME]: selectDesign.reducer,
  [settings.constants.NAME]: settings.reducer,
  [content.constants.NAME]: content.reducer,
  [checklist.constants.NAME]: checklist.reducer
})
