import PropTypes from 'prop-types'
import React, { Component } from 'react'

import content from '../../content'

import STRINGS from 'strings'
const priceRowStrings = STRINGS.BulkMessaging.BulkSms.Checklist.PriceRow

export class PriceRow extends Component {

  goToContent = () => {
    this.props.history.push(content.constants.NAME)
  }

  emptyChange = () => {}

  render () {
    const { bulkSms } = this.props
    const checklist = bulkSms.checklist
    const icon = (checklist.ready_to_send_preview && checklist.enough_balance) ? 'fa fa-check-circle fa-2x pull-left text-success' :
    'fa fa-circle-thin fa-2x pull-left'
    return (
      <div className='checklist-row'>
        <i className={icon} />
        <h4>{priceRowStrings.headline}</h4>
        {checklist.ready_to_send_preview && checklist.enough_balance &&
          <p>{priceRowStrings.validBalanceMessage}</p>
        }
        {checklist.ready_to_send_preview && !checklist.enough_balance &&
          <p>{priceRowStrings.validBalanceMessage}</p>
        }
        {!checklist.ready_to_send_preview &&
          <p>{priceRowStrings.cantCalculateBalanceMessage}</p>
        }
        {checklist.content_ok && checklist.ready_to_send_preview &&
          <div className='row'>
            <div className='col-md-6 col-xs-12'>
              <table className='table table-vcenter'>
                <thead>
                  <tr>
                    <th>{priceRowStrings.Table.location}</th>
                    <th className='text-center'>{priceRowStrings.Table.balance}</th>
                    <th className='text-center'>{priceRowStrings.Table.required}</th>
                  </tr>
                </thead>
                <tbody>
                  {bulkSms.location_estimate_breakdown.map(function (location, index) {
                    const backgroundClass = (!location.enough_balance) ? 'bg-danger' : ''
                    const textClass = (!location.enough_balance) ? 'text-white' : ''
                    return (
                      <tr className={textClass + ' ' + backgroundClass}
                        key={index}>
                        <td>{location.location_name}</td>
                        <td className='text-center'>
                          ${parseFloat(Math.round(location.balance * 100) / 100).toFixed(2)}
                        </td>
                        <td className='text-center'>
                          ${parseFloat(Math.round(location.estimate * 100) / 100).toFixed(2)}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    )
  }
}

PriceRow.SubjectRow = {
  bulkSms: PropTypes.object.isRequired
}

export default PriceRow
