import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import alert from 'modules/components/alert'

import SettingsView from '../components/SettingsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import loadBulkSms from '../../loadBulkSms'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state),
    loading: selectors.getLoading(state),
    bulkSms: loadBulkSms.selectors.getBulkSms(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateData: actions.updateData,
    update: actions.update,
    showAlertWithConfig: alert.actions.showWithConfig,
    deleteBulkSms: actions.deleteBulkSms
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsView))
