import PropTypes from "prop-types";
import React, { Component } from "react";
import STRINGS from "strings/";
import { PagesView } from "modules/components";
import TemplatesEmpty from "images/TemplatesEmpty.jpg";

import {
  ImageEmptyView,
  ContentBlockView,
  SortSearchView,
  GymLeadsLoader
} from "modules/components";

import { SelectDesignCell } from "./DesignCells";
import sortOptions from "../sortOptions";

export class DesignsView extends Component {
  state = {
    searchTerm: null,
    sortOrder: sortOptions[3].id
  };

  componentDidMount() {
    this.getDesignsWithPageNumber(1);
  }

  getDesignsWithPageNumber = pageNumber => {
    const { getDesigns } = this.props;
    const { sortOrder, searchTerm } = this.state;
    getDesigns(pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getDesignsWithPageNumber(pageNumber);
  };

  sortSearchUpdated = (sort, search) => {
    const { getDesigns } = this.props;
    this.setState(
      { searchTerm: search, sortOrder: sort },
      getDesigns(1, sort, search)
    );
  };

  designSelected = design => {
    const { createTemplateFromDesign, company } = this.props;
    createTemplateFromDesign(design.id, company.id);
  };

  render() {
    const { designs, loading, pageDetails } = this.props;
    const { sortOrder, searchTerm } = this.state;

    const designCells = designs.map((design, index) => {
      return (
        <SelectDesignCell
          key={index}
          design={design}
          onSelect={this.designSelected}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={this.sortSearchUpdated}
          sortColumnWidth={"4"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <ContentBlockView
          title={STRINGS.Templates.Email.Designs.systemDesigns}
          bordered
        >
          <GymLeadsLoader
            title={STRINGS.Templates.Email.Designs.loadingDesigns}
            active={loading}
          />
          {designs.length === 0 && !loading && (
            <ImageEmptyView
              image={TemplatesEmpty}
              title={STRINGS.Templates.Email.Designs.noDesigns}
              subtitle={STRINGS.Templates.Email.Designs.noDesignsDetail}
            />
          )}
          {designs.length !== 0 && !loading && (
            <div className="designs-list">
              {designs.length > 0 && designCells}
            </div>
          )}
          {pageDetails.pageCount > 1 && !loading && (
            <PagesView
              pageClicked={this.pageClicked}
              pageDetails={pageDetails}
            />
          )}
        </ContentBlockView>
      </div>
    );
  }
}

DesignsView.propTypes = {
  getDesigns: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  designs: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTemplateFromDesign: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  newName: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired
};

export default DesignsView;
