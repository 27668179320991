import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import NewSmsTemplateModalView from '../components/NewSmsTemplateModalView'
import userData from 'modules/stores/userData'
import listing from '../modules/listing'

import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    company: userData.selectors.getCompany(state),
    loading: modal.selectors.getModalLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createTemplate: listing.actions.createTemplate
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewSmsTemplateModalView))
