import { connect } from 'react-redux'
import { LoginView } from '../components/LoginView'
import { withRouter } from 'react-router'

import session from 'modules/stores/session'
import * as selectors from '../selectors'
import * as actions from '../actions'

const mapStateToProps = state => ({
  loading: selectors.getLoading(state),
  errors: selectors.getErrors(state),
  user: session.selectors.getUser(state),
  attemptedUrl: session.selectors.getAttemptedUrl(state),
  sessionEndReason: session.selectors.getSessionEndReason(state),
  whitelabelProfile: selectors.getWhitelabelProfile(state),
  productName: selectors.getProductName()
})

const mapActionCreators = {
  fetchWhitelabelProfileLogo: actions.fetchWhitelabelProfileLogo,
  login: actions.login
}

export default withRouter(connect(mapStateToProps, mapActionCreators)(LoginView))
