import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import EditTemplateView from "../components/EditTemplateView";

import loadTemplate from "../../loadTemplate";
import userData from "modules/stores/userData";
import * as actions from "../actions";
import * as settingsSelectors from "../../settings/selectors";
import * as settingsActions from "../../settings/actions";

import mergeTags from "modules/stores/mergeTags";

function mapStateToProps(state) {
  return {
    settingsData: settingsSelectors.getData(state),
    template: loadTemplate.selectors.getTemplate(state),
    company: userData.selectors.getCompany(state),
    mergeTags: mergeTags.selectors.getMergeTags(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettingsData: settingsActions.updateData,
      updateTemplate: actions.updateTemplate
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditTemplateView)
);
