import * as actionTypes from './actionTypes'

const SELECT_EXIT_RULES_ACTION_HANDLERS = {
  [actionTypes.SET_EVENT]: (state, action) => {
    return { ...state, event: action.event, hasChanges: true }
  },
  [actionTypes.SET_EXIT_IS_AUTOMATIC]: (state, action) => {
    return { ...state, exitIsAutomatic: action.exitIsAutomatic, hasChanges: true }
  },
  [actionTypes.SET_HAS_CHANGES]: (state, action) => {
    return { ...state, hasChanges: action.hasChanges }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  event: null,
  hasChanges: false,
  exitIsAutomatic: false
}

export default function reducer (state = initialState, action) {
  const handler = SELECT_EXIT_RULES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
