import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import TemplatesView from "../components/TemplatesView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import loadAutomationFunnel from "containers/editAutomationFunnel/modules/loadAutomationFunnel";
import loadAutomationEmail from "../../loadAutomationEmail";

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    templates: selectors.getTemplates(state),
    pageDetails: selectors.getTemplatePageDetails(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: loadAutomationEmail.selectors.currentFunnelStep(
      state
    ),
    searchTerm: selectors.getSearchTerm(state),
    sortOrder: selectors.getSortOrder(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTemplates: actions.getTemplates,
      createTemplate: actions.createTemplate,
      sortSearchUpdated: actions.sortSearchUpdated
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TemplatesView)
);
