import PropTypes from 'prop-types'
import React, { Component } from 'react'

import loadAutomationEmail from '../modules/loadAutomationEmail'
import selectDesign from '../modules/selectDesign'

import header from '../modules/header'
import checklist from '../modules/checklist'
import content from '../modules/content'
import settings from '../modules/settings'

export class EmailView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  currentView = () => {
    const { currentView } = this.props
    switch (currentView) {
      case header.constants.CHECKLIST_VIEW:
        return <checklist.ChecklistContainer />
      case header.constants.EDIT_VIEW:
        return <content.ContentContainer />
      case header.constants.SETTINGS_VIEW:
        return <settings.SettingsContainer />
      default:
        return <div />
    }
  }

  render () {
    const { loadingAutomationEmail, automationEmail } = this.props
    return (
      <div>
        {loadingAutomationEmail &&
          <loadAutomationEmail.LoadAutomationEmailContainer />
        }
        {!loadingAutomationEmail && !automationEmail &&
          <selectDesign.SelectDesignContainer />
        }
        {!loadingAutomationEmail && automationEmail &&
          this.currentView()
        }
      </div>
    )
  }
}

EmailView.propTypes = {
  loadingAutomationEmail: PropTypes.bool.isRequired,
  automationEmail: PropTypes.object,
  currentView: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired
}


export default EmailView
