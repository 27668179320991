import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ComplianceView from '../components/ComplianceView'
import loadTemplate from '../../loadTemplate'

function mapStateToProps (state) {
  return {
    template: loadTemplate.selectors.getTemplate(state),
    compliance: loadTemplate.selectors.getCompliance(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComplianceView))
