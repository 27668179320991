import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

const stepsViewStrings = STRINGS.Funnels.EditAutomationFunnel.Checklist.Steps

export class StepsView extends Component {
  
  goToAudience = () => {
    this.props.history.push('steps')
  }
  
  render () {
    const { automationFunnel } = this.props
    const checklist = automationFunnel.checklist
    const icon = (checklist.steps_ready === false) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row-top'>
        <button
          className='pull-right btn btn-primary'
          onClick={this.goToAudience}>
          {stepsViewStrings.setUpSteps}
        </button>
        <i className={icon} />
        <h4>{stepsViewStrings.steps}</h4>
        {!checklist.steps_ready &&
          <p>
            {stepsViewStrings.notReady}
          </p>
        }
        {checklist.steps_ready &&
          <p>
            {stepsViewStrings.ready}
          </p>
        }
      </div>
    )
  }
}

StepsView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default StepsView
