import { request, POST, PUT, DELETE } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import { fieldNames } from "./constants";
import { push } from "react-router-redux";
import flags from "modules/components/flags";

import { formDataFromData } from "utils/formHelpers";

import loadTemplate from "../loadTemplate";

export function prefillDataForTemplate(template) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_TEMPLATE,
    template
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}

export function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

export function setLocationIds(locationIds) {
  return {
    type: actionTypes.SET_LOCATION_IDS,
    locationIds
  };
}

function setHasChanges(hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  };
}

export const update = (formData, locationIds, templateId, companyId) => {
  return dispatch => {
    dispatch(setLoading(true));
    let templateBody = formDataFromData(formData, fieldNames);
    templateBody = {
      ...templateBody,
      location_ids: locationIds
    };
    const body = {
      text_message_template: templateBody
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setHasChanges(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(loadTemplate.actions.setTemplate(payload.text_message_template));
      dispatch(setLoading(false));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: "Email template updated",
          index: 0
        })
      );
    }

    const url =
      "companies/" + companyId + "/text_message_templates/" + templateId;
    return request(url, PUT, body, onSuccess, onFailure);
  };
};

export const deleteTemplate = (templateId, companyId) => {
  return dispatch => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(push("/secure/templates"));
      dispatch(setLoading(false));
      dispatch(setHasChanges(false));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: "Template deleted",
          index: 0
        })
      );
    }

    const url =
      "companies/" + companyId + "/text_message_templates/" + templateId;
    return request(url, DELETE, null, onSuccess, onFailure);
  };
};

export const generateShareCode = template => {
  return dispatch => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      const updatedTemplate = {
        ...template,
        share_code: payload.share_code
      };
      dispatch(loadTemplate.actions.setTemplate(updatedTemplate));
      dispatch(setLoading(false));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: "Share Code Generated",
          index: 0
        })
      );
    }

    const url = `text_message_template/${template.id}/create_private_recipe`;
    return request(url, POST, null, onSuccess, onFailure);
  };
};
