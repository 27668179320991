import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import PreviewRow from '../components/PreviewRow'

import loadBulkSms from '../../loadBulkSms'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    bulkSms: loadBulkSms.selectors.getBulkSms(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewRow))
