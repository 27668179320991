import React from 'react'
import { createSelector } from 'reselect'

import stepViewModule from './modules/steps/modules/view'

import HeaderContainer from './modules/header/containers/HeaderContainer'
import sms from './modules/steps/modules/sms'
import email from './modules/steps/modules/email'

export const currentHeader = createSelector(
  [ stepViewModule.selectors.getView ],
  (currentStepView) => {
    switch (currentStepView) {
      case stepViewModule.constants.STEP_SMS_VIEW:
        return <sms.SmsHeaderContainer />
      case stepViewModule.constants.STEP_EMAIL_VIEW:
        return <email.EmailHeaderContainer />
      default:
        return <HeaderContainer />
    }
  }
)
