import { combineReducers } from 'redux'
import content from './modules/content'
import loadAutomationTextMessage from './modules/loadAutomationTextMessage'
import createNewTemplate from './modules/createNewTemplate'
import header from './modules/header'
import checklist from './modules/checklist'
import settings from './modules/settings'

export const reducer = combineReducers({
  [header.constants.NAME]: header.reducer,
  [content.constants.NAME]: content.reducer,
  [createNewTemplate.constants.NAME]: createNewTemplate.reducer,
  [loadAutomationTextMessage.constants.NAME]: loadAutomationTextMessage.reducer,
  [checklist.constants.NAME]: checklist.reducer,
  [settings.constants.NAME]: settings.reducer
})
