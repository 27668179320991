import { request, buildIndexUrl, GET, PUT } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

import flags from "modules/components/flags";
import loadBulkSms from "../loadBulkSms";

export const getTextMessageTemplates = (pageNumber, sort, search) => {
  return (dispatch) => {
    function onFailure(payload) {}

    function onSuccess(payload) {
      dispatch(loadPageDetails(payload.meta));
      dispatch(setTextMessageTemplates(payload.text_message_templates));
    }

    const url = buildIndexUrl(
      "text_message_template/templates_to_use",
      pageNumber,
      sort,
      search
    );
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const updateSmsContent = (bulkSms, text) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const templateBody = {
      mass_lead_text_message: {
        text: text,
      },
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setHasChanges(false));
      dispatch(loadBulkSms.actions.setBulkSms(payload.mass_lead_text_message));
      dispatch(setPreviewText(payload.mass_lead_text_message.preview));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: "Content Updated",
          index: 0,
        })
      );
    }

    const url = "mass_lead_text_messages/" + bulkSms.id;
    return request(url, PUT, templateBody, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setTextMessageTemplates(textMessageTemplates) {
  return {
    type: actionTypes.SET_TEXT_MESSAGE_TEMPLATES,
    textMessageTemplates,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function prefillDataForBulkSms(bulkSms) {
  return {
    type: actionTypes.PREFIL_FOR_BULK_SMS,
    bulkSms,
  };
}

export function setText(text) {
  return {
    type: actionTypes.SET_TEXT,
    text,
  };
}

export function setPreviewText(previewText) {
  return {
    type: actionTypes.SET_PREVIEW_TEXT,
    previewText,
  };
}

export function setSelectedTextMessageTemplate(textMessageTemplate) {
  return {
    type: actionTypes.SET_TEXT,
    text: textMessageTemplate.text,
  };
}

function setHasChanges(hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges,
  };
}
