import React from "react";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

import config from "config";

import store from "store/store";
import session from "modules/stores/session";
import userData from "modules/stores/userData";

export function newBugsnagClient() {
  const state = store.getState();
  const user = session.selectors.getUser(state);
  const company = userData.selectors.getCompany(state);
  const appVersion = session.selectors.getCurrentAppVersion(state);

  var bugsnagClient = bugsnag({
    collectUserIp: true,
    apiKey: config.bugsnagApiKey,
    appVersion: appVersion,
    releaseStage: process.env.REACT_APP_ENVIRONMENT,
    appType: "marketing",
  });

  if (user && user.id) {
    bugsnagClient.user = { id: user.id, name: user.display_name };
  } else {
    bugsnagClient.user = null;
  }

  if (company) {
    bugsnagClient.metaData = {
      company: { id: company.id, name: company.title },
    };
  } else {
    bugsnagClient.metaData = null;
  }

  bugsnagClient.use(bugsnagReact, React);
  return bugsnagClient;
}
