import PropTypes from 'prop-types'
import React, { Component } from 'react'

import listing from '../modules/listing'
import selectDesign from '../modules/selectDesign'
import filters from '../modules/filters'

import create from '../modules/create'

export class TemplatesView extends Component {

  render () {
    const { view, templateType } = this.props
    return (
      <div>
        {view === create.constants.LIST_VIEW &&
          <div className='row'>
            <div className='col-md-3'>
              <filters.TemplateFiltersContainer />
            </div>
            <div className='col-md-9'>
              {(templateType === filters.constants.HTML_TEMPLATES || templateType === filters.constants.QUICK_SEND_TEMPLATES) &&
                <listing.TemplateListingContainer />
              }
              {templateType === filters.constants.SYSTEM_TEMPLATES &&
                <listing.SystemTemplateListingContainer />
              }
            </div>
          </div>
        }
        {view === create.constants.SELECT_DESIGN_VIEW &&
          <selectDesign.SelectDesignContainer />
        }
      </div>
    )
  }
}

TemplatesView.propTypes = {
  view: PropTypes.string.isRequired,
  templateType: PropTypes.string.isRequired
}

export default TemplatesView
