import { NAME } from './constants'

export const RESET = NAME + '/RESET'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const SET_JOIN_ROWS = NAME + '/SET_JOIN_ROWS'
export const REMOVE_JOIN_ROW_WITH_ID = NAME + '/REMOVE_JOIN_ROW_WITH_ID'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
