import conditions from './modules/conditions'
import showAudience from './modules/showAudience'

export const prefillDataForBulkEmail = (bulkEmail) => {
  return (dispatch) => {
    dispatch(conditions.actions.prefillDataForBulkEmail(bulkEmail))
  }
}

export const reset = () => {
  return (dispatch) => {
    dispatch(conditions.actions.reset())
    dispatch(showAudience.actions.reset())
  }
}
