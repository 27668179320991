import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ConditionsView from '../components/ConditionsView'
import * as selectors from '../selectors'


function mapStateToProps (state) {
  return {
    rules: selectors.getRules(state),
    selectedGroup: selectors.getSelectedGroup(state),
    condition: selectors.getCondition(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConditionsView))
