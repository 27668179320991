import PropTypes from 'prop-types'
import React from 'react'
import { ModalContainer } from 'modules/components'
import STRINGS from 'strings'

import Empty from 'images/Empty.png'
import Notebook from 'images/Notebook.png'

import styled from 'styled-components'

const OptionsView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0px 0px 16px 0px;
`;

export class RecipeOrNewSelectionModal extends React.Component {

  onFromScratchClick = () => {
    const { showModal, newModalName, hideModal } = this.props 
    hideModal()
    // Pause timeout fixes bug which causes the scroll to stop working
    // when a modal 
    setTimeout(function() {
      showModal(newModalName)
    }, 650);
    
  }

  onRecipeSelect = () => {
    const { hideModal, goToRecipes } = this.props
    hideModal()
    goToRecipes()
  }

  render() {
    const { modalName, title, recipeDetail, scratchDetail } = this.props
    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={title}
        colorThemeClass={'success'}
        showCancelButton>
        <OptionsView>
          <SelectRecipeComponent
            title={STRINGS.Templates.TextMessage.List['recipeTitle']}
            subtitle={recipeDetail}
            image={Notebook}
            onClick={this.onRecipeSelect} />
          <SelectRecipeComponent
            title={STRINGS.Templates.TextMessage.List['fromScratchTitle']}
            subtitle={scratchDetail}
            image={Empty}
            onClick={this.onFromScratchClick} />
        </OptionsView>
      </ModalContainer>
    )
  }
}

RecipeOrNewSelectionModal.propTypes = {
  title: PropTypes.string.isRequired,
  recipeDetail: PropTypes.string.isRequired,
  scratchDetail: PropTypes.string.isRequired,
  modalName: PropTypes.string.isRequired,
  newModalName: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  goToRecipes: PropTypes.func.isRequired
}

const SelectRecipe = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 240px;
  width: 45%;
  cursor: pointer;
  border: 1px solid #D4D5D6;
  border-radius: 6px;
  padding: 16px;

  &:hover {
    padding: 15px;
    border: 2px solid #2065AA
  }
`;

const Title = styled.h3`
  text-align: center;
`;

const Subtitle = styled.p`
  text-align: center;
`;

const Image = styled.img`
  height: 40%;
`;

const SelectRecipeComponent = ({ title, subtitle, image, onClick }) => {
  return (
    <SelectRecipe onClick={onClick}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Image src={image} />
    </SelectRecipe>
  )
}
