import PropTypes from 'prop-types'
import React, { Component } from 'react'

import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'

import { personalRules, dateRules, statusRules } from '../rules'

class RuleMatchOptionsSelection extends Component {

  render () {
    return (
      <div style={{ marginBottom: '10px' }}>
        <DropdownMenu
          trigger={'+ Add filter'}
          triggerType="default">
          <DropdownItemGroup id="personal-details" title="Personal details">
            {personalRules.map((rule, i) => (
              <DropdownItem key={i} id={rule.id} onClick={()=> this.props.ruleSelected({rule})}>
                <i className={rule.icon} /> {rule.name}
              </DropdownItem>
            ))}
          </DropdownItemGroup>
          <DropdownItemGroup id="status-details" title="Status">
            {statusRules.map((rule, i) => (
              <DropdownItem key={i} id={rule.id} onClick={()=> this.props.ruleSelected({rule})}>
                <i className={rule.icon} /> {rule.name}
              </DropdownItem>
            ))}
          </DropdownItemGroup>
          <DropdownItemGroup id="personal-details" title="Date">
            {dateRules.map((rule, i) => (
              <DropdownItem key={i} id={rule.id} onClick={()=> this.props.ruleSelected({rule})}>
                <i className={rule.icon} /> {rule.name}
              </DropdownItem>
            ))}
          </DropdownItemGroup>
        </DropdownMenu>
      </div>
    )
  }
}

RuleMatchOptionsSelection.propTypes = {
  ruleSelected: PropTypes.func.isRequired
}

export default RuleMatchOptionsSelection
