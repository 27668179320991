import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Avatar, LeadStatusTag } from 'modules/components'

import './styles.css'

export class LeadToolTip extends Component {

  render () {
    const { lead } = this.props
    return (
      <div className='lead-tooltip'>
        <div className="row">
          <div className="col-xs-12">
            <div className='lead-tooltip-top'>
              <span className='pull-left' style={{
                marginRight: '10px'
              }}>
                <Avatar size={30} name={lead.display_name} />
              </span>
              <p>{lead.display_name}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-4">
            <div style={{
              marginTop: '10px',
              marginBottom: '10px'
            }}>
              <LeadStatusTag leadStatus={lead.status} />
            </div>
          </div>
          <div className="col-xs-4" />
          <div className="col-xs-4" />
        </div>
      </div>
    )
  }
}

LeadToolTip.propTypes = {
  lead: PropTypes.object.isRequired
}

export default LeadToolTip
