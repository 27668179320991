import { connect } from 'react-redux'
import { SsoView } from '../components/SsoView'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import * as actions from '../actions'

function mapStateToProps (state) {
  return {
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadSsoSession: actions.loadSsoSession
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SsoView))
