import { connect } from 'react-redux'
import { SecureView } from '../components/SecureView'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import session from 'modules/stores/session'
import userData from 'modules/stores/userData'

const mapStateToProps = state => ({
  loadingComplete: userData.selectors.loadingComplete(state),
  sessionLoading: session.selectors.getLoading(state)
})

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadInitialStateForCurrentUser: userData.actions.loadInitialStateForCurrentUser,
    clearAttemptedUrl: session.actions.clearAttemptedUrl,
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecureView))
