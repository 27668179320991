import * as actionTypes from "./actionTypes";
import SortOptions from "./sortOptions";

const LIST_TEMPLATE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_TEMPLATES]: (state, action) => {
    return { ...state, templates: action.templates };
  },
  [actionTypes.SET_SORT_ORDER]: (state, action) => {
    return { ...state, sortOrder: action.sortOrder };
  },
  [actionTypes.SET_SEARCH_TERM]: (state, action) => {
    return { ...state, searchTerm: action.searchTerm };
  },
  [actionTypes.ADD_TEMPLATE]: (state, action) => {
    const updatedTemplates = [...state.templates, action.template];
    return { ...state, templates: updatedTemplates };
  },
  [actionTypes.REMOVE_TEMPLATE]: (state, action) => {
    const index = state.templates.findIndex(
      template => parseInt(template.id, 10) === parseInt(action.templateId, 10)
    );
    const updatedTemplates = [
      ...state.templates.slice(0, index),
      ...state.templates.slice(index + 1)
    ];
    return { ...state, templates: updatedTemplates };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  loading: false,
  errors: null,
  templates: [],
  searchTerm: null,
  sortOrder: SortOptions[3].id,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

export default function(state = initialState, action) {
  const handler = LIST_TEMPLATE_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
