import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ResultsBreakdownView from '../components/ResultsBreakdownView'
import * as actions from '../actions'
import * as selectors from '../selectors'

import loadBulkEmail from '../../loadBulkEmail'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    bulkEmail: loadBulkEmail.selectors.getBulkEmail(state),
    records: selectors.getRecords(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    get: actions.get,
    showModal: modal.actions.showModal,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultsBreakdownView))
