import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import SalesFunnelStepEntryView from './SalesFunnelStepEntryView'

import userData from 'modules/stores/userData'

function mapStateToProps (state) {
  const steps = [
    ...userData.selectors.getSalesFunnelSteps(state),
    { id: 'any', name: 'Anything'}
  ]
  return {
    salesFunnelSteps: steps
  }
}

export default withRouter(connect(mapStateToProps)(SalesFunnelStepEntryView))
