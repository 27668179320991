import { request, GET } from "utils/apiUtils";
import { buildIndexUrl } from "utils/apiClient";
import * as actionTypes from "./actionTypes";

export const getTags = (pageNumber = 1, searchTerm = null) => {
  return (dispatch) => {
    dispatch(startFetchingOptions());
    function onFailure(errors) {
      dispatch(fetchingOptionsFailed(errors));
    }

    function onSuccess(payload) {
      dispatch(fetchingOptionsSuccess(payload.lead_tags));
      dispatch(setPageDetails(payload.meta));
    }

    const url = buildIndexUrl("lead_tags", pageNumber, undefined, searchTerm);

    return request(url, GET, null, onSuccess, onFailure);
  };
};

function startFetchingOptions() {
  return {
    type: actionTypes.FETCHING_OPTIONS,
  };
}

function fetchingOptionsSuccess(options) {
  return {
    type: actionTypes.FETCHING_OPTIONS_SUCCESS,
    options,
  };
}

function fetchingOptionsFailed(errors) {
  return {
    type: actionTypes.FETCHING_OPTIONS_FAILED,
    errors,
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}
