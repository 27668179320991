import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './styles.css'

class EnquiresEntry extends Component {
  render () {
    return (
      <div style={{ display: 'inline' }} />
    )
  }
}

EnquiresEntry.propTypes = {
  id: PropTypes.string.isRequired,
  eventUpdated: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default EnquiresEntry
