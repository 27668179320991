import * as actionTypes from './actionTypes'

const TRIALS_ACTION_HANDLERS = {
  [actionTypes.SET_TRIALS]: (state, action) => {
    return { ...state, trials: action.trials }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  }
}

const initialState = {
  trials: [],
  errors: null,
  loading: false,
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = TRIALS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
