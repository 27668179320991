import * as actionTypes from './actionTypes'

const LOAD_GROUP_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_AUTOMATION_FUNNEL]: (state, action) => {
    return { ...state, automationFunnel: action.automationFunnel }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  },
  [actionTypes.SET_NOT_FOUND]: (state, action) => {
    return { ...state, notFound: action.notFound }
  },
  [actionTypes.SET_CHECKLIST]: (state, action) => {
    const oldFunnel = state.automationFunnel
    const newFunnel = { ...oldFunnel, checklist: action.checklist }
    return { ...state, automationFunnel: newFunnel }
  }
}

const initialState = {
  automationFunnel: null,
  notFound: false,
  loading: false,
  errors: null
}

export default function (state = initialState, action) {
  const handler = LOAD_GROUP_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
