import { NAME } from './constants'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const RESET = NAME + '/RESET'
export const PREFIL_FOR_BULK_SMS = NAME + '/PREFIL_FOR_BULK_SMS'
export const SET_TEXT = NAME + '/SET_TEXT'
export const SET_PREVIEW_TEXT = NAME + '/SET_PREVIEW_TEXT'
export const SET_TEXT_MESSAGE_TEMPLATES = NAME + '/SET_TEXT_MESSAGE_TEMPLATES'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
export const SET_HAS_CHANGES = NAME + '/SET_HAS_CHANGES'
