import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Fade, FormFields } from 'modules/components'
import { RuleOptionsSelection, RuleMatchOptionsSelection, SelectedRuleItem } from 'modules/rules'
import { EventOptionSelection, SelectedEventItem } from 'modules/events'

import { TransitionGroup } from 'react-transition-group'
import './styles.css';
const { Switch } = FormFields

class EntryRulesView extends Component {

  handleToggle = (name, value) => {
    this.props.setEntryIsAutomatic(value)
  }

  render () {
    const {
      rules, condition, setCondition, addRule,
      updateRule, removeRule, setEvent, event,
      entryIsAutomatic
     } = this.props
    return (
      <div>
        <h6 className='push-10'>Entering the group</h6>
        <RuleOptionsSelection
          selectedOption={condition}
          setSelectedOption={setCondition} />
        <RuleMatchOptionsSelection
          ruleSelected={addRule} />
        <TransitionGroup>
          {rules.map((rule, i) => (
            <Fade key={i}>
              <SelectedRuleItem
                rule={rule}
                index={i}
                removeRuleAtIndex={removeRule}
                ruleUpdatedWithData={updateRule} />
            </Fade>
          ))}
        </TransitionGroup>
        <div style={{ borderBottom: '1px solid #e9e9e9', marginBottom: '10px' }} />
        <div style={{ display: 'block', marginBottom: '10px' }}>
          <span>
            Add to the group when:{' '}
            {!event &&
              <EventOptionSelection eventSelected={setEvent} />
            }
          </span>
        </div>
        {event &&
          <SelectedEventItem
            event={event}
            setEvent={setEvent} />
        }
        <Switch
          name='automaticEntry'
          label='Add matching leads to the group without an event happening'
          onChange={this.handleToggle}
          switchStyle='switch-sm switch-primary'
          value={entryIsAutomatic} />
      </div>
    )
  }
}

EntryRulesView.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  condition: PropTypes.string.isRequired,
  event: PropTypes.object,
  setRules: PropTypes.func.isRequired,
  setCondition: PropTypes.func.isRequired,
  addRule: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
  removeRule: PropTypes.func.isRequired,
  setEvent: PropTypes.func.isRequired,
  entryIsAutomatic: PropTypes.bool.isRequired,
  setEntryIsAutomatic: PropTypes.func.isRequired
}

export default EntryRulesView
