import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import alert from 'modules/components/alert'
import modal from 'modules/stores/modal'

import DraftSendView from '../components/DraftSendView'
import * as actions from '../actions'
import loadBulkSms from '../../loadBulkSms'

function mapStateToProps (state) {
  return {
    bulkSms: loadBulkSms.selectors.getBulkSms(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    startSend: actions.startSend,
    showAlertWithConfig: alert.actions.showWithConfig,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DraftSendView))
