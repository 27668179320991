import * as actionTypes from './actionTypes'

import * as constants from './constants'

export function showSelectDesignView () {
  return {
    type: actionTypes.SET_VIEW,
    view: constants.SELECT_DESIGN_VIEW
  }
}

export function showListingView () {
  return {
    type: actionTypes.SET_VIEW,
    view: constants.LIST_VIEW
  }
}

export function setNewName (newName) {
  return {
    type: actionTypes.SET_NEW_NAME,
    newName
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
