export default {
  Global: {
    ok: "Ok",
    cancel: "Cancel",
    edit: "Edit",
    yes: "Yes",
    no: "No",
    done: "Done",
    or: "or",
    OR: "OR",
    SMS: "SMS",
    and: "and",
    email: "Email",
    loading: "Loading",
    create: "Create",
    update: "Update",
    delete: "Delete",
    clearSelection: "Clear selection",
    goBackButton: "Go Back",
    name: "name",
    Name: "Name",
    status: "Status",
    location: "Location",
    number: "Mobile number",
    leads: "leads",
    search: "Search",
    clearSeach: "Clear search",
    copyrightDate: "2016-2022",
    copyrightText: "© GymLeads",
    poweredByGymleads: "© Powered by GymLeads",
    documentTitle: "The CRM for Gyms and fitness clubs",
    username: "Username",
    password: "Password",
    drafts: "Drafts",
    draft: "Draft",
    active: "Active",
    from: "From",
    to: "To",
    date: "Date",
    replied: "Replied",
    scheduled: "Scheduled",
    sent: "Sent",
    templates: "Templates",
    lastUpdated: "Last updated",
    Sort: {
      nameAscending: "Name (Ascending)",
      nameDescending: "Name (Descending)",
      dateAscending: "Date (Ascending)",
      dateDescending: "Date (Descending)",
    },
    SmsHelper: {
      characterLimitsHeading: "Character limits",
      textMessagePreviewHeading: "Text Message Preview",
      textMessageContentHeading: "Text Message Content",
      textfieldPlaceholder: "Insert Merge tag",
      selectTemplateButton: "Select Template",
      updateContentButton: "Update Content",
      charactersRemaining: "characters remaining until it's a new message",
      totalCharactersRemaining: "total characters remaining",
      chargedAt: "each SMS is charged at",
      estimatedPrice: "this bulk sms is estimated to cost",
      usingEmoji:
        "It looks like you're using emojis! {0}Emoijs are a great way to make your message stand out, but they do make the message more expensive because of the way carriers need to enocde the message. See our {1} for more details",
      supportArticle: "support article",
      disclaimer:
        "*The above is an estimate only. Actual numbers may differ based on the actual value of merge fields. {0} also adds an opt out message and link at the end of every message to identify you as the sender and help them opt out if they wish. This is required by law in all countries {0} operates in.",
    },
  },
  Menu: {
    bulkMessaging: "Bulk Messaging",
    templates: "Templates",
    groups: "Groups",
    funnels: "Funnels",
    support: "Support",
    menu: "Menu",
  },
  SetAudience: {
    saveAudienceButton: "Save Audience",
    changeGroupButton: "Change Group",
    useCustomFiltersButton: "Use Custom Filters",
    selectGroupButton: "Select Group",
    selectFiltersHeadline: "Select your filters",
    audienceUpdatedNotification: "Audience Updated",
    noLeadsSelectedTitle: "No Leads Selected",
    noLeadsSelectedSubtitle:
      "Select a group or set some rules on who should get this message.",
    leadsWhoMatchFilter: "Leads who currently match filters:",
  },
  BulkMessaging: {
    bulkEmail: "Bulk Email",
    bulkEmails: "Bulk Emails",
    bulkSms: "Bulk SMS",
    bulkSmss: "Bulk SMSs",
    bulk: "Bulk",
    Header: {
      subheadline: "Create, edit and view your bulk messages",
      button: {
        newBulkEmail: "New Bulk Email",
        newBulkSMS: "New Bulk SMS",
        newBulkMessage: "New Bulk Message",
      },
    },
    NewBulkEmailModal: {
      headline: "Create Bulk Email",
      subheadline:
        "Just give your email template a name and you'll be on your way! You can always change the name later.",
      createNewBulkEmail: "Create a New Bulk Email",
      creatingBulkEmail: "Creating Bulk Email",
      textFieldPlaceholder: "New Summer Deal 2018",
    },
    BulkSmsListing: {
      loadingMessages: "Loading Messages",
      emptyViewSubtitle:
        "Send out a bulk SMS for a flash deal or special announcement.",
    },
    BulkEmail: {
      Header: {
        Menu: {
          checkSend: "Check and Send",
          content: "Content",
          audience: "Set Audience",
          settings: "Send Settings",
        },
        cantEditDescription:
          "You can't make any changes because this email has been scheduled to send. To make any changes, you'll need to cancel the send first.",
        emailNotFoundTitle: "Email Not Found",
        emailNotFoundSubtitle:
          "It looks like this bulk email has been deleted.",
        emailNotFoundButton: "Go Back",
        unsavedChangesWarning: "You will lose any unsaved changes",
        unnamedEmail: "Unammed Bulk Email",
        transactionalEmail:
          "This is a transactional message. It can only be sent to leads that are closed.",
        learnMore: "Learn more",
        loadingText: "Loading Bulk Email",
      },
      Checklist: {
        editContentButton: "Edit Content",
        emailContentHeader: "Email Content",
        complianceInvalidMessage:
          "You must fix the compliance issues in your email.",
        complianceValidMessage: "Your email looks compliant.",
        mergetagsInvalidMessage: "Some of your merge tags are incorrect:",
        mergetagsValidMessage: "Your merge tags are correct.",
        previewSendingNotification: "Preview Sending",
        cancelScheduleSendNotifcation: "Scheduled Email send cancelled",
        scheduledEmailNotification: "Email Send Scheduled",
        SendView: {
          sendEmailConfirmationMessage:
            "Are you sure you want to start sending this email? It will start sending immediately to {0} people.",
          emailReadyTitle: "Ready To Roll!",
          emailReadySubtitle1: "You can send the email to",
          emailReadySubtitle2: "people when you're ready.",
          scheduleLater: "Schedule a Send Time For Later",
          scheduleModalTitle: "Schedule The Send Time",
          scheduleModalButton: "Schedule Email",
          sendEmailButton: "Send Emails Now",
          emailNotFinishedTitle: "You're Almost There!",
          emailNotFinishedMessage:
            "Make sure you've completed all of the above before sending the email.",
        },
        FromRow: {
          editButton: "Edit In Send Settings",
          yourUsingProductEmailMessage: "You're using your {0} email address.",
          yourUsingEmailMessage: "You're using the email address {0}.",
          itWillAppearMessage:
            "It will appear as {0} in your recipients' inbox.",
          from: "From",
        },
        PreviousRow: {
          sendPreviewButton: "Send Preview",
          sendPreviewHeadliner: "Send Yourself A Preview",
          previewSentTo: "Preview sent to {0}",
          sendAPreviewToYourself:
            "Send a preview of the email to yourself to review before sending it to your audience.",
          emailInvalid:
            "Resolve the above issues before sending yourself a preview.",
        },
        ScheduleSend: {
          cancelWarningMessage:
            "Are you sure you want to cancel sending this email?",
          sendingIn: "Sending in",
          sendToPeople: "This message will send to",
          people: "people",
          cancelScheduleSendButton: "Cancel Scheduled Send",
          changeSendTimeButton: "Change The Send Time",
          scheduleModalTitle: "Edit The Send Time",
          scheduleModalButton: "Update Send Time",
          selectDateButton: "Click to select a date",
          whenToSend: "What date should we send the email?",
          whatTimeToSend: "What time should it send?",
          timezone: "*This is based on your timezone of",
          inTimezone: "in timezone",
          oldDate:
            "That date looks like it's in the past! Please select a date in the future.",
        },
        Legacy: {
          headerTitle: "Legacy email Campaign",
          headerSubtitle:
            "Legacy email campaigns (email campaigns created in the sales app) are not compatible with the new marketing product. Please delete this email and create a new one.",
          headerButton: "Go Back",
        },
      },
      Content: {
        saveButton: "Save Email",
        saveSuccessNotification: "Content Updated",
      },
      Settings: {
        settingsTitle: "Send Settings",
        titleFieldLabel: "Bulk Email Title",
        titleFieldPlaceholder: "What should this bulk email be called?",
        subjectFieldLabel: "Default Subject",
        subjectFieldPlaceholder: "Email subject line (merge fields work too!)",
        fromFieldLabel: "From Name",
        fromFieldPlaceholder:
          "Who should the email appear from? (Leave empty for default)",
        communicationTypeLabel: "Is this a transactional email?",
        communicationTypeToolTip:
          "Transactional Emails have different rules and restrictions that apply to who can recieve them. Bulk transactional messages can only be sent to closed leads.",
        communicationTypeToolButton:
          "Learn more about transactional and marketing communication.",
        deleteEmailWarning:
          "Are you sure you want to delete this bulk email? This cannot be undone",
        saveButton: "Save Bulk Email Details",
        deleteButton: "Delete Bulk Email",
        deleteButtonMessage:
          "Permanently delete this mass email. This cannot be undone.",
        emailUpdatedNotification: "Email Updated",
        emailDeletedNotification: "Email Deleted",
      },
    },
    BulkSms: {
      loadingText: "Loading Bulk SMS",
      Header: {
        smsNotFoundTitle: "SMS Not Found",
        smsNotFoundSubtitle: "It looks like this bulk SMS has been deleted.",
        backButtonWarning: "You will lose any unsaved changes",
        unnammedSmsTitle: "Unammed Bulk Sms",
        checkSend: "Check and Send",
        content: "Content",
        setAudience: "Set Audience",
        sendSettings: "Send Settings",
        smsScheduledMessageAlert:
          "You can't make any changes because this sms has been scheduled to send. To make any changes, you'll need to cancel the send first.",
      },
      Checklist: {
        previewSendingNotification: "Preview Sending",
        cancelScheduleSmsNotification: "Scheduled SMS send cancelled",
        setScheduleSmsNotification: "SMS Send Scheduled",
        ContentRow: {
          headline: "SMS Content",
          editContentButton: "Edit Content",
          mergeTagsIncorrect: "Some of your merge tags are incorrect:",
          mergeTagsCorrect: "Your merge tags are correct.",
          emptyContentMessage: "Enter the content of your SMS.",
        },
        DraftSendView: {
          sendButtonConfirmation:
            "Are you sure you want to start sending this sms? It will start sending immediately to {0} people.",
          checklistCompleteTitle: "Ready To Roll!",
          checklistCompleteSubtitle1: "You can send the sms to",
          checklistCompleteSubtitle2: "people when you're ready.",
          sendSmsNowButton: "Send SMS now",
          scheduleLaterButton: "Schedule a Send Time For Later",
          scheduleModalTitle: "Schedule The Send Time",
          scheduleModalButton: "Schedule Message",
        },
        FromRow: {
          title: "From",
          description: "This will be sent from the location's mobile number",
        },
        ToRow: {
          headline: "To",
          subtitle: "Who should the sms go to?",
          setAudienceButton: "Set your audience",
          sendToMarketingGroupMesage: "Sending to the {0} group. ({1} people)",
          sendToCustomFilterMesage:
            "Sending to leads matching a {0} ({1} people)",
          customFilter: "Custom Filter",
          optInMessage:
            "This SMS includes an opt in link. Leads that have already recieved a bulk email or SMS with an opt in link will be excluded.",
          learnMoreLink: "Learn more about opt in.",
        },
        PreviewModal: {
          sendPreviewModalTitle: "Send A Preview",
          sendPreviewModalButton: "Send Preview",
          sendPreviewModalDescription:
            "Enter the mobile number to send the preview to.",
          Validations: {
            numberEmpty: "What number should we send to?",
            min9Numbers: "It should'nt be shorter than 9 numbers",
            max12Numbers: "It should'nt be longer than 12 numbers",
            invalidNumberCharacter:
              "Number should be numbers only with no spaces.",
          },
        },
        PreviewRow: {
          headeline: "Send Yourself A Preview",
          sendPreviewButton: "Send Preview",
          previewSentTo: "Preview sent to",
          sendAPreview:
            "Send a preview of the sms to yourself to review before sending it to your audience.",
          previewRowInvalidMessage:
            "Resolve the above issues before sending yourself a preview.",
        },
        PriceRow: {
          headline: "Message Cost",
          validBalanceMessage: "Your SMS balances are high enough.",
          invalidBalanceMessage:
            "You'll need to top up the balance of the below locations before you can send this message.",
          cantCalculateBalanceMessage:
            "Set your content and audience to check balance amounts.",
          Table: {
            location: "Location",
            balance: "Balance",
            required: "Required",
          },
        },
        ScheduleSend: {
          cancelWarningMessage:
            "Are you sure you want to cancel sending this message?",
          sendingIn: "Sending in",
          sendToPeople: "This message will send to",
          people: "people",
          cancelScheduleSendButton: "Cancel Scheduled Send",
          changeSendTimeButton: "Change The Send Time",
          scheduleModalTitle: "Edit The Send Time",
          scheduleModalButton: "Update Send Time",
          selectDateButton: "Click to select a date",
          whenToSend: "What date should we send the SMS?",
          whatTimeToSend: "What time should it send?",
          timezone: "*This is based on your timezone of",
          inTimezone: "in timezone",
          oldDate:
            "That date looks like it's in the past! Please select a date in the future.",
        },
        componentTitle: "Checklist",
        legacyWarningTitle: "Legacy Text Message Campaign",
        legacyWarningSubtitle:
          "Legacy campaigns (campaigns created in the sales app) are not compatible with the new marketing product. Please delete this and create a new one.",
      },
      Settings: {
        contentTitle: "Send Settings",
        titleLabel: "Bulk SMS Title",
        titlePlaceholder: "What should this Bulk SMS be called?",
        titleSaveButton: "Save Bulk Sms Details",
        updatedSmsNotification: "Sms Updated",
        deleteTitle: "Delete Bulk SMS",
        deleteDescription:
          "Permanently delete this mass SMS. This cannot be undone.",
        deleteButton: "Delete Bulk Sms",
        deleteAreYouSureAlert:
          "Are you sure you want to delete this bulk sms? This cannot be undone",
        deletedSmsNotification: "Sms Deleted",
        Validator: {
          emptyTitleField: "The sms name needs a title",
        },
      },
    },
    NewBulkSMSModal: {
      headline: "Create Bulk SMS",
      subheadline:
        "Just give your bulk SMS a name and you'll be on your way! You can always change the name later.",
      createNewBulkSMS: "Create a new Bulk SMS",
      creatingBulkSMS: "Creating SMS template",
      textFieldPlaceholder: "Seminar reminder",
    },
    Email: {
      Listing: {
        BulkMessageListingView: {
          subtitle:
            "Send out a bulk email for a flash deal or special announcement.",
          loadingEmails: "Loading Emails",
        },
      },
    },
    SelectDesign: {
      title: "Basic layouts",
      chooseTemplate: "Choose a template to get started",
      backToBulkEmails: "Back to bulk emails",
      noTemplates: "No Templates",
      usedforBulkEmails: "HTML Templates can be used for Bulk Emails.",
      TemplateViewTitle: "Templates",
    },
  },
  ViewBulkEmail: {
    notFoundTitle: "Bulk Email Not Found",
    notFoundSubtitle: "It looks like this bulk email has been deleted.",
    notFoundButtonText: "Go Back",
    Header: {
      Menu: {
        titleUnnamedEmail: "Unammed Bulk Email",
        results: "Results",
        content: "Content",
        replies: "Replies",
        resultsBreakdown: "Results Breakdown",
        audience: "Audience",
      },
    },
    ConditionsView: {
      usedMarketingGroupTitle: "This Email uses a marketing group",
      usedCustomFiltersTitle: "This Email used the following custom filters",
      matchedLeads: "Leads that match {0} of these filters.",
    },
    Content: {
      contentTitle: "Subject",
      previewTitle: "Preview",
      unmergedHelperDescription:
        "This is the unmerged content of the email that was sent out. You can view how it looks for an actual lead by searching and selecting a lead below.",
      showPreviewAsDescription: "Showing preview as {0}",
      legacyMessage:
        "Legacy emails can't be previewed in the marketing app. Open a new bulk email to view the full preview.",
    },
    Results: {
      Graph: {
        panelTitle: "Delivery Rates",
        graphTitle: "Email campign stats",
      },
      Metrics: {
        title: "Total",
      },
      Results: {
        title: "Here's how your email campaign performed",
      },
    },
  },
  ViewBulkSharedStrings: {
    Statuses: {
      accepted: "Accepted",
      delivered: "Delivered",
      opened: "Opened",
      clicked: "Clicked",
      bounced: "Bounced",
      pending: "Pending",
      unsubscribe: "Unsubscribe",
      unsubscribed: "Unsubscribed",
      complained: "Complained",
      spamCompliant: "Spam Complaint",
      spamReports: "Spam Reports",
      unknown: "Unknown",
    },
    ResultsBreakdownView: {
      title: "Audience",
      noResultsTitle: "No Results Found",
      noResultsSubtitle: "Tweak your search to see more records",
    },
    RepliesView: {
      title: "Replies",
      noResultsTitle: "No Results Found",
      noResultsSubtitle: "Tweak your search to see more records",
      RepliesDetailView: {
        title: "Reply Preview",
        noResultsTitle: "No reply selected",
        noResultsSubtitle: "Select a reply to view it.",
      },
    },
    StatusFilterModal: {
      title: "Filter By Status",
      description: "Select which status to show.",
      filterButton: "Filter By Selected Statuses",
    },
    SearchView: {
      filterButton: "Filter by status",
    },
    SearchForLeadModal: {
      searchFieldPlaceholder: "Name, email or mobile number",
      noLeadsTitle: "We Couldn't Find Anyone",
      noLeadsSubtitle:
        "No leads could be found. Try searching for something else.",
      modalTitle: "Search For A Lead To Preview",
      modalDescription:
        "Search for a lead using their name, email or mobile number.",
    },
    Audience: {
      title: "Audience Breakdown",
      breakdownHeadline:
        "At the time of sending, these filters selected {0} leads to send to",
      breakdownDescriptionSuccess: "Were sent successfully.",
      breakdownDescriptionOptedOut:
        "Were not sent because they had previously opted out.",
      breakdownDescriptionBounced:
        "Were not sent because they had previously bounced.",
      breakdownDescriptionEnhancedPrivacy:
        "Were not sent because they belong to a location with enhanced privacy, and had not opted in.",
      breakdownDescriptionNoSms: "Were missing a mobile number.",
      breakdownDescriptionNoEmail: "Were missing an email field.",
      noRecordsTitle: "No Audience Records Available.",
      noRecordsDestription:
        "Audience records are only available for messages sent from the 1st of August 2018.",
    },
    DetailsPanel: {
      title: "Details",
      authoredBy: "Authored by",
      deliveredAt: "Delivered at",
      replies: "Replies",
      sentTo: "Sent to",
      subject: "Subject",
      unsubscribes: "Unsubscribes",
      usedCustomFilter: "A custom filter",
    },
  },
  ViewBulkSms: {
    notFoundTitle: "SMS Not Found",
    notFoundSubtitle: "It looks like this bulk SMS has been deleted.",
    notFoundButtonText: "Go Back",
    Header: {
      unammedTitle: "Unammed Bulk Sms",
      Menu: {
        results: "Results",
        content: "Content",
        breakdown: "ResultBreakdown",
        audience: "Audience",
      },
    },
    ConditionsView: {
      usedMarketingGroupTitle: "This SMS uses a marketing group",
      usedCustomFiltersTitle: "This SMS uses a marketing group",
      matchedLeads: "Leads that match {0} of these filters.",
    },
    Content: {
      legacyTitle: "Legacy Sms",
      legacyDescription:
        "Legacy smss can't be previewed in the marketing app. Open a new bulk sms to view the full preview.",
      unmergedTitle: "Bulk Sms preview",
      previewTitle: "Preview",
      previewDescription:
        "This is the unmerged content of the sms that was sent out. You can view how it looks for an actual lead by searching and selecting a lead below.",
      showPreviewAsDescription: "Showing preview as {0}",
    },
    Results: {
      Graph: {
        panelTitle: "Delivery Rates",
        graphTitle: "SMS campaign stats",
      },
      Metrics: {
        title: "Total",
      },
      Results: {
        title: "Here's how your SMS campaign performed",
      },
    },
  },
  Login: {
    marketingAndAutomation: "Marketing and Automation",
    loggingIn: "Logging in",
    logIn: "Log in",
    emailOrPassword: "Type your username or email",
    typePassword: "Type your password",
    rememberMe: "Remember me?",
    Validator: {
      whatIsYourUsername: "What is your username?",
      missingPassword: "Looks like the password is missing",
    },
  },
  Funnels: {
    Steps: {
      triggers: "Triggers",
      schedule: "Schedule",
      deleteStepModalConfirmation:
        "Are you sure you want to delete this step? Your steps will be reordered.",
      LeadsList: {
        title: "Leads in step {0}",
        viewAll: "View leads",
        noLeads: "No leads in this step at the moment",
        leads: "leads",
        leadsIn: "Leads in",
        emptyMessage: "No leads in this step yet",
        warningMessage:
          "Are you sure you want to remove the selected lead from this funnel step?",
        leadRemovedSuccess: "Removed lead. Reload page to see changes.",
        leadAddSuccess: "Lead added to funnel. Reload page to see changes.",
        addLeadToStep: "Add leads",
        addLeadDisclaimer:
          "Leads can not be manually added if they are currently in the funnel or have completed the funnel and are not yet eligible to re-enter the funnel.",
      },
      Checklist: {
        setAndReady: "Set and ready",
        setButIncomplete: "Set but incomplete",
        noneSet: "None Set",
        noFunnelGroupAssigned: "No funnel group assigned",
        sendOnlyAtSpecificTimes: "Send only at specific times",
        sendAtAnyTime: "Send at any time",
      },
    },
    EditAutomationFunnel: {
      loading: "Loading Funnel",
      Header: {
        loseUnsavedChanges: "You will lose any unsaved changes",
        checklist: "Check and Start",
        funnelMembers: "Funnel Members",
        funnelSteps: "Funnel Steps",
        setAudience: "Set Audience",
        settings: "Settings",
      },
      FunnelEmpty: {
        title: "Funnel Not Found",
        subtitle: "It looks like this funnel has been deleted.",
        buttonText: "Go Back",
      },
      AudienceView: {
        funnelActiveWarningMessage:
          "This funnel is active. Changing the audience will affect new and existing leads. Existing leads that do not meet these filters will not be able to progress to the next step.",
        buttonText: "Save Audience",
        Conditions: {
          audienceUpdated: "Audience Updated",
          selectGroup: "Select Group",
          changeGroup: "Change Group",
          useCustomFilters: "Use Custom Filters",
          selectCustomFilters: "Select your filters",
        },
        ShowAudience: {
          emptyTitle: "No Leads Selected",
          emptySubtitle:
            "Select a group or set some rules on who should get this email.",
          audienceHeader: "Leads who currently match filters:",
        },
      },
      Checklist: {
        warningMessage:
          "Are you sure you want to start this funnel? Leads will start coming in right away.",
        funnelStarted: "Funnel Started",
        IncompleteChecklist: {
          title: "You're Almost There!",
          subtitle:
            "Make sure you've completed all of the above before starting this automation.",
        },
        completedChecklist: {
          title: "Ready To Roll!",
          subtitle: "You can start this funnel when you're ready.",
          buttonText: "Start Funnel",
        },
        Steps: {
          setUpSteps: "Set up your steps",
          steps: "Steps",
          ready: "Your steps are ready to rock",
          notReady: "Your steps still need some work",
        },
        To: {
          setAudience: "Set your audience",
          setAudienceMessage: "Who should the funnel apply to?",
          marketingGroup:
            "This funnel will apply to all leads in the {0} group.",
          entryRules:
            "This funnel will apply leads that match your custom filter.",
        },
      },
      ActiveFunnel: {
        Settings: {
          settingsUpdatedNotification: "Funnel settings updated",
          deleteFunnelWarning:
            "Are you sure you want to delete this funnel? All automations will stop. This cannot be undone",
          deleteFunnelMessage:
            "Permanently delete this funnel. This cannot be undone and all future automations will stop.",
          deleteFunnel: "Delete Funnel",
          funnelDeletedNotification: "Funnel deleted",

          stopFunnelWarning:
            "Are you sure you want to stop this funnel? All automations will be stopped until it is turned back on.",
          stopFunnelMessage:
            " Stop the funnel. This will change the funnel back to draft. All automations will stop.",
          stopFunnel: "Stop funnel",
          funnelStoppedNotification: "Funnel stopped",

          activeStateMessage:
            "This funnel is active. Changing the repeat after value will not affect users who have already completed the funnel",
          funnelName: "Funnel name",
          funnelNameQuestion: "What should this funnel be called?",
          repeatFunnelHeadline: "Users can repeat this funnel",
          never: "Never",
          after: "After",
          saveFunnel: "Save funnel details",
          sharecodeGeneratedNotification: "Share Code Generated",
          Validations: {
            funnelNameEmpty: "Your funnel name needs a value",
          },
        },
        ActiveLeads: {
          activeleads: "Active Leads",
          emptyLeadsFunnel: "No leads are in this funnel at the moment",
        },
        CompletedLeads: {
          title: "Completed Leads",
          subtitle: "No leads have completed this funnel yet",
          removedLead: "Lead removed from completed list",
          areYouSureAlert:
            "Are you sure you want to remove {0} from the completed list? This may cause them to repeat the funnel if they match it's filters",
        },
        Steps: {
          activeFunnelMessage:
            "This funnel is active. Any edits you make will affect users already in the funnel. Leads that have already finished will not be moved to a new step.",
          Email: {
            saveEmailButton: "Save Email",
            emailUpdatedNotification: "Email Content Updated",
            Header: {
              checklist: "Checklist",
              editEmail: "Edit Email",
              sendSettings: "Send Settings",
              loseUnsavedChangesWarning: "You will lose any unsaved changes",
              unknownStep: "Unknown Step",
              emailFor: "Email for {0}",
              transactionalMessage:
                "This is a transactional message. It will only be sent to leads that are able to recieve transactional messages.",
              learnMore: "Learn more",
            },
            Checklist: {
              Content: {
                emailContent: "Email Content",
                editContent: "Edit Content",
                fixCompliance:
                  "You must fix the compliance issues in your email.",
                successCompliant: "Your email looks compliant.",
                incorrectMergeTags: "Some of your merge tags are incorrect:",
                successMergeTags: "Your merge tags are correct.",
              },
              From: {
                from: "From",
                editButton: "Edit In Send Settings",
                emailUsed: "You're using your {0} email address.",
                appearInInbox:
                  "It will appear as {0} in your recipients' inbox.",
              },
              Preview: {
                sendingPreviewNotification: "Preview Sending",
                headline: "Send Yourself A Preview",
                sendPreview: "Send Preview",
                sendPreviewMessage:
                  "Send a preview of the email to yourself to review before sending it to your audience.",
                previewSentTo: "Preview sent to {0}",
                notReadyToSend:
                  "Resolve the above issues before sending yourself a preview.",
              },
              Ready: {
                emailIsReady: "This email is ready to roll!",
                emailIsReadyMessage:
                  "The email for this step has completed. You can come back anytime to update it!",
                emailNotReady: "You're Almost There!",
                emailNotReadyMessage:
                  "Make sure you've completed all of the above.",
              },
              Subject: {
                editButton: "Edit In Send Settings",
                subject: "Subject",
                setSubject: "Set a subject for your email.",
              },
            },
            LoadAutomationEmail: {
              cantLoadEmail: "Problem loading Email",
              cantLoadEmailMessage:
                "There was a problem loading this email. Please go back and try again",
              buttonLoadingState: "Loading Automation Email",
            },
            SelectDesign: {
              headline: "Choose A Design To Get Started",
              menuDesigns: "Designs",
              menuYourDesigns: "Your Templates",
              loadingTemplates: "Loading Templates",
              noTemplates: "No Templates",
              noTemplatesMessage:
                "Try starting with a system design, or come back after you've created a template or two.",
            },
            Settings: {
              deleteWarning:
                "Are you sure you want to delete this funnel email? This cannot be undone",
              sendSettings: "Send Settings",
              emailSubjectLabel: "Subject",
              emailSubjectPlaceholder:
                "Email subject line (merge fields work too!)",
              fromNameLabel: "From Name",
              fromNameLabelPlaceholder:
                "Who should the email appear from? (Leave empty for default)",
              transactionalEmailMessage:
                "Transactional Emails have different rules and restrictions that apply to who can recieve them. Transactional messages can only contain details specific to a leads initial communication and cannot be explicitly marketing related.",
              transactionalEmailMessageLearnMore:
                "Learn more about transactional and marketing communication.",
              saveFunnelEmailButton: "Save Funnel Email Details",
              deleteFunnelEmailMessage:
                "Permanently delete this funnel email. This cannot be undone.",
              deleteFunnelEmailButton: "Delete Funnel Email",
              settingsSavedNotification: "Send Settings Updated",
              emailDeletedNotification: "Email Deleted",
              Validators: {
                emailSubject:
                  "The email must have a subject (You can use merge fields here as well)",
                fromAddress:
                  "The from address must be a valid, non-gmail email address",
              },
            },
          },
          SMS: {
            Header: {
              MenuItems: {
                checklist: "Checklist",
                editSms: "Edit SMS",
                sendSettings: "Send Settings",
              },
              unsavedChangesWarning: "You will lose any unsaved changes",
              unknownStep: "Unkown Step",
              smsFor: "SMS For {0}",
              transactionalMessagePopover:
                "This is a transactional message. It will only be sent to leads that are able to recieve transactional messages.",
              learnMore: "Learn more",
            },
            Checklist: {
              checkList: "Checklist",
              editContentButton: "Edit Content",
              from: "From",
              sentFromLocationsPhonenumber:
                "This will be sent from the location's mobile number",
              SmsContent: "Sms Content",
              incorrectMergeTags: "Some of your merge tags are incorrect:",
              correctMergeTags: "Your merge tags are correct.",
              enterContent: "Enter the content of your SMS.",
              sendPreviewModalButton: "Send Preview",
              sendPreviewModalTitle: "Send A Preview",
              sendPreviewModalDescription:
                "Enter the mobile number to send the preview to.",
              sendPreviewRowHeadline: "Send Yourself A Preview",
              previewSentTo: "Preview sent to {0}",
              previewNotSent:
                "Send a preview of the sms to yourself to review before sending it to your audience.",
              checklistIncomplete:
                "Resolve the above issues before sending yourself a preview.",
              smsValidHeadline: "This SMS is ready to roll!",
              smsValidDescription:
                "The SMS for this step has been completed. You can come back anytime to update it",
              smsInvalidHeadline: "You're Almost There!",
              smsInvalidDescription:
                "Make sure you've completed all of the above.",
              previewSendingNotification: "Preview Sending",
              Validators: {
                sendWhere: "What number should we send to?",
                numberShorterThan: "It should'nt be shorter than 9 numbers",
                numberLongerThan: "It should'nt be longer than 12 numbers",
                noSpaces: "Number should be numbers only with no spaces.",
              },
            },
            Content: {
              contentUpdatedNotifications: "Content Updated",
            },
            CreateNewTemplate: {
              title: "No SMS message",
              subtitle: "Click create to set up an SMS for this step",
              createSmsMessageButton: "Create SMS Message",
            },
            LoadAutomationTextMessage: {
              title: "Problem loading Text Message",
              subtitle:
                "There was a problem loading this text message. Please go back and try again",
              loading: "Loading Text Message",
            },
            Settings: {
              deleteWarning:
                "Are you sure you want to delete this funnel Text Message? This cannot be undone",
              transactionalCheckboxLabel:
                "Is this a transactional Text Message?",
              transactionalCheckboxMessage:
                "Transactional Emails have different rules and restrictions that apply to who can recieve them. Transactional messages can only contain details specific to a leads initial communication and cannot be explicitly marketing related.",
              transactionalCheckboxLearnMore:
                "Learn more about transactional and marketing communication.",
              saveButton: "Save Funnel Text Message Details",
              deleteTitle: "Delete Text Message",
              deleteButton: "Delete Funnel Text Message",
              deleteButtonDescription:
                "Permanently delete this funnel Text Message. This cannot be undone.",
              settingsUpdatedNotification: "Send Settings Updated",
              deletedNotification: "Text message deleted",
            },
          },
          Listing: {
            loadingFunnelStep: "Loading Funnel Step",
            noSteps: "No Funnel Steps",
            addFirstStep: "Get started by adding your first step",
            createFirstStep: "Create first step",
            addNewStep: "Add new step",
            addingStep: "Adding Funnel Step",
            newStepAddedNotification: "New Step Added",
            stepDeletedNotification: "Step Deleted",
          },
          Schedule: {
            whichDaysToSend: "Which days should we send?",
            whichTimesToSend: "Which times should we send?",
            removeScheduleWarning:
              "Are you sure you want to remove this schedule? All messages will be sent as soon as they are triggered.",
            setScheduleFor: "Set the schedule for",
            noSchedule: "No Schedule",
            noScheduleMessage:
              "Define some simple rules to tell us when to send these messages out",
            setUpSchedule: "Set up schedule",
            deleteSchedule: "Delete schedule",
            scheduleCreatedNotification: "Created schedule",
            scheduleUpdatedNotification: "Schedule updated",
            scheduleRemovedNotification: "Schedule removed",
          },
          Triggers: {
            modalTitle: "Update Trigger for",
            modalUpdateButton: "Update",
            firstStepOnly: "Can only be first step",
            notFirstStepOnly: "Can not be used for first step",
            updatedNotification: "Trigger updated",
          },
        },
      },
    },
  },
  Automations: {
    Listing: {
      title: "Funnels",
      subtitle: "Create and manage your automation funnels",
      createNewFunnel: "Create New Funnel",
      creatingNewFunnel: "Creating New Funnel",
      textfieldPlaceholderNewSignup: "New signup funnel",
      createNewFunnelSubtitle:
        "Just give your funnel a name and you'll be on your way! You can always change the name later.",
      loading: "Loading Funnels",
      emptyTitle: "No Funnels",
      emptyDetail: "There are no active funnels at the moment.",
      createTitle: "Create a new Automation Funnel",
      recipeSubtitle: "Select from a collection of carefully curated funnels.",
      fromScratchSubtitle:
        "build your own funnel from scratch, however you like.",
    },
    Designs: {
      title: "Choose A Recipe To Get Started",
      designs: "Recipes",
      yourDesigns: "Available Recipes",
      loadingDesigns: "Loading Recipes",
      noDesigns: "No Recipes",
      noDesignsSubtitle: "We don't have any recipes that match your search.",
      selectDesign: "Select a recipe to view details",
      backToDesigns: "Back to Funnels",
      simpleFunnelsTitle: "Legacy Pre Built Funnels",
      loading: "Setting up your funnel",
      enterYourShareCode: "Enter your share code",
      searchForPrivateRecipeDetail:
        "Got a funnel share code? Enter it here to view and create a funnel from someone elses account.",
      weFoundDetail:
        "We found an automation funnel with that share code! Click create to create a copy for yourself.",
      loadingPrivateRecipe: "Looking for an automation funnel for that code",
    },
    helpers: {
      html: {
        title: "Create a New HTML Funnel Automation",
        detail:
          "Just give your funnel automation a name and you'll be on your way! You can always change the name later.",
        button: "Create HTML Funnel Automation",
      },
      quickSend: {
        title: "Create a New Quick Send Funnel Automation",
        detail:
          "Just give your funnel automation a name and you'll be on your way! You can always change the name later.",
        button: "Create Quick Send Funnel Automation",
      },
      default: {
        title: "Create a new funnel automation",
        detail:
          "Just give your funnel automation a name and you'll be on your way! You can always change the name later.",
        button: "Create funnel automation",
      },
    },
  },
  Rules: {
    selectLeadSources: "Select lead sources",
    leadSourcesUsed: "Lead sources used",
    selected: "selected",
  },
  Groups: {
    Listing: {
      title: "Groups",
      description:
        "Segment your leads into specific groups for bulk messages or automations",
      createANewGroup: "Create a new group",
      emptyTitle: "You don't have any groups yet",
      emptyDetail:
        "Segment your contacts into groups to include them in automation funnels and bulk messages.",
      emptyNew: "Create your first group",
      loading: "Loading groups",
      createTitle: "Create A New Group",
      recipeSubtitle: "Select from a collection of carefully curated groups.",
      fromScratchSubtitle:
        "Build your own group from scratch, however you like.",
    },
    Designs: {
      title: "Choose A Recipe To Get Started",
      designs: "Recipes",
      yourDesigns: "Available Recipes",
      loadingDesigns: "Loading Recipes",
      noDesigns: "No Recipes",
      noDesignsSubtitle: "We don't have any recipes that match your search.",
      selectDesign: "Select a recipe to view details.",
      backToDesigns: "Back to Groups",
      detailTitle: "Description",
      searchForPrivateRecipeDetail:
        "Got a marketing group share code? Enter it here to view and create a marketing group from someone elses account.",
      weFoundDetail:
        "We found a marketing group with that share code! Click create to create a copy for yourself.",
      loadingPrivateRecipe: "Looking for a marketing group for that code",
    },
  },
  Templates: {
    manageYourTemplates: "Create or manage your templates",
    noShareCodeFound: "No template was found for that code",
    enterYourShareCode: "Enter your share code",
    shareTitle: "Share Code",
    searchForPrivateRecipe: "Search for share code",
    createTemplate: "Create template",
    shareCodeExplination:
      "Search codes allow users from different accounts to share marketing content with each other.",
    TextMessage: {
      List: {
        title: "SMS Templates",
        newSmsTemplate: "New SMS Template",
        recipeTitle: "Recipe",
        recipeSubtitle:
          "Select from a collection of professionally crafted templates, or one of your own templates.",
        fromScratchTitle: "From Scratch",
        fromScratchSubtitle:
          "Build your own template from scratch, however you like.",
        createTitle: "Create A New SMS Template",
        createSubtitle:
          "Just give your sms template a name and you'll be on your way! You can always change the name later.",
        createPlaceholder: "new signup sms.",
        createButton: "Create SMS Template.",
        creatingSmsTemplate: "Creating SMS Template",
      },
      Designs: {
        designs: "Designs",
        yourTemplates: "Your Templates",
        yourDesigns: "Your Designs",
        loadingTemplates: "Loading Templates",
        loadingDesigns: "Loading Designs",
        noTemplates: "No Templates",
        noDesigns: "No Designs",
        noTemplatesSubtitle:
          "Try starting with a system design, or come back after you've created a template or two.",
        noDesignsSubtitle:
          "It doesn't look like we have any SMS designs yet. Check back soon!.",
        selectTemplate: "Select a template to view a preview",
        selectDesign: "Select a design to view a preview",
        backToDesigns: "Back to designs",
        searchForPrivateRecipeDetail:
          "Got a text message template share code? Enter it here to view and create a text message template from someone elses account.",
        weFoundDetail:
          "We found a text message template with that share code! Click create to create a copy for yourself.",
        loadingPrivateRecipe:
          "Looking for a text message template for that code",
      },
    },
    Email: {
      ComplianceView: {
        errorTitle: "Compliance Error",
        errorBody:
          "This template does not comply with international anti-spam regulations. Don't worry, it's easy to fix! Here are the issues you need to resolve before this template can be saved. For more information, check out the",
        warningTitle: "Compliance Warning",
        warningBody:
          "This template does not comply with international anti-spam regulations. Don't worry, it's easy to fix! Here are the issues you need to resolve before this template can be saved. For more information, check out the",
        knowledgeBaseCTA: "knowledge base",
        quickEmailTextOk:
          "Include the '{{quick_email_text}}' merge tag so the quck send template knows where to insert the email text.",
        companyNameOk:
          "Include the name of your company in the footer. You can use the '{{company_title}}' merge tag for this.",
        unsubscribeOk:
          "Include an unsubscribe link in your footer. Make sure you have a link that has '{{unsubscribe_link}}' as the 'url' value.",
        addressOk:
          "Include your club address in the footer. You can use the '{{club_address}}' merge tag for this.",
        subjectOk: "Your email must contain a subject.",
      },
      Designs: {
        title: "Choose A Design To Get Started",
        systemDesigns: "Designs",
        loadingDesigns: "Loading Designs",
        noDesigns: "No Designs Found",
        noDesignsDetail:
          "We couldn't find any designs with that title. Try searching for a different term",
        basicLayouts: "Basic Layouts",
        loadingLayouts: "Loading layouts",
        yourTemplates: "Your Templates",
        loadingTemplates: "Loading Templates",
        noTemplates: "No Templates",
        noTemplatesDetail:
          "Try starting with a system design, or come back after you've created a template or two.",
        loadingPrivateRecipe: "Looking for an email for that code",
        searchForPrivateRecipeDetail:
          "Got an email template share code? Enter it here to view and create an email template from someone elses account.",
        weFoundDetail:
          "We found an email template with that share code! Click create to create a copy for yourself",
        backToTemplates: "Back to templates",
      },
      Settings: {
        shareDetailNoCode:
          "Share this email template with other accounts by generating a share code. This will allow other accounts able to find and copy this email template into their account.",
        shareDetailWithCode:
          "This is the share code for thie email template. Share this with other accounts to let them copy your email template.",
        generateShareCode: "Generate share code",
        yourShareCodeIs: "Your share code is:",
        regenerateShareCode: "Regenerate share code",
      },
    },
  },
};
