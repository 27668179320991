import * as actionTypes from './actionTypes'

const FLAGS_ACTION_HANDLERS = {
  [actionTypes.ADD_FLAG]: (state, action) => {
    const newFlags = [
      action.flag,
      ...state.flags
    ]
    return { ...state, flags: newFlags }
  },
  [actionTypes.REMOVE_FLAG_AT_INDEX]: (state, action) => {
    const newFlags = [
      ...state.flags.slice(0, action.index),
      ...state.flags.slice(action.index + 1)
    ]
    return { ...state, flags: newFlags }
  },
  [actionTypes.CLEAR_ALL_FLAGS]: (state, action) => {
    return { ...state, flags: [] }
  }
}

const initialState = {
  flags: []
}

export default function reducer (state = initialState, action) {
  const handler = FLAGS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
