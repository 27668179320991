export const NAME = 'templateFilters'

export const HTML_TEMPLATES = 'html'
export const QUICK_SEND_TEMPLATES = 'quick_send'
export const SYSTEM_TEMPLATES = 'system'

export const selectOptions = [{
  id: HTML_TEMPLATES,
  title: 'HTML templates',
  newButtonTitle: 'Create new HTML email template',
  icon: 'fa fa-html5'
}, {
  id: QUICK_SEND_TEMPLATES,
  title: 'Quick send templates',
  newButtonTitle: 'Create new Quick Send email template',
  icon: 'si si-speech'
}, {
  id: SYSTEM_TEMPLATES,
  title: 'System templates',
  newButtonTitle: 'Edit the style of System templates',
  icon: 'si si-settings'
}]
