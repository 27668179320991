import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'

import './styles.css'

const options = [
  {
    type: 'greater_than',
    display: 'more than',
    use_text: true
  }, {
    type: 'less_than',
    display: 'less than',
    use_text: true
  }, {
    type: 'has_a_value',
    display: ' any time',
    use_text: false
  }, {
    type: 'is_empty',
    display: 'never',
    use_text: false
  }
]

class TrialTimeEntry extends Component {
  constructor(props) {
    super(props)
    if (props.data) {
      const optionIndex = options.findIndex(option => option.type === props.data.option)
      this.state = { value: props.data.value, option: options[optionIndex] }
    } else {
      this.state = {
        option: options[0],
        value: ''
      }
    }
  }

  handleTextChange = (event) => {
    const textValue = event.target.value
    this.setState({ ...this.state, value: textValue })
    this.updateWithResult({ option: this.state.option.type, value: textValue })
  }

  handleOptionSelection = (option) => {
    this.setState({ ...this.state, option: option })
    this.updateWithResult({ option: option.type, value: this.state.value })
  }

  updateWithResult = (result) => {
    const { id, ruleUpdated } = this.props
    ruleUpdated(id, result)
  }

  render () {
    const { id, readonly } = this.props
    const { option, value } = this.state
    return (
      <div style={{ display: 'inline' }}>
        {readonly &&
          <span>
            <strong>{option.display}</strong>
            {option.use_text &&
              <span>
                {' ' + value + ' '} days
              </span>
            }
          </span>
        }
        {!readonly &&
          <span>
            <span style={{ display: 'inline-block' }}>
              <DropdownMenu
                trigger={option.display}
                triggerType="button">
                <DropdownItemGroup>
                  {options.map((option, i) => (
                    <DropdownItem key={i} onClick={()=> this.handleOptionSelection(option)}>
                      {option.display}
                    </DropdownItem>
                  ))}
                </DropdownItemGroup>
              </DropdownMenu>
            </span>{' '}
            {option.use_text &&
              <span style={{ display: 'inline' }}>
                <input
                  value={value}
                  className='rule-entry-number'
                  type='number'
                  id={id}
                  placeholder={'123...'}
                  onChange={this.handleTextChange} />{' '}
                  {'days'}
              </span>
            }
          </span>
        }
      </div>
    )
  }
}

TrialTimeEntry.propTypes = {
  id: PropTypes.string.isRequired,
  ruleUpdated: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  data: PropTypes.object
}

TrialTimeEntry.defaultProps = {
  readonly: false
}

export default TrialTimeEntry
