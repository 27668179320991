import { request, GET, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import { push } from "react-router-redux";

import modal from "modules/stores/modal";
import listing from "../listing";
import create from "../create";
import editTextMessageTemplate from "containers/editSmsTemplate/modules/editTemplate";

import STRINGS from "strings";

export const getDesigns = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setDesigns(payload.text_message_template_recipes));
    }

    let url = "text_message_template_recipes?page[number]=" + pageNumber;
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }

    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const designsSortSearchUpdated = (sort, search) => {
  return async dispatch => {
    dispatch(setDesignsSortOrder(sort));
    dispatch(setDesignsSearchTerm(search));
    dispatch(getDesigns(1, sort, search));
  };
};

export const getTemplates = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setTemplates(payload.text_message_templates));
    }
    let url = "text_message_templates?page[number]=" + pageNumber;
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const templatesSortSearchUpdated = (sort, search) => {
  return async dispatch => {
    dispatch(setTemplatesSortOrder(sort));
    dispatch(setTemplatesSearchTerm(search));
    dispatch(getTemplates(1, sort, search));
  };
};

export const createTemplate = (textMessageTemplate, companyId) => {
  return dispatch => {
    dispatch(modal.actions.startLoading());
    const body = {
      text_message_template: {
        title: textMessageTemplate.title,
        text: textMessageTemplate.text,
        detail: textMessageTemplate.detail,
        is_legacy: false,
        location_ids: []
      }
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      const template = payload.text_message_template;
      dispatch(listing.actions.addTemplate(template));
      const id = template.id;
      dispatch(
        push(
          "templates/sms/" + id + "/" + editTextMessageTemplate.constants.NAME
        )
      );
      dispatch(create.actions.reset());
    }

    const url = "companies/" + companyId + "/text_message_templates";
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const createTemplateFromDesign = (designId, shareCode = null) => {
  return dispatch => {
    dispatch(modal.actions.startLoading());
    const body = {
      share_code: shareCode,
      text_message_template_recipe_id: designId
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      const template = payload.text_message_template;
      dispatch(listing.actions.addTemplate(template));
      const id = template.id;
      dispatch(
        push(
          "templates/sms/" + id + "/" + editTextMessageTemplate.constants.NAME
        )
      );
      dispatch(create.actions.reset());
    }

    const url = "text_message_template/create_from_recipe";
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const getPrivateRecipe = shareCode => {
  return dispatch => {
    dispatch(setLoading(true));
    dispatch(setErrors(null));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(
        setErrors({
          not_found: STRINGS.Templates.noShareCodeFound
        })
      );
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      if (payload) {
        dispatch(setPrivateRecipe(payload));
      } else {
        dispatch(
          setErrors({
            not_found: STRINGS.Templates.noShareCodeFound
          })
        );
      }
    }

    const url = `text_message_template_recipes/${shareCode}`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setDesigns(designs) {
  return {
    type: actionTypes.SET_DESIGNS,
    designs
  };
}

function setTemplates(templates) {
  return {
    type: actionTypes.SET_TEMPLATES,
    templates
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

export function setPrivateRecipe(privateRecipe) {
  return {
    type: actionTypes.SET_PRIVATE_RECIPE,
    privateRecipe
  };
}

function setDesignsSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_DESIGNS_SEARCH_TERM,
    searchTerm
  };
}

function setDesignsSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_DESIGNS_SORT_ORDER,
    sortOrder
  };
}

function setTemplatesSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_TEMPLATES_SEARCH_TERM,
    searchTerm
  };
}

function setTemplatesSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_TEMPLATES_SORT_ORDER,
    sortOrder
  };
}

export function resetSortSearch() {
  return {
    type: actionTypes.RESET_SORT_SEARCH
  };
}
