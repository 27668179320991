import PropTypes from 'prop-types'
import React, { Component } from 'react'

class OptionsPanel extends Component {

  header = () => {
    const { title, active } = this.props
    return (
      <div>
        {active &&
          <div className='block-header' style={{
            backgroundColor: '#5c90d2',
            borderColor: '#3675c5'
          }}>
            <h3 className='block-title' style={{ color: '#fff' }}>
              {title}
            </h3>
          </div>
        }
        {!active &&
          <div className='block-header bg-gray-lighter'>
            <h3 className='block-title'>{title}</h3>
          </div>
        }
      </div>
    )
  }

  render () {
    const { children } = this.props
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className='block block-bordered'>
            {this.header()}
            <div className='block-content'>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OptionsPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  title: PropTypes.string,
  active: PropTypes.bool.isRequired,
}

export default OptionsPanel
