import * as actionTypes from './actionTypes'

export const prefilForRuleSet = (ruleSet) => {
  return (dispatch) => {
    if (!ruleSet) { return }
    const rules = (ruleSet.rules) ? ruleSet.rules : []
    dispatch(setCondition(ruleSet.condition))
    dispatch(setRules(rules))
    dispatch(setEvent(ruleSet.event))
    dispatch(setHasChanges(false))
  }
}

export function setCondition (condition) {
  return {
    type: actionTypes.SET_CONDITION,
    condition
  }
}

export function setRules (rules) {
  return {
    type: actionTypes.SET_RULES,
    rules
  }
}

export function addRule (rule) {
  return {
    type: actionTypes.ADD_RULE,
    rule
  }
}

export function updateRule (index, ruleData) {
  return {
    type: actionTypes.UPDATE_RULE,
    index,
    ruleData
  }
}

export function removeRule (index) {
  return {
    type: actionTypes.REMOVE_RULE,
    index
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setEvent (event) {
  return {
    type: actionTypes.SET_EVENT,
    event
  }
}

export function setEntryIsAutomatic (entryIsAutomatic) {
  return {
    type: actionTypes.SET_ENTRY_IS_AUTOMATIC,
    entryIsAutomatic
  }
}

export function prefilEntryIsAutomatic (entryIsAutomatic) {
  return (dispatch) => {
    if (!entryIsAutomatic) { return }
    dispatch(setEntryIsAutomatic(entryIsAutomatic))
    dispatch(setHasChanges(false))
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}
