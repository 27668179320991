import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ResultsView from '../components/ResultsView'
import * as selectors from '../selectors'
import loadBulkEmail from '../../loadBulkEmail'

import session from 'modules/stores/session'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    bulkEmail: loadBulkEmail.selectors.getBulkEmail(state),
    formattedStatistics: selectors.formattedStatistics(state),
    timezone: session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultsView))
