import { request, buildIndexUrl, GET, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { push } from 'react-router-redux'
import modal from 'modules/stores/modal'

export const getGroups = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setGroups(payload.marketing_groups))
    }

    const url = buildIndexUrl('marketing_groups', pageNumber, sort, search)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const sortSearchUpdated = (sort, search) => {
  return async dispatch => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getGroups(1, sort, search));
  };
};

export const createGroup = (name) => {
  return dispatch => {
    dispatch(modal.actions.startLoading())
    const groupBody = {
      marketing_group: {
        name: name
      }
    }

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.hideModal())
      const group = payload.marketing_group
      dispatch(addGroup(group))
      const id = group.id
      dispatch(push('groups/' + id + '/members'))
    }

    return request('marketing_groups', POST, groupBody, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setGroups (groups) {
  return {
    type: actionTypes.SET_GROUPS,
    groups
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function addGroup (group) {
  return {
    type: actionTypes.ADD_GROUP,
    group
  }
}

export function removeGroup (group) {
  return {
    type: actionTypes.REMOVE_GROUP,
    group
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder
  };
}
