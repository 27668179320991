import PropTypes from "prop-types";
import React, { Component } from "react";
import { ModalContainer } from "modules/components";

import LeadLostRow from "./LeadLostRow";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class SelectLeadLostReasonModalView extends Component {
  componentWillMount() {
    const { leadLostReasons } = this.props;
    if (leadLostReasons.length > 0) {
      return;
    }
    this.getLeadLostReasonsWithPageNumber(1);
  }

  getLeadLostReasonsWithPageNumber = pageNumber => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadLostReasonsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getLeadLostReasons } = this.props;
    getLeadLostReasons(1, searchTerm);
  };

  leadLostReasonSelected = leadLostReason => {
    const { setSelectedLeadLostReason, hideModal } = this.props;
    setSelectedLeadLostReason(leadLostReason);
    hideModal();
  };

  render() {
    const {
      leadLostReasons,
      selectedLeadLostReasonId,
      modalName,
      loading,
      pageDetails
    } = this.props;
    const pageCount = pageDetails.pageCount;

    const rows = leadLostReasons.map((leadLostReason, index) => {
      const selected = selectedLeadLostReasonId
        ? selectedLeadLostReasonId === leadLostReason.id
        : false;
      return (
        <LeadLostRow
          key={index}
          selected={selected}
          leadLostReason={leadLostReason}
          selectLeadLostReason={this.leadLostReasonSelected}
        />
      );
    });

    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={"Select A LeadLostReason"}
        colorThemeClass={"primary"}
        showCancelButton
      >
        <ContentBlockView loading={loading}>
          <div className="row" style={{ marginTop: "-20px" }}>
            <TextSearchView
              sectionName={"leadLostReasonSearch"}
              onUpdate={this.searchUpdated}
            />
          </div>
          {leadLostReasons.length > 0 && (
            <div>
              <div className="table-responsive">
                <table className="table table-vcenter table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          )}
          {leadLostReasons.length === 0 && (
            <EmptyView
              colorThemeClass="primary"
              iconClass="si si-magnifier"
              title="No results"
              subtitle="Check the search field"
            />
          )}
          {pageCount > 1 && (
            <div className="text-center push">
              <PagesView
                pageDetails={pageDetails}
                pageClicked={this.pageClicked}
              />
            </div>
          )}
        </ContentBlockView>
      </ModalContainer>
    );
  }
}

SelectLeadLostReasonModalView.propTypes = {
  getLeadLostReasons: PropTypes.func.isRequired,
  pageDetails: PropTypes.object,
  selectedLeadLostReasonId: PropTypes.number,
  leadLostReasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedLeadLostReason: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default SelectLeadLostReasonModalView;
