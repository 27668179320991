import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading: loading
  }
}

export function loadBillingDataForUser () {
  return dispatch => Promise.all([
    dispatch(setLoading(true)),
    dispatch(getDefaultSmsCost())
  ]).then(() => {
    dispatch(setLoading(true))
  })
}

// GET
export const getDefaultSmsCost = () => {
  return dispatch => {
    function onFailure (errors) { }

    function onSuccess (payload) {
      dispatch(setDefaultSmsCost(payload))
    }

    return request('default_sms_cost', GET, null, onSuccess, onFailure)
  }
}


// UPDATE
function setDefaultSmsCost (defaultSmsCost) {
  return {
    type: actionTypes.SET_DEFAULT_SMS_COST,
    defaultSmsCost
  }
}
