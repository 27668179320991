import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'

import './styles.css'

class TagsEntryView extends Component {
  constructor(props) {
    super(props)
    if (props.data) {
      const index = props.tags.findIndex(tag => tag === props.data.value)
      this.state = { value: props.tags[index] }
    } else {
      this.state = {
        value: null
      }
    }
  }

  handleOptionSelection = (tag) => {
    this.setState({ ...this.state, value: tag })
    this.updateWithResult({ value: tag })
  }

  updateWithResult = (result) => {
    const { id, ruleUpdated } = this.props
    ruleUpdated(id, result)
  }

  render () {
    const { tags, readonly } = this.props
    const { value } = this.state
    const dropdownDisplay = (value) ? value : 'Select one'
    return (
      <div style={{ display: 'inline' }}>
        <span style={{ display: 'inline-block' }}>
          {!readonly &&
            <DropdownMenu
              trigger={dropdownDisplay}
              triggerType="button">
              <DropdownItemGroup>
                {tags.map((tag, i) => (
                  <DropdownItem key={i} onClick={()=> this.handleOptionSelection(tag)}>
                    {tag}
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </DropdownMenu>
          }
          {readonly &&
            <strong>{value}</strong>
          }
        </span>
      </div>
    )
  }
}

TagsEntryView.propTypes = {
  id: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  ruleUpdated: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  data: PropTypes.object
}

TagsEntryView.defaultProps = {
  readonly: false
}

export default TagsEntryView