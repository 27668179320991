import { request, GET, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import flags from 'modules/components/flags'

import entryRules from '../rules/modules/entryRules'
import exitRules from '../rules/modules/exitRules'
import settings from '../settings'

export const get = (groupId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      if (payload.status === 404) {
          dispatch(setNotFound(true))
          return
      }
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setGroup(payload.marketing_group))
      dispatch(entryRules.actions.prefilForRuleSet(payload.marketing_group.entry_rules))
      dispatch(entryRules.actions.prefilEntryIsAutomatic(payload.marketing_group.entry_is_automatic))
      dispatch(exitRules.actions.prefilForRuleSet(payload.marketing_group.exit_rules))
      dispatch(exitRules.actions.prefilExitIsAutomatic(payload.marketing_group.exit_is_automatic))
      dispatch(settings.actions.prefillDataForGroup(payload.marketing_group))
      dispatch(setNotFound(false))
      dispatch(setLoading(false))
    }

    return request('marketing_groups/' + groupId, GET, null, onSuccess, onFailure)
  }
}

export const update = (groupId, updateBody) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const body = {
      marketing_group: updateBody
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setGroup(payload.marketing_group))
      dispatch(setLoading(false))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: 'Group saved',
        index: 0
      }))
    }

    return request('marketing_groups/' + groupId, PUT, body, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function reset () {
  return {
    type: actionTypes.RESET,
  }
}

export function setGroup (group) {
  return {
    type: actionTypes.SET_GROUP,
    group
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

function setNotFound (notFound) {
  return {
    type: actionTypes.SET_NOT_FOUND,
    notFound
  }
}
