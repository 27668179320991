import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import HeaderView from '../components/HeaderView'

import loadAutomationFunnel from '../../loadAutomationFunnel'
import alert from 'modules/components/alert'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    hasUnsavedChanges: selectors.hasUnsavedChanges(state),
    currentPath: state.routing.location.pathname
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showWithConfig: alert.actions.showWithConfig,
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderView))
