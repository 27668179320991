import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ANY, ALL } from '../rules'

import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'

class RuleOptionsSelection extends Component {

  dropdownText = () => {
    const { selectedOption } = this.props
    switch (selectedOption) {
      case ANY:
        return "any"
      case ALL:
        return "all"
      default:
        return "any"
    }
  }

  render () {
    const { setSelectedOption } = this.props
    return (
      <div style={{ marginBottom: '10px', display: 'inline' }}>
        Leads that match{' '}
        <span style={{ display: 'inline-block' }}>
          <DropdownMenu trigger={this.dropdownText()} triggerType="button">
            <DropdownItemGroup>
              <DropdownItem id={ALL} onClick={()=> setSelectedOption(ALL)}>all</DropdownItem>
              <DropdownItem id={ANY} onClick={()=> setSelectedOption(ANY)}>any</DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>{' '}
        </span>
        of these filters
      </div>
    )
  }
}

RuleOptionsSelection.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired
}

RuleOptionsSelection.defaultProps = {
  allowNever: false
}

export default RuleOptionsSelection
