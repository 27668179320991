import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'

import './styles.css'

const options = [
  {
    type: 'not_assigned',
    display: 'unassigned',
  }, {
    type: 'active',
    display: 'active',
  }, {
    type: 'closed',
    display: 'closed',
  }, {
    type: 'lost',
    display: 'lost',
  }, {
    type: 'inactive',
    display: 'inactive',
  }, {
    type: 'any',
    display: 'anything',
  }
]

class StatusEntry extends Component {
  constructor(props) {
    super(props)
    if (props.data) {
      const optionIndex = options.findIndex(option => option.type === props.data.value)
      this.state = { option: options[optionIndex] }
    } else {
      this.state = {
        option: null,
        value: null
      }
    }
  }

  handleOptionSelection = (option) => {
    this.setState({ ...this.state, option: option })
    this.updateWithResult({ value: option.type })
  }

  updateWithResult = (result) => {
    const { id, eventUpdated } = this.props
    eventUpdated(id, result)
  }

  render () {
    const { option } = this.state
    const dropdownDisplay = (option) ? option.display : 'Select one'
    return (
      <div style={{ display: 'inline' }}>
        <span style={{ display: 'inline-block' }}>
          <DropdownMenu
            trigger={dropdownDisplay}
            triggerType="button">
            <DropdownItemGroup>
              {options.map((option, i) => (
                <DropdownItem key={i} onClick={()=> this.handleOptionSelection(option)}>
                  {option.display}
                </DropdownItem>
              ))}
            </DropdownItemGroup>
          </DropdownMenu>
        </span>
      </div>
    )
  }
}

StatusEntry.propTypes = {
  id: PropTypes.string.isRequired,
  eventUpdated: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default StatusEntry
