import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";
import STRINGS from "strings";

import moment from "moment-timezone";

export class BulkSmsRow extends Component {
  rowSelected = e => {
    const { bulkSms, onSelect } = this.props;
    onSelect(bulkSms);
  };

  render() {
    const { bulkSms, timezone, showCreatedBy } = this.props;
    const { user } = bulkSms;
    const createdBy = user && user.display_name ? user.display_name : null;

    return (
      <ContentBlockView>
        <div className="row selectable" onClick={this.rowSelected}>
          <div className="col-xs-5">
            <h5>{bulkSms.title || "-- No title --"}</h5>
            <p>
              {STRINGS.Global.lastUpdated}{" "}
              {moment(bulkSms.updated_at)
                .tz(timezone)
                .format("Do MMM YYYY, h:mm a")}
              <br />
              {showCreatedBy && createdBy && `Created by ${createdBy}`}
            </p>
          </div>
        </div>
      </ContentBlockView>
    );
  }
}

BulkSmsRow.propTypes = {
  bulkSms: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  showCreatedBy: PropTypes.bool
};

export default BulkSmsRow;
