import { NAME } from "./constants";

export const getErrors = state => state.bulkMessaging.bulkSms[NAME].errors;
export const getLoading = state => state.bulkMessaging.bulkSms[NAME].loading;
export const getBulkSmss = state => state.bulkMessaging.bulkSms[NAME].bulkSmss;
export const getPageDetails = state =>
  state.bulkMessaging.bulkSms[NAME].pageDetails;
export const getSearchTerm = state =>
  state.bulkMessaging.bulkSms[NAME].searchTerm;
export const getSortOrder = state =>
  state.bulkMessaging.bulkSms[NAME].sortOrder;
export const getSelectedLocations = state =>
  state.bulkMessaging.bulkSms[NAME].selectedLocations;
