import { createSelector } from 'reselect'
import { NAME } from './constants'

import * as stepListingSelectors from '../../../listing/selectors'

export const getErrors = state => state.editAutomationFunnel.steps.stepEmail[NAME].errors
export const getLoading = state => state.editAutomationFunnel.steps.stepEmail[NAME].loading
export const getAutomationEmail = state => state.editAutomationFunnel.steps.stepEmail[NAME].automationEmail
export const getSelectedFunnelStepId = state => state.editAutomationFunnel.steps.stepEmail[NAME].selectedFunnelStepId

export const currentFunnelStep = createSelector(
  [ getSelectedFunnelStepId,
    stepListingSelectors.getSteps ],
  ( selectedFunnelStepId, steps ) => {
    const index = steps.findIndex(step => parseInt((step.id), 10) === parseInt((selectedFunnelStepId), 10))
    return steps[index]
  }
)
