import { request, PUT, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { fieldNames } from './constants'
import flags from 'modules/components/flags'
import modal from 'modules/stores/modal'

import { getSteps } from '../listing/actions'
import { formDataFromData } from 'utils/formHelpers'

import listing from '../listing'

import STRINGS from "strings";
const triggersStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Triggers;

export function prefillDataForTrigger (trigger) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_TRIGGER,
    trigger
  }
}

export const update = (funnelId, stepID, triggerId, formData) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading())
    const body = {
      automation_funnel_step_trigger: formDataFromData(formData, fieldNames)
    }

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(listing.actions.setTriggerForStepId(stepID, payload.automation_funnel_step_trigger))
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.hideModal())
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: triggersStrings.updatedNotification,
        index: 0
      }))
      dispatch(getSteps(funnelId))
    }

    const url = 'automation_funnels/' + funnelId +
    '/automation_funnel_steps/' + stepID +
    '/automation_funnel_step_triggers/' + triggerId
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

export const getTriggerOptions = () => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setTriggerOptions(payload.automation_funnel_step_triggers))
    }

    return request('funnel_step_trigger_options', GET, null, onSuccess, onFailure)
  }
}

function setTriggerOptions (triggerOptions) {
  return {
    type: actionTypes.SET_TRIGGER_OPTIONS,
    triggerOptions
  }
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setSelectedFunnelStepId (selectedFunnelStepId) {
  return {
    type: actionTypes.SET_SELECTED_FUNNEL_STEP_ID,
    selectedFunnelStepId
  }
}

export function setView (view) {
  return {
    type: actionTypes.SET_VIEW,
    view
  }
}
