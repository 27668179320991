import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import alert from 'modules/components/alert'
import { getProductName } from 'modules/stores/userData/selectors'

import RulesView from '../components/RulesView'
import * as actions from '../actions'
import * as selectors from '../selectors'

import loadGroup from '../../loadGroup'

function mapStateToProps (state) {
  return {
    group: loadGroup.selectors.getGroup(state),
    updateBody: selectors.getUpdateBody(state),
    active: true,
    productName: getProductName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    update: actions.update,
    setAlertShowing: alert.actions.setShowing,
    setAlertConfig: alert.actions.setConfig
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RulesView))
