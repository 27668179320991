import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields, ModalContainer } from 'modules/components'
import { SEND_PREVIEW_MODAL } from '../constants'
import { isPresent, isMaxLength, isMinLength, isNumber } from 'utils/validation'
import STRINGS from 'strings'

const smsChecklistStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Checklist
const { TextField } = FormFields

export const validator = {
  number: [{
    rule: isPresent,
    errorMessage: smsChecklistStrings.Validators.sendWhere
  }, {
    rule: isMinLength(9),
    errorMessage: smsChecklistStrings.Validators.numberShorterThan
  }, {
    rule: isMaxLength(12),
    errorMessage: smsChecklistStrings.Validators.numberLongerThan
  }, {
    rule: isNumber,
    errorMessage: smsChecklistStrings.Validators.noSpaces
  }]
}

export class NewGroupModalView extends Component {

  state = {
    number: this.props.user.mobile_number,
    isValid: (this.props.user.mobile_number !== null)
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    const { automationFunnel, automationFunnelStep, automationTextMessage, sendPreview } = this.props
    sendPreview(automationFunnel.id, automationFunnelStep.id, automationTextMessage.id, this.state.number)
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value, isValid: isValid })
  }

  render () {
    const { user } = this.props
    return (
      <ModalContainer
        modalName={SEND_PREVIEW_MODAL}
        modalTitle={smsChecklistStrings.sendPreviewModalTitle}
        colorThemeClass={'primary'}
        showCancelButton
        actionEnabled={this.state.isValid}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={<span>{smsChecklistStrings.sendPreviewModalButton}</span>}>
        <p>{smsChecklistStrings.sendPreviewModalDescription}</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}
          style={{
            marginLeft: '15px',
            marginRight: '15px'
          }}>
          <TextField
            name='number'
            label=''
            initialValue={user.mobile_number}
            placeholder='0000 111 222'
            formFieldStyle='form-material form-material-primary'
            validator={validator}
            onChange={this.handleInput} />
        </form>
      </ModalContainer>
    )
  }
}

NewGroupModalView.propTypes = {
  sendPreview: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  automationTextMessage: PropTypes.object.isRequired,
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired
}

export default NewGroupModalView
