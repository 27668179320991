import { request, GET, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import { push } from "react-router-redux";

import modal from "modules/stores/modal";
import listing from "../listing";
import create from "../create";
import editEmailTemplate from "containers/editEmailTemplate/modules/editHtmlTemplate";

import STRINGS from "strings";

export const getLayouts = () => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setLayouts(payload.email_template_designs));
    }

    return request("email_template_designs", GET, null, onSuccess, onFailure);
  };
};

export const getDesigns = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setDesignsLoading(true));
    function onFailure(payload) {
      dispatch(setDesignsLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setDesignsLoading(false));
      dispatch(loadDesignPageDetails(payload.meta));
      dispatch(setDesigns(payload.email_template_recipes));
    }

    let url = "email_template_recipes?page[number]=" + pageNumber;
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const getTemplates = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadTemplatePageDetails(payload.meta));
      dispatch(setTemplates(payload.email_templates));
    }
    let url =
      "email_templates?template_type=html&detail=true&page[number]=" +
      pageNumber +
      "&viewable=true";
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const createTemplate = (name, companyId, html, json) => {
  return dispatch => {
    dispatch(modal.actions.startLoading());
    const templateBody = {
      email_template: {
        title: name,
        template_type: "html",
        location_ids: [],
        html: html,
        design_json: json
      }
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      const template = payload.email_template;
      dispatch(listing.actions.addTemplate(template));
      const id = template.id;
      dispatch(
        push("templates/email/" + id + "/" + editEmailTemplate.constants.NAME)
      );
      dispatch(create.actions.reset());
    }

    const url = "companies/" + companyId + "/email_templates";
    return request(url, POST, templateBody, onSuccess, onFailure);
  };
};

export const createTemplateFromDesign = (designId, shareCode = null) => {
  return dispatch => {
    dispatch(setDesignsLoading(true));
    const body = {
      share_code: shareCode,
      email_template_recipe_id: designId
    };

    function onFailure(payload) {
      dispatch(setDesignsLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setDesignsLoading(false));
      const template = payload.email_template;
      dispatch(listing.actions.addTemplate(template));
      const id = template.id;
      dispatch(
        push("templates/email/" + id + "/" + editEmailTemplate.constants.NAME)
      );
      dispatch(setPrivateRecipe(null));
      dispatch(create.actions.reset());
    }

    const url = "email_template/create_from_recipe";
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const getPrivateRecipe = shareCode => {
  return dispatch => {
    dispatch(setLoading(true));
    dispatch(setErrors(null));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(
        setErrors({
          not_found: STRINGS.Templates.noShareCodeFound
        })
      );
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      if (payload) {
        dispatch(setPrivateRecipe(payload.email_template_recipe));
      } else {
        dispatch(
          setErrors({
            not_found: STRINGS.Templates.noShareCodeFound
          })
        );
      }
    }

    const url = `email_template_recipes/${shareCode}`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setDesignsLoading(loading) {
  return {
    type: actionTypes.SET_DESIGNS_LOADING,
    loading
  };
}

function setDesigns(designs) {
  return {
    type: actionTypes.SET_DESIGNS,
    designs
  };
}

function setLayouts(layouts) {
  return {
    type: actionTypes.SET_LAYOUTS,
    layouts
  };
}

function setTemplates(templates) {
  return {
    type: actionTypes.SET_TEMPLATES,
    templates
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function loadTemplatePageDetails(pageDetails) {
  return {
    type: actionTypes.TEMPLATE_PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

function loadDesignPageDetails(pageDetails) {
  return {
    type: actionTypes.DESIGNS_PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

export function setPrivateRecipe(privateRecipe) {
  return {
    type: actionTypes.SET_PRIVATE_RECIPE,
    privateRecipe
  };
}
