import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ChecklistView from '../components/ChecklistView'
import * as selectors from '../selectors'
import loadBulkEmail from '../../loadBulkEmail'
import userData from 'modules/stores/userData'
import { getProductName } from 'modules/stores/userData/selectors'

    

function mapStateToProps (state) {
  const bulkEmail = loadBulkEmail.selectors.getBulkEmail(state)
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    company: userData.selectors.getCompany(state),
    bulkEmail: bulkEmail,
    isLegacy: (bulkEmail && !bulkEmail.checklist),
    productName: getProductName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistView))
