import { request, buildIndexUrl, GET, DELETE, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import flags from 'modules/components/flags'
import loadGroup from '../loadGroup'

import modal from 'modules/stores/modal'

export const get = (groupId, page, search) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setMembers(payload.leads))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setLoading(false))
    }
    const baseUrl = 'marketing_groups/' + groupId + '/list_leads'
    const url = buildIndexUrl(baseUrl, page, null, search)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const addLeadIds = (groupId, leadIds) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading())
    const body = {
      lead_ids: leadIds
    }

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(loadGroup.actions.setGroup(payload.marketing_group))
      dispatch(get(groupId, 1))
      dispatch(setSearchLeads([]))
      dispatch(modal.actions.hideModal())
      dispatch(modal.actions.stopLoading())

      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: 'Member added to the group',
        index: 0
      }))
    }

    let url = 'marketing_groups/' + groupId + '/add_leads'
    return request(url, POST, body, onSuccess, onFailure)
  }
}

export const removeLeadIds = (groupId, leadIds) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const body = {
      lead_ids: leadIds
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(loadGroup.actions.setGroup(payload.marketing_group))
      dispatch(get(groupId, 1))
      dispatch(setLoading(false))
      dispatch(setSelectedIds([]))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: 'Members removed from the group',
        index: 0
      }))
    }

    let url = 'marketing_groups/' + groupId + '/remove_leads'
    return request(url, DELETE, body, onSuccess, onFailure)
  }
}

export const searchForLeads = (name) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading())

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(setSearchLeads(payload.leads))
    }

    let url = 'leads?filter[full_text_search]=' + name
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setMembers (members) {
  return {
    type: actionTypes.SET_MEMBERS,
    members
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

function setSelectedIds (selectedIds) {
  return {
    type: actionTypes.SET_SELECTED_IDS,
    selectedIds
  }
}

export function selectMember (member) {
  return {
    type: actionTypes.SELECT_MEMBER,
    member
  }
}

export function deSelectMember (member) {
  return {
    type: actionTypes.DESELECT_MEMBER,
    member
  }
}

export function setSearchLeads (searchLeads) {
  return {
    type: actionTypes.SET_SEARCH_LEADS,
    searchLeads
  }
}
