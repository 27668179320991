import { NAME } from './constants'
import { createSelector } from 'reselect'
import entryRules from '../entryRules'

export const getPreviewLeads = state => state.editGroup.rules[NAME].previewLeads
export const getLoading = state => state.editGroup.rules[NAME].loading
export const getTotalCount = state => state.editGroup.rules[NAME].totalCount

export const getEntryRulesJson = createSelector(
  [ entryRules.selectors.getRules, entryRules.selectors.getCondition],
  (entryRules, entryCondition) => {
    const rules = entryRules.filter(rule => rule.data !== undefined)
    return {
      entry_rules: {
        condition: entryCondition,
        rules: rules
      }
    }
  }
)
