import PropTypes from "prop-types";
import React, { Component } from "react";
import alert from "modules/components/alert";
import { SCHEDULE_SEND_MODAL } from "../constants";

import { SimpleImageEmptyView } from "modules/components";
import ScheduleModalContainer from "../containers/ScheduleModalContainer";

import EmailReady from "images/EmailReady.jpg";

import STRINGS from "strings";
const checklistSendViewStrings =
  STRINGS.BulkMessaging.BulkEmail.Checklist.SendView;

export class DraftSendView extends Component {
  showSendWarning = () => {
    const { showAlertWithConfig, bulkEmail } = this.props;
    const message = STRINGS.formatString(
      checklistSendViewStrings.sendEmailConfirmationMessage,
      <b>{bulkEmail.estimated_lead_count}</b>
    );
    const alertConfig = {
      ...alert.defaultConfig,
      children: message,
      onConfirm: this.sendEmail,
      style: "warning",
    };
    showAlertWithConfig(alertConfig);
  };

  showScheduleModal = () => {
    this.props.showModal(SCHEDULE_SEND_MODAL);
  };

  sendEmail = () => {
    const { startSend, bulkEmail } = this.props;
    startSend(bulkEmail);
  };

  render() {
    const { bulkEmail } = this.props;
    const viewSubtitle = `${checklistSendViewStrings.emailReadySubtitle1} ${bulkEmail.estimated_lead_count} ${checklistSendViewStrings.emailReadySubtitle2}`;

    return (
      <div>
        <SimpleImageEmptyView
          image={EmailReady}
          title={checklistSendViewStrings.emailReadyTitle}
          subtitle={viewSubtitle}
        />
        <div className="row">
          <div className={"col-sm-6 col-sm-offset-3 text-center text-success"}>
            <button
              className={
                "btn btn-minw btn-rounded btn-noborder btn-success push-5"
              }
              onClick={this.showSendWarning}
            >
              {checklistSendViewStrings.sendEmailButton}
            </button>
          </div>
          <div className={"col-sm-6 col-sm-offset-3 text-center push-5"}>
            <p style={{ marginBottom: "0px" }}>Or</p>
          </div>
          <div
            className={"col-sm-6 col-sm-offset-3 text-center text-primary"}
            style={{ marginBottom: "20px" }}
          >
            <button
              className={
                "btn btn-minw btn-rounded btn-noborder btn-primary push-5"
              }
              onClick={this.showScheduleModal}
            >
              {checklistSendViewStrings.scheduleLater}
            </button>
          </div>
        </div>
        <ScheduleModalContainer
          title={checklistSendViewStrings.scheduleModalTitle}
          buttonTitle={checklistSendViewStrings.scheduleModalButton}
          color={"success"}
          modalName={SCHEDULE_SEND_MODAL}
        />
      </div>
    );
  }
}

DraftSendView.SubjectRow = {
  bulkEmail: PropTypes.object.isRequired,
  startSend: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default DraftSendView;
