import { request, POST } from 'utils/apiUtils'
import session from 'modules/stores/session'
import * as actionTypes from './actionTypes'

export const getArticles = (articleId, name, totp, userId) => {
  return (dispatch) => {
    const body = {
      articles: {
        id: articleId,
        name: name,
        totp: totp,
        user_id: userId
      }
    }

    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(session.actions.startSession(payload, false))
    }

    return request('articles', POST, body, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}
