import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import DesignsView from '../components/DesignsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import create from '../../create'
import userData from 'modules/stores/userData'

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    designs: selectors.getDesigns(state),
    company: userData.selectors.getCompany(state),
    pageDetails: selectors.getPageDetails(state)
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getDesigns: actions.getDesigns,
    createGroupFromDesign: actions.createGroupFromDesign,
    showListingView: create.actions.reset
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignsView))
