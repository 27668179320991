import React from "react";

const SelectViewMenu = ({ menuItems, currentView, setView }) => {
  return (
    <div className="block">
      <ul className="nav nav-tabs nav-tabs-alt">
        {menuItems.map((menuItem, index) => {
          const className =
            menuItem.id === currentView ? "active" : "selectable";
          return (
            <li
              className={className}
              key={index}
              onClick={() => setView(menuItem.id)}
            >
              <button className="linkButton">{menuItem.name}</button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SelectViewMenu;
