import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { colors } from '@atlaskit/theme';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';

import AutoDismissFlag, { FlagGroup } from '@atlaskit/flag';

export class FlagsView extends Component {

  flagDismissed = (index) => {
    this.props.removeFlagAtIndex(index)
  }

  render () {
    const { flags } = this.props
    return (
      <FlagGroup onDismissed={this.flagDismissed}>
        {flags.map(flag => (
          <AutoDismissFlag
            description={flag.description}
            icon={<SuccessIcon primaryColor={colors.G300} label="Info" />}
            id={flag.index}
            key={flag.index}
            title={flag.title}
          />
        )
        )}
      </FlagGroup>
    )
  }
}

FlagsView.propTypes = {
  flags: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeFlagAtIndex: PropTypes.func.isRequired
}

export default FlagsView
