import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getMembers = state => state.editGroup[NAME].members
export const getLoading = state => state.editGroup[NAME].loading
export const getErrors = state => state.editGroup[NAME].errors
export const getSelectedIds = state => state.editGroup[NAME].selectedIds
export const getSearchLeads = state => state.editGroup[NAME].searchLeads

export const getPageDetails = state => state.editGroup[NAME].pageDetails

export function memberIdIsSelected (id) {
  return createSelector(
    [ getSelectedIds ],
    (selectedIds) => {
      return selectedIds.indexOf(id) !== -1
    }
  )
}
