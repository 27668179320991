export const NAME = 'smsTemplateFilters'

export const YOUR_TEMPLATES = 'user'
export const SYSTEM_TEMPLATES = 'system'

export const selectOptions = [{
  id: YOUR_TEMPLATES,
  title: 'Your templates',
  newButtonTitle: 'Create new text message template',
  icon: 'si si-speech'
}, {
  id: SYSTEM_TEMPLATES,
  title: 'System templates',
  newButtonTitle: 'Edit the text of system templates',
  icon: 'si si-settings'
}]
