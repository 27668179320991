import React, { Component } from 'react'

import listing from '../modules/listing'
import filters from '../modules/filters'

export class BulkSmsView extends Component {

  render () {
    return (
      <div className='row'>
        <div className='col-md-3'>
          <filters.BulkSmsFiltersContainer />
        </div>
        <div className='col-md-9'>
          <listing.BulkSmsListingContainer />
        </div>
      </div>
    )
  }
}

BulkSmsView.propTypes = {

}

export default BulkSmsView
