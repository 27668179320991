import PropTypes from 'prop-types'
import React, { Component } from 'react'

import content from '../../content'

import STRINGS from 'strings'
const contentRowStrings = STRINGS.BulkMessaging.BulkSms.Checklist.ContentRow

export class ContentRow extends Component {

  goToContent = () => {
    this.props.history.push(content.constants.NAME)
  }

  emptyChange = () => {}

  render () {
    const { bulkSms } = this.props
    const checklist = bulkSms.checklist
    const icon = (!checklist.content_ok) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        {bulkSms.status !== 'scheduled' &&
          <button
            className='pull-right btn btn-primary'
            onClick={this.goToContent}>
            {contentRowStrings.editContentButton}
          </button>
        }
        <i className={icon} />
        <h4>{contentRowStrings.headline}</h4>
        {!checklist.correct_merge_tags &&
          <div>
             {contentRowStrings.mergeTagsIncorrect} [
              {checklist.incorrect_merge_tags.map((tag, index) => {
                if (index === checklist.incorrect_merge_tags.length - 1) {
                  return tag
                }
                return tag + ', '
              })}
            ]
          </div>
        }
        {checklist.content_ok &&  checklist.correct_merge_tags &&
          <div>{contentRowStrings.mergeTagsCorrect}</div>
        }
        {!checklist.content_ok &&
          <div>
            {contentRowStrings.emptyContentMessage}
          </div>
        }
      </div>
    )
  }
}

ContentRow.SubjectRow = {
  bulkSms: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default ContentRow
