import { createSelector } from 'reselect'
import emailCreate from './modules/email/modules/create'
import smsCreate from './modules/sms/modules/create'

export const shouldHideHeader = createSelector(
  [emailCreate.selectors.isSettingUpNewTemplate, smsCreate.selectors.isSettingUpNewTemplate ],
  (isSettingUpNewEmailTemplate, isSettingUpNewSmsTemlate) => {
    return isSettingUpNewEmailTemplate || isSettingUpNewSmsTemlate
  }
)
