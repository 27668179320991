import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import BulkEmailFiltersView from '../components/BulkEmailFiltersView'
import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    selection: selectors.getSelection(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setSelection: actions.setSelection
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkEmailFiltersView))
