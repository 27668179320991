import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'

import './styles.css'

const options = [
  {
    type: 'not_assigned',
    display: 'unassigned',
    use_text: true
  }, {
    type: 'active',
    display: 'active',
    use_text: true
  }, {
    type: 'closed',
    display: 'closed',
    use_text: false
  }, {
    type: 'lost',
    display: 'lost',
    use_text: false
  }, {
    type: 'inactive',
    display: 'inactive',
    use_text: false
  }
]

class StatusEntry extends Component {
  constructor(props) {
    super(props)
    if (props.data) {
      const optionIndex = options.findIndex(option => option.type === props.data.value)
      this.state = { option: options[optionIndex] }
    } else {
      this.state = {
        option: null,
        value: null
      }
    }
  }

  handleOptionSelection = (option) => {
    this.setState({ ...this.state, option: option })
    this.updateWithResult({ value: option.type })
  }

  updateWithResult = (result) => {
    const { id, ruleUpdated } = this.props
    ruleUpdated(id, result)
  }

  render () {
    const { option } = this.state
    const { readonly } = this.props
    const dropdownDisplay = (option) ? option.display : 'Select one'
    return (
      <div style={{ display: 'inline' }}>
        <span style={{ display: 'inline-block' }}>
          {!readonly &&
            <DropdownMenu
              trigger={dropdownDisplay}
              triggerType="button">
              <DropdownItemGroup>
                {options.map((option, i) => (
                  <DropdownItem key={i} onClick={()=> this.handleOptionSelection(option)}>
                    {option.display}
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </DropdownMenu>
          }
          {readonly &&
            <strong>{dropdownDisplay}</strong>
          }
        </span>
      </div>
    )
  }
}

StatusEntry.propTypes = {
  id: PropTypes.string.isRequired,
  ruleUpdated: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  data: PropTypes.object
}

StatusEntry.defaultProps = {
  readonly: false
}

export default StatusEntry
