import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import modal from "modules/stores/modal";
import session from "modules/stores/session";

import RepliesView from "../components/RepliesView";
import * as actions from "../actions";
import * as selectors from "../selectors";

import loadBulkEmail from "../../loadBulkEmail";

function mapStateToProps(state) {
  return {
    bulkEmail: loadBulkEmail.selectors.getBulkEmail(state),
    records: selectors.getRecords(state),
    selectedRecordId: selectors.getSelectedRecordId(state),
    selectedReply: selectors.getSelectedReply(state),
    loading: selectors.getLoading(state),
    loadingReply: selectors.getLoadingReply(state),
    pageDetails: selectors.getPageDetails(state),
    timezone: session.selectors.getUserTimezone(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRecords: actions.fetchRecords,
      setSelectedRecordId: actions.setSelectedRecordId,
      fetchReply: actions.fetchLeadEmail,
      showModal: modal.actions.showModal,
      hideModal: modal.actions.hideModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RepliesView)
);
