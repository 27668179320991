import { request, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import flags from 'modules/components/flags'

import loadTemplate from '../loadTemplate'

export const updateTemplate = (template, html, json, subject, companyId) => {
  return dispatch => {
    dispatch(setLoading(true))
    const templateBody = {
      email_template: {
        title: template.title,
        html: html,
        design_json: json,
        subject: subject,
        location_ids: template.restricted_location_ids
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
      dispatch(loadTemplate.actions.setCompliance(payload.compliance))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadTemplate.actions.setTemplate(payload.email_template))
      dispatch(loadTemplate.actions.setCompliance(payload.email_template.compliance))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: 'Email template updated',
        index: 0
      }))
    }

    const url = 'companies/' + companyId + '/email_templates/' + template.id
    return request(url, PUT, templateBody, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function prefillForTemplate (template) {
  return {
    type: actionTypes.PREFIL_FOR_TEMPLATE,
    template
  }
}
