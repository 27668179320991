import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './styles.css'
import { validator } from "../../settings/validator";

import { generateNewFormState } from "utils/formHelpers";
import { ContentBlockView, FormFields } from 'modules/components'
const { TextArea, SelectField, TextField } = FormFields

export class TextInputView extends Component {

  state = {
    cursorPosition: 0
  }

  onSelect = (name, value) => {
    const textData = this.props.settingsData.fields.text;
    const { cursorPosition } = this.state
    if (textData.value) {
      const newText = textData.value.slice(0, cursorPosition) + value + textData.value.slice(cursorPosition)
      this.handleChange('text', newText, 'true')
    } else {
      this.handleChange('text', value, 'true')
    }
  }

  saveDesignClicked = () => {
    const { subject, text } = this.props.settingsData.fields
    const { template, company, updateTemplate } = this.props
    updateTemplate(template, text.value, subject.value, company.id)
  }

  handleChange = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.settingsData,
      fieldName,
      value,
      isValid
    );
    this.props.updateSettingsData(newData);
  };

  updateCusorPosition = (name, cursorPosition) => {
    this.setState({ ...this.state, cursorPosition: cursorPosition })
  }

  render () {
    const { subject, text } = this.props.settingsData.fields;
    const { mergeTags, loading } = this.props
    return (
      <ContentBlockView title={'Template content'} loading={loading} bordered>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <SelectField
              id='personalization'
              placeHolderText='Insert Merge tag'
              onChange={this.onSelect}
              name='personalization'
              initialValue={null}
              formFieldStyle={'input-group'}
              icon={'fa fa-user'}
              possibleOptions={mergeTags}
              displayFieldName='title'
              />
          </div>
        </div>
        <TextField
          name="subject"
          placeholder="Email subject line (merge fields work too!)"
          formFieldStyle=""
          onChange={this.handleChange}
          value={subject.value}
          validator={validator}
        />
        <div className='editor'>
          <TextArea
            name='text'
            onChange={this.handleChange}
            cursorUpdated={this.updateCusorPosition}
            needsFormGroup={false}
            autoResize
            value={text.value}
            validator={validator}
            rows={5} />
        </div>
        <button className='btn btn-primary push-20' onClick={this.saveDesignClicked}>
          <i className='fa fa-save' /> Save template
        </button>
      </ContentBlockView>
    )
  }
}

TextInputView.propTypes = {
  template: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  setText: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  updateSettingsData: PropTypes.func.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default TextInputView
