import React, { useState, useEffect } from "react";
import { getLocation } from "modules/api/location";

import Button from "@atlaskit/button";

export const LocationButtonView = (props) => {
  const [locationName, setLocationName] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadButtonTitle = async () => {
      const { locations, data } = props;
      const locationId = data?.value[0];
      if (!locationId) {
        return;
      }
      const index = locations.findIndex(
        (location) => location.id === locationId
      );
      if (index > -1) {
        setLocationName(locations[index]?.display_name);
        return;
      }

      setLoading(true);
      const location = await getLocation(locationId);
      setLocationName(location?.display_name);
      setLoading(false);
    };
    if (locationName && locationName.length > 0) {
      return;
    }
    loadButtonTitle();
  }, [props, locationName]);

  const buttonTitle = () => {
    const { data } = props;
    const selectedLength = data?.value?.length || 0;

    if (selectedLength === 0) return "Select a Location";
    if (selectedLength > 1) return `${selectedLength} selected`;
    if (loading) return "Loading";
    if (locationName) return locationName;
    return "A location you don't have access to.";
  };

  return (
    <span style={{ display: "inline-block" }}>
      <Button appearance="default" onClick={props.onClick}>
        {buttonTitle()}
      </Button>
    </span>
  );
};
