import { request, GET, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

import loadAutomationEmail from "../loadAutomationEmail";

export const getDesigns = () => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setDesigns(payload.email_template_designs));
    }

    return request("email_template_designs", GET, null, onSuccess, onFailure);
  };
};

export const getTemplates = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadTemplatePageDetails(payload.meta));
      dispatch(setTemplates(payload.email_templates));
    }
    let url =
      "email_templates?template_type=html&detail=true&page[number]=" +
      pageNumber +
      "&viewable=true";
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const sortSearchUpdated = (sort, search) => {
  return async dispatch => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getTemplates(1, sort, search));
  };
};

export const createTemplate = (
  automationFunnelId,
  automationFunnelStepId,
  html,
  json
) => {
  return dispatch => {
    dispatch(setLoading(true));
    const templateBody = {
      automation_email_template: {
        html: html,
        design_json: json
      }
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(
        loadAutomationEmail.actions.setAutomationEmail(
          payload.automation_email_template
        )
      );
    }

    const url =
      "automation_funnels/" +
      automationFunnelId +
      "/automation_funnel_steps/" +
      automationFunnelStepId +
      "/automation_email_templates";
    return request(url, POST, templateBody, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setDesigns(designs) {
  return {
    type: actionTypes.SET_DESIGNS,
    designs
  };
}

function setTemplates(templates) {
  return {
    type: actionTypes.SET_TEMPLATES,
    templates
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function loadTemplatePageDetails(pageDetails) {
  return {
    type: actionTypes.TEMPLATE_PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder
  };
}
