import React, { Component } from "react";
import STRINGS from "strings";

import SelectViewMenu from "modules/components/selectViewMenu";

import DesignsContainer from "../containers/DesignsContainer";
import TemplatesContainer from "../containers/TemplatesContainer";
import ShareCodeContainer from "../containers/ShareCodeContainer";

const DESIGNS = "DESIGNS";
const USER_TEMPLATES = "USER_TEMPLATES";
const SHARE_CODE = "SHARE_CODE";

const menuItems = [
  {
    id: DESIGNS,
    name: STRINGS.Templates.TextMessage.Designs["designs"]
  },
  {
    id: USER_TEMPLATES,
    name: STRINGS.Templates.TextMessage.Designs["yourTemplates"]
  },
  {
    id: SHARE_CODE,
    name: STRINGS.Templates.shareTitle
  }
];

export class SelectDesignView extends Component {
  state = {
    view: DESIGNS
  };

  setView = view => {
    this.setState({ ...this.state, view: view });
  };

  render() {
    const { view } = this.state;
    return (
      <div>
        <h3 className="push-10">{STRINGS.Templates.Email.Designs.title}</h3>
        <SelectViewMenu
          menuItems={menuItems}
          currentView={view}
          setView={this.setView}
        />
        {view === USER_TEMPLATES && <TemplatesContainer />}
        {view === DESIGNS && <DesignsContainer />}
        {view === SHARE_CODE && <ShareCodeContainer />}
      </div>
    );
  }
}

export default SelectDesignView;
