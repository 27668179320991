import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'
import header from '../../header'

export class ContentRow extends Component {

  goToContent = () => {
    this.props.setView(header.constants.EDIT_VIEW)
  }

  render () {
    const contentViewStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Checklist.Content
    const { automationEmail } = this.props
    const checklist = automationEmail.checklist
    const icon = (!checklist.content_ok) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        <button
          className='pull-right btn btn-primary'
          onClick={this.goToContent}>
          {contentViewStrings.editContent}
        </button>
        <i className={icon} />
        <h4>{contentViewStrings.emailContent}</h4>
        {!checklist.compliance.is_valid &&
          <div>{contentViewStrings.fixCompliance}</div>
        }
        {checklist.compliance.is_valid &&
          <div>{contentViewStrings.successCompliant}</div>
        }
        {!checklist.correct_merge_tags &&
          <div>
            {contentViewStrings.incorrectMergeTags} [
              {checklist.incorrect_merge_tags.map((tag, index) => {
                if (index === checklist.incorrect_merge_tags.length - 1) {
                  return tag
                }
                return tag + ', '
              })}
            ]
          </div>
        }
        {checklist.correct_merge_tags &&
          <div>{contentViewStrings.successMergeTags}</div>
        }
      </div>
    )
  }
}

ContentRow.SubjectRow = {
  automationEmail: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
}

export default ContentRow
