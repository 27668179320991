import PropTypes from 'prop-types'
import React, { Component } from 'react'

import settings from '../../settings'

export class SubjectRow extends Component {

  editSubject = () => {
    this.props.history.push(settings.constants.NAME)
  }

  render () {
    const { bulkEmail } = this.props
    const checklist = bulkEmail.checklist
    const icon = (!checklist.subject_ready) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        {bulkEmail.status !== 'scheduled' &&
          <button
            className='pull-right btn btn-primary'
            onClick={this.editSubject}>
            Edit In Send Settings
          </button>
        }
        <i className={icon} />
        <h4>Subject</h4>
        {checklist.subject_ready &&
          <p>{bulkEmail.subject}</p>
        }
        {!checklist.subject_ready &&
          <p>
            Set a subject for your email.
          </p>
        }
      </div>
    )
  }
}

SubjectRow.SubjectRow = {
  bulkEmail: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default SubjectRow
