import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #e9e9e9;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Title = styled.p`
  margin: 0px !important;
`;

const SelectTemplateRow = ({ template, onClick, selected }) => {

  function _onClick () {
    onClick(template)
  }

  const selectedClassName = (selected) ? 'selected' : 'selectable';

  return (
    <Row onClick={_onClick} className={selectedClassName}>
      <Title>{template.title}</Title>
    </Row>
  )

}

export default SelectTemplateRow