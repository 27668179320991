import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Script from 'react-load-script'
import styled from 'styled-components'
import { GymLeadsLoader } from 'modules/components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
`

const Designer = styled.div`
  flex: 1;
  display: flex;
  > iframe {
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: ${props => props.minHeight || '800px'} !important;
    display: flex;
    border: 0px;
  }
`
export class EmailEditor extends Component {

  state = {
    loading: true
  }

  unrollReady = () => {
    const { designJson, mergeTags } = this.props
    window.unlayer.init({
      displayMode: 'email',
      id: 'editor',
      projectId: 256,
      mergeTags: mergeTags
    })
    window.unlayer.loadDesign(designJson)
    this.setState({ ...this.state, loading: false })
  }

  render() {
    const { loading } = this.state
    return (
      <div>
        <Script
          id='editor'
          url="https://editor.unlayer.com/embed.js"
          onLoad={this.unrollReady}
        />
        <GymLeadsLoader text='Loading Editor' active={loading} />
        <Wrapper>
          <Designer
            id="editor"
            style={this.props.style}
            minHeight={this.props.minHeight}
          />
        </Wrapper>
      </div>
    )
  }
}

EmailEditor.propTypes = {
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  designJson: PropTypes.object.isRequired
}

export default EmailEditor
