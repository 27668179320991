import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'

const FUNNEL_TEXT_MESSAGE_SETTINGS_ACTION_HANDLERS = {
  [actionTypes.PREFIL_DATA_FOR_TEXT_MESSAGE]: (state, action) => {
    return initialStateForTextMessage(action.textMessage)
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data, hasChanges: true }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_HAS_CHANGES]: (state, action) => {
    return { ...state, hasChanges: action.hasChanges }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialStateForTextMessage(null)
  }
}

const payloadKeys = {
  communicationType: 'communication_type'
}

function initialStateForTextMessage (textMessage) {
  const fields = generateFieldsForKeys(textMessage, fieldNames, payloadKeys)
  return {
    loading: false,
    errors: null,
    hasChanges: false,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function (state = initialStateForTextMessage(null), action) {
  const handler = FUNNEL_TEXT_MESSAGE_SETTINGS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
