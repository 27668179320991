import PropTypes from "prop-types";
import React, { Component } from "react";
import { ModalContainer } from "modules/components";
import {
  UPDATE_TRIGGER_MODAL_NAME,
  SELECT_TRIGGER_VIEW,
  EDIT_TRIGGER_VIEW,
} from "../constants";

import EditTriggerFormContainer from "../containers/EditTriggerFormContainer";
import SelectTriggerContainer from "../containers/SelectTriggerContainer";
import store from "store/store";

import STRINGS from "strings";
const triggersStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Triggers;

const Provider = require("react-redux").Provider;

export class TriggersModal extends Component {
  updateSelected = () => {
    const { automationFunnel, selectedFunnelStep, update, data } = this.props;
    const trigger = selectedFunnelStep.trigger;
    update(automationFunnel.id, selectedFunnelStep.id, trigger.id, data);
  };

  toggleView = () => {
    const { view, setView } = this.props;
    switch (view) {
      case SELECT_TRIGGER_VIEW:
        setView(EDIT_TRIGGER_VIEW);
        break;
      case EDIT_TRIGGER_VIEW:
        setView(SELECT_TRIGGER_VIEW);
        break;
      default:
        break;
    }
  };

  changeViewText = () => {
    const { view } = this.props;
    switch (view) {
      case SELECT_TRIGGER_VIEW:
        return "Cancel";
      case EDIT_TRIGGER_VIEW:
        return "Change trigger";
      default:
        return "";
    }
  };

  render() {
    const {
      selectedFunnelStep,
      selectedTrigger,
      view,
      TriggerSelectionContainer,
    } = this.props;
    return (
      <div>
        {selectedFunnelStep && (
          <ModalContainer
            modalName={UPDATE_TRIGGER_MODAL_NAME}
            modalTitle={`${triggersStrings.modalTitle} ${selectedFunnelStep.name}`}
            colorThemeClass={"primary"}
            showCancelButton
            actionEnabled={this.props.data ? this.props.data.isValid : true}
            width="x-large"
            actionButtonClicked={this.updateSelected}
            actionButtonElement={
              <span>{triggersStrings.modalUpdateButton}</span>
            }
          >
            <h5>{selectedTrigger.title}</h5>
            <p>{selectedTrigger.description}</p>
            <button
              className="linkButton"
              onClick={this.toggleView}
              style={{ paddingLeft: 0 }}
            >
              {this.changeViewText()}
            </button>
            {view === EDIT_TRIGGER_VIEW && (
              <Provider store={store}>
                <div style={{ paddingTop: "20px" }}>
                  <EditTriggerFormContainer selectedTrigger={selectedTrigger} />
                  {TriggerSelectionContainer && <TriggerSelectionContainer />}
                </div>
              </Provider>
            )}
            {view === SELECT_TRIGGER_VIEW && (
              <Provider store={store}>
                <SelectTriggerContainer currentTrigger={selectedTrigger} />
              </Provider>
            )}
          </ModalContainer>
        )}
      </div>
    );
  }
}

TriggersModal.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  selectedTrigger: PropTypes.object,
  view: PropTypes.string.isRequired,
  selectedFunnelStep: PropTypes.object,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  TriggerSelectionContainer: PropTypes.func,
};

export default TriggersModal;
