import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class DesignCell extends Component {

  rowSelected = (e) => {
    const { design, onSelect } = this.props
    onSelect(design)
  }

  render () {
    const { design } = this.props
    return (
      <div className='selectable design-cell' onClick={this.rowSelected}>
        <div className='design-container'>
          <img src={design.url} alt='design preview' />
        </div>
        <h5>{design.title}</h5>
        {design.detail &&
          <p>{design.detail}</p>
        }
      </div>
    )
  }
}

DesignCell.propTypes = {
  design: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default DesignCell
