import PropTypes from "prop-types";
import React, { Component } from "react";

import SelectLeadLostModalContainer from "modules/components/selectLeadLostReasonModal/SelectLeadLostModalContainer";
import Button from "@atlaskit/button";

import "./styles.css";

class LostEntryView extends Component {
  constructor(props) {
    super(props);
    if (props.data) {
      switch (typeof props.data.value) {
        case "number":
          this.state = { value: props.data.value };
          break;
        case "string":
          this.state = { value: parseInt(props.data.value, 10) };
          break;
        default:
          this.state = { value: null };
          break;
      }
    } else {
      this.state = {
        value: null
      };
    }
  }

  updateLostReason = selectedLostReason => {
    this.setState({
      ...this.state,
      value: selectedLostReason.id,
      displayValue: selectedLostReason.lost_reason_title
    });
    this.updateWithResult({ value: selectedLostReason.id });
  };

  updateWithResult = result => {
    const { id, eventUpdated } = this.props;
    eventUpdated(id, result);
  };

  showSelectLostReasonsModal = () => {
    const { showModal, lostReasonSelectModalName } = this.props;
    showModal(lostReasonSelectModalName);
  };

  buttonStringTitle = () => {
    const { data } = this.props;
    if (data && data.value) {
      return this.state.displayValue || "Reason selected";
    } else {
      return "Select a reason";
    }
  };

  render() {
    const { lostReasonSelectModalName, hideModal } = this.props;
    return (
      <div style={{ display: "inline" }}>
        <span style={{ display: "inline-block" }}>
          <Button
            appearance="default"
            onClick={this.showSelectLostReasonsModal}
          >
            {this.buttonStringTitle()}
          </Button>
        </span>
        <span style={{ display: "inline-block" }}>
          <SelectLeadLostModalContainer
            hideModal={hideModal}
            modalName={lostReasonSelectModalName}
            setSelectedLeadLostReason={this.updateLostReason}
            selectedLeadLostReasonId={this.state.value}
          />
        </span>
      </div>
    );
  }
}

LostEntryView.propTypes = {
  id: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  lostReasonSelectModalName: PropTypes.string.isRequired,
  eventUpdated: PropTypes.func.isRequired,
  data: PropTypes.object
};

export default LostEntryView;
