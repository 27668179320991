import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import FunnelListingView from "../components/FunnelListingView";
import * as selectors from "../selectors";
import * as actions from "../actions";
import funnelState from "../../funnelState";

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    funnels: selectors.getFunnels(state),
    funnelState: funnelState.selectors.getSelection(state),
    pageDetails: selectors.getPageDetails(state),
    searchTerm: selectors.getSearchTerm(state),
    sortOrder: selectors.getSortOrder(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFunnels: actions.getFunnels,
      sortSearchUpdated: actions.sortSearchUpdated
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FunnelListingView)
);
