import { request, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import flags from 'modules/components/flags'

import loadTemplate from '../loadTemplate'

export const updateTemplate = (template, text, companyId) => {
  return dispatch => {
    dispatch(setLoading(true))
    const templateBody = {
      text_message_template: {
        text: text,
        is_legacy: false,
        location_ids: template.restricted_location_ids
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setHasChanges(false))
      dispatch(loadTemplate.actions.setTemplate(payload.text_message_template))
      if (payload.meta) {
        dispatch(setPreviewText(payload.meta.preview))
      }
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: 'Text message template updated',
        index: 0
      }))
    }

    const url = 'companies/' + companyId + '/text_message_templates/' + template.id
    return request(url, PUT, templateBody, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function prefillForTemplate (template) {
  return {
    type: actionTypes.PREFIL_FOR_TEMPLATE,
    template
  }
}

export function setText (text) {
  return {
    type: actionTypes.SET_TEXT,
    text
  }
}

export function setPreviewText (previewText) {
  return {
    type: actionTypes.SET_PREVIEW_TEXT,
    previewText
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}
