import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectDesign from "images/SmsNotReady.jpg";
import styled from "styled-components";

import {
  ContentBlockView,
  GymLeadsLoader,
  ImageEmptyView,
  SortSearchView
} from "modules/components";
import DesignsEmpty from "images/TemplatesEmpty.jpg";

import SelectDesignRow from "./SelectDesignRow";
import sortOptions from "../sortOptions";

import { PagesView } from "modules/components";
import STRINGS from "strings";

const PreviewText = styled.p``;

export class DesignsView extends Component {
  state = {
    selectedDesign: null
  };

  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getDesignsWithPageNumber(currentPage ? currentPage : 1);
  }

  componentWillUnmount() {
    this.props.resetSortSearch();
  }

  getDesignsWithPageNumber = pageNumber => {
    const { getDesigns, sortOrder, searchTerm } = this.props;
    getDesigns(pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getDesignsWithPageNumber(pageNumber);
  };

  designSelected = design => {
    this.setState({ selectedDesign: design });
  };

  createDesignSelected = () => {
    const { createTemplateFromDesign, company } = this.props;
    const { selectedDesign } = this.state;
    createTemplateFromDesign(selectedDesign.id, company.id);
  };

  goBack = () => {
    this.props.showListingView();
  };

  render() {
    const {
      designs,
      loading,
      pageDetails,
      sortSearchUpdated,
      sortOrder,
      searchTerm
    } = this.props;
    const { selectedDesign } = this.state;

    const designRows = designs.map((design, index) => {
      const selected = selectedDesign && selectedDesign.id === design.id;
      return (
        <SelectDesignRow
          key={index}
          design={design}
          onClick={this.designSelected}
          selected={selected}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={sortSearchUpdated}
          sortColumnWidth={"3"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <div className="row">
          <div className="col-md-6">
            <ContentBlockView
              title={STRINGS.Templates.TextMessage.Designs["yourDesigns"]}
              bordered
              minHeight={397}
            >
              <GymLeadsLoader
                title={STRINGS.Templates.TextMessage.Designs["loadingDesigns"]}
                active={loading}
              />
              {designs.length > 0 && !loading && designRows}
              {designs.length === 0 && (
                <ImageEmptyView
                  image={DesignsEmpty}
                  title={STRINGS.Templates.TextMessage.Designs["noDesigns"]}
                  subtitle={
                    STRINGS.Templates.TextMessage.Designs["noDesignsSubtitle"]
                  }
                />
              )}
            </ContentBlockView>
          </div>
          <div className="col-md-6">
            <ContentBlockView title={"Preview"} bordered minHeight={397}>
              {!selectedDesign && (
                <ImageEmptyView
                  image={SelectDesign}
                  title={STRINGS.Templates.TextMessage.Designs["selectDesign"]}
                />
              )}
              {selectedDesign && (
                <PreviewText>{selectedDesign.text}</PreviewText>
              )}
            </ContentBlockView>
          </div>
        </div>
        {designs.length > 0 && !loading && (
          <PagesView pageClicked={this.pageClicked} pageDetails={pageDetails} />
        )}
        <button
          className="btn btn-primary push-20-t push-20"
          onClick={this.goBack}
        >
          <i className="fa fa-angle-left" />{" "}
          {STRINGS.Templates.TextMessage.Designs["backToDesigns"]}
        </button>
        <button
          className="btn btn-success push-20-t push-20 pull-right"
          onClick={this.createDesignSelected}
          disabled={!selectedDesign}
        >
          <i className="fa fa-plus" /> {STRINGS.Global["create"]}
        </button>
      </div>
    );
  }
}

DesignsView.propTypes = {
  showListingView: PropTypes.func.isRequired,
  getDesigns: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  designs: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTemplateFromDesign: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  pageDetails: PropTypes.object.isRequired,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  resetSortSearch: PropTypes.func.isRequired
};

export default DesignsView;
