import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { errorPanelForErrors } from 'utils/formHelpers'

import { ContentBlockView, ImageEmptyView } from 'modules/components'

import DraftSendContainer from '../containers/DraftSendContainer'
import ScheduledSendContainer from '../containers/ScheduledSendContainer'

import EmailNotReady from 'images/EmailNotReady.jpg'
import STRINGS from "strings";

const checklistSendViewStrings =
  STRINGS.BulkMessaging.BulkEmail.Checklist.SendView;
export class SendView extends Component {

  render () {
    const { bulkEmail, loading, errors } = this.props
    const checklist = bulkEmail.checklist
    return (
      <ContentBlockView bordered loading={loading}>
        {errorPanelForErrors(errors)}
        {checklist.ready_to_send && bulkEmail.status === 'draft' &&
          <DraftSendContainer />
        }
        {checklist.ready_to_send && bulkEmail.status === 'scheduled' &&
          <ScheduledSendContainer />
        }
        {!checklist.ready_to_send &&
          <ImageEmptyView
            title={checklistSendViewStrings.emailNotFinishedTitle}
            subtitle={checklistSendViewStrings.emailNotFinishedMessage}
            image={EmailNotReady} />
        }
      </ContentBlockView>
    )
  }
}

SendView.SubjectRow = {
  bulkEmail: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired
}

export default SendView
