import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ToRow from './ToRow'
import StepsView from './StepsView'
import StartContainer from '../containers/StartContainer'
import './styles.css'

import { ContentBlockView } from 'modules/components'

export class ChecklistView extends Component {

  componentDidMount () {
    const { getChecklist, automationFunnel } = this.props
    if (automationFunnel) { getChecklist(automationFunnel) }
  }

  render () {
    const { automationFunnel, history } = this.props
    return (
      <div>
        <ContentBlockView title={'Checklist'} bordered>
          <StepsView automationFunnel={automationFunnel} history={history}  />
          <ToRow automationFunnel={automationFunnel} history={history}  />
        </ContentBlockView>
        <StartContainer />
      </div>
    )
  }
}

ChecklistView.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  automationFunnel: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  getChecklist: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default ChecklistView
