import { createSelector } from 'reselect'
import { NAME } from './constants'

import content from '../content'
import settings from '../settings'

export const getView = state => state.editAutomationFunnel.steps.stepEmail[NAME].view

export const hasUnsavedChanges = createSelector(
  [ content.selectors.getHasChanges, settings.selectors.getHasChanges ],
  (contentHasChanges, settingsHaveChanges) => {
    if (contentHasChanges) { return true }
    if (settingsHaveChanges) { return true }
    return false
  }
)
