import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import NewBulkSmsModalView from '../components/NewBulkSmsModalView'
import listing from '../modules/listing'

import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createBulkSms: listing.actions.createBulkSms
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewBulkSmsModalView))
