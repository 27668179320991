import loadGroup from './modules/loadGroup'
import settings from './modules/settings'
import members from './modules/members'
import entryRules from './modules/rules/modules/entryRules'
import exitRules from './modules/rules/modules/exitRules'

export const reset = () => {
  return (dispatch) => {
    dispatch(members.actions.reset())
    dispatch(loadGroup.actions.reset())
    dispatch(entryRules.actions.reset())
    dispatch(exitRules.actions.reset())
    dispatch(settings.actions.reset())
  }
}
