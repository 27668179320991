import filters from './modules/filters'

export function modalStringsForTemplateType (templateType) {
  switch (templateType) {
    case filters.constants.HTML_TEMPLATES:
      return {
        title: 'Create a New HTML Email Template',
        detail: 'Just give your email template a name and you\'ll be on your way! You can always change the name later.',
        button: 'Create HTML Email Template'
      }
    case filters.constants.QUICK_SEND_TEMPLATES:
      return {
        title: 'Create a New Quick Send Email Template',
        detail: 'Just give your email template a name and you\'ll be on your way! You can always change the name later.',
        button: 'Create Quick Send Email Template'
      }
    default:
      return {
        title: 'Create a new email template',
        detail: 'Just give your email template a name and you\'ll be on your way! You can always change the name later.',
        button: 'Create email template'
      }
  }
}
