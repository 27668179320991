import PropTypes from "prop-types";
import React, { Component } from "react";

import MetricsView from "./MetricsView";
import GraphView from "./GraphView";
import DetailsView from "./DetailsView";

import STRINGS from "strings";
const resultsStrings = STRINGS.ViewBulkSms.Results.Results;

export class ResultsView extends Component {
  titleForStatus = () => {
    const { bulkSms } = this.props;
    switch (bulkSms.status) {
      case "sent":
        return resultsStrings.title;
      case "sending":
      case "queued":
        return "Your campaign is sending now";
      default:
        return resultsStrings.title;
    }
  };

  render() {
    const { formattedStatistics, bulkSms, timezone } = this.props;
    return (
      <div>
        <h2 className="text-center push">{this.titleForStatus()}</h2>
        <div className="row">
          <div className="col-xs-12">
            <MetricsView formattedStatistics={formattedStatistics} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-xs-12">
            <DetailsView bulkSms={bulkSms} timezone={timezone} />
          </div>
          <div className="col-md-8 col-xs-12">
            <GraphView formattedStatistics={formattedStatistics} />
          </div>
        </div>
      </div>
    );
  }
}

ResultsView.propTypes = {
  formattedStatistics: PropTypes.object.isRequired,
  bulkSms: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  errors: PropTypes.object,
};

export default ResultsView;
