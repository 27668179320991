import { createSelector } from 'reselect'
import { linksForUser } from './components/Menu/linksForUser'
import session from 'modules/stores/session'

const getCurrentPath = state => state.routing.location.pathname

export const shouldShowMenu = createSelector(
  [ getCurrentPath, session.selectors.getUser ],
  (currentPath, user) => {

    if (!String.prototype.endsWith) {
      // eslint-disable-next-line
      String.prototype.endsWith = function(searchString, position) {
          var subjectString = this.toString();
          if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length;
          }
          position -= searchString.length;
          var lastIndex = subjectString.lastIndexOf(searchString, position);
          return lastIndex !== -1 && lastIndex === position;
      };
    }

    const links = linksForUser(user).map( l => l.to)
    let found = false
    links.forEach(function (link) {
      if (currentPath.endsWith(link)) { found = true }
      if (currentPath.endsWith(link + '/')) { found = true }
    })
    return found
  }
)
