import { createSelector } from 'reselect'
import loadBulkSms from '../loadBulkSms'
import { NAME } from './constants'

export const getSearchText = state => state.viewBulkSms[NAME].searchText
export const getSearchResults = state => state.viewBulkSms[NAME].searchResults
export const getPreviewText = state => state.viewBulkSms[NAME].previewText
export const getPreviewResult = state => state.viewBulkSms[NAME].previewResult

export const getErrors = state => state.viewBulkSms[NAME].errors
export const getLoading = state => state.viewBulkSms[NAME].loading

export const validPreviewText = createSelector(
  [ getPreviewText, loadBulkSms.selectors.getBulkSms ],
  (previewText, bulkSms) => {
    if (!previewText) { return bulkSms.text }
    return previewText
  }
)
