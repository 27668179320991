import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ImageEmptyView, TextSearchView } from 'modules/components'
import LeadsEmpty from 'images/LeadsEmpty.jpg'
import STRINGS from 'strings'

import LeadsTableView from './LeadsTableView'
import PagesContainer from '../containers/PagesContainer'

export class ActiveLeadsView extends Component {

  state = {
    showingSearch: false,
    search: null
  }

  componentDidMount () {
    this.getLeadsWithPageNumber(1)
  }

  getLeadsWithPageNumber = (pageNumber)  => {
    const { get, automationFunnel } = this.props
    get(automationFunnel.id, pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getLeadsWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm, fieldName) => {
    const { get, automationFunnel } = this.props
    get(automationFunnel.id, 1, searchTerm)
  }

  toggleSearch = () => {
    if (this.state.showingSearch === true) {
      const { pageDetails } = this.props
      this.setState({ ...this.state, search: null })
      this.getLeadsWithPageNumber(pageDetails.currentPage)
    }
    this.setState({ ...this.state, showingSearch: !this.state.showingSearch })
  }

  render () {
    const activeLeadsViewStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.ActiveLeads
    const { leads, loading } = this.props
    const { search, showingSearch } = this.state
    const className = (loading) ? 'block block-bordered block-opt-refresh' : 'block block-bordered'
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className={className}>
            <div className='block-header'>
              <div className='block-options-simple'>
                <button type='button' className='pull-right btn btn-xs btn-primary'
                  onClick={this.toggleSearch}>
                  <i className='fa fa-search' />
                </button>
              </div>
              <h3 className='block-title pull-left'
                style={{
                  marginRight: '5px'
                }}>{activeLeadsViewStrings.activeleads}</h3>
            </div>
            <div className='block-content'>
              {showingSearch &&
                <div>
                  <TextSearchView onUpdate={this.searchUpdated} searchText={search || ''} sectionName={'search'} />
                </div>
              }
              {leads.length > 0 &&
                <div className='table-responsive'>
                  <LeadsTableView leads={leads} />
                  <PagesContainer pageClicked={this.pageClicked} />
                </div>
                }
                {leads.length === 0 &&
                  <ImageEmptyView
                    image={LeadsEmpty}
                    height={100}
                    title={activeLeadsViewStrings.activeleads}
                    subtitle={activeLeadsViewStrings.emptyLeadsFunnel} />
                }
          </div>
        </div>
      </div>
    </div>
    )
  }
}

ActiveLeadsView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pageDetails: PropTypes.object.isRequired
}

export default ActiveLeadsView
