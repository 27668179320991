import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getRules = state => state.editBulkSms.bulkSmsAudience[NAME].rules
export const getCondition = state => state.editBulkSms.bulkSmsAudience[NAME].condition
export const getGroups = state => state.editBulkSms.bulkSmsAudience[NAME].groups
export const getSelectedGroup = state => state.editBulkSms.bulkSmsAudience[NAME].selectedGroup
export const getLoading = state => state.editBulkSms.bulkSmsAudience[NAME].loading
export const getErrors = state => state.editBulkSms.bulkSmsAudience[NAME].errors
export const getPageDetails = state => state.editBulkSms.bulkSmsAudience[NAME].pageDetails
export const getHasChanges = state => state.editBulkSms.bulkSmsAudience[NAME].hasChanges

export const getValidRules = createSelector(
  [ getRules ],
  (rules) => {
    return rules.filter(rule => rule.data !== null)
  }
)

export const entryRulesJson = createSelector(
  [ getRules, getCondition],
  (entryRules, entryCondition) => {
    const rules = entryRules.filter(rule => rule.data !== undefined)
    return {
      entry_rules: {
        condition: entryCondition,
        rules: rules
      }
    }
  }
)
