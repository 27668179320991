import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Fade } from 'modules/components'
import { RuleOptionsSelection, RuleMatchOptionsSelection, SelectedRuleItem } from 'modules/rules'

import SelectGroupModalContainer from '../containers/SelectGroupModalContainer'

import { TransitionGroup } from 'react-transition-group'
import './styles.css';

import { MODAL_NAME } from '../constants'

import STRINGS from 'strings'
const bulkEmailAudienceStrings = STRINGS.SetAudience
class ConditionsView extends Component {

  showGroupSelectModal = () => {
    this.props.showModal(MODAL_NAME)
  }

  clearGroup = () => {
    this.props.setSelectedGroup(null)
  }

  render () {
    const {
      rules, condition, setCondition, addRule,
      updateRule, removeRule, selectedGroup
     } = this.props
    return (
      <div>
        {selectedGroup &&
          <div>
            <h4 className='push-10'>{selectedGroup.name}</h4>
            <button type='button'
              style={{ width: '50%' }}
              className='btn btn-block btn-primary push'
              onClick={this.showGroupSelectModal}>
              {bulkEmailAudienceStrings.changeGroupButton}
            </button>
            <p className='or-seperator'>OR</p>
            <button type='button'
              style={{ width: '50%' }}
              className='btn btn-block btn-primary'
              onClick={this.clearGroup}>
              {bulkEmailAudienceStrings.useCustomFiltersButton}
            </button>
          </div>
        }
        {!selectedGroup &&
          <div>
            <button type='button'
              style={{ width: '50%' }}
              className='btn btn-block btn-primary push'
              onClick={this.showGroupSelectModal}>
              {bulkEmailAudienceStrings.selectGroupButton}
            </button>
            <p className='or-seperator'>OR</p>
            <h4 className='push-10'>{bulkEmailAudienceStrings.selectFiltersHeadline}</h4>
            <RuleOptionsSelection
              selectedOption={condition}
              setSelectedOption={setCondition} />
            <RuleMatchOptionsSelection
              ruleSelected={addRule} />
            <TransitionGroup>
              {rules.map((rule, i) => (
                <Fade key={i}>
                  <SelectedRuleItem
                    rule={rule}
                    index={i}
                    removeRuleAtIndex={removeRule}
                    ruleUpdatedWithData={updateRule} />
                </Fade>
              ))}
            </TransitionGroup>
          </div>
        }
        <SelectGroupModalContainer />
      </div>
    )
  }
}

ConditionsView.propTypes = {
  setSelectedGroup: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  showModal: PropTypes.func.isRequired,
  condition: PropTypes.string.isRequired,
  setRules: PropTypes.func.isRequired,
  setCondition: PropTypes.func.isRequired,
  addRule: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
  removeRule: PropTypes.func.isRequired,
}

export default ConditionsView
