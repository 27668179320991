import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { SEARCH_MODAL_NAME } from '../constants'
import SearchForLeadModalContainer from '../containers/SearchForLeadModalContainer'

import { ContentBlockView, FormFields } from 'modules/components'
import STRINGS from "strings";

const { TextArea } = FormFields
const contentStrings = STRINGS.ViewBulkSms.Content;

export class ContentView extends Component {

  showSearchModal = () => {
    this.props.showModal(SEARCH_MODAL_NAME)
  }

  onChange = (name, value) => {}

  render () {
    const { validPreviewText, previewResult, loading, bulkSms } = this.props
    return (
      <div className='row'>
        {bulkSms.is_legacy === true &&
          <div>
            <div className='col-md-6 col-md-offset-3 col-xs-12'>
              <ContentBlockView title={contentStrings.legacyTitle} bordered>
                <div style={{ pointerEvents: 'none' }}>
                  <p>{contentStrings.legacyDescription}</p>
                </div>
              </ContentBlockView>
            </div>
          </div>
        }
        {bulkSms.is_legacy === false &&
          <Fragment>
            <div className='col-md-6 col-md-offset-1 col-xs-12'>
              <ContentBlockView title={contentStrings.unmergedTitle} bordered>
                <div className='editor'>
                  <TextArea
                    name={'bulkSms preview'}
                    onChange={this.onChange}
                    needsFormGroup={false}
                    disabled
                    autoResize
                    value={validPreviewText}
                    rows={5} />
                </div>
              </ContentBlockView>
            </div>
            <div className='col-md-4 col-xs-12'>
              <ContentBlockView title={contentStrings.previewTitle} bordered loading={loading}>
                <p>{contentStrings.previewDescription}</p>
                <button className='btn btn-primary push'
                  style={{ marginTop: '10px' }}
                  type='button'
                  onClick={this.showSearchModal}>
                  <span><i className='fa fa-search' /> {STRINGS.Global.search}</span>
                </button>
                {previewResult &&
                  <p>
                    {STRINGS.formatString(contentStrings.showPreviewAsDescription,
                      <strong>{previewResult.lead.display_name}</strong>)
                    }
                  </p>
                }
              </ContentBlockView>
            </div>
            <SearchForLeadModalContainer />
          </Fragment>
        }
      </div>
    )
  }
}

ContentView.propTypes = {
  loading: PropTypes.bool.isRequired,
  bulkSms: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  previewResult: PropTypes.object,
  validPreviewText: PropTypes.string.isRequired,
  errors: PropTypes.object
}

export default ContentView
