import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectTriggerView from '../components/SelectTriggerView'
import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  const selectedFunnelStep = selectors.selectedFunnelStep(state)
  return {
    triggerOptions: selectors.getTriggerOptions(state),
    stepNumber: selectedFunnelStep.step_number,
    categories: selectors.triggerCategories(state),
    data: selectors.getData(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateData: actions.updateData,
    setView: actions.setView
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTriggerView)
