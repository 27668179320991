import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LeadSourceEntryView from "./LeadSourceEntryView";

import leadSources from "modules/stores/leadSources";
import modal from "modules/stores/modal";

function mapStateToProps(state) {
  return {
    leadSources: leadSources.selectors.getLeadSources(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeadSourceEntryView)
);
