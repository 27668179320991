import PropTypes from "prop-types";
import React, { Component } from "react";
import STRINGS from "strings";

import {
  ContentBlockView,
  ImageEmptyView,
  SortSearchView
} from "modules/components";
import TemplatesEmpty from "images/TemplatesEmpty.jpg";
import TemplatesPagesContainer from "../containers/TemplatesPagesContainer";

import TemplateCell from "./TemplateCell";
import sortOptions from "../sortOptions";

const selectDesignStrings = STRINGS.BulkMessaging.SelectDesign;

export class TemplatesView extends Component {
  state = {
    showingSearch: false
  };

  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getTemplatesWithPageNumber(currentPage ? currentPage : 1);
  }

  getTemplatesWithPageNumber = pageNumber => {
    const { getTemplates, sortOrder, searchTerm } = this.props;
    getTemplates(pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getTemplatesWithPageNumber(pageNumber);
  };

  sortSearchUpdated = (sort, search) => {
    const { sortSearchUpdated } = this.props;
    sortSearchUpdated(sort, search);
  };

  templateSelected = template => {
    const { createMassEmail, newName } = this.props;
    createMassEmail(newName, template.design_json, template.html);
  };

  toggleSearch = () => {
    this.setState({ ...this.state, showingSearch: !this.state.showingSearch });
  };

  render() {
    const { templates, loading, sortOrder, searchTerm } = this.props;
    const { showingSearch } = this.state;

    const cells = templates.map((template, index) => {
      return (
        <TemplateCell
          key={index}
          template={template}
          onSelect={this.templateSelected}
        />
      );
    });

    const showSearchButton = (
      <button
        type="button"
        className="pull-right btn btn-xs btn-primary"
        onClick={this.toggleSearch}
      >
        <i className="fa fa-search" />
      </button>
    );

    return (
      <ContentBlockView
        title={selectDesignStrings.TemplateViewTitle}
        bordered
        loading={loading}
        blockOptions={showSearchButton}
      >
        {showingSearch && (
          <SortSearchView
            sortSearchUpdated={this.sortSearchUpdated}
            sortOptions={sortOptions}
            sortOrder={sortOrder}
            initialSearchTerm={searchTerm}
          />
        )}
        {!loading && templates.length === 0 && (
          <ImageEmptyView
            image={TemplatesEmpty}
            title={selectDesignStrings.noTemplates}
            subtitle={selectDesignStrings.usedforBulkEmails}
          />
        )}
        {templates.length > 0 && <div className="designs-list">{cells}</div>}
        <TemplatesPagesContainer pageClicked={this.pageClicked} />
      </ContentBlockView>
    );
  }
}

TemplatesView.propTypes = {
  getTemplates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  createMassEmail: PropTypes.func.isRequired,
  newName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string
};

export default TemplatesView;
