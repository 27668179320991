import { NAME } from "./constants";

export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_DESIGNS_LOADING = NAME + "/SET_DESIGNS_LOADING";
export const SET_ERRORS = NAME + "/SET_ERRORS";

export const SET_PRIVATE_RECIPE = NAME + "/SET_PRIVATE_RECIPE";

export const SET_LAYOUTS = NAME + "/SET_LAYOUTS";
export const SET_DESIGNS = NAME + "/SET_DESIGNS";
export const SET_TEMPLATES = NAME + "/SET_TEMPLATES";

export const DESIGNS_PAGE_DETAILS_UPDATED =
  NAME + "/DESIGNS_PAGE_DETAILS_UPDATED";
export const TEMPLATE_PAGE_DETAILS_UPDATED =
  NAME + "/TEMPLATE_PAGE_DETAILS_UPDATED";
