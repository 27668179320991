import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ContentBlockView, FormFields } from 'modules/components'
const { TextArea } = FormFields

export class BulkSmsPreviewView extends Component {

  onChange = (name, value) => {}

  render () {
    const { previewText } = this.props

    return (
      <div>
        <ContentBlockView title={'BulkSms preview'} bordered>
          <div className='editor'>
            <TextArea
              name={'bulkSms preview'}
              onChange={this.onChange}
              needsFormGroup={false}
              disabled
              autoResize
              value={previewText}
              rows={5} />
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

BulkSmsPreviewView.propTypes = {
  previewText: PropTypes.string.isRequired
}

export default BulkSmsPreviewView
