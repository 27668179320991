import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PrivateRouteContainer, ImageEmptyView } from 'modules/components'

import loadBulkSms from '../modules/loadBulkSms'
import checklist from '../modules/checklist'
import settings from '../modules/settings'
import content from '../modules/content'
import audience from '../modules/audience'
import header from '../modules/header'

import EmptyImage from 'images/EmptyGraph.jpg'
import STRINGS from 'strings'
const headerStrings = STRINGS.BulkMessaging.BulkSms.Header

export class EditTemplateView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  backSelected = () => {
    this.props.history.push('/secure/bulkMessaging')
  }

  render () {
    const { bulkSms, notFound } = this.props
    return (
      <div className='content'>
        {bulkSms &&
          <div>
            <header.HeaderContainer />
            {bulkSms.status === 'scheduled' &&
              <div className='alert alert-warning'>
                <p>
                  <i className='fa fa-2x fa-clock-o push-5-r' />
                  {headerStrings.smsScheduledMessageAlert}
                </p>
              </div>
            }
            <div className='row'>
              <div className='col-sm-12'>
                <PrivateRouteContainer exact path={"/secure/bulkMessaging/editBulkSms/:id/" + checklist.constants.NAME} component={checklist.ChecklistContainer} />
                <PrivateRouteContainer exact path={"/secure/bulkMessaging/editBulkSms/:id/" + content.constants.NAME} component={content.ContentContainer} />
                <PrivateRouteContainer exact path={"/secure/bulkMessaging/editBulkSms/:id/" + audience.constants.NAME} component={audience.AudienceContainer} />
                <PrivateRouteContainer exact path={"/secure/bulkMessaging/editBulkSms/:id/" + settings.constants.NAME} component={settings.SettingsContainer}  />
              </div>
            </div>
          </div>
        }
        {!bulkSms && !notFound &&
          <loadBulkSms.LoadBulkSmsContainer />
        }
        {!bulkSms && notFound &&
          <ImageEmptyView
            image={EmptyImage}
            title={headerStrings.smsNotFoundTitle}
            subtitle={headerStrings.smsNotFoundSubtitle}
            buttonText={STRINGS.Global.goBackButton}
            buttonSelected={this.backSelected} />
        }
      </div>
    )
  }
}

EditTemplateView.propTypes = {
  bulkSms: PropTypes.object,
  notFound: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired
}

export default EditTemplateView
