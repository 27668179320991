export const NAME = "triggers";
export const SELECT_TRIGGER_VIEW = NAME + "/SELECT_TRIGGER_VIEW";
export const EDIT_TRIGGER_VIEW = NAME + "/EDIT_TRIGGER_VIEW";

export const UPDATE_TRIGGER_MODAL_NAME = NAME + "/UPDATE_TRIGGER_MODAL_NAME";

export const fieldNames = [
  "timeToWait",
  "timeType",
  "triggerCondition",
  "timeBefore",
  "valueId",
];

export const editTriggerValueInputType = {
  NUMBER: "number",
  SELECT: "select",
};
