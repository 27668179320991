import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SelectTextMessageTemplateModalView } from 'modules/components'

import * as actions from '../actions'
import * as selectors from '../selectors'
import { SELECT_TEMPLATE_MODAL_NAME } from '../constants'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    textMessageTemplates: selectors.getTextMessageTemplates(state),
    pageDetails: selectors.getPageDetails(state),
    modalName: SELECT_TEMPLATE_MODAL_NAME
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getTextMessageTemplates: actions.getTextMessageTemplates,
    setSelectedTextMessageTemplate: actions.setSelectedTextMessageTemplate,
    hideModal: modal.actions.hideModal,
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTextMessageTemplateModalView)
