import { request, PUT, POST, DELETE } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import { fieldNames } from "./constants";
import { push } from "react-router-redux";
import flags from "modules/components/flags";
import STRINGS from 'strings'
import { formDataFromData } from "utils/formHelpers";

import loadAutomationFunnel from "../loadAutomationFunnel";

export function prefillDataForAutomationFunnel(automationFunnel) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_AUTOMATION_FUNNEL,
    automationFunnel
  };
}

export function updateData(data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}

export function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setHasChanges(hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  };
}

export const update = (formData, automationFunnelId) => {
  return dispatch => {
    dispatch(setLoading(true));
    const body = {
      automation_funnel: formDataFromData(formData, fieldNames)
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(
        loadAutomationFunnel.actions.setAutomationFunnel(
          payload.automation_funnel
        )
      );
      dispatch(setLoading(false));
      dispatch(setHasChanges(false));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Settings.settingsUpdatedNotification,
          index: 0
        })
      );
    }

    return request(
      "automation_funnels/" + automationFunnelId,
      PUT,
      body,
      onSuccess,
      onFailure
    );
  };
};

export const stopAutomationFunnel = automationFunnelid => {
  return dispatch => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(setHasChanges(false));
      dispatch(
        loadAutomationFunnel.actions.setAutomationFunnel(
          payload.automation_funnel
        )
      );
      dispatch(
        flags.actions.showFlag({
          appearance: "warning",
          title: STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Settings.funnelStoppedNotification,
          index: 0
        })
        );
      }
      
      const url = "automation_funnels/" + automationFunnelid + "/stop";
      return request(url, POST, null, onSuccess, onFailure);
    };
  };
  
  export const deleteAutomationFunnel = automationFunnelId => {
    return dispatch => {
      dispatch(setLoading(true));
      
      function onFailure(payload) {
        dispatch(setLoading(false));
        dispatch(setErrors(payload.errors));
      }
      
      function onSuccess(payload) {
        dispatch(push("/secure/automation"));
        dispatch(setLoading(false));
        dispatch(
          flags.actions.showFlag({
          appearance: "success",
          title: STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Settings.funnelDeletedNotification,
          index: 0
        })
      );
    }

    return request(
      "automation_funnels/" + automationFunnelId,
      DELETE,
      null,
      onSuccess,
      onFailure
    );
  };
};

export const generateShareCode = funnel => {
  return dispatch => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      const updatedFunnel = {
        ...funnel,
        share_code: payload.automation_funnel_recipe.share_code
      };
      dispatch(loadAutomationFunnel.actions.setAutomationFunnel(updatedFunnel));
      dispatch(setLoading(false));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Settings.sharecodeGeneratedNotification,
          index: 0
        })
      );
    }

    const url = `automation_funnel/${funnel.id}/create_private_recipe`;
    return request(url, POST, null, onSuccess, onFailure);
  };
};
