import React from 'react'
import NumberFormat from 'react-number-format'

export function readableMobileNumber (number) {
  if (number === null || number === undefined || number.length === 0) { return number }
  if (number.startsWith('+61')) {
    const formattedNumber = number.replace('+61', '0').replace(' ', '')
    return <NumberFormat value={formattedNumber} format='#### ### ###' displayType={'text'} />
  }

  if (number.startsWith('+1')) {
    const formattedNumber = number.replace('+1', '').replace('(', '').replace('(', '').replace(' ', '')
    return <NumberFormat value={formattedNumber} format='(###) ###-####' displayType={'text'} />
  }

  if (number.startsWith('+353')) {
    const formattedNumber = number.replace('+353', '0').replace('(', '').replace('(', '').replace(' ', '')
    return <NumberFormat value={formattedNumber} format='(##) ### ####' displayType={'text'} />
  }

  if (number.startsWith('+44')) {
    const formattedNumber = number.replace('+44', '0').replace('(', '').replace('(', '').replace(' ', '')
    return <NumberFormat value={formattedNumber} format='##### ### ###' displayType={'text'} />
  }

  if (number.startsWith('+64')) {
    const formattedNumber = number.replace('+64', '0').replace(' ', '')
    return <NumberFormat value={formattedNumber} format='#### ### ###' displayType={'text'} />
  }
  return number
}

export function placeholdersForCountry (countryId, countries) {
  const index = countries.findIndex(country => parseInt(country.id, 10) === parseInt(countryId, 10))
  const country = countries[index]
  if (!country) { return defaultPlaceholders }
  switch (country.code) {
    case 'AU':
      return defaultPlaceholders
    case 'US':
      return usPlaceholders
    case 'CA':
      return caPlaceholders
    case 'NZ':
      return defaultPlaceholders
    case 'UK':
      return ukPlaceholders
    case 'IE':
      return iePlaceholders
    default:
      return defaultPlaceholders
  }
}

const defaultPlaceholders = {
  phone: '#### ### ###',
  date: 'DD/MM/YYYY',
  locale: 'en-AU'
}

const usPlaceholders = {
  phone: '(###) ###-####',
  date: 'MM/DD/YYYY',
  locale: 'en-US'
}

const caPlaceholders = {
  phone: '(###) ###-####',
  date: 'DD/MM/YYYY',
  locale: 'en-CA'
}

const ukPlaceholders = {
  phone: '##### ### ###',
  date: 'DD/MM/YYYY',
  locale: 'en_GB'
}

const iePlaceholders = {
  phone: '(##) ### ####',
  date: 'DD/MM/YYYY',
  locale: 'en_IE'
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatPercentage = value => (value * 100).toFixed(2);
