import PropTypes from 'prop-types'
import React, { Component } from 'react'

var colors = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e",
"#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22",
"#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"]

export class Avatar extends Component {

  initials = () => {
    const { name } = this.props
    const nameSplit = name.split(" ")
    if (nameSplit.length < 2) {
      return nameSplit[0].charAt(0).toUpperCase()
    }  else {
      return nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase()  
    }
  }

  render () {
    const { size, round, textSizeRatio } = this.props
    const initials = this.initials()
    const charIndex = initials.charCodeAt(0) - 65
    const colorIndex = charIndex % 19
    const initialsStyle = {
      width: size,
      height: size,
      font: Math.floor(size / textSizeRatio) + 'px Helvetica, Arial, sans-serif',
      lineHeight: size + 'px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#fff',
      background: colors[colorIndex],
      borderRadius: (round ? '100%' : 0)
    }
    return (
      <div style={initialsStyle}>
        {initials}
      </div>
    )
  }
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  textSizeRatio: PropTypes.number.isRequired,
  round: PropTypes.bool
}

Avatar.defaultProps = {
  size: 100,
  round: false,
  textSizeRatio: 3
}

export default Avatar
