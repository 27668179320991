import * as actionTypes from './actionTypes'

const BILLING_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_DEFAULT_SMS_COST]: (state, action) => {
    return { ...state, defaultSmsCost: action.defaultSmsCost }
  }
}

const initialState = {
  loading: false,
  defaultSmsCost: 0.10
}

export default function reducer (state = initialState, action) {
  const handler = BILLING_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
