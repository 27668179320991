import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ChecklistView from '../components/ChecklistView'
import * as selectors from '../selectors'
import loadAutomationEmail from '../../loadAutomationEmail'
import header from '../../header'
import userData from 'modules/stores/userData'
import session from 'modules/stores/session'
import { getProductName } from 'modules/stores/userData/selectors'


function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    user: session.selectors.getUser(state),
    errors: selectors.getErrors(state),
    company: userData.selectors.getCompany(state),
    automationEmail: loadAutomationEmail.selectors.getAutomationEmail(state),
    productName: getProductName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setView: header.actions.setView
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistView))
