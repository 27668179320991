import { request, PUT, DELETE } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { fieldNames } from './constants'

import flags from 'modules/components/flags'
import view from '../../../view'

import { formDataFromData } from 'utils/formHelpers'

import loadAutomationEmail from '../loadAutomationEmail'

import STRINGS from 'strings'
const settingsStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Settings

export function prefillDataForEmail (email) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_EMAIL,
    email
  }
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}

export const update = (automationFunnelId, automationFunnelStepId, automationEmailId, formData) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const body = {
      automation_email_template: formDataFromData(formData, fieldNames)
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(loadAutomationEmail.actions.setAutomationEmail(payload.automation_email_template))
      dispatch(setLoading(false))
      dispatch(setHasChanges(false))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: settingsStrings.settingsSavedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_email_templates/' + automationEmailId
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

export const deleteEmail = (automationFunnelId, automationFunnelStepId, automationEmailId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(view.actions.setView(view.constants.LIST_STEP_VIEW))
      dispatch(setHasChanges(false))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: settingsStrings.emailDeletedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_email_templates/' + automationEmailId
    return request(url, DELETE, null, onSuccess, onFailure)
  }
}
