import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectLocationTableView from "./TableView/SelectLocationTableView";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class MultiLocationSelectView extends Component {
  state = {
    searchTerm: null
  };

  componentWillMount() {
    this.getLocationsWithPageNumber(1);
  }

  getLocationsWithPageNumber = pageNumber => {
    const { getLocations } = this.props;
    const { searchTerm } = this.state;
    getLocations(pageNumber, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getLocationsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getLocations } = this.props;
    this.setState({ searchTerm: searchTerm }, () =>
      getLocations(1, searchTerm)
    );
  };

  render() {
    const {
      locations,
      pageDetails,
      loading,
      selectedLocationIds,
      readonly
    } = this.props;
    const { searchTerm } = this.state;
    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView loading={loading}>
        <div className="row" style={{ marginTop: "-20px" }}>
          <TextSearchView
            sectionName={"locationSearch"}
            onUpdate={this.searchUpdated}
            searchText={searchTerm}
          />
        </div>
        {locations.length > 0 && (
          <div>
            <SelectLocationTableView
              locations={locations}
              selectedLocationIds={selectedLocationIds}
              selectLocation={this.props.selectLocation}
              deselectLocation={this.props.deselectLocation}
              readonly={readonly}
            />
          </div>
        )}
        {locations.length === 0 && (
          <EmptyView
            colorThemeClass="primary"
            iconClass="si si-magnifier"
            title="No results"
            subtitle="Check the search field"
          />
        )}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesView
              pageDetails={pageDetails}
              pageClicked={this.pageClicked}
            />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

MultiLocationSelectView.propTypes = {
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectLocation: PropTypes.func.isRequired,
  deselectLocation: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired
};

MultiLocationSelectView.defaultProps = {
  buttonTitle: "Filter by selected",
  readonly: false
};

export default MultiLocationSelectView;
