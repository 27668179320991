import { request, GET } from 'utils/apiUtils'
import modal from 'modules/stores/modal'
import * as actionTypes from './actionTypes'

export const searchForLeads = (massLeadSmsId, searchText) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading())

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(setSearchResults(payload.leads_mass_lead_text_messages))
    }

    const url = 'mass_lead_text_messages/' + massLeadSmsId + '/leads_mass_lead_text_messages?search=' + searchText
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const getPreviewHtml = (massLeadSmsId, leadsMassLeadSmsId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setPreviewText(payload.preview))
    }

    const url = 'mass_lead_text_messages/' + massLeadSmsId + '/leads_mass_lead_text_messages/' + leadsMassLeadSmsId + '/preview'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export function setSearchText (searchText) {
  return {
    type: actionTypes.SET_SEARCH_TEXT,
    searchText
  }
}

export function setPreviewResult (previewResult) {
  return {
    type: actionTypes.SET_PREVIEW_RESULT,
    previewResult
  }
}

function setSearchResults (searchResults) {
  return {
    type: actionTypes.SET_SEARCH_RESULTS,
    searchResults
  }
}

function setPreviewText (previewText) {
  return {
    type: actionTypes.SET_PREVIEW_TEXT,
    previewText
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
