import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import EntryRulesView from '../components/EntryRulesView'
import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    rules: selectors.getRules(state),
    condition: selectors.getCondition(state),
    event: selectors.getEvent(state),
    entryIsAutomatic: selectors.getEntryIsAutomatic(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setRules: actions.setRules,
    setCondition: actions.setCondition,
    addRule: actions.addRule,
    updateRule: actions.updateRule,
    removeRule: actions.removeRule,
    setEvent: actions.setEvent,
    setEntryIsAutomatic: actions.setEntryIsAutomatic
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntryRulesView))
