import config from 'config'
import { createSelector } from 'reselect'
import session from 'modules/stores/session'

export const getWhitelabelProfile = state => state.userData.company.whitelabel_profile

export const shouldShowVersionUpdate = createSelector(
  [ session.selectors.getCurrentAppVersion ],
  (currentVersion) => {
    return currentVersion !== config.version
  }
)
