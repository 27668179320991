import loadAutomationEmail from "./modules/loadAutomationEmail";
import header from "./modules/header";
import settings from "./modules/settings";
import checklist from "./modules/checklist";

export const reset = () => {
  return dispatch => {
    dispatch(loadAutomationEmail.actions.reset());
    dispatch(header.actions.reset());
    dispatch(settings.actions.reset());
    dispatch(checklist.actions.reset());
  };
};

export function setSelectedFunnelStepId(selectedFunnelStepId) {
  return dispatch => {
    dispatch(
      loadAutomationEmail.actions.setSelectedFunnelStepId(selectedFunnelStepId)
    );
  };
}
