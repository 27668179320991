import PropTypes from 'prop-types'
import React, { Component } from 'react'

import FromRow from './FromRow'
import SubjectRow from './SubjectRow'
import ContentRow from './ContentRow'
import ReadyView from './ReadyView'
import PreviewContainer from '../containers/PreviewContainer'
import './styles.css'

import { ContentBlockView } from 'modules/components'

export class ChecklistView extends Component {

  render () {
    const { automationEmail, company, user, setView, loading, productName } = this.props
    return (
      <div>
        <ContentBlockView title={'Checklist'} bordered>
          <FromRow automationEmail={automationEmail} user={user} company={company} setView={setView} productName={productName} />
          <SubjectRow automationEmail={automationEmail} setView={setView} />
          <ContentRow automationEmail={automationEmail} setView={setView} />
          <PreviewContainer />
        </ContentBlockView>
        <ReadyView automationEmail={automationEmail} loading={loading} />
      </div>
    )
  }
}

ChecklistView.propTypes = {
  loading: PropTypes.bool.isRequired,
  setView: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  automationEmail: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  productName: PropTypes.string,
  errors: PropTypes.object
}

export default ChecklistView
