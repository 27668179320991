import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import PrivateRoute from './PrivateRoute'

import session from 'modules/stores/session'

const mapStateToProps = (state) => {
  return {
    user: session.selectors.getUser(state)
  }
}
export default withRouter(connect(mapStateToProps)(PrivateRoute))
