import * as actionTypes from './actionTypes'

const EDIT_SMS_ACTION_HANDLERS = {
  [actionTypes.SET_SEARCH_TEXT]: (state, action) => {
    return { ...state, searchText: action.searchText }
  },
  [actionTypes.SET_SEARCH_RESULTS]: (state, action) => {
    return { ...state, searchResults: action.searchResults }
  },
  [actionTypes.SET_PREVIEW_RESULT]: (state, action) => {
    return { ...state, previewResult: action.previewResult }
  },
  [actionTypes.SET_PREVIEW_TEXT]: (state, action) => {
    return { ...state, previewText: action.previewText }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  loading: false,
  errors: null,
  searchText: null,
  searchResults: [],
  previewResult: null,
  previewText: null
}

export default function (state = initialState, action) {
  const handler = EDIT_SMS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
