import { request, GET, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import { push } from "react-router-redux";

import modal from "modules/stores/modal";
import create from "../create";
import STRINGS from "strings";

export const getDesigns = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setDesigns(payload.marketing_group_recipes));
    }

    let url = "marketing_group_recipes?page[number]=" + pageNumber;
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }

    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const createGroupFromDesign = (designId, shareCode = null) => {
  return dispatch => {
    dispatch(modal.actions.startLoading());
    const body = {
      share_code: shareCode,
      marketing_group_recipe_id: designId
    };

    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload.errors));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      const group = payload.marketing_group;
      const id = group.id;
      dispatch(push("groups/" + id + "/members"));
      dispatch(create.actions.reset());
    }

    const url = "marketing_group/create_from_recipe";
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const getPrivateRecipe = shareCode => {
  return dispatch => {
    dispatch(setLoading(true));
    dispatch(setErrors(null));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(
        setErrors({
          not_found: STRINGS.Templates.noShareCodeFound
        })
      );
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      if (payload) {
        dispatch(setPrivateRecipe(payload.marketing_group_recipe));
      } else {
        dispatch(
          setErrors({
            not_found: STRINGS.Templates.noShareCodeFound
          })
        );
      }
    }

    const url = `marketing_group_recipes/${shareCode}`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setDesigns(designs) {
  return {
    type: actionTypes.SET_DESIGNS,
    designs
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

export function setPrivateRecipe(privateRecipe) {
  return {
    type: actionTypes.SET_PRIVATE_RECIPE,
    privateRecipe
  };
}
