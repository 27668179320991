import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState, errorPanelForErrors } from 'utils/formHelpers'
import { validator } from '../validator'
import alert from 'modules/components/alert'
import ToolTip from 'react-portal-tooltip'

import { FormFields, ContentBlockView } from 'modules/components'
import STRINGS from "strings";

const { TextField, Checkbox } = FormFields
const settingsStrings = STRINGS.BulkMessaging.BulkEmail.Settings;

export class ChecklistView extends Component {

  state = {
    tooltipShowing: false
  }

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.updateData(newData)
  }

  handleToggle = (fieldName, value) => {
    const { updateData, data } = this.props
    const communicationType = (value) ? 'transactional' : 'marketing'
    const newData = generateNewFormState(data, fieldName, communicationType, true)
    updateData(newData)
  }

  saveTemplateClicked = () => {
    const { bulkEmail, update, data } = this.props
    update(bulkEmail, data)
  }

  deleteClicked = () => {
    const { showAlertWithConfig } = this.props
    const alertConfig = {
      ...alert.defaultConfig,
      children: settingsStrings.deleteEmailWarning,
      style: 'danger',
      onConfirm: this.deleteBulkEmail
    }
    showAlertWithConfig(alertConfig)
  }

  deleteBulkEmail = () => {
    const { bulkEmail, deleteBulkEmail, company } = this.props
    deleteBulkEmail(bulkEmail, company)
  }

  showToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: true })
  }

  hideToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: false })
  }

  render () {
    const { loading, data, bulkEmail, errors } = this.props
    const { title, subject, fromName, communicationType } = data.fields
    const { tooltipShowing } = this.state
    return (
      <div>
        <ContentBlockView title={settingsStrings.settingsTitle} loading={loading} bordered disabled={bulkEmail.status === 'scheduled'}>
          {errorPanelForErrors(errors)}
          <div className='row'>
            <div className='col-md-6'>
              <TextField
                name='title'
                label={settingsStrings.titleFieldLabel}
                placeholder={settingsStrings.titleFieldPlaceholder}
                onChange={this.handleInput}
                value={title.value}
                validator={validator} />
            </div>
            <div className='col-md-6'>
              <TextField
                name='subject'
                label={settingsStrings.subjectFieldLabel}
                placeholder={settingsStrings.subjectFieldPlaceholder}
                onChange={this.handleInput}
                value={subject.value}
                validator={validator} />
            </div>
            <div className='col-md-6'>
              <TextField
                name='fromName'
                label={settingsStrings.subjectFieldPlaceholder}
                placeholder={settingsStrings.subjectFieldPlaceholder}
                value={fromName.value}
                onChange={this.handleInput} />
            </div>
          </div>
          <div className='row push'>
            <div className='col-md-6'>
              <Checkbox name='communicationType'
                label={settingsStrings.communicationTypeLabel}
                onChange={this.handleToggle}
                switchStyle='primary'
                display={'inline'}
                checked={communicationType.value === 'transactional'} />
              <i className='fa fa-question-circle selectable'
                style={{marginLeft: '10px'}}
                id={'tooltip'}
                onMouseEnter={this.showToolTip}
                onMouseLeave={this.hideToolTip}>
                <ToolTip active={tooltipShowing} parent={'#tooltip'} position={'top'} arrow={'center'}>
                  <div style={{ width: '300px' }}>
                    <p>{settingsStrings.communicationTypeToolTip} {' '}
                      <a href='http://support.gymleads.net/gymleads-marketing-app/transactional-vs-marketing-communication'>{settingsStrings.communicationTypeToolButton}</a>
                    </p>
                  </div>
                </ToolTip>
              </i>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-4 push-20'>
              <button className='btn btn-primary' onClick={this.saveTemplateClicked}>
                <i className='fa fa-save' /> {settingsStrings.saveButton}
              </button>
            </div>
          </div>
        </ContentBlockView>
        <ContentBlockView title={'Delete Mass Email'} bordered>
          <div className='row'>
            <div className='col-xs-12 push-20'>
              <p>{settingsStrings.deleteButtonMessage}</p>
              <button className='btn btn-danger' onClick={this.deleteClicked}>
                <i className='fa fa-cross' /> {settingsStrings.deleteButton}
              </button>
            </div>
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

ChecklistView.propTypes = {
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  bulkEmail: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  deleteBulkEmail: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default ChecklistView
