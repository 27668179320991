import { request, buildIndexUrl, GET, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import flags from 'modules/components/flags'
import loadBulkEmail from '../../../loadBulkEmail'

import STRINGS from 'strings'
const bulkEmailAudienceStrings = STRINGS.SetAudience

export const getGroups = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setGroups(payload.marketing_groups))
    }

    const url = buildIndexUrl('marketing_groups', pageNumber, sort, search) + '&detail=true'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const update = (bulkEmail, groupId, entryRulesJson) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const body = {
      mass_lead_email: {
        marketing_group_id: groupId,
        query_body: entryRulesJson
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(loadBulkEmail.actions.setBulkEmail(payload.mass_lead_email))
      dispatch(setErrors(null))
      dispatch(setLoading(false))
      dispatch(setHasChanges(true))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: bulkEmailAudienceStrings.audienceUpdatedNotification,
        index: 0
      }))
    }

    const url = 'mass_lead_emails/' + bulkEmail.id
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export const prefillDataForBulkEmail = (massLeadEmail) => {
  return (dispatch) => {
    if (massLeadEmail.query_body) {
      const rules = (massLeadEmail.query_body) ? massLeadEmail.query_body.rules : []
      dispatch(setCondition(massLeadEmail.query_body.condition))
      dispatch(setRules(rules))
    }
    dispatch(setSelectedGroup(massLeadEmail.marketing_group))
    dispatch(setHasChanges(false))
  }
}

export function setCondition (condition) {
  return {
    type: actionTypes.SET_CONDITION,
    condition
  }
}

export function setRules (rules) {
  return {
    type: actionTypes.SET_RULES,
    rules
  }
}

export function addRule (rule) {
  return {
    type: actionTypes.ADD_RULE,
    rule
  }
}

export function updateRule (index, ruleData) {
  return {
    type: actionTypes.UPDATE_RULE,
    index,
    ruleData
  }
}

export function removeRule (index) {
  return {
    type: actionTypes.REMOVE_RULE,
    index
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setGroups (groups) {
  return {
    type: actionTypes.SET_GROUPS,
    groups
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

export function setSelectedGroup (selectedGroup) {
  return {
    type: actionTypes.SET_SELECTED_GROUP,
    selectedGroup
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}
