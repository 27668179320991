import { combineReducers } from 'redux'
import loadBulkSms from './modules/loadBulkSms'
import audience from './modules/audience'
import resultsBreakdown from './modules/resultsBreakdown'
import content from './modules/content'
import results from './modules/results'

export const reducer = combineReducers({
  [audience.constants.NAME]: audience.reducer,
  [loadBulkSms.constants.NAME]: loadBulkSms.reducer,
  [results.constants.NAME]: results.reducer,
  [resultsBreakdown.constants.NAME]: resultsBreakdown.reducer,
  [content.constants.NAME]: content.reducer
})
