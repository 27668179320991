import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { GymLeadsLoader } from 'modules/components'

export class LoadGroupView extends Component {

  componentDidMount () {
    const { getGroup, match } = this.props
    getGroup(match.params.id)
  }

  render () {
    return <GymLeadsLoader text='Loading Group' active />
  }
}

LoadGroupView.propTypes = {
  getGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default LoadGroupView
