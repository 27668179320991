import PropTypes from 'prop-types'
import React, { Component } from 'react'

import FromRow from './FromRow'
import ToRow from './ToRow'
import ContentRow from './ContentRow'
import PriceRow from './PriceRow'
import PreviewContainer from '../containers/PreviewContainer'
import SendContainer from '../containers/SendContainer'
import './styles.css'

import { ContentBlockView, ImageEmptyView } from 'modules/components'
import EmptyImage from 'images/EmptyGraph.jpg'

import STRINGS from 'strings'
const checklistStrings = STRINGS.BulkMessaging.BulkSms.Checklist

export class ChecklistView extends Component {

  backSelected = () => {
    this.props.history.push('/secure/bulkMessaging')
  }

  render () {
    const { bulkSms, history } = this.props
    return (
      <div>
        {bulkSms.is_legacy &&
          <ImageEmptyView
            image={EmptyImage}
            title={checklistStrings.legacyWarningTitle}
            subtitle={checklistStrings.legacyWarningSubtitle}
            buttonText={STRINGS.Global.goBackButtonButton}
            buttonSelected={this.backSelected} />
        }
        {!bulkSms.is_legacy &&
          <div>
            <ContentBlockView title={checklistStrings.componentTitle} bordered>
              <FromRow />
              <ToRow bulkSms={bulkSms} history={history}  />
              <ContentRow bulkSms={bulkSms} history={history}  />
              <PriceRow bulkSms={bulkSms} />
              <PreviewContainer />
            </ContentBlockView>
            <SendContainer />
          </div>
        }
      </div>
    )
  }
}

ChecklistView.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  bulkSms: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default ChecklistView
