import PropTypes from 'prop-types'
import React, { Component } from 'react'

import MemberRowContainer from '../containers/MemberRowContainer'

export class MembersTableView extends Component {

  render () {
    const { members } = this.props
    return (
      <table className='table table-hover table-vcenter'>
        <thead>
          <tr>
            <th />
            <th>Name</th>
            <th>Email</th>
            <th>Mobile number</th>
            <th>Status</th>
            <th>Source</th>
            <th>Location</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => {
            return <MemberRowContainer member={member} key={index} />
          })}
        </tbody>
      </table>

    )
  }
}

MembersTableView.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default MembersTableView
