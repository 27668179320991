import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import alert from 'modules/components/alert'
import modal from 'modules/stores/modal'

import MembersView from '../components/MembersView'
import * as actions from '../actions'
import * as selectors from '../selectors'

import loadGroup from '../../loadGroup'

function mapStateToProps (state) {
  return {
    group: loadGroup.selectors.getGroup(state),
    members: selectors.getMembers(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state),
    selectedIds: selectors.getSelectedIds(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    get: actions.get,
    removeLeadIds: actions.removeLeadIds,
    setAlertShowing: alert.actions.setShowing,
    setAlertConfig: alert.actions.setConfig,
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersView))
