import * as actionTypes from "./actionTypes";

const EXCLUDED_TAG_ACTION_HANDLERS = {
  [actionTypes.FETCHING_OPTIONS]: (state, action) => {
    return { ...state, loading: true };
  },
  [actionTypes.FETCHING_OPTIONS_SUCCESS]: (state, action) => {
    return { ...state, loading: false, options: action.options };
  },
  [actionTypes.FETCHING_OPTIONS_FAILED]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalCount: action.pageDetails.total_count,
      },
    };
  },
};

const initialState = {
  loading: false,
  options: [],
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    totalCount: 0,
  },
};

export default function reducer(state = initialState, action) {
  const handler = EXCLUDED_TAG_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
