import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ContentView from '../components/ContentView'
import * as selectors from '../selectors'
import loadBulkSms from '../../loadBulkSms'

import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    bulkSms: loadBulkSms.selectors.getBulkSms(state),
    validPreviewText: selectors.validPreviewText(state),
    previewResult: selectors.getPreviewResult(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentView))
