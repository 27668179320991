import styled from "styled-components";

export const RowContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const PreviewImageContainer = styled.div`
  margin-right: 8px;
`;
