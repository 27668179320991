import PropTypes from "prop-types";
import React, { Component } from "react";
import { ImageEmptyView, SortSearchView, PagesView } from "modules/components";
import GroupsEmpty from "images/GroupsEmpty.jpg";
import { ModalContainer } from "modules/components";

import GroupRow from "./GroupRow";

export class SelectGroupModalView extends Component {
  state = {
    searchTerm: null
  };

  componentDidMount() {
    this.getGroupsWithPageNumber(1);
  }

  getGroupsWithPageNumber = pageNumber => {
    const { getGroups } = this.props;
    const { searchTerm } = this.state;
    getGroups(pageNumber, null, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getGroupsWithPageNumber(pageNumber);
  };

  sortSearchUpdated = (sort, search) => {
    const { getGroups } = this.props;
    this.setState({ searchTerm: search }, getGroups(1, sort, search));
  };

  groupSelected = group => {
    const { setSelectedGroup, hideModal } = this.props;
    setSelectedGroup(group);
    hideModal();
  };

  render() {
    const { groups, pageDetails, selectedGroup, modalName } = this.props;
    const { searchTerm } = this.state;

    const rows = groups.map((group, index) => {
      const selected = selectedGroup ? selectedGroup.id === group.id : false;
      return (
        <GroupRow
          key={index}
          selected={selected}
          group={group}
          selectGroup={this.groupSelected}
        />
      );
    });

    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={"Select A Group"}
        colorThemeClass={"primary"}
        showCancelButton
      >
        <SortSearchView
          sortColumnWidth={"4"}
          searchColumnWidth={"8"}
          sortSearchUpdated={this.sortSearchUpdated}
          initialSearchTerm={searchTerm}
        />
        {groups.length === 0 && (
          <ImageEmptyView
            image={GroupsEmpty}
            title={"Can't Find Any Groups"}
            subtitle="Try refining your search, or create some groups if you haven't already."
          />
        )}
        {groups.length > 0 && (
          <div>
            <div className="table-responsive">
              <table className="table table-vcenter table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-center">Number of leads</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
            <PagesView
              pageClicked={this.pageClicked}
              pageDetails={pageDetails}
            />
          </div>
        )}
      </ModalContainer>
    );
  }
}

SelectGroupModalView.propTypes = {
  getGroups: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object.isRequired,
  setSelectedGroup: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default SelectGroupModalView;
