import PropTypes from "prop-types";
import React, { Component } from "react";
import logo from "resources/logo.png";
import LoginForm from "./LoginForm";
import STRINGS from "strings";

import { InfoView } from "modules/components";

export class LoginView extends Component {
  componentDidMount() {
    this.lookForWhitelabelProfile(this.props.productName);
    if (this.props.user) {
      const nextUrl = this.props.attemptedUrl || "/secure/automation";
      this.props.history.push(nextUrl);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.productName !== nextProps.productName) {
      this.lookForWhitelabelProfile(nextProps.productName);
    }
  }

  lookForWhitelabelProfile(productName) {
    const { fetchWhitelabelProfileLogo } = this.props;
    if (productName) {
      fetchWhitelabelProfileLogo(productName);
    }
  }

  setLogo() {
    const { whitelabelProfile, productName } = this.props;

    if (!productName) {
      this.setDocumentTitle("GymLeads");
      return logo;
    } else if (whitelabelProfile && whitelabelProfile.logo_url) {
      this.setDocumentTitle(whitelabelProfile.name);
      return whitelabelProfile.logo_url;
    } else if (whitelabelProfile && whitelabelProfile.name === "Gymleads") {
      this.setDocumentTitle("GymLeads");
      return logo;
    }
  }

  setDocumentTitle(name) {
    document.title = `${name} | ${STRINGS.Global.documentTitle}`;
  }

  setCopyright() {
    if (this.props.productName) {
      return (
        <div>
          <span className="js-year-copy">{STRINGS.Global.copyrightDate}</span>{" "}
          {STRINGS.Global.poweredByGymleads}
        </div>
      );
    } else {
      return (
        <div>
          <span className="js-year-copy">{STRINGS.Global.copyrightDate}</span>{" "}
          {STRINGS.Global.copyrightText}
        </div>
      );
    }
  }

  render() {
    const { login, loading, errors, sessionEndReason } = this.props;
    const logoImage = this.setLogo();
    return (
      <div>
        <div className="bg-white pulldown">
          <div className="content context-boxed overflow-hidden">
            <div className="row">
              {sessionEndReason && (
                <div className="row">
                  <div
                    className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4"
                    style={{ textAlign: "center" }}
                  >
                    <InfoView
                      colorThemeClass={"info"}
                      detail={sessionEndReason}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-12 text-center push-30-t">
                  {logoImage && (
                    <img
                      src={logoImage}
                      alt="logo"
                      style={{ height: "60px", paddingBottom: "10px" }}
                    />
                  )}
                  <p className="animated fadeIn" style={{ margin: "10px" }}>
                    {STRINGS.Login.marketingAndAutomation}
                  </p>
                </div>
              </div>
              <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                <div className="push-30-t push-50 animated fadeIn">
                  <LoginForm
                    onSubmit={login}
                    loading={loading}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pulldown push-30-t text-center animated fadeInUp">
          <small className="text-muted">{this.setCopyright()}</small>
        </div>
      </div>
    );
  }
}

LoginView.propTypes = {
  match: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  attemptedUrl: PropTypes.string,
  sessionEndReason: PropTypes.string,
  user: PropTypes.object,
};

export default LoginView;
