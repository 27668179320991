import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import ShareCodeView from "../components/ShareCodeView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import create from "../../create";

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    privateRecipe: selectors.getPrivateRecipe(state),
    errors: selectors.getErrors(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createFunnelFromRecipe: actions.createFunnelFromRecipe,
      setPrivateRecipe: actions.setPrivateRecipe,
      getPrivateRecipe: actions.getPrivateRecipe,
      showListingView: create.actions.reset
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShareCodeView)
);
