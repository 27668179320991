import PropTypes from "prop-types";
import React, { Component } from "react";
import { ModalContainer } from "modules/components";

import {
  ImageEmptyView,
  TextSearchView,
  GymLeadsLoader
} from "modules/components";

import LeadsEmpty from "images/LeadsEmpty.jpg";
import alert from "modules/components/alert";

import LeadsTableView from "./LeadsTableView";
import { PagesView } from "modules/components";

import { SHOW_STEP_LEADS_MODAL_VIEW } from "../constants";
import STRINGS from "strings";

export class StepLeadsModal extends Component {
  state = {
    search: null,
    stepLeadIdToRemove: null
  };

  componentDidMount() {
    const { setAlertConfig } = this.props;
    const alertConfig = {
      ...alert.defaultConfig,
      children: STRINGS.Funnels.Steps.LeadsList.warningMessage,
      style: "danger",
      onConfirm: this.removeLead
    };
    setAlertConfig(alertConfig);
    this.getLeadsWithPageNumber(1);
  }

  getLeadsWithPageNumber = pageNumber => {
    const {
      getLeadsForFunnelStep,
      automationFunnel,
      selectedFunnelStep
    } = this.props;
    getLeadsForFunnelStep(automationFunnel, selectedFunnelStep, pageNumber);
  };

  pageClicked = pageNumber => {
    this.getLeadsWithPageNumber(pageNumber);
  };

  showWarningForRemoval = stepLead => {
    this.setState({ stepLeadIdToRemove: stepLead.id });
    this.props.setAlertShowing(true);
  };

  removeLead = () => {
    const { stepLeadIdToRemove } = this.state;
    const {
      removeLeadFromStep,
      automationFunnel,
      selectedFunnelStep
    } = this.props;
    removeLeadFromStep(
      automationFunnel,
      selectedFunnelStep,
      stepLeadIdToRemove
    );
  };

  searchUpdated = searchTerm => {
    const {
      getLeadsForFunnelStep,
      automationFunnel,
      selectedFunnelStep
    } = this.props;
    getLeadsForFunnelStep(
      automationFunnel,
      selectedFunnelStep,
      1,
      null,
      searchTerm
    );
  };

  cleanUp = () => {
    this.props.reset();
  };

  render() {
    const { selectedFunnelStep, stepsLeads, pageDetails, loading } = this.props;
    const { search } = this.state;
    return (
      <ModalContainer
        modalName={SHOW_STEP_LEADS_MODAL_VIEW}
        width={"large"}
        modalTitle={
          STRINGS.Funnels.Steps.LeadsList.leadsIn +
          " " +
          selectedFunnelStep.name
        }
        colorThemeClass={"primary"}
        showCancelButton
        cleanUp={this.cleanUp}
        actionEnabled={false}>
        <div className="block-content">
          <GymLeadsLoader active={loading} text="Loading" />
          {!loading && (
            <div>
              <TextSearchView
                onUpdate={this.searchUpdated}
                searchText={search || ""}
                sectionName={"search"}
              />
              {stepsLeads.length > 0 && (
                <div className="table-responsive">
                  <LeadsTableView
                    stepsLeads={stepsLeads}
                    onRemoveSelected={this.showWarningForRemoval}
                  />
                  <PagesView
                    pageDetails={pageDetails}
                    pageClicked={this.pageClicked}
                  />
                </div>
              )}
              {stepsLeads.length === 0 && (
                <ImageEmptyView
                  image={LeadsEmpty}
                  height={100}
                  title={STRINGS.Funnels.Steps.LeadsList.emptyMessage}
                />
              )}
            </div>
          )}
        </div>
      </ModalContainer>
    );
  }
}

StepLeadsModal.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  selectedFunnelStep: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  stepsLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object.isRequired,
  getLeadsForFunnelStep: PropTypes.func.isRequired,
  removeLeadFromStep: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  setAlertShowing: PropTypes.func.isRequired,
  setAlertConfig: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

export default StepLeadsModal;
