import { request, buildIndexUrl, GET, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import modal from "modules/stores/modal";
import { push } from "react-router-redux";

export const getTemplates = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setTemplates(payload.text_message_templates));
    }
    const url = buildIndexUrl(
      "text_message_templates",
      pageNumber,
      sort,
      search
    );
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const sortSearchUpdated = (sort, search) => {
  return async dispatch => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getTemplates(1, sort, search));
  };
};

export const createTemplate = (name, company) => {
  return dispatch => {
    const body = {
      text_message_template: {
        title: name,
        is_legacy: false,
        location_ids: []
      }
    };

    dispatch(modal.actions.startLoading());
    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      const template = payload.text_message_template;
      dispatch(addTemplate(template));
      const id = template.id;
      dispatch(push("templates/sms/" + id + "/edit"));
    }
    const url = "companies/" + company.id + "/text_message_templates";
    return request(url, POST, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setTemplates(templates) {
  return {
    type: actionTypes.SET_TEMPLATES,
    templates
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

export function addTemplate(template) {
  return {
    type: actionTypes.ADD_TEMPLATE,
    template
  };
}

export function removeTemplate(template) {
  return {
    type: actionTypes.REMOVE_TEMPLATE,
    template
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder
  };
}
