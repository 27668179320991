import { request, GET, POST, DELETE } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import flags from 'modules/components/flags'

import STRINGS from 'strings'
const listingStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Listing

export const getSteps = (automationFunnelId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setSteps(payload.automation_funnel_steps))
    }

    const url = 'automation_funnels/' + automationFunnelId + '/automation_funnel_steps'

    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const createNewStep = (automationFunnelId) => {
  return (dispatch) => {
    dispatch(setLoadingAdd(true))

    function onFailure (payload) {
      dispatch(setLoadingAdd(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoadingAdd(false))
      dispatch(addStep(payload.automation_funnel_step))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: listingStrings.newStepAddedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId + '/automation_funnel_steps'

    return request(url, POST, {}, onSuccess, onFailure)
  }
}

export const deleteStep = (automationFunnelId, automationFunnelStepId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(removeStep(automationFunnelStepId))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: listingStrings.stepDeletedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId

    return request(url, DELETE, null, onSuccess, onFailure)
  }
}

function setSteps (steps) {
  return {
    type: actionTypes.SET_STEPS,
    steps
  }
}

function addStep (step) {
  return {
    type: actionTypes.ADD_STEP,
    step
  }
}

function removeStep (step) {
  return {
    type: actionTypes.REMOVE_STEP,
    step
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setLoadingAdd (loadingAdd) {
  return {
    type: actionTypes.SET_LOADING_ADD,
    loadingAdd
  }
}

export function setTriggerForStepId (stepId, trigger) {
  return {
    type: actionTypes.SET_STEP_TRIGGER,
    stepId,
    trigger
  }
}

export function setSelectedStepId (selectedStepId) {
  return {
    type: actionTypes.SET_SELECTED_STEP_ID,
    selectedStepId
  }
}

export function setScheduleForStepId (stepId, schedule) {
  return {
    type: actionTypes.SET_SCHEDULE_FOR_STEP_ID,
    stepId,
    schedule
  }
}
