import { request, buildIndexUrl, GET, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import flags from 'modules/components/flags'
import loadAutomationTextMessage from '../loadAutomationTextMessage'

import STRINGS from 'strings'
const contentStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Content

export const getTextMessageTemplates = (pageNumber, sort, search) => {
  return dispatch => {
    function onFailure (payload) {

    }

    function onSuccess (payload) {
      dispatch(loadPageDetails(payload.meta))
      dispatch(setTextMessageTemplates(payload.text_message_templates))
    }

    const url = buildIndexUrl('text_message_templates', pageNumber, sort, search)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const updateSmsContent = (automationFunnelId, automationFunnelStepId, automationTextMessage, text) => {
  return dispatch => {
    dispatch(setLoading(true))
    const templateBody = {
      automation_text_message_template: {
        text: text
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadAutomationTextMessage.actions.setAutomationTextMessage(payload.automation_text_message_template))
      dispatch(setPreviewText(payload.automation_text_message_template.preview))
      dispatch(setHasChanges(false))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: contentStrings.contentUpdatedNotifications,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_text_message_templates/' + automationTextMessage.id
    return request(url, PUT, templateBody, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function setTextMessageTemplates (textMessageTemplates) {
  return {
    type: actionTypes.SET_TEXT_MESSAGE_TEMPLATES,
    textMessageTemplates
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function prefillDataForAutomationTextMessage (automationTextMessage) {
  return {
    type: actionTypes.PREFIL_FOR_AUTOMATION_TEXT_MESSAGE,
    automationTextMessage
  }
}

export function setText (text) {
  return {
    type: actionTypes.SET_TEXT,
    text
  }
}

export function setPreviewText (previewText) {
  return {
    type: actionTypes.SET_PREVIEW_TEXT,
    previewText
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}

export function setSelectedTextMessageTemplate (textMessageTemplate) {
  return {
    type: actionTypes.SET_TEXT,
    text: textMessageTemplate.text
  }
}
