import { NAME } from './constants'

export const PREFIL_DATA_FOR_TEMPLATE = NAME + '/PREFIL_DATA_FOR_TEMPLATE'
export const UPDATE_DATA = NAME + '/UPDATE_DATA'
export const RESET = NAME + '/RESET'
export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const SET_LOCATION_IDS = NAME + '/SET_LOCATION_IDS'

export const SET_HAS_CHANGES = NAME + '/SET_HAS_CHANGES'
