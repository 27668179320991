import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import StepAddLeadsModal from "../components/StepAddLeadsModal";

import * as actions from "../actions";
import * as selectors from "../selectors";
import modal from "modules/stores/modal";
import alert from "modules/components/alert";

import loadAutomationFunnel from "../../../../loadAutomationFunnel";

function mapStateToProps(state) {
  return {
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    selectedFunnelStep: selectors.selectedFunnelStep(state),
    leads: selectors.getLeads(state),
    pageDetails: selectors.getPageDetails(state),
    loading: selectors.getLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addLeadToStep: actions.addLeadToStep,
      getLeadsForFunnelStep: actions.getLeadsForFunnelStep,
      hideModal: modal.actions.hideModal,
      reset: actions.reset,
      setAlertShowing: alert.actions.setShowing,
      setAlertConfig: alert.actions.setConfig,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepAddLeadsModal);
