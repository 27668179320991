import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView, ImageEmptyView } from 'modules/components'

import image from 'images/SmsReady.jpg'
import STRINGS from 'strings'
const createNewTemplateStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.CreateNewTemplate
export class CreateNewTemplateView extends Component {

  createTemplate = () => {
    const { create, automationFunnel, automationFunnelStep } = this.props
    create(automationFunnel.id, automationFunnelStep.id)
  }

  render () {
    const { loading } = this.props
    return (
      <ContentBlockView bordered loading={loading}>
        <ImageEmptyView
          image={image}
          title={createNewTemplateStrings.title}
          subtitle={createNewTemplateStrings.subtitle}
          buttonText={createNewTemplateStrings.createSmsMessageButton}
          buttonSelected={this.createTemplate}
        />
      </ContentBlockView>
    )
  }
}

CreateNewTemplateView.propTypes = {
  automationFunnelStep: PropTypes.object.isRequired,
  create: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default CreateNewTemplateView
