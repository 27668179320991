import * as actionTypes from "./actionTypes";

const STEP_LEADS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_STEPS_LEADS]: (state, action) => {
    return { ...state, stepsLeads: action.stepsLeads };
  },
  [actionTypes.SET_SELECTED_FUNNEL_STEP_ID]: (state, action) => {
    return { ...state, selectedFunnelStepId: action.selectedFunnelStepId };
  },
  [actionTypes.REMOVE_STEPS_LEAD_WITH_ID]: (state, action) => {
    const index = state.stepsLeads.findIndex(
      stepsLead =>
        parseInt(stepsLead.id, 10) === parseInt(action.stepsLeadId, 10)
    );
    const updatedStepsLeads = [
      ...state.stepsLeads.slice(0, index),
      ...state.stepsLeads.slice(index + 1)
    ];
    return { ...state, stepsLeads: updatedStepsLeads };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    };
  },
  [actionTypes.RESET]: () => {
    return initialState();
  }
};

function initialState() {
  return {
    loading: false,
    stepsLeads: [],
    selectedFunnelStepId: null,
    pageDetails: {
      currentPage: 1,
      pageCount: 0,
      total: 0
    }
  };
}

export default function(state = initialState(), action) {
  const handler = STEP_LEADS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
