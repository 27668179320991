import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { validator } from "../../settings/validator";
import { generateNewFormState } from "utils/formHelpers";

import { ContentBlockView, EmailEditor, FormFields } from 'modules/components'
import './styles.css'
const { TextField } = FormFields;

export class EditTemplateView extends Component {

  saveDesignClicked = () => {
    const { template, updateTemplate, company } = this.props
    const subject = this.props.settingsData.fields.subject.value
    window.unlayer.exportHtml(data => {
      const { design, html } = data
      updateTemplate(template, html, design, subject, company.id)
    })
  }

  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.settingsData,
      fieldName,
      value,
      isValid
    );
    this.props.updateSettingsData(newData);
  };

  render () {
    const { subject } = this.props.settingsData.fields;
    const { template, mergeTags } =  this.props
    return (
      <ContentBlockView>
        <TextField
          name="subject"
          placeholder="Email subject line (merge fields work too!)"
          formFieldStyle=""
          onChange={this.handleInput}
          value={subject.value}
          validator={validator}
        />
        <EmailEditor
          designJson={template.design_json}
          mergeTags={mergeTags}
        />
        <button className='btn btn-primary push-20-t push-20' onClick={this.saveDesignClicked}>
          <i className='fa fa-save' /> Save template
        </button>
      </ContentBlockView>
    )
  }
}

EditTemplateView.propTypes = {
  template: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateTemplate: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  updateSettingsData: PropTypes.func.isRequired
}

export default EditTemplateView
