import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PrivateRouteContainer, ImageEmptyView } from 'modules/components'

import loadTemplate from '../modules/loadTemplate'
import editTemplate from '../modules/editTemplate'
import settings from '../modules/settings'
import links from '../modules/links'
import header from '../modules/header'

import EmptyImage from 'images/EmptyGraph.jpg'

export class EditTemplateView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  backSelected = () => {
    this.props.history.push('/secure/templates')
  }

  render () {
    const { template, notFound } = this.props
    return (
      <div className='content'>
        {template &&
          <div>
            <header.HeaderContainer />
            <div className='row'>
              <div className='col-sm-12'>
                <PrivateRouteContainer exact path={"/secure/templates/sms/:id/" + editTemplate.constants.NAME} component={editTemplate.EditTemplateContainer} />
                <PrivateRouteContainer exact path={"/secure/templates/sms/:id/" + links.constants.NAME} component={links.LinksContainer}  />
                <PrivateRouteContainer exact path={"/secure/templates/sms/:id/" + settings.constants.NAME} component={settings.SettingsContainer}  />
              </div>
            </div>
          </div>
        }
        {!template && !notFound &&
          <loadTemplate.LoadTemplateContainer />
        }
        {!template && notFound &&
          <ImageEmptyView
            image={EmptyImage}
            title={"Template Not Found"}
            subtitle="It looks like this SMS template has been deleted."
            buttonText='Go Back'
            buttonSelected={this.backSelected} />
        }
      </div>
    )
  }
}

EditTemplateView.propTypes = {
  template: PropTypes.object,
  notFound: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired
}

export default EditTemplateView
