import * as actionTypes from "./actionTypes";

const VIEW_TEMPLATE_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_DESIGNS_LOADING]: (state, action) => {
    return { ...state, designsLoading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_LAYOUTS]: (state, action) => {
    return { ...state, layouts: action.layouts };
  },
  [actionTypes.SET_TEMPLATES]: (state, action) => {
    return { ...state, templates: action.templates };
  },
  [actionTypes.SET_DESIGNS]: (state, action) => {
    return { ...state, designs: action.designs };
  },
  [actionTypes.SET_PRIVATE_RECIPE]: (state, action) => {
    return { ...state, privateRecipe: action.privateRecipe };
  },
  [actionTypes.TEMPLATE_PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      templatePageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        total: action.pageDetails.total_count
      }
    };
  },
  [actionTypes.DESIGNS_PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      designPageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        total: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  loading: false,
  designsLoading: false,
  errors: null,
  privateRecipe: null,
  templates: [],
  designs: [],
  layouts: [],
  templatePageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  },
  designPageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

export default function(state = initialState, action) {
  const handler = VIEW_TEMPLATE_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
