import PropTypes from 'prop-types'
import React, { Component } from 'react'

import FromRow from './FromRow'
import ContentRow from './ContentRow'
import PreviewContainer from '../containers/PreviewContainer'
import ReadyView from './ReadyView'
import './styles.css'

import { ContentBlockView } from 'modules/components'

import STRINGS from 'strings'
const smsChecklistStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Checklist

export class ChecklistView extends Component {

  render () {
    const { automationTextMessage, setView, loading } = this.props
    return (
      <div>
        <ContentBlockView title={smsChecklistStrings.checkList} bordered>
          <FromRow />
          <ContentRow automationTextMessage={automationTextMessage} setView={setView}  />
          <PreviewContainer />
        </ContentBlockView>
        <ReadyView automationTextMessage={automationTextMessage} loading={loading} />
      </div>
    )
  }
}

ChecklistView.propTypes = {
  loading: PropTypes.bool.isRequired,
  automationTextMessage: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default ChecklistView
