import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import AudienceView from '../components/AudienceView'
import loadBulkEmail from '../../loadBulkEmail'

function mapStateToProps (state) {
  return {
    bulkEmail: loadBulkEmail.selectors.getBulkEmail(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AudienceView))
