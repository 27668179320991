import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import UserEntryView from './UserEntryView'
import multiUserSelect from 'modules/components/multiUserSelect'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    users: multiUserSelect.selectors.getUsers(state),
    userSelectModalName: multiUserSelect.constants.MODAL_NAME
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    setModalUserIds: multiUserSelect.actions.setSelectedUserIds,
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEntryView))
