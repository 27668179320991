import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ContentBlockView, FormFields } from 'modules/components'
const { TextArea } = FormFields

export class TemplatePreviewView extends Component {

  onChange = (name, value) => {

  }

  render () {
    const { previewText } = this.props

    return (
      <div>
        <ContentBlockView title={'Template preview'} bordered>
          <div className='editor'>
            <TextArea
              name={'template preview'}
              onChange={this.onChange}
              needsFormGroup={false}
              disabled
              autoResize
              value={previewText}
              rows={5} />
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

TemplatePreviewView.propTypes = {
  previewText: PropTypes.string.isRequired
}

export default TemplatePreviewView
