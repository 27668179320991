import { NAME } from './constants'

export const RESET = NAME + '/RESET'
export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_LOADING_ADD = NAME + '/SET_LOADING_ADD'
export const SET_ERRORS = NAME + '/SET_ERRORS'

export const SET_STEPS = NAME + '/SET_STEPS'
export const ADD_STEP = NAME + '/ADD_STEP'
export const REMOVE_STEP = NAME + '/REMOVE_STEP'

export const SET_SCHEDULE_FOR_STEP_ID = NAME + '/SET_SCHEDULE_FOR_STEP_ID'

export const SET_SELECTED_STEP_ID = NAME + '/SET_SELECTED_STEP_ID'

export const SET_STEP_TRIGGER = NAME + '/SET_STEP_TRIGGER'
