import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import TrialEntryView from './TrialEntryView'

import trials from 'modules/stores/trials'

function mapStateToProps (state) {
  const trialItems = [
    ...trials.selectors.getTrials(state),
    { id: 'any', name: 'Anything'},
    { id: 'none', name: 'None'}
  ]
  return {
    trials: trialItems
  }
}

export default withRouter(connect(mapStateToProps)(TrialEntryView))
