import PropTypes from 'prop-types'
import React, { Component } from 'react'

import STRINGS from 'strings'
import { ContentBlockView, FormFields } from 'modules/components'
const { TextArea } = FormFields
const smsHelperStrings = STRINGS.Global.SmsHelper


export class AutomationTextMessagePreviewView extends Component {

  onChange = (name, value) => {}

  render () {
    const { previewText } = this.props

    return (
      <div>
        <ContentBlockView title={smsHelperStrings.textMessagePreviewHeading} bordered>
          <div className='editor'>
            <TextArea
              name={'automationTextMessage preview'}
              onChange={this.onChange}
              needsFormGroup={false}
              disabled
              autoResize
              value={previewText}
              rows={5} />
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

AutomationTextMessagePreviewView.propTypes = {
  previewText: PropTypes.string.isRequired
}

export default AutomationTextMessagePreviewView
