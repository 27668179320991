import PropTypes from 'prop-types'
import React, { Component } from 'react'

import alert from 'modules/components/alert'

import { ContentBlockView, NavLink } from 'modules/components'

export class HeaderView extends Component {

  backSelected = () => {
    const { showWithConfig, hasUnsavedChanges } = this.props
    if (hasUnsavedChanges) {
      showWithConfig({
        ...alert.defaultConfig,
        children: 'You will lose any unsaved changes',
        style: 'warning',
        onConfirm: this.goBack
      })
    } else {
      this.goBack()
    }
  }

  goBack = () => {
    this.props.history.push('/secure/groups')
  }

  render () {
    const { group, currentPath } = this.props
    const baseLink = '/secure/groups/' + group.id
    return (
      <ContentBlockView>
        <i className='si si-arrow-left fa-2x pull-left selectable'
          style={{ marginTop: '5px', marginRight: '20px' }}
          onClick={this.backSelected} />
        <h2 className='push'>
          {group.name}{' '}
          <span style={{ color: '#5c90d2' }}>
            {group.lead_count}
          </span>
        </h2>
        <div className='block'>
          <ul className='nav nav-tabs nav-tabs-alt'>
            <NavLink
              to={baseLink + '/members'}
              currentPath={currentPath}>
              <i className='si si-users' /> Current members
            </NavLink>
            <NavLink
              to={baseLink + '/member_rules'}
              currentPath={currentPath}>
              <i className='si si-shuffle' /> Automation rules
            </NavLink>
            <NavLink
              to={baseLink + '/settings'}
              currentPath={currentPath}>
              <i className='si si-settings' /> Settings
            </NavLink>
          </ul>
        </div>
      </ContentBlockView>
    )
  }
}

HeaderView.propTypes = {
  group: PropTypes.object.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  showWithConfig: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired
}

export default HeaderView
