import PropTypes from "prop-types";
import React, { Component } from "react";

import {
  ContentBlockView,
  GymLeadsLoader,
  ImageEmptyView,
  SortSearchView
} from "modules/components";
import TemplatesEmpty from "images/TemplatesEmpty.jpg";

import SelectTemplateCell from "./SelectTemplateCell";
import sortOptions from "../sortOptions";

import TemplatePagesContainer from "../containers/TemplatePagesContainer";

import STRINGS from "strings";
const selectDesignStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.SelectDesign;

export class TemplatesView extends Component {
  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getTemplatesWithPageNumber(currentPage ? currentPage : 1);
  }

  getTemplatesWithPageNumber = pageNumber => {
    const { getTemplates, sortOrder, searchTerm } = this.props;
    getTemplates(pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getTemplatesWithPageNumber(pageNumber);
  };

  templateSelected = template => {
    const {
      createTemplate,
      automationFunnel,
      automationFunnelStep
    } = this.props;
    createTemplate(
      automationFunnel.id,
      automationFunnelStep.id,
      template.html,
      template.design_json
    );
  };

  render() {
    const {
      templates,
      loading,
      sortOrder,
      searchTerm,
      sortSearchUpdated
    } = this.props;

    const templateCells = templates.map((template, index) => {
      return (
        <SelectTemplateCell
          key={index}
          template={template}
          onSelect={this.templateSelected}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={sortSearchUpdated}
          sortColumnWidth={"3"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <GymLeadsLoader
          title={selectDesignStrings.loadingTemplates}
          active={loading}
        />
        {!loading && (
          <ContentBlockView
            title={selectDesignStrings.menuYourTemplates}
            bordered
          >
            <div className="designs-list">
              {templates.length > 0 && templateCells}
              {templates.length === 0 && (
                <ImageEmptyView
                  image={TemplatesEmpty}
                  title={selectDesignStrings.noTemplates}
                  subtitle={selectDesignStrings.noTemplatesMessage}
                />
              )}
            </div>
          </ContentBlockView>
        )}
        {templates.length > 0 && !loading && (
          <TemplatePagesContainer pageClicked={this.pageClicked} />
        )}
      </div>
    );
  }
}

TemplatesView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  getTemplates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTemplate: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string
};

export default TemplatesView;
