import PropTypes from "prop-types";
import React, { Component } from "react";

import RecordRow from "./RecordRow";

import STRINGS from "strings";
const globalStrings = STRINGS.Global;

export class RecordsTableView extends Component {
  render() {
    const { records, onRecordSelected, selectedRecordId } = this.props;
    return (
      <table className="table table-hover table-vcenter">
        <thead>
          <tr>
            <th>{globalStrings.Name}</th>
            <th>{globalStrings.email}</th>
            <th>{globalStrings.replied}</th>
          </tr>
        </thead>
        <tbody>
          {records.map(record => {
            return (
              <RecordRow
                record={record}
                key={record.id}
                onRecordSelected={onRecordSelected}
                selectedRecordId={selectedRecordId}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
}

RecordsTableView.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRecordSelected: PropTypes.func.isRequired,
  selectedRecordId: PropTypes.number
};

export default RecordsTableView;
