import { NAME } from './constants'

export const SET_MEMBERS = NAME + '/SET_MEMBERS'
export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'

export const RESET = NAME + '/RESET'

export const SET_SELECTED_IDS = NAME + '/SET_SELECTED_IDS'
export const SELECT_MEMBER = NAME + '/SELECT_MEMBER'
export const DESELECT_MEMBER = NAME + '/DESELECT_MEMBER'

export const SET_SEARCH_LEADS = NAME + '/SET_SEARCH_LEADS'
