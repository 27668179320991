import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields, ModalContainer } from 'modules/components'
import { SEND_PREVIEW_MODAL } from '../constants'
import { isPresent, isMaxLength, isMinLength, isNumber } from 'utils/validation'

import STRINGS from 'strings'
const previewModalStrings = STRINGS.BulkMessaging.BulkSms.Checklist.PreviewModal

const { TextField } = FormFields

export const validator = {
  number: [{
    rule: isPresent,
    errorMessage: previewModalStrings.Validations.numberEmpty
  }, {
    rule: isMinLength(9),
    errorMessage: previewModalStrings.Validations.min9Numbers
  }, {
    rule: isMaxLength(12),
    errorMessage: previewModalStrings.Validations.max12Numbers
  }, {
    rule: isNumber,
    errorMessage: previewModalStrings.Validations.invalidNumberCharacter
  }]
}

export class NewGroupModalView extends Component {

  state = {
    number: this.props.user.mobile_number,
    isValid: (this.props.user.mobile_number !== null)
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    const { bulkSms, sendPreview } = this.props
    sendPreview(bulkSms, this.state.number)
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value, isValid: isValid })
  }

  render () {
    const { user } = this.props
    return (
      <ModalContainer
        modalName={SEND_PREVIEW_MODAL}
        modalTitle={previewModalStrings.sendPreviewModalTitle}
        colorThemeClass={'primary'}
        showCancelButton
        actionEnabled={this.state.isValid}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={
          <span>{previewModalStrings.sendPreviewModalButton}</span>
        }>
        <p>{previewModalStrings.sendPreviewModalDescription}</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}
          style={{
            marginLeft: '15px',
            marginRight: '15px'
          }}>
          <TextField
            name='number'
            label=''
            initialValue={user.mobile_number}
            placeholder='0000 111 222'
            formFieldStyle='form-material form-material-primary'
            validator={validator}
            onChange={this.handleInput} />
        </form>
      </ModalContainer>
    )
  }
}

NewGroupModalView.propTypes = {
  sendPreview: PropTypes.func.isRequired,
  bulkSms: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default NewGroupModalView
