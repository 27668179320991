import PropTypes from "prop-types";
import React, { Component } from "react";
import alert from "modules/components/alert";
import STRINGS from "strings";

import { ContentBlockView, InfoView } from "modules/components";
import stepsView from "../../../../view";

import { CHECKLIST_VIEW, EDIT_VIEW, SETTINGS_VIEW } from "../constants";

const emailHeaderStrings =
  STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Header;

const menuItems = [
  {
    id: CHECKLIST_VIEW,
    name: emailHeaderStrings.checklist,
    icon: "fa fa-list-ol",
  },
  {
    id: EDIT_VIEW,
    name: emailHeaderStrings.editEmail,
    icon: "si si-pencil",
  },
  {
    id: SETTINGS_VIEW,
    name: emailHeaderStrings.sendSettings,
    icon: "si si-settings",
  },
];

export class EmailHeaderView extends Component {
  backSelected = () => {
    const { showWithConfig, hasUnsavedChanges } = this.props;
    if (hasUnsavedChanges) {
      showWithConfig({
        ...alert.defaultConfig,
        children: emailHeaderStrings.loseUnsavedChangesWarning,
        style: "warning",
        onConfirm: this.goBack,
      });
    } else {
      this.goBack();
    }
  };

  goBack = () => {
    this.props.setStepView(stepsView.constants.LIST_STEP_VIEW);
  };

  menuItemClicked = (view) => {
    this.props.setView(view);
  };

  render() {
    const { funnelStep, automationEmail, view } = this.props;
    const name = funnelStep ? funnelStep.name : emailHeaderStrings.unknownStep;

    return (
      <ContentBlockView>
        <div className="row">
          <div className="col-md-6">
            <i
              className="si si-arrow-left fa-2x pull-left selectable"
              style={{ marginTop: "5px", marginRight: "20px" }}
              onClick={this.backSelected}
            />
            <h2 className="push">
              {funnelStep &&
                STRINGS.formatString(emailHeaderStrings.emailFor, name)}
            </h2>
          </div>
          {automationEmail &&
            automationEmail.communication_type === "transactional" && (
              <div className="col-md-6">
                <InfoView colorThemeClass={"info"}>
                  <p>{emailHeaderStrings.transactionalMessage}</p>
                  <a href="http://support.gymleads.net/gymleads-marketing-app/transactional-vs-marketing-communication">
                    {emailHeaderStrings.learnMore}
                  </a>
                </InfoView>
              </div>
            )}
        </div>
        {automationEmail && (
          <div className="row">
            <div className="col-xs-12">
              <div className="block">
                <ul className="nav nav-tabs nav-tabs-alt">
                  {menuItems.map((menuItem, index) => {
                    const className =
                      menuItem.id === view ? "active" : "selectable";
                    return (
                      <li
                        className={className}
                        key={index}
                        onClick={() => this.menuItemClicked(menuItem.id)}
                      >
                        <div className="topSubButton">
                          <i className={menuItem.icon} /> {menuItem.name}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
      </ContentBlockView>
    );
  }
}

EmailHeaderView.propTypes = {
  view: PropTypes.string.isRequired,
  setStepView: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  setView: PropTypes.func.isRequired,
  funnelStep: PropTypes.object.isRequired,
  automationEmail: PropTypes.object,
  showWithConfig: PropTypes.func.isRequired,
};

export default EmailHeaderView;
