import PropTypes from 'prop-types'
import React, { Component } from 'react'

import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'

import { statusEvents, actionEvents } from '../events'

class EventOptionSelection extends Component {

  render () {
    const { eventSelected } = this.props
    return (
      <div style={{ marginBottom: '10px', display: 'inline-block' }}>
        <DropdownMenu
          trigger={'Select event'}
          triggerType="button">
          <DropdownItemGroup id="status-events" title="Status">
            {statusEvents.map((event, i) => (
              <DropdownItem key={i} id={event.id} onClick={()=> eventSelected(event)}>
                <i className={event.icon} /> {event.name}
              </DropdownItem>
            ))}
          </DropdownItemGroup>
          <DropdownItemGroup id="action-events" title="Actions">
            {actionEvents.map((event, i) => (
              <DropdownItem key={i} id={event.id} onClick={()=> eventSelected(event)}>
                <i className={event.icon} /> {event.name}
              </DropdownItem>
            ))}
          </DropdownItemGroup>
        </DropdownMenu>
      </div>
    )
  }
}

EventOptionSelection.propTypes = {
  eventSelected: PropTypes.func.isRequired
}

export default EventOptionSelection
