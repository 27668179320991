import PropTypes from "prop-types";
import React, { Component } from "react";

import STRINGS from "strings";
const checklistPreviousRowStrings =
  STRINGS.BulkMessaging.BulkEmail.Checklist.PreviousRow;

export class PreviewRow extends Component {
  sendPreview = () => {
    const { bulkEmail, sendPreview } = this.props;
    sendPreview(bulkEmail);
  };

  render() {
    const { bulkEmail } = this.props;
    const checklist = bulkEmail.checklist;
    const icon = !checklist.preview_sent
      ? "fa fa-circle-thin fa-2x pull-left"
      : "fa fa-check-circle fa-2x pull-left text-success";
    return (
      <div className="checklist-row">
        {bulkEmail.status !== "scheduled" && (
          <button
            className="pull-right btn btn-primary"
            disabled={!checklist.ready_to_send_preview}
            onClick={this.sendPreview}
          >
            {checklistPreviousRowStrings.sendPreviewButton}
          </button>
        )}
        <i className={icon} />
        <h4>{checklistPreviousRowStrings.sendPreviewHeadliner}</h4>
        {checklist.ready_to_send_preview && (
          <div>
            {bulkEmail.preview_sent_to && (
              <p>
                {STRINGS.formatString(
                  checklistPreviousRowStrings.previewSentTo,
                  <b>{bulkEmail.preview_sent_to}</b>
                )}
                .
              </p>
            )}
            {!bulkEmail.preview_sent_to && (
              <p>{checklistPreviousRowStrings.sendAPreviewToYourself}</p>
            )}
          </div>
        )}
        {!checklist.ready_to_send_preview && (
          <p>{checklistPreviousRowStrings.emailInvalid}</p>
        )}
      </div>
    );
  }
}

PreviewRow.SubjectRow = {
  bulkEmail: PropTypes.object.isRequired,
  sendPreview: PropTypes.func.isRequired
};

export default PreviewRow;
