import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";

import moment from "moment-timezone";

import { RowContainer, PreviewImageContainer } from "./styles";

export class TemplateRow extends Component {
  rowSelected = e => {
    const { template, onSelect } = this.props;
    onSelect(template);
  };

  render() {
    const { template, timezone, showCreatedBy } = this.props;
    const createdBy = (template.user && template.user.display_name) || null;

    return (
      <ContentBlockView>
        <RowContainer onClick={this.rowSelected}>
          <PreviewImageContainer>
            <img
              src={template.url}
              style={{
                width: "70px",
                maxHeight: "88px",
                border: " 1px solid #e9e9e9",
                marginBottom: "20px"
              }}
              alt="email preview"
            />
          </PreviewImageContainer>
          <div className="col-xs-4" style={{ width: "auto" }}>
            <h5>
              {template.title}
              {template.compliance && !template.compliance.is_valid && (
                <i
                  className="fa fa-warning text-warning"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </h5>
            <p>
              Last updated{" "}
              {moment(template.updated_at)
                .tz(timezone)
                .format("Do MMM YYYY, h:mm a")}
              <br />
              {showCreatedBy && createdBy && `Created by ${createdBy}`}
            </p>
          </div>
        </RowContainer>
      </ContentBlockView>
    );
  }
}

TemplateRow.propTypes = {
  template: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  showCreatedBy: PropTypes.bool.isRequired
};

export default TemplateRow;
