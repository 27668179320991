import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'

import { FormFields } from 'modules/components'
import { calculatedTimeTypeSelection } from 'modules/helpers/TimeHelpers'

const BEFORE = 'Before'
const AFTER = 'After'

const beforeAfterOptions = [AFTER, BEFORE]

const { TextField, SelectField } = FormFields

export class EditTriggerFormView extends Component {

  handleChange = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.updateData(newData)
  }

  handleBeforeAfterChange = (fieldName, value, isValid) => {
    const { data, updateData } = this.props
    const { timeBefore, timeToWait } = data.fields

    switch (value) {
      case BEFORE:
        let newDataBefore = generateNewFormState(data, 'timeBefore', timeToWait.value, true)
        newDataBefore = generateNewFormState(newDataBefore, 'timeToWait', null, true)
        updateData(newDataBefore)
        break;
      case AFTER:
        let newDataAfter = generateNewFormState(data, 'timeToWait', timeBefore.value, true)
        newDataAfter = generateNewFormState(newDataAfter, 'timeBefore', null, true)
        updateData(newDataAfter)
        break;
      default:
        break;
    }
  }

  timeName = () => {
    const { timeBefore } = this.props.data.fields
    if (timeBefore.value) {
      return 'timeBefore'
    } else {
      return 'timeToWait'
    }

  }

  timeValue = () => {
    const { timeToWait, timeBefore } = this.props.data.fields
    if (timeBefore.value) {
      return timeBefore.value
    } else {
      return timeToWait.value
    }
  }

  beforeAfterValue = () => {
    const { timeBefore } = this.props.data.fields
    if (timeBefore.value) {
      return BEFORE
    } else {
      return AFTER
    }
  }

  render () {
    const { selectedTrigger, data } = this.props
    const { timeType } = data.fields
    const timeValue = this.timeValue()
    return (
      <div className='row'>
        <div className='col-sm-2'>
          <TextField
            name={this.timeName()}
            label='Time to wait'
            placeholder='6'
            onChange={this.handleChange}
            value={timeValue} />
        </div>
        <div className='col-sm-2'
          style={{ marginTop: '23px', paddingLeft: '0px', marginLeft: '-15px' }}>
          <SelectField
            name='timeType'
            possibleOptions={calculatedTimeTypeSelection(timeValue)}
            displayFieldName={'display'}
            onChange={this.handleChange}
            initialValue={timeType.value} />
        </div>
        <div className='col-sm-2'
          style={{ marginTop: '23px', paddingLeft: '0px', marginLeft: '-15px' }}>
          <SelectField
            name='beforeAfter'
            possibleOptions={beforeAfterOptions}
            disabled={!selectedTrigger.allow_before_time}
            onChange={this.handleBeforeAfterChange}
            initialValue={this.beforeAfterValue()} />
        </div>
      </div>
    )
  }
}

EditTriggerFormView.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  selectedTrigger: PropTypes.object.isRequired
}

export default EditTriggerFormView
