import { request, buildIndexUrl, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getLeads = (automationFunnelId, pageNumber, search, sort) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setLeads(payload.leads))
    }

    const baseUrl = 'automation_funnels/' + automationFunnelId + '/current_leads'

    const url = buildIndexUrl(baseUrl, pageNumber, sort, search)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setLeads (leads) {
  return {
    type: actionTypes.SET_LEADS,
    leads
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}
