import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import PreviewRow from '../components/PreviewRow'

import loadAutomationTextMessage from '../../loadAutomationTextMessage'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    automationTextMessage: loadAutomationTextMessage.selectors.getAutomationTextMessage(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewRow))
