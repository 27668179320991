import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  ImageEmptyView,
  SortSearchView,
  GymLeadsLoader
} from "modules/components";

import TemplateRowContainer from "../containers/TemplateRowContainer";
import TemplatesEmpty from "images/TemplatesEmpty.jpg";

import editSmsTemplate from "containers/editSmsTemplate/modules/editTemplate";
import PagesContainer from "../containers/PagesContainer";

import sortOptions from "../sortOptions";

export class TemplateListingView extends Component {
  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getTemplatesWithPageNumber(currentPage ? currentPage : 1);
  }

  templateSelected = template => {
    const url =
      "/secure/templates/sms/" +
      template.id +
      "/" +
      editSmsTemplate.constants.NAME;
    this.props.history.push(url);
  };

  getTemplatesWithPageNumber = pageNumber => {
    const { getTemplates, sortOrder, searchTerm } = this.props;
    getTemplates(pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getTemplatesWithPageNumber(pageNumber);
  };

  render() {
    const {
      templates,
      loading,
      sortSearchUpdated,
      sortOrder,
      searchTerm,
      userPermissionRuleSet
    } = this.props;

    const showCreatedBy =
      !!(userPermissionRuleSet && userPermissionRuleSet.view_all_marketing_assets);

    const templateRows = templates.map((template, index) => {
      return (
        <TemplateRowContainer
          key={index}
          template={template}
          onSelect={this.templateSelected}
          showCreatedBy={showCreatedBy}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={sortSearchUpdated}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <div className="row">
          <div className="col-xs-12">
            {templates.length === 0 && !loading && (
              <ImageEmptyView
                image={TemplatesEmpty}
                title={"No SMS templates"}
                subtitle="Create SMS templates for common messages"
              />
            )}
            <GymLeadsLoader active={loading} text="Loading Templates" />
            {templates.length > 0 && !loading && (
              <div>
                {templateRows}
                <PagesContainer pageClicked={this.pageClicked} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

TemplateListingView.propTypes = {
  getTemplates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  pageDetails: PropTypes.object.isRequired,
  errors: PropTypes.object,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  userPermissionRuleSet: PropTypes.object.isRequired
};

export default TemplateListingView;
