import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";

import {
  ContentBlockView,
  GymLeadsLoader,
  ImageEmptyView,
  SortSearchView
} from "modules/components";
import NoDesignsImage from "images/AutomationsEmpty.jpg";
import NoSelectedDesignImage from "images/AutomationNotReady.jpg";

import SelectRecipeRow from "./SelectRecipeRow";
import sortOptions from "../sortOptions";

import { PagesView } from "modules/components";
import STRINGS from "strings";

const PreviewText = styled.p``;

export class RecipesListView extends Component {
  state = {
    selectedRecipe: null
  };

  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getRecipesWithPageNumber(currentPage ? currentPage : 1);
  }

  getRecipesWithPageNumber = pageNumber => {
    const { getRecipes, sortOrder, searchTerm } = this.props;
    getRecipes(pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getRecipesWithPageNumber(pageNumber);
  };

  recipeSelected = recipe => {
    this.setState({ selectedRecipe: recipe });
  };

  createRecipeSelected = () => {
    const { createFunnelFromDesign } = this.props;
    const { selectedRecipe } = this.state;
    createFunnelFromDesign(selectedRecipe.id);
  };

  goBack = () => {
    this.props.showListingView();
  };

  render() {
    const {
      recipes,
      loading,
      pageDetails,
      sortSearchUpdated,
      sortOrder,
      searchTerm
    } = this.props;
    const { selectedRecipe } = this.state;

    const recipeRows = recipes.map((recipe, index) => {
      const selected = selectedRecipe && selectedRecipe.id === recipe.id;
      return (
        <SelectRecipeRow
          key={index}
          recipe={recipe}
          onClick={this.recipeSelected}
          selected={selected}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={sortSearchUpdated}
          sortColumnWidth={"3"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <div className="row">
          <div className="col-md-6">
            <ContentBlockView
              title={STRINGS.Automations.Designs["yourDesigns"]}
              bordered
              minHeight={397}
            >
              <GymLeadsLoader
                title={STRINGS.Automations.Designs["loadingDesigns"]}
                active={loading}
              />
              {recipes.length > 0 && !loading && recipeRows}
              {recipes.length === 0 && (
                <ImageEmptyView
                  image={NoDesignsImage}
                  title={STRINGS.Automations.Designs["noDesigns"]}
                  subtitle={STRINGS.Automations.Designs["noDesignsSubtitle"]}
                />
              )}
            </ContentBlockView>
          </div>
          <div className="col-md-6">
            <ContentBlockView title={"Preview"} bordered minHeight={397}>
              {!selectedRecipe && (
                <ImageEmptyView
                  image={NoSelectedDesignImage}
                  title={STRINGS.Automations.Designs["selectDesign"]}
                />
              )}
              {selectedRecipe && (
                <PreviewText>{selectedRecipe.recipe_description}</PreviewText>
              )}
            </ContentBlockView>
          </div>
        </div>
        {recipes.length > 0 && !loading && (
          <PagesView pageClicked={this.pageClicked} pageDetails={pageDetails} />
        )}
        <button
          className="btn btn-primary push-20-t push-20"
          onClick={this.goBack}
        >
          <i className="fa fa-angle-left" />{" "}
          {STRINGS.Automations.Designs["backToDesigns"]}
        </button>
        <button
          className="btn btn-success push-20-t push-20 pull-right"
          onClick={this.createRecipeSelected}
          disabled={!selectedRecipe}
        >
          <i className="fa fa-plus" /> {STRINGS.Global["create"]}
        </button>
      </div>
    );
  }
}

RecipesListView.propTypes = {
  showListingView: PropTypes.func.isRequired,
  getRecipes: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  recipes: PropTypes.arrayOf(PropTypes.object).isRequired,
  createFunnelFromDesign: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  sortSearchUpdated: PropTypes.func.isRequired
};

export default RecipesListView;
