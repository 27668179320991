import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { errorPanelForErrors } from "utils/formHelpers";

import STRINGS from "strings";

import HeroImage from "images/privateRecipesImage.jpg";

import {
  ContentBlockView,
  GymLeadsLoader,
  FormFields
} from "modules/components";

const HeroImageView = styled.img`
  width: 400px;
  margin: 0px 10px 0px 0px;
`;

const SearchDescription = styled.p``;

const ShareCodeDescription = styled.p`
  color: #c3c3c3;
`;

const { TextField } = FormFields;

class ShareCodeView extends React.Component {
  state = {
    shareCode: null
  };

  searchForRecipe = () => {
    const { getPrivateRecipe } = this.props;
    const { shareCode } = this.state;
    getPrivateRecipe(shareCode);
  };

  createButtonSelected = () => {
    const { createTemplateFromDesign, privateRecipe } = this.props;
    const { shareCode } = this.state;

    createTemplateFromDesign(privateRecipe.id, shareCode);
    this.setState({ shareCode: null });
  };

  clearPrivateTemplate = () => {
    this.setState({ shareCode: null });
    this.props.setPrivateRecipe(null);
  };

  handleInput = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  render() {
    const { loading, privateRecipe, errors } = this.props;
    const { shareCode } = this.state;
    const buttonDisabled = !shareCode;
    return (
      <div className="row">
        <div className="col-sm-6">
          <ContentBlockView
            loading={loading}
            title={STRINGS.Templates.shareTitle}
            bordered>
            <div className="row">
              <div className="col-xs-12">
                <GymLeadsLoader
                  title={STRINGS.Templates.Email.Designs.loadingPrivateRecipe}
                  active={loading}
                />
                {errorPanelForErrors(errors)}
                {!privateRecipe && (
                  <div>
                    <TextField
                      name="shareCode"
                      label="Enter your share code"
                      placeholder="23-11jf-29zk"
                      formFieldStyle=""
                      onChange={this.handleInput}
                    />
                    <button
                      className="btn btn-primary push-10"
                      disabled={buttonDisabled}
                      onClick={this.searchForRecipe}>
                      {STRINGS.Templates.searchForPrivateRecipe}
                    </button>
                  </div>
                )}
                {privateRecipe && (
                  <div>
                    <p>{STRINGS.Templates.Email.Designs.weFoundDetail}</p>
                    {privateRecipe.url && (
                      <div className="selectable design-cell">
                        {privateRecipe.url && (
                          <div className="design-container">
                            <img src={privateRecipe.url} alt="design preview" />
                          </div>
                        )}
                        <h5>{privateRecipe.title}</h5>
                        {privateRecipe.recipe_description && (
                          <p>{privateRecipe.recipe_description}</p>
                        )}
                      </div>
                    )}
                    <button
                      className="btn btn-primary push-10"
                      onClick={this.clearPrivateTemplate}>
                      {STRINGS.Global.clearSeach}
                    </button>
                    <button
                      className="btn btn-success pull-right"
                      onClick={this.createButtonSelected}>
                      {STRINGS.Templates.createTemplate}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </ContentBlockView>
        </div>
        <div className="col-sm-6">
          <ContentBlockView bordered>
            <HeroImageView src={HeroImage} />
            <SearchDescription>
              {STRINGS.Templates.searchForPrivateRecipeDetail}
            </SearchDescription>
            <ShareCodeDescription>
              {STRINGS.Templates.shareCodeExplination}
            </ShareCodeDescription>
          </ContentBlockView>
        </div>
      </div>
    );
  }
}

ShareCodeView.propTypes = {
  errors: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  getPrivateRecipe: PropTypes.func.isRequired,
  privateRecipe: PropTypes.object,
  setPrivateRecipe: PropTypes.func.isRequired,
  createTemplateFromDesign: PropTypes.func.isRequired
};

export default ShareCodeView;
