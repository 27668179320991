import { createSelector } from 'reselect'
import { NAME, SELECT_DESIGN_VIEW } from './constants'

export const getNewName = state => state.templates.emailTemplates[NAME].newName
export const getView = state => state.templates.emailTemplates[NAME].view

export const isSettingUpNewTemplate = createSelector(
  [ getView ],
  (view) => {
    return view === SELECT_DESIGN_VIEW
  }
)
