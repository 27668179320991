import * as actionTypes from './actionTypes'

const MEMBERS_ACTION_HANDLERS = {
  [actionTypes.SET_MEMBERS]: (state, action) => {
    return { ...state, members: action.members }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  },
  [actionTypes.SET_SELECTED_IDS]: (state, action) => {
    return { ...state, selectedIds: action.selectedIds }
  },
  [actionTypes.SELECT_MEMBER]: (state, action) => {
    const newSelectedIds = [
      ...state.selectedIds,
      action.member.id
    ]
    return { ...state, selectedIds: newSelectedIds }
  },
  [actionTypes.SET_SEARCH_LEADS]: (state, action) => {
    return { ...state, searchLeads: action.searchLeads }
  },
  [actionTypes.DESELECT_MEMBER]: (state, action) => {
    const index = state.selectedIds.indexOf(action.member.id)
    const newSelectedIds = [
      ...state.selectedIds.slice(0, index),
      ...state.selectedIds.slice(index + 1)
    ]
    return { ...state, selectedIds: newSelectedIds }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  }
}

const initialState = {
  members: [],
  selectedIds: [],
  searchLeads: [],
  loading: false,
  errors: null,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function (state = initialState, action) {
  const handler = MEMBERS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
