import * as actionTypes from './actionTypes'

const TAG_ACTION_HANDLERS = {
  [actionTypes.FETCHING_OPTIONS]: (state, action) => {
    return { ...state, loading: true }
  },
  [actionTypes.FETCHING_OPTIONS_SUCCESS]: (state, action) => {
    return { ...state, loading: false, options: action.options }
  },
  [actionTypes.FETCHING_OPTIONS_FAILED]: (state, action) => {
    return { ...state, loading: false }
  }
}

const initialState = {
  loading: false,
  options: []
}

export default function reducer (state = initialState, action) {
  const handler = TAG_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}