import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ImageEmptyView, TextSearchView } from 'modules/components'
import LeadsEmpty from 'images/LeadsEmpty.jpg'
import STRINGS from 'strings'
import LeadsTableView from './LeadsTableView'
import PagesContainer from '../containers/PagesContainer'

export class CompletedLeadsView extends Component {

  state = {
    showingSearch: false,
    search: null
  }

  componentDidMount () {
    this.getLeadsWithPageNumber(1)
  }

  getLeadsWithPageNumber = (pageNumber)  => {
    const { get, automationFunnel } = this.props
    get(automationFunnel.id, pageNumber)
  }

  pageClicked = (pageNumber) => {
    this.getLeadsWithPageNumber(pageNumber)
  }

  searchUpdated = (searchTerm, fieldName) => {
    const { get, automationFunnel } = this.props
    get(automationFunnel.id, 1, searchTerm)
  }

  toggleSearch = () => {
    if (this.state.showingSearch === true) {
      const { pageDetails } = this.props
      this.setState({ ...this.state, search: null })
      this.getLeadsWithPageNumber(pageDetails.currentPage)
    }
    this.setState({ ...this.state, showingSearch: !this.state.showingSearch })
  }

  render () {
    const completedLeadsStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.CompletedLeads
    const { joinRows, loading, automationFunnel } = this.props
    const { search, showingSearch } = this.state
    const className = (loading) ? 'block block-bordered block-opt-refresh' : 'block block-bordered'
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className={className}>
            <div className='block-header'>
              <div className='block-options-simple'>
                <button type='button' className='pull-right btn btn-xs btn-primary'
                  onClick={this.toggleSearch}>
                  <i className='fa fa-search' />
                </button>
              </div>
              <h3 className='block-title pull-left'
                style={{
                  marginRight: '5px'
                }}>{completedLeadsStrings.completedLeads}</h3>
            </div>
            <div className='block-content'>
              {showingSearch &&
                <TextSearchView
                  onUpdate={this.searchUpdated}
                  searchText={search || ''}
                  sectionName={'search'} />
              }
              {joinRows.length > 0 &&
                <div className='table-responsive'>
                  <LeadsTableView joinRows={joinRows} automationFunnel={automationFunnel} />
                  <PagesContainer pageClicked={this.pageClicked} />
                </div>
                }
                {joinRows.length === 0 &&
                  <ImageEmptyView
                    image={LeadsEmpty}
                    height={100}
                    title={completedLeadsStrings.title}
                    subtitle={completedLeadsStrings.subtitle} />
                }
          </div>
        </div>
      </div>
    </div>
    )
  }
}

CompletedLeadsView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  pageDetails: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  joinRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired
}

export default CompletedLeadsView
