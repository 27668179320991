import { request, buildIndexUrl, GET, DELETE } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import flags from "modules/components/flags";

import STRINGS from "strings";

export const getLeadsForFunnelStep = (
  funnel,
  funnelStep,
  pageNumber,
  sort,
  search
) => {
  return dispatch => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setStepsLeads(payload.automation_funnel_steps_leads));
    }

    let url = `automation_funnels/${funnel.id}/automation_funnel_steps/${
      funnelStep.id
    }/automation_funnel_steps_leads`;
    url = buildIndexUrl(url, pageNumber, sort, search);

    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const removeLeadFromStep = (funnel, funnelStep, stepsLeadsId) => {
  return dispatch => {
    dispatch(setLoading(true));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess() {
      dispatch(setLoading(false));
      dispatch(removeStepsLeadWithId(stepsLeadsId));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: STRINGS.Funnels.Steps.LeadsList.leadRemovedSuccess,
          index: 0
        })
      );
    }

    const url = `automation_funnels/${funnel.id}/automation_funnel_steps/${
      funnelStep.id
    }/automation_funnel_steps_leads/${stepsLeadsId}`;

    return request(url, DELETE, null, onSuccess, onFailure);
  };
};

export function setSelectedFunnelStepId(selectedFunnelStepId) {
  return {
    type: actionTypes.SET_SELECTED_FUNNEL_STEP_ID,
    selectedFunnelStepId
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function setStepsLeads(stepsLeads) {
  return {
    type: actionTypes.SET_STEPS_LEADS,
    stepsLeads
  };
}

function removeStepsLeadWithId(stepsLeadId) {
  return {
    type: actionTypes.REMOVE_STEPS_LEAD_WITH_ID,
    stepsLeadId
  };
}
