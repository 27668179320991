import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'

export class TextDetailsView extends Component {

  render () {
    return (
      <div>
        <ContentBlockView title={'Quick Send Templates'} bordered>
          <p>
            Quick send templates let you define simple, text based templates that can be quickly{' '}
            used by your sales team in the main sales app.
          </p>
        </ContentBlockView>
      </div>
    )
  }
}

TextDetailsView.propTypes = {
}

export default TextDetailsView
