import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState, errorPanelForErrors } from "utils/formHelpers";
import { validator } from "../validator";
import alert from "modules/components/alert";
import ShareContainer from "../containers/ShareContainer";

import { FormFields, ContentBlockView } from "modules/components";
const { TextField } = FormFields;

export class SettingsView extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.updateData(newData);
  };

  saveGroupClicked = () => {
    const { group, update, data } = this.props;
    update(data, group.id);
  };

  deleteGroupClicked = () => {
    const { showAlertWithConfig } = this.props;
    const alertConfig = {
      ...alert.defaultConfig,
      children:
        "Are you sure you want to delete this group? This cannot be undone",
      style: "danger",
      onConfirm: this.deleteGroup
    };
    showAlertWithConfig(alertConfig);
  };

  deleteGroup = () => {
    const { group, deleteGroup } = this.props;
    deleteGroup(group.id);
  };

  render() {
    const { name } = this.props.data.fields;
    const { loading, errors } = this.props;

    return (
      <div>
        <ContentBlockView title={"Group details"} loading={loading} bordered>
          <div className="row">
            <div className="col-md-6">
              <TextField
                name="name"
                label="Group name"
                placeholder="What should this group be called?"
                formFieldStyle=""
                onChange={this.handleInput}
                value={name.value}
                validator={validator}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4 push-20">
              <button
                className="btn btn-primary"
                onClick={this.saveGroupClicked}>
                <i className="fa fa-save" /> Save group details
              </button>
            </div>
          </div>
        </ContentBlockView>
        <ShareContainer />
        <ContentBlockView title={"Delete Group"} bordered>
          {errorPanelForErrors(errors)}
          <div className="row">
            <div className="col-xs-12 push-20">
              <p>
                Permanently delete this group. This cannot be undone and members
                will leave any active automations.
              </p>
              <button
                className="btn btn-danger"
                onClick={this.deleteGroupClicked}>
                <i className="fa fa-cross" /> Delete group
              </button>
            </div>
          </div>
        </ContentBlockView>
      </div>
    );
  }
}

SettingsView.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  group: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired
};

export default SettingsView;
