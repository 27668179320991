import { NAME } from './constants'

export const SET_RULES = NAME + '/SET_RULES'
export const SET_CONDITION = NAME + '/SET_CONDITION'

export const RESET = NAME + '/RESET'

export const ADD_RULE = NAME + '/ADD_RULE'
export const UPDATE_RULE = NAME + '/UPDATE_RULE'
export const REMOVE_RULE = NAME + '/REMOVE_RULE'

export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const SET_GROUPS = NAME + '/SET_GROUPS'

export const PAGE_DETAILS_UPDATED = NAME + '/PAGE_DETAILS_UPDATED'
export const SET_SELECTED_GROUP = NAME + '/SET_SELECTED_GROUP'

export const SET_HAS_CHANGES = NAME + '/SET_HAS_CHANGES'
