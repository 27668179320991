import { request, buildIndexUrl, GET, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import flags from 'modules/components/flags'
import loadAutomationFunnel from '../../../loadAutomationFunnel'
import { ALL } from 'modules/rules/rules'
import STRINGS from 'strings'

export const getGroups = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setGroups(payload.marketing_groups))
    }

    const url = buildIndexUrl('marketing_groups', pageNumber, sort, search) + '&detail=true'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const update = (automationFunnel, groupId, entryRulesJson) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const body = {
      automation_funnel: {
        marketing_group_ids: [groupId],
        entry_rules: (groupId) ? null: entryRulesJson
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(loadAutomationFunnel.actions.setAutomationFunnel(payload.automation_funnel))
      dispatch(setLoading(false))
      dispatch(setHasChanges(false))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: STRINGS.Funnels.EditAutomationFunnel.AudienceView.Conditions.audienceUpdated,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnel.id
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export const prefillDataForAutomationFunnel = (automationFunnel) => {
  return (dispatch) => {
    if (automationFunnel.entry_rules) {
      const rules = (automationFunnel.entry_rules) ? automationFunnel.entry_rules.rules : []
      dispatch(setCondition(automationFunnel.entry_rules.condition))
      dispatch(setRules(rules))
    }
    dispatch(setSelectedGroup(automationFunnel.marketing_group))
    dispatch(setHasChanges(false))
  }
}

export function setCondition (condition) {
  return {
    type: actionTypes.SET_CONDITION,
    condition
  }
}

export function setRules (rules) {
  return {
    type: actionTypes.SET_RULES,
    rules
  }
}

export function addRule (rule) {
  return {
    type: actionTypes.ADD_RULE,
    rule
  }
}

export function updateRule (index, ruleData) {
  return {
    type: actionTypes.UPDATE_RULE,
    index,
    ruleData
  }
}

export function removeRule (index) {
  return {
    type: actionTypes.REMOVE_RULE,
    index
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setGroups (groups) {
  return {
    type: actionTypes.SET_GROUPS,
    groups
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}

function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}

export function setSelectedGroup (selectedGroup) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_GROUP,
      selectedGroup
    })
    if (selectedGroup) {
      dispatch(setCondition(ALL))
      dispatch(setRules([]))
    }
  }
}
