import React from 'react'
import { CSSTransition } from 'react-transition-group'

const Fade = ({ children, ...props }) => (
  <CSSTransition
    {...props}
    timeout={150}
    classNames="fade">
    {children}
  </CSSTransition>
);

export default Fade
