import { request, GET, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import flags from 'modules/components/flags'

import loadTemplate from '../loadTemplate'

export const fetchMergeTags = (template, detail, companyId) => {
  return dispatch => {

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setMergeTags(payload.personalization_fields))
    }

    return request('personalization_fields?type=lead', GET, null, onSuccess, onFailure)
  }
}


export const updateTemplate = (template, text, subject, companyId) => {
  return dispatch => {
    dispatch(setLoading(true))
    const templateBody = {
      email_template: {
        title: template.title,
        subject: subject,
        text: text,
        location_ids: template.restricted_location_ids
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadTemplate.actions.setTemplate(payload.email_template))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: 'Email template updated',
        index: 0
      }))
    }

    const url = 'companies/' + companyId + '/email_templates/' + template.id
    return request(url, PUT, templateBody, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setMergeTags (mergeTags) {
  return {
    type: actionTypes.SET_MERGE_TAGS,
    mergeTags
  }
}

export function prefillForTemplate (template) {
  return {
    type: actionTypes.PREFIL_FOR_TEMPLATE,
    template
  }
}
