import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ModalView from './ModalView'

import modal from 'modules/stores/modal'

const mapStateToProps = (state) => {
  return {
    loading: modal.selectors.getModalLoading(state),
    currentModal: modal.selectors.getCurrentModal(state),
    errors: modal.selectors.getModalErrors(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    hideModal: modal.actions.hideModal
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalView)
