import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LeadsEmpty from 'images/LeadsEmpty.jpg'

import equal from 'deep-equal'

import { SimpleImageEmptyView, GymLeadsLoader, LeadSquareView } from 'modules/components'
import './styles.css'

import STRINGS from 'strings'
const bulkEmailAudienceStrings = STRINGS.SetAudience

class ShowAudienceView extends Component {

  componentDidMount () {
    const { selectedGroup, entryRulesJson, getPreviewLeads, getGroupLeads } = this.props
    if (selectedGroup) {
      getGroupLeads(selectedGroup.id)
      return
    }

    getPreviewLeads(entryRulesJson)
  }

  componentWillReceiveProps (nextProps) {
    const nextGroupId = (nextProps.selectedGroup) ? nextProps.selectedGroup.id : null
    const previousGroupId = (this.props.selectedGroup) ? this.props.selectedGroup.id : null
    if (nextGroupId && nextGroupId !== previousGroupId) {
      nextProps.getGroupLeads(nextGroupId)
      return
    }

    if (!nextGroupId && previousGroupId) {
      nextProps.getPreviewLeads(nextProps.entryRulesJson)
    }
    if (equal(nextProps.entryRulesJson, this.props.entryRulesJson) === false) {
      nextProps.getPreviewLeads(nextProps.entryRulesJson)
    }
  }

  render () {
    const { previewLeads, loading, totalCount } = this.props
    return (
      <div>
        <GymLeadsLoader active={loading} />
        {!loading && previewLeads.length === 0 &&
          <SimpleImageEmptyView
            image={LeadsEmpty}
            height={100}
            title={bulkEmailAudienceStrings.noLeadsSelectedTitle}
            subtitle={bulkEmailAudienceStrings.noLeadsSelectedSubtitle } />
        }
        {!loading && previewLeads.length > 0 &&
          <div className='audience'>
            <p className='audience-header'>{bulkEmailAudienceStrings.leadsWhoMatchFilter}</p>
            <div className='group-users'>
              {previewLeads.map((lead, i) => (
                <LeadSquareView lead={lead} key={i} />
              ))}
            </div>
            <p><span className='audience-count-number'>{totalCount}</span> leads</p>
          </div>
        }
      </div>
    )
  }
}

ShowAudienceView.propTypes = {
  previewLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
  getGroupLeads: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  getPreviewLeads: PropTypes.func.isRequired,
  entryRulesJson: PropTypes.object.isRequired
}

ShowAudienceView.defaultProps = {
  count: 0
}

export default ShowAudienceView
