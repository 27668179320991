import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ImageEmptyView } from 'modules/components'
import LeadsEmpty from 'images/LeadsEmpty.jpg'

import RecordsTableView from './RecordsTableView'
import PagesContainer from '../containers/PagesContainer'
import SearchView from './SearchView'

import './styles.css'

import STRINGS from 'strings'
const resultsBreakdownStrings = STRINGS.ViewBulkSharedStrings.ResultsBreakdownView

export class ResultsBreakdownView extends Component {

  state = {
    search: null,
    filterOptions: []
  }

  componentDidMount () {
    this.getRecordsWithPageNumber(1)
  }

  onSearchUpdate = (searchText, sectionName) => {
    this.setState({ ...this.state, [sectionName]: searchText })
    this.searchFilterUpdated(searchText, this.state.filterOptions)
  }

  onStatusUpdate = (statuses) => {
    this.setState({ ...this.state, filterOptions: statuses })
    this.searchFilterUpdated(this.state.searchText, statuses)
  }

  getRecordsWithPageNumber = (pageNumber) => {
    const { search, filterOptions } = this.state
    const { bulkSms, get } = this.props
    get(bulkSms.id, pageNumber, search, filterOptions)
  }

  pageClicked = (pageNumber) => {
    this.getRecordsWithPageNumber(pageNumber)
  }

  searchFilterUpdated = (search, statuses) => {
    const { bulkSms, get} = this.props
    get(bulkSms.id, 1, search, statuses)
  }

  render () {
    const { search } = this.state
    const { records, loading, showModal, hideModal } = this.props
    const className = (loading) ? 'block block-bordered block-opt-refresh' : 'block block-bordered'
    return (
      <div>
        <SearchView
          showModal={showModal}
          hideModal={hideModal}
          search={search}
          onSearchUpdate={this.onSearchUpdate}
          onStatusUpdate={this.onStatusUpdate}
        />
        <div className='row'>
          <div className='col-xs-12'>
            <div className={className}>
              <div className='block-header'>
                <h3 className='block-title pull-left'
                  style={{
                    marginRight: '5px'
                  }}>{resultsBreakdownStrings.title}</h3>
              </div>
              <div className='block-content'>
                {records.length > 0 &&
                  <div className='table-responsive'>
                    <RecordsTableView records={records} />
                    <PagesContainer pageClicked={this.pageClicked} />
                  </div>
                  }
                  {records.length === 0 &&
                    <ImageEmptyView
                      image={LeadsEmpty}
                      height={100}
                      title={resultsBreakdownStrings.noResultsTitle}
                      subtitle={resultsBreakdownStrings.noResultsSubtitle}
                    />
                  }
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

ResultsBreakdownView.propTypes = {
  bulkSms: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
}

export default ResultsBreakdownView
