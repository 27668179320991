import config from "config";
import PropTypes from "prop-types";
import React from "react";
import { readableMobileNumber } from "modules/helpers/FormatHelpers";

export const LeadRow = ({ lead, addLead }) => {
  function addSelected() {
    addLead(lead);
  }

  function goToLead() {
    window.open(
      config.mainHost + "/Secure/manageLeads?lead_id=" + lead.id,
      "_blank"
    );
  }

  const iseligible = lead.eligible;

  return (
    <tr>
      <td>{lead.display_name}</td>
      <td>{lead.email}</td>
      <td>{readableMobileNumber(lead.mobile_number)}</td>
      <td>
        {iseligible && (
          <button
            className="btn btn-sm btn-success"
            onClick={addSelected}
            type="button"
          >
            <i className="fa fa-plus" />
          </button>
        )}
      </td>
      <td>
        <button
          className="btn btn-sm btn-primary"
          onClick={goToLead}
          type="button"
        >
          <i className="fa fa-user" />
        </button>
      </td>
    </tr>
  );
};

LeadRow.propTypes = {
  lead: PropTypes.PropTypes.object.isRequired,
  addLead: PropTypes.func.isRequired
};

export default LeadRow;
