import * as actionTypes from "./actionTypes";
import * as locationApi from "modules/api/location";

export const getLocations = (pageNumber, searchTerm = null) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await locationApi.fetchLocations(
        pageNumber,
        null,
        searchTerm
      );
      dispatch(setLocations(response.locations));
      dispatch(setPageDetails(response.meta));
      dispatch(setLoading(false));
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(setErrors(error.response.data.errors));
      }
      dispatch(setLoading(false));
    }
  };
};

export const loadInitialLocations = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await locationApi.fetchLocations(1);
      dispatch(setLoading(false));
      const locations = response.locations;
      const isMultiLocationAccount = locations.length > 1;
      dispatch(setLocations(response.locations));
      dispatch(setDefaultLocation(locations[0]));
      dispatch(setIsMultiLocationAccount(isMultiLocationAccount));
      dispatch(setPageDetails(response.meta));
      dispatch(setInitialLoadComplete(true));
    } catch (error) {
      if (error.response && error.response.status === 422) {
        dispatch(setErrors(error.response.data.errors));
      }
      dispatch(setLoading(false));
    }
  };
};

export function addLocation(location) {
  return {
    type: actionTypes.ADD_LOCATION,
    location,
  };
}

export function editLocation(location, oldValue) {
  return {
    type: actionTypes.EDIT_LOCATION,
    location,
    oldValue,
  };
}

function setLocations(locations) {
  return {
    type: actionTypes.SET_LOCATIONS,
    locations,
  };
}

// Utility
function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

function setIsMultiLocationAccount(isMultiLocationAccount) {
  return {
    type: actionTypes.SET_IS_MULTI_LOCATION_ACCOUNT,
    isMultiLocationAccount,
  };
}

function setDefaultLocation(defaultLocation) {
  return {
    type: actionTypes.SET_DEFAULT_LOCATION,
    defaultLocation,
  };
}

function setInitialLoadComplete(initialLoadComplete) {
  return {
    type: actionTypes.SET_INITIAL_LOAD_COMPLETE,
    initialLoadComplete,
  };
}
