import { combineReducers } from 'redux'
import email from './modules/email'
import sms from './modules/sms'
import selection from './modules/selection'

export const reducer = combineReducers({
  [email.constants.NAME]: email.reducer,
  [sms.constants.NAME]: sms.reducer,
  [selection.constants.NAME]: selection.reducer
})
