import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import DesignsView from '../components/DesignsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import create from '../../create'
import userData from 'modules/stores/userData'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    basicDesigns: selectors.basicDesigns(state),
    featuredDesigns: selectors.featuredDesigns(state),
    newName: create.selectors.getNewName(state),
    company: userData.selectors.getCompany(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getDesigns: actions.getDesigns,
    createMassEmail: actions.createMassEmail
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignsView))
