import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class TextMessageTemplateRow extends Component {

  rowSelected = (event) => {
    const { textMessageTemplate, selectTemplate } = this.props
    selectTemplate(textMessageTemplate)
  }

  render () {
    const { textMessageTemplate, selected } = this.props
    const className = (selected) ? 'selected' : 'selectable'
    return (
      <tr className={className} onClick={() => this.rowSelected(textMessageTemplate)}>
        <td>{textMessageTemplate.title}</td>
      </tr>
    )
  }
}

TextMessageTemplateRow.propTypes = {
  textMessageTemplate: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectTemplate: PropTypes.func.isRequired
}

TextMessageTemplateRow.defaultProps = {
  selected: false
}


export default TextMessageTemplateRow
