import React, { Component } from 'react'

export class HomeView extends Component {

  render () {
    return (
      <div className='content content-boxed'>
      </div>
    )
  }
}

export default HomeView
