import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectTagTableView from "./TableView/SelectTagTableView";

import {
  ContentBlockView,
  EmptyView,
  TextSearchView,
  PagesView
} from "modules/components";

export class MultiTagSelectView extends Component {
  componentWillMount() {
    this.getTagsWithPageNumber(1);
  }

  getTagsWithPageNumber = pageNumber => {
    const { getTags } = this.props;
    getTags(pageNumber);
  };

  pageClicked = pageNumber => {
    this.getTagsWithPageNumber(pageNumber);
  };

  searchUpdated = searchTerm => {
    const { getTags } = this.props;
    getTags(1, searchTerm);
  };

  render() {
    const {
      excludedTags,
      pageDetails,
      loading,
      selectedTagIds,
      readonly
    } = this.props;
    const pageCount = pageDetails.pageCount;

    return (
      <ContentBlockView loading={loading}>
        <div className="row" style={{ marginTop: "-20px" }}>
          <TextSearchView
            sectionName={"tagSearch"}
            onUpdate={this.searchUpdated}
          />
        </div>
        {excludedTags.length > 0 && (
          <div>
            <SelectTagTableView
              excludedTags={excludedTags}
              selectedTagIds={selectedTagIds}
              selectTag={this.props.selectTag}
              deselectTag={this.props.deselectTag}
              readonly={readonly}
            />
          </div>
        )}
        {excludedTags.length === 0 && (
          <EmptyView
            colorThemeClass="primary"
            iconClass="si si-magnifier"
            title="No results"
            subtitle="Check the search field"
          />
        )}
        {pageCount > 1 && (
          <div className="text-center push">
            <PagesView
              pageDetails={pageDetails}
              pageClicked={this.pageClicked}
            />
          </div>
        )}
      </ContentBlockView>
    );
  }
}

MultiTagSelectView.propTypes = {
  getTags: PropTypes.func.isRequired,
  excludedTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectTag: PropTypes.func.isRequired,
  deselectTag: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired
};

MultiTagSelectView.defaultProps = {
  buttonTitle: "Filter by selected",
  readonly: false
};

export default MultiTagSelectView;
