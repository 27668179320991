import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getErrors = state =>
  state.editAutomationFunnel.steps.stepEmail[NAME].errors;
export const getLoading = state =>
  state.editAutomationFunnel.steps.stepEmail[NAME].loading;
export const getDesigns = state =>
  state.editAutomationFunnel.steps.stepEmail[NAME].designs;
export const getTemplates = state =>
  state.editAutomationFunnel.steps.stepEmail[NAME].templates;
export const getTemplatePageDetails = state =>
  state.editAutomationFunnel.steps.stepEmail[NAME].templatePageDetails;
export const getSearchTerm = state =>
  state.editAutomationFunnel.steps.stepEmail[NAME].searchTerm;
export const getSortOrder = state =>
  state.editAutomationFunnel.steps.stepEmail[NAME].sortOrder;

export const featuredDesigns = createSelector(
  [getDesigns],
  designs => {
    return designs.filter(design => design.design_type === "featured");
  }
);

export const basicDesigns = createSelector(
  [getDesigns],
  designs => {
    return designs.filter(design => design.design_type === "basic");
  }
);
