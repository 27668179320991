import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import ShareView from "modules/components/shareView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import loadGroup from "../../loadGroup";

function mapStateToProps(state) {
  return {
    template: loadGroup.selectors.getGroup(state),
    loading: selectors.getLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      generateShareCode: actions.generateShareCode
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShareView)
);
