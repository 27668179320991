import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import alert from 'modules/components/alert'

import SettingsView from '../components/SettingsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import loadAutomationEmail from '../../loadAutomationEmail'
import loadAutomationFunnel from 'containers/editAutomationFunnel/modules/loadAutomationFunnel'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state),
    errors: selectors.getErrors(state),
    loading: selectors.getLoading(state),
    automationEmail: loadAutomationEmail.selectors.getAutomationEmail(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: loadAutomationEmail.selectors.currentFunnelStep(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateData: actions.updateData,
    update: actions.update,
    showAlertWithConfig: alert.actions.showWithConfig,
    deleteEmail: actions.deleteEmail
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsView))
