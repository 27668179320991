import PropTypes from 'prop-types'
import React, { Component } from 'react'
import alert from 'modules/components/alert'
import { errorPanelForErrors } from 'utils/formHelpers'
import STRINGS from 'strings'
import { ContentBlockView, ImageEmptyView } from 'modules/components'

import AutomationsEmpty from 'images/AutomationsEmpty.jpg'
import AutomationNotReady from 'images/AutomationNotReady.jpg'

const checklistStrings = STRINGS.Funnels.EditAutomationFunnel.Checklist

export class StartView extends Component {

  showWarning = () => {
    const { showAlertWithConfig } = this.props
    const message = checklistStrings.warningMessage
    const alertConfig = {
      ...alert.defaultConfig,
      children: message,
      onConfirm: this.sendEmail,
      style: 'warning'
    }
    showAlertWithConfig(alertConfig)
  }

  sendEmail = () => {
    const { startAutomation, automationFunnel } = this.props
    startAutomation(automationFunnel)
  }

  render () {
    const { automationFunnel, loading, errors } = this.props
    const checklist = automationFunnel.checklist
    return (
      <ContentBlockView bordered loading={loading}>
        {errorPanelForErrors(errors)}
        {!checklist.funnel_ready &&
          <ImageEmptyView
            title={checklistStrings.IncompleteChecklist.title}
            subtitle={checklistStrings.IncompleteChecklist.subtitle}
            image={AutomationNotReady} />
        }
        {checklist.funnel_ready &&
          <ImageEmptyView
            title={checklistStrings.completedChecklist.title}
            subtitle={checklistStrings.completedChecklist.title}
            buttonText={checklistStrings.completedChecklist.buttonText}
            buttonSelected={this.showWarning}
            image={AutomationsEmpty} />
        }
      </ContentBlockView>
    )
  }
}

StartView.SubjectRow = {
  automationFunnel: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  startAutomation: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired
}

export default StartView
