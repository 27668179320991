import { NAME } from "./constants";

export const SET_RECORDS = NAME + "/SET_RECORDS";
export const SET_SELECTED_RECORD_ID = NAME + "/SET_SELECTED_RECORD_ID";
export const SET_SELECTED_REPLY = NAME + "/SET_SELECTED_REPLY";
export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_LOADING_REPLY = NAME + "/SET_LOADING_REPLY";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";

export const RESET = NAME + "/RESET";
