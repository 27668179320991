import { NAME } from "./constants";

export const getErrors = state => state.templates.smsTemplates[NAME].errors;
export const getLoading = state => state.templates.smsTemplates[NAME].loading;
export const getTemplates = state =>
  state.templates.smsTemplates[NAME].templates;
export const getPageDetails = state =>
  state.templates.smsTemplates[NAME].pageDetails;
export const getSearchTerm = state =>
  state.templates.smsTemplates[NAME].searchTerm;
export const getSortOrder = state =>
  state.templates.smsTemplates[NAME].sortOrder;

export const systemTemplates = [
  {
    id: "OptInTextMessage",
    title: "Edit The Opt In Text Message Template",
    detail: "A system defined text message to ask users to opt in."
  }
];
