import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings/'

import { ContentBlockView, GymLeadsLoader } from 'modules/components'

import { SelectLayoutCell } from './DesignCells'
import DesignListContainer from '../containers/DesignListContainer'

export class DesignsView extends Component {

  componentDidMount () {
    const { getLayouts } = this.props
    getLayouts()
  }

  designSelected = (design) => {
    const { createTemplate, company, newName } = this.props
    createTemplate(newName, company.id, design.html, design.design_json)
  }

  render () {
    const { layouts, loading } = this.props

    const layoutCells = layouts.map((layout, index) => {
      return (
        <SelectLayoutCell
          key={index}
          design={layout}
          onSelect={this.designSelected} />
      )
    })

    return (
      <div>
        <DesignListContainer />
        <ContentBlockView title={STRINGS.Templates.Email.Designs.basicLayouts} bordered>
          <GymLeadsLoader title={STRINGS.Templates.Email.Designs.loadingLayouts} active={loading} />
          <div className='designs-list'>
            {layouts.length > 0 &&
              layoutCells
            }
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

DesignsView.propTypes = {
  getLayouts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  layouts: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTemplate: PropTypes.func.isRequired,
  newName: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired
}

export default DesignsView
