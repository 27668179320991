import { combineReducers } from 'redux'
import loadTemplate from './modules/loadTemplate'
import editHtmlTemplate from './modules/editHtmlTemplate'
import editPlainTextTemplate from './modules/editPlainTextTemplate'
import links from './modules/links'
import settings from './modules/settings'

export const reducer = combineReducers({
  [loadTemplate.constants.NAME]: loadTemplate.reducer,
  [links.constants.NAME]: links.reducer,
  [editHtmlTemplate.constants.NAME]: editHtmlTemplate.reducer,
  [editPlainTextTemplate.constants.NAME]: editPlainTextTemplate.reducer,
  [settings.constants.NAME]: settings.reducer
})
