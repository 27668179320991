import PropTypes from "prop-types";
import React, { Component } from "react";

export class SelectTagTableRow extends Component {
  rowSelected = event => {
    const {
      tag,
      tagSelected,
      selectTag,
      deselectTag,
      readonly
    } = this.props;
    if (readonly) {
      return;
    }
    if (tagSelected) {
      deselectTag(tag);
    } else {
      selectTag(tag);
    }
  };

  render() {
    const { tag, tagSelected } = this.props;
    return (
      <tr className="selectable" onClick={() => this.rowSelected(tag)}>
        <td className="text-center">
          <label className="css-input css-checkbox css-checkbox-primary">
            <input
              type="checkbox"
              disabled
              checked={tagSelected}
              readOnly
            />
            <span />
          </label>
        </td>
        <td>{tag.name}</td>
      </tr>
    );
  }
}

SelectTagTableRow.propTypes = {
  tag: PropTypes.object.isRequired,
  tagSelected: PropTypes.bool.isRequired,
  selectTag: PropTypes.func.isRequired,
  deselectTag: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired
};
