export const ANY = 'any'
export const ALL = 'all'
export const NEVER = 'never'

export const statusEvents = [
  {
    id: 'enquiry',
    name: 'The lead is created',
    icon: 'si si-login',
    entry: 'NoOptionEntry'
  }, {
    id: 'assignment',
    name: 'The lead is assigned',
    icon: 'si si-login',
    entry: 'NoOptionEntry'
  }, {
    id: 'sales_funnel_step',
    name: 'The sales funnel step changes to',
    icon: 'fa fa-filter',
    entry: 'SalesFunnelStepEntry'
  }, {
    id: 'status',
    name: 'The status changes to',
    icon: 'fa fa-arrow-circle-right',
    entry: 'StatusEntry'
  }, {
    id: 'lead_lost',
    name: 'The lead is lost with reason',
    icon: 'fa fa-times',
    entry: 'LostEntry'
  }, {
    id: 'lead_closed',
    name: 'The lead is closed with deal',
    icon: 'fa fa-check',
    entry: 'ClosedEntry'
  }
]

export const actionEvents = [
  {
    id: 'call_scheduled',
    name: 'A call is scheduled',
    icon: 'si si-call-end',
    entry: 'NoOptionEntry'
  }, {
    id: 'call_recorded',
    name: 'A call is recorded',
    icon: 'si si-call-end',
    entry: 'NoOptionEntry'
  }, {
    id: 'appointment_scheduled',
    name: 'An appointment is scheduled',
    icon: 'si si-calendar',
    entry: 'NoOptionEntry'
  }, {
    id: 'appointment_recorded',
    name: 'An appointment is recorded',
    icon: 'si si-calendar',
    entry: 'NoOptionEntry'
  }
]
