import * as actionTypes from "./actionTypes";

const SELECT_DESIGN_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_DESIGNS]: (state, action) => {
    return { ...state, designs: action.designs };
  },
  [actionTypes.SET_PRIVATE_RECIPE]: (state, action) => {
    return { ...state, privateRecipe: action.privateRecipe };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  loading: false,
  errors: null,
  privateRecipe: null,
  designs: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

export default function(state = initialState, action) {
  const handler = SELECT_DESIGN_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
