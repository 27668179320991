import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";
import SideMenuItem from "./SideMenuItem";

import { selectOptions } from "../constants";

export class BulkEmailFiltersView extends Component {
  optionSelected = (option) => {
    this.props.setSelection(option);
  };

  render() {
    const { selection } = this.props;
    const options = selectOptions.map((option, index) => {
      const isSelected = option.id === selection.id;
      return (
        <SideMenuItem
          key={index}
          item={option}
          isSelected={isSelected}
          onSelect={this.optionSelected}
        />
      );
    });

    return (
      <ContentBlockView>
        <ul className="nav nav-pills nav-stacked push">{options}</ul>
      </ContentBlockView>
    );
  }
}

BulkEmailFiltersView.propTypes = {
  selection: PropTypes.object.isRequired,
  setSelection: PropTypes.func.isRequired,
};

export default BulkEmailFiltersView;
