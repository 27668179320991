import { isPresent } from 'utils/validation'
import STRINGS from 'strings'

export const LoginFormValidator = {
  username: [{
    rule: isPresent,
    errorMessage: STRINGS.Login.Validator.whatIsYourUsername
  }],
  password: [{
    rule: isPresent,
    errorMessage: STRINGS.Login.Validator.missingPassword
  }]
}
