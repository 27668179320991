import { request, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getPreviewLeads = (ruleSet) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setPreviewLeads(payload.leads))
      if (payload.meta) {
        dispatch(setTotalCount(payload.meta.total_count))
      }
      dispatch(setLoading(false))
    }

    return request('marketing_group_audience_preview', POST, ruleSet, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setPreviewLeads (previewLeads) {
  return {
    type: actionTypes.SET_PREVIEW_LEADS,
    previewLeads
  }
}

export function setTotalCount (totalCount) {
  return {
    type: actionTypes.SET_TOTAL_COUNT,
    totalCount
  }
}
