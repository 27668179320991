import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields, ModalContainer, LeadStatusTag, ImageEmptyView } from 'modules/components'
import { SEARCH_MODAL_NAME } from '../constants'

import LeadsEmpty from 'images/LeadsEmpty.jpg'
import STRINGS from 'strings'

const { TextField } = FormFields
const modalStrings = STRINGS.ViewBulkSharedStrings.SearchForLeadModal

export class SearchForLeadModalView extends Component {

  state = {
    searched: false
  }

  handleSearch = (event) => {
    event && event.preventDefault()
    const { searchForLeads, searchText, bulkSms } = this.props
    if (!searchText || searchText.length === 0) { return }
    this.setState({...this.state, searched: true })
    searchForLeads(bulkSms.id, searchText)
  }

  handleInput = (name, value, isValid) => {
    this.props.setSearchText(value)
  }

  selectResult = (result) => {
    const { getPreviewHtml, bulkSms, setPreviewResult, hideModal } = this.props
    setPreviewResult(result)
    getPreviewHtml(bulkSms.id, result.id)
    hideModal()
  }

  render () {
    const { searchResults, searchText, loading } = this.props
    const { searched } = this.state
    return (
      <ModalContainer
        modalName={SEARCH_MODAL_NAME}
        modalTitle={modalStrings.modalTitle}
        colorThemeClass={'primary'}
        showCancelButton>
        <p>{modalStrings.modalDescription}</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSearch}
          style={{
            marginLeft: '15px',
            marginRight: '15px'
          }}>
          <div className='row'>
            <div className='col-md-8'>
              <TextField
                name='search'
                label=''
                placeholder={modalStrings.searchFieldPlaceholder}
                formFieldStyle='form-material form-material-primary'
                onChange={this.handleInput} />
            </div>
            <div className='col-md-4'>
              <button className={'btn btn-primary'}
                style={{ marginTop: '10px' }}
                type='button'
                disabled={loading || !searchText || searchText.length === 0}
                onClick={this.handleSearch}>
                {loading &&
                  <span><i className='fa fa-circle-o-notch fa-spin' /> {STRINGS.Global.loading}</span>
                }
                {!loading &&
                  <span><i className='fa fa-search' /> {STRINGS.Global.search}</span>
                }
              </button>
            </div>
          </div>
        </form>
        {searchResults &&
          <table className='table table-hover table-vcenter'>
            <tbody>
              {searchResults.map((result, index) => {
                const lead = result.lead
                return (
                  <tr onClick={() => this.selectResult(result)} key={result.id} className='selectable'>
                    <td>{lead.display_name}</td>
                    <td><LeadStatusTag leadStatus={lead.status}/></td>
                    <td>{lead.location_name}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        }
        {searched && !loading && searchResults.length === 0 &&
          <ImageEmptyView
            image={LeadsEmpty}
            height={100}
            title={modalStrings.noLeadsTitle}
            subtitle={modalStrings.noLeadsSubtitle} />
        }
      </ModalContainer>
    )
  }
}

SearchForLeadModalView.propTypes = {
  loading: PropTypes.bool.isRequired,
  bulkSms: PropTypes.object.isRequired,
  searchText: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSearchText: PropTypes.func.isRequired,
  setPreviewResult: PropTypes.func.isRequired,
  searchForLeads: PropTypes.func.isRequired,
  getPreviewHtml: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

export default SearchForLeadModalView
