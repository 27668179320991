import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import EditScheduleFormView from '../components/EditScheduleFormView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import session from 'modules/stores/session'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state),
    timezone: session.selectors.getUserTimezone(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateData: actions.updateData
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditScheduleFormView)
