import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

import { ContentBlockView } from 'modules/components'

export class ComplianceView extends Component {

  render () {
    const { compliance, template } = this.props
    return (
      <ContentBlockView>
        <div className='row'>
          <div className='col-xs-2 col-md-1 text-center'>
            {template.design_type === 'user' &&
              <i className='fa fa-warning fa-5x text-warning'/>
            }
            {template.design_type === 'system' &&
              <i className='fa fa-times-circle fa-5x text-danger'/>
            }
          </div>
          <div className='col-xs-10'>
            {template.design_type === 'user' &&
              <div>
                <h4>{STRINGS.Templates.Email.ComplianceView['warningTitle']}</h4>
                <p>
                  {STRINGS.Templates.Email.ComplianceView['warningBody']}
                  <a href='http://support.gymleads.net/' target='__blank'> {STRINGS.Templates.Email.ComplianceView['knowledgeBaseCTA']}.</a>
                </p>
              </div>
            }
            {template.design_type === 'system' &&
              <div>
                <h4>{STRINGS.Templates.Email.ComplianceView['errorTitle']}</h4>
                <p>
                  {STRINGS.Templates.Email.ComplianceView['errorBody']}
                  <a href='http://support.gymleads.net/' target='__blank'> {STRINGS.Templates.Email.ComplianceView['knowledgeBaseCTA']}.</a>
                </p>
              </div>
            }
            <ul>
              {compliance.subject_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['subjectOk']}</li>
              }
              {compliance.address_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['addressOk']}</li>
              }
              {compliance.unsubscribe_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['unsubscribeOk']}</li>
              }
              {compliance.company_name_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['companyNameOk']}</li>
              }
              {compliance.quick_email_text_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['quickEmailTextOk']}</li>
              }
            </ul>
          </div>
        </div>
      </ContentBlockView>
    )
  }
}

ComplianceView.propTypes = {
  template: PropTypes.object.isRequired,
  compliance: PropTypes.object.isRequired,
}

export default ComplianceView
