import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class TemplateCell extends Component {

  rowSelected = (e) => {
    const { template, onSelect } = this.props
    onSelect(template)
  }

  render () {
    const { template } = this.props
    return (
      <div className='selectable design-cell' onClick={this.rowSelected}>
        <div className='design-container'>
          <img src={template.url} alt='template preview' />
        </div>
        <h5>{template.title}</h5>
      </div>
    )
  }
}

TemplateCell.propTypes = {
  template: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default TemplateCell
