import PropTypes from 'prop-types'
import React, { Component } from 'react'

import listing from '../modules/listing'
import create from '../modules/create'
import selectDesign from '../modules/selectDesign'

export class GroupsView extends Component {

  render() {
    const { view } = this.props
    return (
      <div className='content content-boxed'>
        {view === create.constants.LIST_VIEW &&
          <listing.ListingContainer />
        }
        {view === create.constants.SELECT_DESIGN_VIEW &&
          <selectDesign.SelectDesignContainer />
        }
      </div>
    )
  }
}

GroupsView.propTypes = {
  view: PropTypes.string.isRequired
}

export default GroupsView
