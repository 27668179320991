import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ModalContainer } from 'modules/components'
import { MODAL_NAME } from '../constants'

import MutliUserSelectView from './MultiUserSelectView'

export class MultiUserSelectModal extends Component {

  selectUser = (user) => {
    this.props.addUserId(user.id)
  }

  deselectUser = (user) => {
    this.props.removeUserId(user.id)
  }

  updateClubIds = () => {
    const { selectedUserIds, onUpdate, hideModal } = this.props
    if (onUpdate) {
      onUpdate(selectedUserIds)
    }
    hideModal()
  }

  render () {
    const { selectedUserIds, buttonTitle, readonly, title } = this.props
    const disabled = (selectedUserIds.length === 0)

    return (
      <ModalContainer
        modalName={MODAL_NAME}
        modalTitle={title}
        colorThemeClass={'primary'}
        showCancelButton
        readonly={readonly}
        actionEnabled={!disabled}
        actionButtonClicked={this.updateClubIds}
        actionButtonElement={
          <span><i className='fa fa-user' /> {buttonTitle}</span>
        }>
        <MutliUserSelectView
          {...this.props}
          selectUser={this.selectUser}
          deselectUser={this.deselectUser} />
      </ModalContainer>
    )
  }
}

MultiUserSelectModal.propTypes = {
  selectedUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  setSelectedUserIds: PropTypes.func.isRequired,
  addUserId: PropTypes.func.isRequired,
  removeUserId: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  searchTerm: PropTypes.string,
  buttonTitle: PropTypes.string,
  identifer: PropTypes.string
}

MultiUserSelectModal.defaultProps = {
  buttonTitle: 'Filter by selected',
  title: 'Select Users',
  readonly: false
}

export default MultiUserSelectModal
