import * as actionTypes from "./actionTypes";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.SET_LOADING:
        return { ...state, loading: action.loading };
      case actionTypes.SET_ERRORS:
        return { ...state, errors: action.errors };
      case actionTypes.PAGE_DETAILS_UPDATED:
        return {
          ...state,
          pageDetails: {
            pageCount: action.pageDetails.total_pages,
            currentPage: action.pageDetails.current_page,
            total: action.pageDetails.total_count
          }
        };
      case actionTypes.SET_LEADS:
        return { ...state, leads: action.leads }
      case actionTypes.SET_SELECTED_FUNNEL_STEP_ID:
        return { ...state, selectedFunnelStepId: action.selectedFunnelStepId };
      case actionTypes.ADD_LEAD_TO_STEP_WITH_ID:
        const index = state.leads.findIndex(
          lead =>
            parseInt(lead.id, 10) === parseInt(action.leadId, 10)
        );
        const updatedLeads = [
          ...state.leads.slice(0, index),
          ...state.leads.slice(index + 1)
        ];
    return { ...state, leads: updatedLeads };
      case actionTypes.RESET:
        return initialState;
      default:
        return state;
    }
  }
  return state;
};
export default reducer;
