import { createSelector } from 'reselect'
import { NAME } from './constants'

import { selectOptions } from './constants'

export const getSelection = state => state.templates.smsTemplates[NAME].selection

export const selectedOption = createSelector(
  [ getSelection ],
  (selection) => {
    const index = selectOptions.findIndex(option => option.id === selection)
    return selectOptions[index]
  }
)
