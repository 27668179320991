import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import HeaderView from '../components/HeaderView'

import loadBulkSms from '../../loadBulkSms'
import alert from 'modules/components/alert'

function mapStateToProps (state) {
  return {
    bulkSms: loadBulkSms.selectors.getBulkSms(state),
    currentPath: state.routing.location.pathname,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setAlertShowing: alert.actions.setShowing,
    setAlertConfig: alert.actions.setConfig
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderView))
