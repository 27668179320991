import PropTypes from "prop-types";
import React, { Component } from "react";

import styled from "styled-components";

import { ContentBlockView } from "modules/components";
import STRINGS from "strings";

const DetailText = styled.p``;

const ShareCodePrefix = styled.span`
  font-weight: 100;
`;

const ShareCode = styled.h4``;

const GenerateShareCodeButton = styled.button`
  margin: 10px 0px 20px 0px;
`;
export class ShareView extends Component {
  generateShareCodeSelected = () => {
    const { template, generateShareCode } = this.props;
    generateShareCode(template);
  };

  render() {
    const { loading, template } = this.props;

    const detailCopy = template.share_code
      ? STRINGS.Templates.Email.Settings.shareDetailWithCode
      : STRINGS.Templates.Email.Settings.shareDetailNoCode;

    return (
      <ContentBlockView
        title={STRINGS.Templates.Email.Settings.shareTitle}
        loading={loading}
        bordered
      >
        <div className="row">
          <div className="col-xs-12">
            <DetailText>{detailCopy}</DetailText>
            {!template.share_code && (
              <GenerateShareCodeButton
                className="btn btn-success"
                onClick={this.generateShareCodeSelected}
              >
                {STRINGS.Templates.Email.Settings.generateShareCode}
              </GenerateShareCodeButton>
            )}
            {template.share_code && (
              <div>
                <ShareCode>
                  <ShareCodePrefix>
                    {STRINGS.Templates.Email.Settings.yourShareCodeIs}{" "}
                  </ShareCodePrefix>
                  {template.share_code}
                </ShareCode>
                <GenerateShareCodeButton
                  className="btn btn-success"
                  onClick={this.generateShareCodeSelected}
                >
                  {STRINGS.Templates.Email.Settings.regenerateShareCode}
                </GenerateShareCodeButton>
              </div>
            )}
          </div>
        </div>
      </ContentBlockView>
    );
  }
}

ShareView.propTypes = {
  template: PropTypes.object.isRequired,
  generateShareCode: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ShareView;
