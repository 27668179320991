import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.editEmailTemplate[NAME].errors
export const getLoading = state => state.editEmailTemplate[NAME].loading
export const getTemplate = state => state.editEmailTemplate[NAME].template
export const getCompliance = state => state.editEmailTemplate[NAME].compliance
export const getNotFound = state => state.editEmailTemplate[NAME].notFound

export const showCompliance = createSelector(
  [ getCompliance ],
  (compliance) => {
    if (!compliance) { return false }
    if (compliance.address_ok === false) { return true }
    if (compliance.subject_ok === false) { return true }
    if (compliance.unsubscribe_ok === false) { return true }
    if (compliance.company_name_ok === false) { return true }
    if (compliance.quick_email_text_ok === false) { return true }
    return false
  }
)