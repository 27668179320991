import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import ClosedEntryView from './ClosedEntryView'

import deals from 'modules/stores/deals'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    deals: deals.selectors.getDeals(state),
    dealSelectModalName: 'SELECT_DEAL_MODAL'
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getDeals: deals.actions.getDeals,
    showModal: modal.actions.showModal,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClosedEntryView))
