import * as actionTypes from './actionTypes'

import { LIST_STEP_VIEW } from './constants'

const TRIGGERS_VIEW_ACTION_HANDLERS = {
  [actionTypes.SET_VIEW]: (state, action) => {
    return { ...state, view: action.view }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  }
}

function initialState () {
  return {
    view: LIST_STEP_VIEW,
  }
}

export default function (state = initialState(), action) {
  const handler = TRIGGERS_VIEW_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
