import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LoggedInHeader from './LoggedInHeader'

export class Header extends Component {
  
  setDocumentTitle() {
    const { whitelabelProfile } = this.props
    if (whitelabelProfile && whitelabelProfile.name) {
      document.title = `${whitelabelProfile.name} | Marketing & Automation`
    } else {
      document.title = 'GymLeads | Marketing & Automation'
    }
  }

  render () {
    const { user, logout, updateAvailable, whitelabelProfile } = this.props

    this.setDocumentTitle()

    return (
      <LoggedInHeader user={user} logout={logout} updateAvailable={updateAvailable} whitelabelProfile={whitelabelProfile} />
    )
  }
}

Header.propTypes = {
  user: PropTypes.object,
  whitelabelProfile: PropTypes.object,
  logout: PropTypes.func.isRequired,
  updateAvailable: PropTypes.bool.isRequired
}

export default Header
