import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'
import { ContentBlockView } from 'modules/components'

import conditions from '../modules/conditions'
import showAudience from '../modules/showAudience'
const audienceStrings = STRINGS.Funnels.EditAutomationFunnel.AudienceView

export class AudienceView extends Component {

  saveAudienceClicked = () => {
    const { update, automationFunnel, selectedGroup, entryRulesJson } = this.props
    const selectedGroupId = (selectedGroup) ? selectedGroup.id : null
    update(automationFunnel, selectedGroupId, entryRulesJson.entry_rules)
  }

  render () {
    const { automationFunnel } = this.props
    return (
      <div>
        {automationFunnel.automation_state === 'active' &&
          <div className='alert alert-warning'>
            <p>
              {audienceStrings.funnelActiveWarningMessage}
            </p>
          </div>
        }
        <ContentBlockView title={'Set Audience'} bordered>
          <div className='row' style={{ marginLeft: '-20px', marginRight: '-20px' }}>
            <div className='col-xs-4 options-container-left'>
              <conditions.ConditionsContainer automationFunnel={automationFunnel} />
            </div>
            <div className='col-xs-8'>
              <showAudience.ShowAudienceContainer />
            </div>
          </div>
        </ContentBlockView>
        <button className='btn btn-primary '
          onClick={this.saveAudienceClicked}>
          <i className='fa fa-save' /> {audienceStrings.buttonText}
        </button>
      </div>
    )
  }
}

AudienceView.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  entryRulesJson: PropTypes.object.isRequired
}

export default AudienceView
