import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SEARCH_MODAL_NAME } from '../constants'
import SearchForLeadModalContainer from '../containers/SearchForLeadModalContainer'

import { ContentBlockView } from 'modules/components'

import STRINGS from "strings";
const contentStrings = STRINGS.ViewBulkEmail.Content;

export class ContentView extends Component {

  showSearchModal = () => {
    this.props.showModal(SEARCH_MODAL_NAME)
  }

  render () {
    const { validPreviewHtml, previewResult, loading, bulkEmail, validPreviewSubject } = this.props
    return (
      <div className='row'>
        {bulkEmail.is_legacy === true &&
          <div>
            <div className='col-md-6 col-md-offset-3 col-xs-12'>
              <ContentBlockView title={'Legacy Email'} bordered>
                <div style={{ pointerEvents: 'none' }}>
                  <p>{contentStrings.legacyMessage}</p>
                </div>
              </ContentBlockView>
            </div>
          </div>
        }
        {bulkEmail.is_legacy === false && validPreviewHtml &&
          <div>
            <div className='col-md-6 col-md-offset-1 col-xs-12'>
              <ContentBlockView title={'Email Content'} bordered style={{ padding: '0px' }} loading={loading}>
                <h3 style={{ padding: '10px' }}>
                  <span className='text-muted'>{contentStrings.contentTitle}:</span>{' '}
                  {validPreviewSubject}</h3>
                <div style={{ pointerEvents: 'none' }}>
                  <div dangerouslySetInnerHTML={{ __html: validPreviewHtml }} />
                </div>
              </ContentBlockView>
            </div>
            <div className='col-md-4 col-xs-12'>
              <ContentBlockView title={contentStrings.previewTitle} bordered loading={loading}>
                <p>{contentStrings.unmergedHelperDescription}</p>
                <button className='btn btn-primary push'
                  style={{ marginTop: '10px' }}
                  type='button'
                  onClick={this.showSearchModal}>
                  <span><i className='fa fa-search' /> {STRINGS.Global.search}</span>
                </button>
                {previewResult &&
                  <p>{STRINGS.formatString(
                    contentStrings.showPreviewAsDescription,
                    <strong>{previewResult.lead.display_name}</strong>
                  )}</p>
                }
              </ContentBlockView>
            </div>
            <SearchForLeadModalContainer />
          </div>
        }
      </div>
    )
  }
}

ContentView.propTypes = {
  loading: PropTypes.bool.isRequired,
  bulkEmail: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  previewResult: PropTypes.object,
  validPreviewHtml: PropTypes.string,
  validPreviewSubject: PropTypes.string.isRequired,
  errors: PropTypes.object
}

export default ContentView
