import { NAME } from './constants'
import { createSelector } from 'reselect'

export const getLocations = (state) => state[NAME].locations
export const getLoading = (state) => state[NAME].loading
export const getErrors = (state) => state[NAME].errors
export const getPageDetails = (state) => state[NAME].pageDetails
export const getIsMultiLocationAccount = (state) => state[NAME].isMultiLocationAccount
export const getDefaultLocation = (state) => state[NAME].defaultLocation
export const getInitialLoadComplete = (state) => state[NAME].initialLoadComplete

export function locationForIdFactory (locationId) {
  return createSelector(
    [ getLocations ],
    (locations) => {
      if (locations && locations.length > 0) {
        return locationForId(locations, locationId)
      }
    }
  )
}

function locationForId (locations, locationId) {
  const index = locations.findIndex(location => parseInt(location.id, 10) === parseInt(locationId, 10))
  return locations[index]
}

export function integrationWithStaffAccountForLocation (location) {
  if (location === null || location === undefined) { return null }
  if (location.third_party_integrations === null || location.third_party_integrations === undefined) { return null }
  const integrations = location.third_party_integrations
  .filter((integration) => integration.direction === 'outgoing')
  .filter((integration) => integration.integration_permission_set)
  .filter((integration) => integration.integration_permission_set.staff_account === true)
  return integrations[0]
}
