import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import LinksView from '../components/LinksView'

import loadTemplate from '../../loadTemplate'

function mapStateToProps (state) {
  const template = loadTemplate.selectors.getTemplate(state)
  return {
    links: template.links,
    template: template
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LinksView))
