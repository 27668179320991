import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { pluralizationHelper } from 'modules/helpers/PluralizationHelpers'
import { ContentBlockView, InfoView } from 'modules/components'
import STRINGS from 'strings'
const smsHelperStrings = STRINGS.Global.SmsHelper

export class TextDetailsView extends Component {

  render () {
    const { automationTextMessage, pricingDetails, messageCost, productName } = this.props
    const supportArticleLink = <a style={{ fontWeight: 'bold' }} href='http://support.gymleads.net/how-it-works/communication/sending-an-sms' target='__blank'>{smsHelperStrings.supportArticle}</a>
    const emoji = <span role='img' aria-label='smile'>😀<br /></span>
    return (
      <div>
        <ContentBlockView title={pricingDetails.characterLimitsHeading} bordered>
          <p><span className='text-muted'>{pricingDetails.remainingCharsLength} {smsHelperStrings.charactersRemaining}</span><br />
          {pricingDetails.totalCharsRemaining} {smsHelperStrings.totalCharactersRemaining}<br />
          {pluralizationHelper('messages', pricingDetails.messageCount)} {' '} is ${parseFloat(pricingDetails.currentCost).toFixed(2)} in total, {smsHelperStrings.chargedAt} ${messageCost.toFixed(2)}</p>      
          {automationTextMessage.total_leads_count > 0 &&
            <p>With {pluralizationHelper('leads', automationTextMessage.total_leads_count)}, {pricingDetails.estimatedPrice} ${(automationTextMessage.total_leads_count * pricingDetails.currentCost).toFixed(2)}</p>
          }

          {pricingDetails.emojiDetected &&
            <InfoView colorThemeClass='info'>
              <p>{STRINGS.formatString(smsHelperStrings.usingEmoji, emoji, supportArticleLink )}</p>
            </InfoView>
          }
          <p className='text-muted'>
            {STRINGS.formatString(smsHelperStrings.disclaimer, productName )}
          </p>
        </ContentBlockView>
      </div>
    )
  }
}

TextDetailsView.propTypes = {
  messageCost: PropTypes.number.isRequired,
  automationTextMessage: PropTypes.object.isRequired,
  automationTextMessageText: PropTypes.string,
  productName: PropTypes.string
}

TextDetailsView.defaultProps = {
}

export default TextDetailsView
