import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

import DesignsContainer from '../containers/DesignsContainer'
import TemplatesContainer from '../containers/TemplatesContainer'

const selectDesignStrings = STRINGS.BulkMessaging.SelectDesign

export class SelectDesignView extends Component {

  goBack = () => {
    this.props.showListingView()
  }

  render () {
    return (
      <div>
        <h3 className='push-10'>{selectDesignStrings.chooseTemplate}</h3>
        <TemplatesContainer />
        <DesignsContainer />
        <button className='btn btn-primary push-20-t push-20' onClick={this.goBack}>
          <i className='fa fa-angle-left' /> {selectDesignStrings.backToBulkEmails}
        </button>
      </div>
    )
  }
}

SelectDesignView.propTypes = {
  showListingView: PropTypes.func.isRequired,
}

export default SelectDesignView
