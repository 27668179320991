import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";
import { validator } from "../validator";
import alert from "modules/components/alert";
import { SELECT_LOCATIONS_MODAL } from "../constants";

import ShareContainer from "../containers/ShareContainer";

import {
  FormFields,
  ContentBlockView,
  MultiLocationSelect
} from "modules/components";
const { TextField } = FormFields;

export class SettingsView extends Component {
  handleInput = (fieldName, value, isValid) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.updateData(newData);
  };

  updateLocationIds = selectedLocationIds => {
    const { setLocationIds } = this.props;
    setLocationIds(selectedLocationIds);
  };

  saveTemplateClicked = () => {
    const { template, update, data, company, locationIds } = this.props;
    update(data, locationIds, template.id, company.id);
  };

  deleteTemplateClicked = () => {
    const { showAlertWithConfig } = this.props;
    const alertConfig = {
      ...alert.defaultConfig,
      children:
        "Are you sure you want to delete this template? This cannot be undone",
      style: "danger",
      onConfirm: this.deleteTemplate
    };
    showAlertWithConfig(alertConfig);
  };

  showSelectLocationsModal = () => {
    const { showModal } = this.props;
    showModal(SELECT_LOCATIONS_MODAL);
  };

  deleteTemplate = () => {
    const { template, deleteTemplate, company } = this.props;
    deleteTemplate(template.id, company.id);
  };

  render() {
    const { title, subject } = this.props.data.fields;
    const { loading, locationIds } = this.props;
    const clubTitle = locationIds.length + " selected";

    return (
      <div>
        <ContentBlockView title={"Template details"} loading={loading} bordered>
          <div className="row">
            <div className="col-md-6">
              <TextField
                name="title"
                label="Template Title"
                placeholder="What should this template be called?"
                formFieldStyle=""
                onChange={this.handleInput}
                value={title.value}
                validator={validator}
              />
            </div>
            <div className="col-md-6">
              <TextField
                name="subject"
                label="Default Subject"
                placeholder="Email subject line (merge fields work too!)"
                formFieldStyle=""
                onChange={this.handleInput}
                value={subject.value}
                validator={validator}
              />
            </div>
            <div className="col-md-6">
              <h3
                style={{
                  display: "inline-block",
                  fontSize: "13px",
                  marginBottom: "5px"
                }}>
                Manage club access?
              </h3>
              <br />
              <button
                className="btn btn-default push-5-r push-10"
                style={{ display: "inline-block" }}
                type="button"
                onClick={this.showSelectLocationsModal}>
                {clubTitle}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4 push-20">
              <button
                className="btn btn-primary"
                onClick={this.saveTemplateClicked}>
                <i className="fa fa-save" /> Save template details
              </button>
            </div>
          </div>
        </ContentBlockView>
        <ShareContainer />
        <ContentBlockView title={"Delete Template"} bordered>
          <div className="row">
            <div className="col-xs-12 push-20">
              <p>Permanently delete this template. This cannot be undone.</p>
              <button
                className="btn btn-danger"
                onClick={this.deleteTemplateClicked}>
                <i className="fa fa-cross" /> Delete template
              </button>
            </div>
          </div>
        </ContentBlockView>
        <MultiLocationSelect.MultiLocationSelectModalContainer
          modalName={SELECT_LOCATIONS_MODAL}
          locationIds={locationIds}
          onUpdate={this.updateLocationIds}
        />
      </div>
    );
  }
}

SettingsView.propTypes = {
  data: PropTypes.object.isRequired,
  locationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  company: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setLocationIds: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired
};

export default SettingsView;
