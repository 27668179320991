import PropTypes from "prop-types";
import React, { Component } from "react";
import { errorPanelForErrors } from "utils/formHelpers";
import STRINGS from 'strings'
export class PreviewRow extends Component {
  sendPreview = () => {
    const {
      automationFunnel,
      automationFunnelStep,
      automationEmail,
      sendPreview
    } = this.props;
    sendPreview(
      automationFunnel.id,
      automationFunnelStep.id,
      automationEmail.id
    );
  };

  render() {
    const previewRowStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Checklist.Preview

    const { automationEmail, errors } = this.props;
    const checklist = automationEmail.checklist;
    const icon = !checklist.preview_sent
      ? "fa fa-circle-thin fa-2x pull-left"
      : "fa fa-check-circle fa-2x pull-left text-success";
    return (
      <div className="checklist-row">
        <button
          className="pull-right btn btn-primary"
          disabled={!checklist.ready_to_send_preview}
          onClick={this.sendPreview}>
          {previewRowStrings.sendPreview}
        </button>
        <i className={icon} />
        <h4>{previewRowStrings.headline}</h4>
        {checklist.ready_to_send_preview && (
          <div>
            {errorPanelForErrors(errors)}
            {automationEmail.preview_sent_to && (
              <p>{STRINGS.formatString(previewRowStrings.previewSentTo, <b>{automationEmail.preview_sent_to}</b>)}</p>
            )}
            {!automationEmail.preview_sent_to && (
              <p>{previewRowStrings.sendPreviewMessage}</p>
            )}
          </div>
        )}
        {!checklist.ready_to_send_preview && (
          <p>{previewRowStrings.notReadyToSend}</p>
        )}
      </div>
    );
  }
}

PreviewRow.SubjectRow = {
  automationEmail: PropTypes.object.isRequired,
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  sendPreview: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default PreviewRow;
