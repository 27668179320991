import PropTypes from "prop-types";
import React, { Component } from "react";
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem
} from "@atlaskit/dropdown-menu";

import "./styles.css";

const options = [
  {
    type: "true",
    display: "yes",
    use_text: true
  },
  {
    type: "false",
    display: "no",
    use_text: true
  }
];

class YesNoEntry extends Component {
  constructor(props) {
    super(props);
    if (props.data) {
      const optionIndex = options.findIndex(
        option => option.type === props.data.value
      );
      this.state = { value: options[optionIndex] };
    } else {
      this.state = {
        value: null
      };
    }
  }

  handleOptionSelection = option => {
    this.setState({ ...this.state, value: option });
    this.updateWithResult({ value: option.type });
  };

  updateWithResult = result => {
    const { id, ruleUpdated } = this.props;
    ruleUpdated(id, result);
  };

  render() {
    const { readonly } = this.props;
    const { value } = this.state;
    const dropdownDisplay = value ? value.display : "Select one";
    return (
      <div style={{ display: "inline" }}>
        {readonly && (
          <span>
            <strong>{dropdownDisplay}</strong>
          </span>
        )}
        {!readonly && (
          <span style={{ display: "inline-block" }}>
            <DropdownMenu trigger={dropdownDisplay} triggerType="button">
              <DropdownItemGroup>
                {options.map((option, i) => (
                  <DropdownItem
                    key={i}
                    onClick={() => this.handleOptionSelection(option)}
                  >
                    {option.display}
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </DropdownMenu>
          </span>
        )}
      </div>
    );
  }
}

YesNoEntry.propTypes = {
  id: PropTypes.string.isRequired,
  ruleUpdated: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  data: PropTypes.object
};

YesNoEntry.defaultProps = {
  readonly: false
};

export default YesNoEntry;
