import { createSelector } from "reselect";
import moment from "moment";
import locationsStore from "modules/stores/locations";

// Sales Funnel
export const getSalesFunnelSteps = state => state.userData.salesFunnel.steps;

export const firstStep = createSelector(
  [getSalesFunnelSteps],
  salesFunnelSteps => {
    return salesFunnelSteps[0];
  }
);

// regions
export const getRegions = state => state.userData.regions;

export function regionForIdFactory(regionId) {
  return createSelector(
    [getRegions],
    regions => {
      if (regions && regions.length > 0) {
        return regionForId(regions, regionId);
      }
    }
  );
}

function regionForId(regions, regionId) {
  const index = regions.findIndex(
    region => parseInt(region.id, 10) === parseInt(regionId, 10)
  );
  return regions[index];
}

// loading is complete

export const getSalesFunnel = state => state.userData.salesFunnel;
export const getCompany = state => state.userData.company;
export const getCompanyTargetMetrics = state =>
  state.userData.company.default_target_rule_set;
export const getMembership = state => state.userData.company.default_membership;
export const getMembershipPermissions = state =>
  state.userData.company.default_membership.membership_permission;
export const getUserRoles = state => state.userData.userRoles;

export const loadingComplete = createSelector(
  [
    locationsStore.selectors.getInitialLoadComplete,
    getCompany,
    getSalesFunnel,
    getUserRoles
  ],
  (locationsAreLoaded, company, salesFunnel, userRoles) => {
    if (locationsAreLoaded !== true) return false;
    if (company === {}) return false;
    if (!salesFunnel.steps) return false;
    if (userRoles.length === 0) return false;
    return true;
  }
);

export const locationLoadComplete = createSelector(
  [
    locationsStore.selectors.getInitialLoadComplete,
    getCompany,
    getSalesFunnel,
    getUserRoles
  ],
  (locationsAreLoaded, company, salesFunnel, userRoles, locationMetrics) => {
    if (locationsAreLoaded !== true) return false;
    if (company === {}) return false;
    if (salesFunnel === {}) return false;
    if (userRoles.length === 0) return false;
    return true;
  }
);

export const companyName = createSelector(
  [getCompany],
  company => {
    return company.title;
  }
);

export function isAvailableForScheduleInTimezone(schedule) {
  if (!schedule) {
    return true;
  }
  if (!schedule.schedule_dates.length === 0) {
    return true;
  }

  const now = moment().utc();
  const length = schedule.schedule_dates.length;

  for (let i = 0; i < length; i++) {
    const slot = schedule.schedule_dates[i];
    const isBetween = now.isBetween(slot.start, slot.end);
    if (isBetween === true) {
      return true;
    }
  }
  return false;
}

// Whitelabel
export const getWhitelabelProfile = state =>
  state.userData.company.whitelabel_profile;
export const getWhitelabelProfileLogo = state =>
  state.userData.company.whitelabel_profile.whitelabel_profile_logo;

export const getProductName = createSelector(
  [getWhitelabelProfile],
  whitelabelProfile => {
    if (whitelabelProfile && whitelabelProfile.name) {
      return whitelabelProfile.name;
    } else {
      return "Gymleads";
    }
  }
);
