import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Header from '../components/Header'

import session from 'modules/stores/session'
import * as selectors from '../selectors'

const mapStateToProps = (state) => {
  return {
    user: session.selectors.getUser(state),
    updateAvailable: selectors.shouldShowVersionUpdate(state),
    whitelabelProfile: selectors.getWhitelabelProfile(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    logout: session.actions.logout
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
