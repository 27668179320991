import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'
import STRINGS from 'strings/'

import funnels from '../modules/funnels'
const listingStrings = STRINGS.Automations.Listing

export class AutomationsView extends Component {

  newAutomationClicked = () => {
    this.props.showModal(funnels.constants.SELECT_RECIPE_MODAL)
  }

  render () {
    const { shouldHideHeader } = this.props
    return (
      <div className='content content-boxed'>
        {!shouldHideHeader &&
          <ContentBlockView>
            <div className='row'>
              <div className='col-md-6'>
                <h2>{listingStrings.title}</h2>
                <p>{listingStrings.subtitle}</p>
              </div>
              <div className='col-md-6'>
                <button className='btn btn-success pull-right' style={{ marginTop: '5px' }} onClick={this.newAutomationClicked}>
                <i className='fa fa-plus' /> {listingStrings.createNewFunnel}
                </button>
              </div>
            </div>
          </ContentBlockView>
        }
        <div className='row'>
          <div className='col-sm-12'>
            <div>
              <funnels.FunnelsContainer />
              <funnels.NewFunnelModalContainer />
              <funnels.RecipeOrNewSelectionContainer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AutomationsView.propTypes = {
  showModal: PropTypes.func.isRequired,
  shouldHideHeader: PropTypes.bool.isRequired
}

export default AutomationsView
