import PropTypes from 'prop-types'
import React, { Component } from 'react'

import FromRow from './FromRow'
import ToRow from './ToRow'
import SubjectRow from './SubjectRow'
import ContentRow from './ContentRow'
import PreviewContainer from '../containers/PreviewContainer'
import SendContainer from '../containers/SendContainer'
import './styles.css'
import emptyImage from 'images/TemplatesEmpty.jpg'

import { ContentBlockView, ImageEmptyView } from 'modules/components'

import STRINGS from 'strings'
const bulkEmailChecklistStrings = STRINGS.BulkMessaging.BulkEmail.Checklist

export class ChecklistView extends Component {

  backSelected = () => {
    this.props.history.push('/secure/bulkMessaging')
  }

  render () {
    const { bulkEmail, company, history, isLegacy, productName } = this.props
    return (
      <div>
        {isLegacy &&
          <ImageEmptyView
            image={emptyImage}
            title={bulkEmailChecklistStrings.Legacy.headerTitle}
            subtitle={bulkEmailChecklistStrings.Legacy.headerSubtitle}
            buttonText={bulkEmailChecklistStrings.Legacy.headerButton}
            buttonSelected={this.backSelected} />
        }
        {!isLegacy &&
          <div>
            <ContentBlockView title={'Checklist'} bordered>
              <FromRow bulkEmail={bulkEmail} company={company} history={history} productName={productName}/>
              <ToRow bulkEmail={bulkEmail} history={history} />
              <SubjectRow bulkEmail={bulkEmail} history={history} />
              <ContentRow bulkEmail={bulkEmail} history={history} />
              <PreviewContainer />
            </ContentBlockView>
            <SendContainer />
          </div>
        }
      </div>
    )
  }
}

ChecklistView.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  bulkEmail: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  errors: PropTypes.object,
  productName: PropTypes.string,
  isLegacy: PropTypes.bool.isRequired
}

export default ChecklistView
