import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import audience from '../audience'

export const get = (bulkEmailId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      if (payload.status === 404) {
          dispatch(setNotFound(true))
          return
      }
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setBulkEmail(payload.mass_lead_email))
      dispatch(audience.actions.prefillDataForBulkEmail(payload.mass_lead_email))
      dispatch(setNotFound(false))
      dispatch(setLoading(false))
    }

    return request('mass_lead_emails/' + bulkEmailId, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setBulkEmail (bulkEmail) {
  return {
    type: actionTypes.SET_BULK_EMAIL,
    bulkEmail
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setNotFound (notFound) {
  return {
    type: actionTypes.SET_NOT_FOUND,
    notFound
  }
}
