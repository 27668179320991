import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";

import moment from "moment-timezone";

export class BulkMessageRow extends Component {
  rowSelected = e => {
    const { bulkEmail, onSelect } = this.props;
    onSelect(bulkEmail);
  };

  render() {
    const { bulkEmail, timezone, showCreatedBy } = this.props;
    const { user } = bulkEmail;
    const createdBy = user && user.display_name ? user.display_name : null;
    return (
      <ContentBlockView>
        <div className="row selectable" onClick={this.rowSelected}>
          <div className="col-xs-6">
            <h5>{bulkEmail.title || bulkEmail.subject}</h5>
            <p>
              Last updated{" "}
              {moment(bulkEmail.updated_at)
                .tz(timezone)
                .format("Do MMM YYYY, h:mm a")}
              <br />
              {showCreatedBy && createdBy && `Created by ${createdBy}`}
            </p>
          </div>
        </div>
      </ContentBlockView>
    );
  }
}

BulkMessageRow.propTypes = {
  bulkEmail: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  showCreatedBy: PropTypes.bool
};

export default BulkMessageRow;
