import STRINGS from "strings";
export const NAME = "bulkEmailFilters";

export const DRAFT = "draft";
export const SENT = "sent";
export const SCHEDULED = "scheduled";

export const selectOptions = [
  {
    id: DRAFT,
    title: STRINGS.Global.drafts,
    icon: "fa fa-edit",
    value: DRAFT,
  },
  {
    id: SCHEDULED,
    title: STRINGS.Global.scheduled,
    icon: "fa fa-clock-o",
    value: SCHEDULED,
  },
  {
    id: SENT,
    title: STRINGS.Global.sent,
    icon: "fa fa-send",
    value: ["sent", "sending", "queued"],
  },
];
