import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import DesignListView from '../components/DesignListView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import create from '../../create'
import userData from 'modules/stores/userData'

function mapStateToProps(state) {
  return {
    loading: selectors.getDesignsLoading(state),
    designs: selectors.getDesigns(state),
    pageDetails: selectors.getDesignPageDetails(state),
    newName: create.selectors.getNewName(state),
    company: userData.selectors.getCompany(state)
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getDesigns: actions.getDesigns,
    createTemplateFromDesign: actions.createTemplateFromDesign
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignListView))
