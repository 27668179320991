import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as actions from "modules/stores/excludedTags/actions";
import TagsExcludeEntryView from './TagsExcludeEntryView'

import excludedTags from 'modules/stores/excludedTags'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    excludedTags: excludedTags.selectors.getOptions(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    getTags: actions.getTags,
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagsExcludeEntryView))
