import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ConditionsView from '../components/ConditionsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    rules: selectors.getRules(state),
    selectedGroup: selectors.getSelectedGroup(state),
    loading: selectors.getLoading(state),
    condition: selectors.getCondition(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    update: actions.update,
    showModal: modal.actions.showModal,
    setSelectedGroup: actions.setSelectedGroup,
    getGroups: actions.getGroups,
    setRules: actions.setRules,
    setCondition: actions.setCondition,
    addRule: actions.addRule,
    updateRule: actions.updateRule,
    removeRule: actions.removeRule,
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConditionsView))
