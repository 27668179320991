import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MultiUserSelectModal from '../components/MultiUserSelectModal'

import * as actions from '../actions'
import * as selectors from '../selectors'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    selectedUserIds: selectors.getSelectedUserIds(state),
    users: selectors.getUsers(state),
    pageDetails: selectors.getPageDetails(state),
    loading: selectors.getLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchUsers: actions.fetchUsers,
    setSelectedUserIds: actions.setSelectedUserIds,
    addUserId: actions.addUserId,
    removeUserId: actions.removeUserId,
    hideModal: modal.actions.hideModal,
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiUserSelectModal)
