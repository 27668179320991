import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import LoadAutomationTextMessageView from '../components/LoadAutomationTextMessageView'
import loadAutomationFunnel from 'containers/editAutomationFunnel/modules/loadAutomationFunnel'
import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: selectors.currentFunnelStep(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getAutomationTextMessage: actions.get
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoadAutomationTextMessageView))
