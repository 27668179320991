import { createSelector } from "reselect";
import { editTriggerValueInputType, NAME } from "./constants";
import * as listingSelectors from "../listing/selectors";

import userData from "modules/stores/userData";
import trials from "modules/stores/trials";

import EditTriggerValueContainer from "./containers/EditTriggerValueContainer";
import EditDealTriggerValueContainer from "./containers/EditDealTriggerValueContainer";
import EditLeadLostReasonTriggerValueContainer from "./containers/EditLeadLostReasonTriggerValueContainer";

export const getLoading = (state) =>
  state.editAutomationFunnel.steps[NAME].loading;
export const getSelectedFunnelStepId = (state) =>
  state.editAutomationFunnel.steps[NAME].selectedFunnelStepId;
export const getData = (state) => state.editAutomationFunnel.steps[NAME].data;
export const getView = (state) => state.editAutomationFunnel.steps[NAME].view;
export const getTriggerOptions = (state) =>
  state.editAutomationFunnel.steps[NAME].triggerOptions;
export const getErrors = (state) =>
  state.editAutomationFunnel.steps[NAME].errors;

export const selectedFunnelStep = createSelector(
  [getSelectedFunnelStepId, listingSelectors.getSteps],
  (selectedFunnelStepId, steps) => {
    if (!selectedFunnelStepId) {
      return null;
    }
    const index = steps.findIndex(
      (step) => parseInt(step.id, 10) === parseInt(selectedFunnelStepId, 10)
    );
    return steps[index];
  }
);

export const triggerCategories = createSelector(
  [getTriggerOptions],
  (triggerOptions) => {
    return [...new Set(triggerOptions.map((o) => o.trigger_category))];
  }
);

export const selectedTrigger = createSelector(
  [getData, getTriggerOptions],
  (data, triggerOptions) => {
    const selectedTrigger = data.fields.triggerCondition.value;
    const index = triggerOptions.findIndex(
      (trigger) => trigger.trigger_condition === selectedTrigger
    );
    return triggerOptions[index];
  }
);

export const triggerSelectionContainer = createSelector([getData], (data) => {
  const selectedTrigger = data.fields.triggerCondition.value;
  switch (selectedTrigger) {
    case "moved_to_step":
    case "status_updated":
    case "added_to_trial":
    case "days_from_trial":
    case "appointment_completed":
    case "started_trial":
    case "collect_visits":
      return EditTriggerValueContainer;
    case "lead_closed":
      return EditDealTriggerValueContainer;
    case "lead_lost":
      return EditLeadLostReasonTriggerValueContainer;
    default:
      return null;
  }
});

export const triggerValueOptions = createSelector(
  [getData, userData.selectors.getSalesFunnelSteps, trials.selectors.getTrials],
  (data, salesFunnelSteps, trials) => {
    const selectedTrigger = data.fields.triggerCondition.value;
    switch (selectedTrigger) {
      case "moved_to_step":
        return salesFunnelSteps.map((step) => {
          return { display: step.name, id: step.id };
        });
      case "status_updated":
        return [
          { display: "Unassigned", id: "not_assigned" },
          { display: "Active", id: "active" },
          { display: "Lost", id: "lost" },
          { display: "Inactive", id: "inactive" },
          { display: "Closed", id: "closed" },
        ];
      case "started_trial":
      case "days_from_trial":
      case "added_to_trial":
        return trials.map((trial) => {
          return { display: trial.name, id: trial.id };
        });
      case "appointment_completed":
        return [
          { display: "Any", id: "any" },
          { display: "Signed up", id: "signed_up" },
          { display: "Wants to think about it", id: "wants_to_think_about_it" },
          { display: "No Show", id: "no_show" },
          { display: "Not Interested", id: "not_interested" },
          { display: "Started Trial", id: "started_trial" },
          { display: "Other", id: "other" },
        ];
      default:
        return [];
    }
  }
);

export const triggerValueText = createSelector(
  [getData, userData.selectors.getSalesFunnelSteps],
  (data) => {
    const selectedTrigger = data.fields.triggerCondition.value;
    switch (selectedTrigger) {
      case "moved_to_step":
        return "Moved To Step:";
      case "status_updated":
        return "Status changes to:";
      case "lead_closed":
        return "Lead closes with deal:";
      case "days_from_trial":
      case "started_trial":
      case "added_to_trial":
        return "Select the trial to use:";
      case "collect_visits":
        return "How many vists to trigger:";
      case "lead_lost":
        return "Lead lost with reason:";
      case "appointment_completed":
        return "Appointment completed with outcome:";
      default:
        return [];
    }
  }
);

export const triggerValueType = createSelector(
  [getData, userData.selectors.getSalesFunnelSteps],
  (data) => {
    const selectedTrigger = data.fields.triggerCondition.value;
    switch (selectedTrigger) {
      case "collect_visits":
        return editTriggerValueInputType.NUMBER;
      default:
        return editTriggerValueInputType.SELECT;
    }
  }
);
