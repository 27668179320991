import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState, errorPanelForErrors } from 'utils/formHelpers'
import { validator } from '../validator'
import alert from 'modules/components/alert'
import ToolTip from 'react-portal-tooltip'
import { FormFields, ContentBlockView } from 'modules/components'
import STRINGS from 'strings'

const { TextField, Checkbox } = FormFields
const settingsStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Settings


export class ChecklistView extends Component {

  state = {
    tooltipShowing: false
  }

  handleInput = (fieldName, value, isValid) => {
    const { updateData, data } = this.props
    const newData = generateNewFormState(data, fieldName, value, isValid)
    updateData(newData)
  }

  saveTemplateClicked = () => {
    const { automationEmail, automationFunnel, automationFunnelStep, update, data } = this.props
    update(automationFunnel.id, automationFunnelStep.id, automationEmail.id, data)
  }

  handleToggle = (fieldName, value) => {
    const { updateData, data } = this.props
    const communicationType = (value) ? 'transactional' : 'marketing'
    const newData = generateNewFormState(data, fieldName, communicationType, true)
    updateData(newData)
  }

  deleteClicked = () => {
    const { showAlertWithConfig } = this.props
    const alertConfig = {
      ...alert.defaultConfig,
      children: settingsStrings.deleteWarning,
      style: 'danger',
      onConfirm: this.deleteBulkEmail
    }
    showAlertWithConfig(alertConfig)
  }

  deleteBulkEmail = () => {
    const { automationEmail, automationFunnel, automationFunnelStep, deleteEmail } = this.props
    deleteEmail(automationFunnel.id, automationFunnelStep.id, automationEmail.id)
  }

  showToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: true })
  }

  hideToolTip = () => {
    this.setState({ ...this.state, tooltipShowing: false })
  }

  render () {
    const { loading, data, errors } = this.props
    const { subject, fromName, communicationType } = data.fields
    const { tooltipShowing } = this.state
    return (
      <div>
        <ContentBlockView title={settingsStrings.sendSettings} loading={loading} bordered>
          {errorPanelForErrors(errors)}
          <div className='row'>
            <div className='col-md-6'>
              <TextField
                name='subject'
                label={settingsStrings.emailSubjectLabel}
                placeholder={settingsStrings.emailSubjectPlaceholder}
                onChange={this.handleInput}
                value={subject.value}
                validator={validator} />
            </div>
            <div className='col-md-6'>
              <TextField
                name='fromName'
                label={settingsStrings.fromNameLabel}
                placeholder={settingsStrings.fromNameLabelPlaceholder}
                value={fromName.value}
                onChange={this.handleInput} />
            </div>
          </div>
          <div className='row push'>
            <div className='col-md-6'>
              <Checkbox name='communicationType'
                label='Is this a transactional email?'
                onChange={this.handleToggle}
                switchStyle='primary'
                display={'inline'}
                checked={communicationType.value === 'transactional'} />
              <i className='fa fa-question-circle selectable'
                style={{marginLeft: '10px'}}
                id={'tooltip'}
                onMouseEnter={this.showToolTip}
                onMouseLeave={this.hideToolTip}>
                <ToolTip active={tooltipShowing} parent={'#tooltip'} position={'top'} arrow={'center'}>
                  <div style={{ width: '300px' }}>
                    <p>{settingsStrings.transactionalEmailMessage} <a href='http://support.gymleads.net/gymleads-marketing-app/transactional-vs-marketing-communication'> {settingsStrings.transactionalEmailMessageLearnMore}</a>
                    </p>
                  </div>
                </ToolTip>
              </i>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-4 push-20'>
              <button className='btn btn-primary' onClick={this.saveTemplateClicked}>
                <i className='fa fa-save' /> {settingsStrings.saveFunnelEmailButton}
              </button>
            </div>
          </div>
        </ContentBlockView>
        <ContentBlockView title={'Delete Email'} bordered>
          <div className='row'>
            <div className='col-xs-12 push-20'>
              <p>{settingsStrings.deleteFunnelEmailMessage}</p>
              <button className='btn btn-danger' onClick={this.deleteClicked}>
                <i className='fa fa-cross' /> {settingsStrings.deleteFunnelEmailButton}
              </button>
            </div>
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

ChecklistView.propTypes = {
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  automationEmail: PropTypes.object.isRequired,
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default ChecklistView
