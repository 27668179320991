import config from 'config'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { readableMobileNumber } from 'modules/helpers/FormatHelpers'
import { LeadStatusTag } from 'modules/components'

export class MemberRow extends Component {

  rowSelected = () => {
    const { member, deSelect, select, selected } = this.props
    if (selected) {
      deSelect(member)
    } else {
      select(member)
    }
  }

  goToLead = () => {
    const { member } = this.props
    window.open(config.mainHost + '/Secure/manageLeads?lead_id=' + member.id, "_blank")  
  }

  render () {
    const { member, selected } = this.props
    return (
      <tr>
        <td className='text-center' onClick={this.rowSelected}>
          <label className='css-input css-checkbox css-checkbox-primary'>
            <input type='checkbox' disabled checked={selected} readOnly /><span />
          </label>
        </td>
        <td>{member.display_name}</td>
        <td>{member.email}</td>
        <td>{readableMobileNumber(member.mobile_number)}</td>
        <td><LeadStatusTag leadStatus={member.status}/></td>
        <td>{member.source}</td>
        <td>{member.location_name}</td>
        <td>
          <button className='btn btn-sm btn-primary' onClick={this.goToLead} type='button'>
            <i className='fa fa-user' />
          </button>
        </td>
      </tr>
    )
  }
}

MemberRow.propTypes = {
  member: PropTypes.PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  deSelect: PropTypes.func.isRequired,
}

export default MemberRow
