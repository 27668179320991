import { NAME } from "./constants";

export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const SET_GROUPS = NAME + "/SET_GROUPS";
export const SET_SORT_ORDER = NAME + "/SET_SORT_ORDER";
export const SET_SEARCH_TERM = NAME + "/SET_SEARCH_TERM";

export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";

export const ADD_GROUP = NAME + "/ADD_GROUP";
export const REMOVE_GROUP = NAME + "/REMOVE_GROUP";
