import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import TextInputView from '../components/TextInputView'
import loadTemplate from '../../loadTemplate'

import userData from 'modules/stores/userData'
import mergeTags from 'modules/stores/mergeTags'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    template: loadTemplate.selectors.getTemplate(state),
    mergeTags: mergeTags.selectors.mergeTagsForSelect(state),
    text: selectors.getText(state),
    company: userData.selectors.getCompany(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setText: actions.setText,
    updateTemplate: actions.updateTemplate
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextInputView))
