import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getErrors = state => state.templates.emailTemplates[NAME].errors;
export const getLoading = state => state.templates.emailTemplates[NAME].loading;
export const getPrivateRecipe = state =>
  state.templates.emailTemplates[NAME].privateRecipe;
export const getDesignsLoading = state =>
  state.templates.emailTemplates[NAME].designsLoading;
export const getDesigns = state => state.templates.emailTemplates[NAME].designs;
export const getLayouts = state => state.templates.emailTemplates[NAME].layouts;
export const getTemplates = state =>
  state.templates.emailTemplates[NAME].templates;
export const getTemplatePageDetails = state =>
  state.templates.emailTemplates[NAME].templatePageDetails;
export const getDesignPageDetails = state =>
  state.templates.emailTemplates[NAME].designPageDetails;

export const featuredDesigns = createSelector([getDesigns], designs => {
  return designs.filter(design => design.design_type === "featured");
});

export const basicDesigns = createSelector([getDesigns], designs => {
  return designs.filter(design => design.design_type === "basic");
});
