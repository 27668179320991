import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'

import moment from 'moment-timezone'

import { TimeSelectView } from 'modules/components'

export class SendTimeSelect extends Component {

  timeObjectFromMoment (momentValue) {
    if (!momentValue) {
      return {
        hour: '1',
        adjustedHour: '13',
        minute: '00',
        period: 'pm'
      }
    }
    const adjustedHour = momentValue.hour()
    const hourValue = (adjustedHour < 13) ? adjustedHour : adjustedHour - 12
    const periodValue = (adjustedHour < 13) ? 'am' : 'pm'
    const minuteValue = momentValue.minute()
    return {
      hour: hourValue,
      adjustedHour: adjustedHour,
      minute: minuteValue,
      period: periodValue
    }
  }

  generateDateTimeWithTimeObject (timeObject) {
    const { timezone } = this.props
    if (!timeObject) { return null }
    const { adjustedHour, minute } = timeObject

    return moment().set({'hour': adjustedHour, 'minute': minute}).tz(timezone).utc()
  }

  handleStartTimeChange = (timeObject) => {
    const startDateTimeString = this.generateDateTimeWithTimeObject(timeObject)
    const newData = generateNewFormState(this.props.data, 'startTime', startDateTimeString, true)
    this.props.updateData(newData)
  }

  handleEndTimeChange = (timeObject) => {
    const endDateTimeString = this.generateDateTimeWithTimeObject(timeObject)
    const newData = generateNewFormState(this.props.data, 'endTime', endDateTimeString, true)
    this.props.updateData(newData)
  }

  render () {
    const { data, timezone } = this.props
    const { beforeAfterBetween, startTime, endTime } = data.fields

    const startTimeMoment = (startTime.value) ? moment(startTime.value).tz(timezone) : null
    const startTimeObject = this.timeObjectFromMoment(startTimeMoment)

    const endTimeMoment = (endTime.value) ? moment(endTime.value).tz(timezone) : null
    const endTimeObject = this.timeObjectFromMoment(endTimeMoment)

    return (
      <div style={{ display: 'inline-block' }}>
        {beforeAfterBetween.value !== 'anytime' &&
          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            <TimeSelectView time={startTimeObject} onTimeChange={this.handleStartTimeChange} />
          </div>
        }
        {beforeAfterBetween.value === 'between' &&
          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            and
          </div>
        }
        {beforeAfterBetween.value === 'between' &&
          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            <TimeSelectView time={endTimeObject} onTimeChange={this.handleEndTimeChange}/>
          </div>
        }
      </div>
    )
  }
}

SendTimeSelect.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
}

export default SendTimeSelect
