import { request, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import content from '../content'
import loadAutomationTextMessage from '../loadAutomationTextMessage'

export const create = (automationFunnelId, automationFunnelStepId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(content.actions.prefillDataForAutomationTextMessage(payload.automation_text_message_template))
      dispatch(loadAutomationTextMessage.actions.setAutomationTextMessage(payload.automation_text_message_template))
      dispatch(setLoading(false))
    }
    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_text_message_templates'
    return request(url, POST, {}, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
