import { isPresent, isEmail } from 'utils/validation'

export const validator = {
  title: [{
    rule: isPresent,
    errorMessage: 'The email name needs a title'
  }],
  subject: [{
    rule: isPresent,
    errorMessage: 'The email must have a subject (You can use merge fields here as well)'
  }],
  fromAddress: [{
    rule: isEmail,
    errorMessage: 'The from address must be a valid, non-gmail email address'
  }]
}
