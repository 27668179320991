import STRINGS from "strings";
export const sortOptions = [
  {
    id: "name_asc",
    name: STRINGS.Global.Sort.nameAscending,
    icon: "fa fa-sort-alpha-asc"
  },
  {
    id: "name_desc",
    name: STRINGS.Global.Sort.nameDescending,
    icon: "fa fa-sort-alpha-desc"
  },
  {
    id: "date_asc",
    name: STRINGS.Global.Sort.dateAscending,
    icon: "fa fa-sort-numeric-asc"
  },
  {
    id: "date_desc",
    name: STRINGS.Global.Sort.dateDescending,
    icon: "fa fa-sort-numeric-desc"
  }
];

export default sortOptions;
