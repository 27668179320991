import { NAME } from './constants'

export const PREFIL_DATA_FOR_SCHEDULE = NAME + '/PREFIL_DATA_FOR_SCHEDULE'
export const UPDATE_DATA = NAME + '/UPDATE_DATA'
export const SET_LOADING = NAME + '/SET_LOADING'
export const SET_ERRORS = NAME + '/SET_ERRORS'
export const RESET = NAME + '/RESET'

export const SET_SELECTED_FUNNEL_STEP_ID = NAME + '/SET_SELECTED_FUNNEL_STEP_ID'
export const SET_VIEW = NAME + '/SET_VIEW'
