import loadAutomationFunnel from './modules/loadAutomationFunnel'
import automationFunnelSteps from './modules/steps/'
import settings from './modules/settings'
import audience from './modules/audience'

export const reset = () => {
  return (dispatch) => {
    dispatch(loadAutomationFunnel.actions.reset())
    dispatch(automationFunnelSteps.actions.reset())
    dispatch(settings.actions.reset())
    dispatch(audience.actions.reset())
  }
}
