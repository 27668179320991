import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView, TextSearchView } from "modules/components";
import { LocationEntry } from "modules/rules/ruleEntries";

import DropdownMenu, { DropdownItem } from "@atlaskit/dropdown-menu";

export class SortSearchView extends Component {
  state = {
    search: this.props.initialSearchTerm,
    selectedLocations: this.props.initialSelectedLocations
  };

  onSearchUpdate = (searchText, sectionName) => {
    this.setState({ ...this.state, [sectionName]: searchText });
    this.props.sortSearchUpdated(
      this.props.sortOrder,
      searchText,
      this.state.selectedLocations
    );
  };

  sortSelected = sortOption => {
    this.props.sortSearchUpdated(
      sortOption.id,
      this.state.search,
      this.state.selectedLocations
    );
  };

  formatLocationData = (locations = []) => {
    return { value: locations };
  };

  handleLocationsUpdate = (id, locations) => {
    const { locationsUpdated } = this.props;
    if (!locationsUpdated) return;
    const { value } = locations;
    const returnedLocations = value ? value : [];
    this.setState(
      { selectedLocations: returnedLocations },
      locationsUpdated(returnedLocations)
    );
  };

  render() {
    const { search, selectedLocations } = this.state;
    const {
      sortOrder,
      showLocationFilter,
      sortColumnWidth,
      searchColumnWidth,
      sortOptions,
      loading
    } = this.props;

    const selectedSortOption =
      sortOrder &&
      sortOptions &&
      sortOptions.find(option => option.id === sortOrder);

    return (
      <ContentBlockView loading={loading}>
        <div className="row">
          {sortOptions && (
            <div
              className={"col-md-" + sortColumnWidth + " col-xs-12"}
              style={{ display: "inline" }}
            >
              Sort by{" "}
              <div style={{ display: "inline-block" }}>
                <DropdownMenu
                  trigger={
                    selectedSortOption && (
                      <span>
                        <i className={selectedSortOption.icon} />{" "}
                        {selectedSortOption.name}
                      </span>
                    )
                  }
                  triggerType="button"
                >
                  {sortOptions.map((option, i) => (
                    <DropdownItem
                      key={i}
                      id={option.id}
                      onClick={() => this.sortSelected(option)}
                    >
                      <i className={option.icon} /> {option.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </div>
            </div>
          )}
          <div className={"col-md-" + searchColumnWidth + " col-xs-12"}>
            <TextSearchView
              onUpdate={this.onSearchUpdate}
              searchText={search || ""}
              sectionName={"search"}
            />
          </div>
          {showLocationFilter && (
            <div className={"col-md-" + searchColumnWidth + " col-xs-12"}>
              <LocationEntry
                id="1"
                ruleUpdated={this.handleLocationsUpdate}
                readOnly={true}
                data={this.formatLocationData(selectedLocations)}
                showClearSelectionButton
              />
            </div>
          )}
        </div>
      </ContentBlockView>
    );
  }
}

SortSearchView.propTypes = {
  loading: PropTypes.bool.isRequired,
  sortSearchUpdated: PropTypes.func.isRequired,
  sortColumnWidth: PropTypes.string,
  searchColumnWidth: PropTypes.string,
  sortOptions: PropTypes.arrayOf(PropTypes.object),
  showLocationFilter: PropTypes.bool,
  locationsUpdated: PropTypes.func,
  selectedLocations: PropTypes.arrayOf(PropTypes.number),
  initialSearchTerm: PropTypes.string,
  initialSelectedLocations: PropTypes.arrayOf(PropTypes.number)
};

SortSearchView.defaultProps = {
  sortColumnWidth: "4",
  searchColumnWidth: "4",
  loading: false,
  showLocationFilter: false
};

export default SortSearchView;
