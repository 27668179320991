import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectTagTableRow } from "./SelectTagTableRow";

export class SelectTagTableView extends Component {
  render() {
    const {
      excludedTags,
      readonly,
      selectedTagIds,
      selectTag,
      deselectTag
    } = this.props;
    const rows = excludedTags.map((tag, index) => {
      const selectedIndex = selectedTagIds.findIndex(
        selectedTagId => tag.id === selectedTagId
      );
      const selected = selectedIndex >= 0;
      return (
        <SelectTagTableRow
          key={index}
          tag={tag}
          tagSelected={selected}
          selectTag={selectTag}
          deselectTag={deselectTag}
          readonly={readonly}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectTagTableView.propTypes = {
  excludedTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectTag: PropTypes.func.isRequired,
  deselectTag: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired
};

SelectTagTableView.defaultProps = {
  readonly: false
};

export default SelectTagTableView;
