import * as actionTypes from './actionTypes'
import { ALL } from 'modules/rules/rules'

const SELECT_RULES_ACTION_HANDLERS = {
  [actionTypes.SET_RULES]: (state, action) => {
    return { ...state, rules: action.rules }
  },
  [actionTypes.SET_CONDITION]: (state, action) => {
    return { ...state, condition: action.condition, hasChanges: true }
  },
  [actionTypes.SET_SELECTED_GROUP]: (state, action) => {
    return { ...state, selectedGroup: action.selectedGroup, hasChanges: true }
  },
  [actionTypes.RESET]: (state, action) => {
      return initialState
  }
}

const initialState = {
  condition: ALL,
  rules: [],
  hasChanges: false,
  selectedGroup: null
}

export default function reducer (state = initialState, action) {
  const handler = SELECT_RULES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
