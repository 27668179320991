import * as actionTypes from './actionTypes'

export const prefillDataForBulkEmail = (massLeadEmail) => {
  return (dispatch) => {
    if (massLeadEmail.query_body) {
      const rules = (massLeadEmail.query_body) ? massLeadEmail.query_body.rules : []
      dispatch(setCondition(massLeadEmail.query_body.condition))
      dispatch(setRules(rules))
    }
    dispatch(setSelectedGroup(massLeadEmail.marketing_group))
  }
}

function setCondition (condition) {
  return {
    type: actionTypes.SET_CONDITION,
    condition
  }
}

function setRules (rules) {
  return {
    type: actionTypes.SET_RULES,
    rules
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setSelectedGroup (selectedGroup) {
  return {
    type: actionTypes.SET_SELECTED_GROUP,
    selectedGroup
  }
}
