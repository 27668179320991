import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import AudienceView from '../components/AudienceView'
import loadBulkSms from '../../loadBulkSms'

import conditions from '../modules/conditions'

function mapStateToProps (state) {
  return {
    bulkSms: loadBulkSms.selectors.getBulkSms(state),
    entryRulesJson: conditions.selectors.entryRulesJson(state),
    selectedGroup: conditions.selectors.getSelectedGroup(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    update: conditions.actions.update,
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AudienceView))
