import { combineReducers } from "redux";
import loadBulkEmail from "./modules/loadBulkEmail";
import resultsBreakdown from "./modules/resultsBreakdown";
import replies from "./modules/replies";
import content from "./modules/content";
import results from "./modules/results";
import audience from "./modules/audience";

export const reducer = combineReducers({
  [loadBulkEmail.constants.NAME]: loadBulkEmail.reducer,
  [audience.constants.NAME]: audience.reducer,
  [results.constants.NAME]: results.reducer,
  [resultsBreakdown.constants.NAME]: resultsBreakdown.reducer,
  [replies.constants.NAME]: replies.reducer,
  [content.constants.NAME]: content.reducer
});
