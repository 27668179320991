import * as actionTypes from './actionTypes'

export function setView (view) {
  return {
    type: actionTypes.SET_VIEW,
    view
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
