import PropTypes from "prop-types";
import React, { Component } from "react";
import { generateNewFormState } from "utils/formHelpers";

import { FormFields } from "modules/components";
import { editTriggerValueInputType } from "../constants";
import { editTriggerValueValidator } from "../validator";

const { SelectField, TextField } = FormFields;

export class EditTriggerValueView extends Component {
  componentDidMount = () => {
    const { data } = this.props;
    generateNewFormState(data, "valueId", this.getInitialValue(), true);
  };

  componentWillUnmount = () => {
    const { data } = this.props;
    const newData = generateNewFormState(
      data,
      "valueId",
      this.getInitialValue(),
      true
    );
    this.props.updateData(newData);
  };

  handleChange = (fieldName, value, isValid = true) => {
    const newData = generateNewFormState(
      this.props.data,
      fieldName,
      value,
      isValid
    );
    this.props.updateData(newData);
  };

  getInitialValue = () => {
    const { data, options } = this.props;
    if (data.fields.valueId.value) {
      return String(data.fields.valueId.value);
    } else if (options.length > 0) {
      return String(options[0].id);
    } else {
      return null;
    }
  };

  render() {
    const { data, options, triggerValueText, type } = this.props;
    const { valueId } = data.fields;

    return (
      <div className="row">
        <div className="col-sm-4">
          <label>{triggerValueText}</label>
          {type === editTriggerValueInputType.SELECT && (
            <SelectField
              name="valueId"
              possibleOptions={options}
              displayFieldName={"display"}
              onChange={this.handleChange}
              initialValue={valueId.value}
            />
          )}
          {type === editTriggerValueInputType.NUMBER && (
            <TextField
              name="valueId"
              possibleOptions={options}
              displayFieldName={"display"}
              onChange={this.handleChange}
              initialValue={valueId.value}
              validator={editTriggerValueValidator}
            />
          )}
        </div>
      </div>
    );
  }
}

EditTriggerValueView.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  triggerValueText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EditTriggerValueView;
