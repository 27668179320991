import * as actionTypes from "./actionTypes";
import SortOptions from "./sortOptions";

const LIST_BULK_SMS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_BULK_SMSS]: (state, action) => {
    return { ...state, bulkSmss: action.bulkSmss };
  },
  [actionTypes.SET_SORT_ORDER]: (state, action) => {
    return { ...state, sortOrder: action.sortOrder };
  },
  [actionTypes.SET_SELECTED_LOCATIONS]: (state, action) => {
    return { ...state, selectedLocations: action.selectedLocations };
  },
  [actionTypes.SET_SEARCH_TERM]: (state, action) => {
    return { ...state, searchTerm: action.searchTerm };
  },
  [actionTypes.ADD_BULK_SMS]: (state, action) => {
    const updatesBulkSmss = [...state.bulkSmss, action.bulkSms];
    return { ...state, bulkSmss: updatesBulkSmss };
  },
  [actionTypes.REMOVE_BULK_SMS]: (state, action) => {
    const index = state.bulkMessaging.findIndex(
      bulkSms => parseInt(bulkSms.id, 10) === parseInt(action.bulkSmsId, 10)
    );
    const updatesBulkSmss = [
      ...state.bulkSmss.slice(0, index),
      ...state.bulkSmss.slice(index + 1)
    ];
    return { ...state, bulkSmss: updatesBulkSmss };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  loading: false,
  errors: null,
  bulkSmss: [],
  searchTerm: null,
  sortOrder: SortOptions[3].id,
  selectedLocations: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

export default function(state = initialState, action) {
  const handler = LIST_BULK_SMS_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
