import React, { Component } from "react";
import STRINGS from "strings";

import SelectViewMenu from "modules/components/selectViewMenu";

import DesignsContainer from "../containers/DesignsContainer";
import ShareCodeContainer from "../containers/ShareCodeContainer";

const SYSTEM_DESIGNS = "SYSTEM_DESIGNS";
const SHARE_CODE = "SHARE_CODE";

const menuItems = [
  {
    id: SYSTEM_DESIGNS,
    name: STRINGS.Groups.Designs.designs
  },
  {
    id: SHARE_CODE,
    name: STRINGS.Templates.shareTitle
  }
];

export class SelectDesignView extends Component {
  state = {
    view: SYSTEM_DESIGNS
  };

  setView = view => {
    this.setState({ ...this.state, view: view });
  };

  render() {
    const { view } = this.state;
    return (
      <div>
        <h3 className="push-10">{STRINGS.Groups.Designs["title"]}</h3>
        <SelectViewMenu
          menuItems={menuItems}
          currentView={view}
          setView={this.setView}
        />
        {view === SYSTEM_DESIGNS && <DesignsContainer />}
        {view === SHARE_CODE && <ShareCodeContainer />}
      </div>
    );
  }
}

export default SelectDesignView;
