import PropTypes from "prop-types";
import React, { Component } from "react";

import SelectDealModalContainer from "modules/components/selectDealModal/SelectDealModalContainer";
import Button from "@atlaskit/button";

class ClosedEntryView extends Component {
  constructor(props) {
    super(props);
    if (props.data) {
      switch (typeof props.data.value) {
        case "number":
          this.state = { value: props.data.value };
          break;
        case "string":
          this.state = { value: parseInt(props.data.value, 10) };
          break;
        default:
          this.state = { value: null };
          break;
      }
    } else {
      this.state = {
        value: null
      };
    }
  }

  updateDeal = selectedDeal => {
    this.setState({ ...this.state, value: selectedDeal.id });
    this.updateWithResult({ value: selectedDeal.id });
  };

  updateWithResult = result => {
    const { id, eventUpdated } = this.props;
    eventUpdated(id, result);
  };

  showSelectDealsModal = () => {
    const { showModal, dealSelectModalName } = this.props;
    showModal(dealSelectModalName);
  };

  buttonStringTitle = () => {
    const { deals, data } = this.props;
    if (data && data.value) {
      const index = deals.findIndex(deal => deal.id === data.value);
      if (index >= 0) {
        return deals[index].title;
      } else {
        return "";
      }
    } else {
      return "Select a deal";
    }
  };

  render() {
    const { dealSelectModalName, hideModal } = this.props;
    return (
      <div style={{ display: "inline" }}>
        <span style={{ display: "inline-block" }}>
          <Button appearance="default" onClick={this.showSelectDealsModal}>
            {this.buttonStringTitle()}
          </Button>
        </span>
        <span style={{ display: "inline-block" }}>
          <SelectDealModalContainer
            hideModal={hideModal}
            modalName={dealSelectModalName}
            setSelectedDeal={this.updateDeal}
            selectedDealId={this.state.value}
          />
        </span>
      </div>
    );
  }
}

ClosedEntryView.propTypes = {
  id: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  dealSelectModalName: PropTypes.string.isRequired,
  eventUpdated: PropTypes.func.isRequired,
  data: PropTypes.object
};

export default ClosedEntryView;
