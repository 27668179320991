import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment-timezone";

export class RecordRow extends Component {
  handleRecordSelected = () => {
    const { onRecordSelected, record, selectedRecordId } = this.props;
    if (record.id === selectedRecordId) return;
    onRecordSelected(record);
  };

  render() {
    const { record, selectedRecordId } = this.props;
    const lead = record.lead;
    const replyDate = moment(record.replied_at).fromNow();
    const setClasses =
      selectedRecordId && selectedRecordId === record.id
        ? "selected"
        : "selectable";

    return (
      <tr className={setClasses} onClick={this.handleRecordSelected}>
        <td>{lead.display_name}</td>
        <td>{lead.email}</td>
        <td>{replyDate}</td>
      </tr>
    );
  }
}

RecordRow.propTypes = {
  record: PropTypes.PropTypes.object.isRequired,
  selectedRecordId: PropTypes.number,
  onRecordSelected: PropTypes.PropTypes.func.isRequired
};

export default RecordRow;
