import PropTypes from 'prop-types'
import React, { Component } from 'react'

import header from '../../header'
import STRINGS from 'strings'
const smsChecklistStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Checklist

export class ContentRow extends Component {

  goToContent = () => {
    this.props.setView(header.constants.EDIT_VIEW)
  }

  render () {
    const { automationTextMessage } = this.props
    const checklist = automationTextMessage.checklist
    const icon = (!checklist.content_ok) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        <button
          className='pull-right btn btn-primary'
          onClick={this.goToContent}>
          {smsChecklistStrings.editContentButton}
        </button>
        <i className={icon} />
        <h4>{smsChecklistStrings.SmsContent}</h4>
        {!checklist.correct_merge_tags &&
          <div>
            {smsChecklistStrings.incorrectMergeTags} [
              {checklist.incorrect_merge_tags.map((tag, index) => {
                if (index === checklist.incorrect_merge_tags.length - 1) {
                  return tag
                }
                return tag + ', '
              })}
            ]
          </div>
        }
        {checklist.content_ok &&  checklist.correct_merge_tags &&
          <div>
            {smsChecklistStrings.correctMergeTags}
          </div>
        }
        {!checklist.content_ok &&
          <div>
            {smsChecklistStrings.enterContent}
          </div>
        }
      </div>
    )
  }
}

ContentRow.SubjectRow = {
  automationTextMessage: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired
}

export default ContentRow
