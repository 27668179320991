import { NAME } from "./constants";

export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const SET_BULK_EMAILS = NAME + "/SET_BULK_EMAILS";
export const SET_SEARCH_TERM = NAME + "/SET_SEARCH_TERM";
export const SET_SORT_ORDER = NAME + "/SET_SORT_ORDER";
export const SET_SELECTED_LOCATIONS = NAME + "/SET_SELECTED_LOCATIONS";

export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";

export const ADD_BULK_EMAIL = NAME + "/ADD_BULK_EMAIL";
export const REMOVE_BULK_EMAIL = NAME + "/REMOVE_BULK_EMAIL";
