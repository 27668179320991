import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import EmailView from '../components/EmailView'
import loadAutomationEmail from '../modules/loadAutomationEmail'

import * as actions from '../actions'
import header from '../modules/header'

function mapStateToProps (state) {
  return {
    loadingAutomationEmail: loadAutomationEmail.selectors.getLoading(state),
    automationEmail: loadAutomationEmail.selectors.getAutomationEmail(state),
    currentView: header.selectors.getView(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    reset: actions.reset
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailView))
