import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PrivateRouteContainer, ImageEmptyView } from 'modules/components'

import loadGroup from '../modules/loadGroup'
import HeaderContainer from '../modules/header/containers/HeaderContainer'
import stats from '../modules/stats'
import members from '../modules/members'
import rules from '../modules/rules'
import settings from '../modules/settings'

import EmptyImage from 'images/GroupsEmpty.jpg'

export class EditGroupView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  backSelected = () => {
    this.props.history.push('/secure/groups')
  }

  render () {
    const { group, notFound } = this.props
    return (
      <div className='content'>
        {group &&
          <div>
            <HeaderContainer />
            <div className='row'>
              <div className='col-sm-12'>
                <PrivateRouteContainer exact path="/secure/groups/:id/stats" component={stats.StatsContainer} />
                <PrivateRouteContainer exact path="/secure/groups/:id/members" component={members.MembersContainer} />
                <PrivateRouteContainer exact path="/secure/groups/:id/member_rules" component={rules.RulesContainer} />
                <PrivateRouteContainer exact path="/secure/groups/:id/settings" component={settings.SettingsContainer} />
              </div>
            </div>
          </div>
        }
        {!group && !notFound &&
          <loadGroup.LoadGroupContainer />
        }
        {!group && notFound &&
          <ImageEmptyView
            image={EmptyImage}
            title={"Group Not Found"}
            subtitle="It looks like this group has been deleted."
            buttonText='Go Back'
            buttonSelected={this.backSelected} />
        }
      </div>
    )
  }
}

EditGroupView.propTypes = {
  group: PropTypes.object,
  reset: PropTypes.func.isRequired,
  notFound: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
}

export default EditGroupView
