import PropTypes from 'prop-types'
import React, { Component } from 'react'

import AutomationTextMessageDetailsContainer from '../containers/AutomationTextMessageDetailsContainer'
import TextInputContainer from '../containers/TextInputContainer'
import AutomationTextMessagePreviewContainer from '../containers/AutomationTextMessagePreviewContainer'

import { getPricingDetailsFromText } from 'modules/helpers/SmsHelpers'

export class ContentView extends Component {

  render () {
    const { previewText, text, messageCost } = this.props
    const pricingDetails = getPricingDetailsFromText(text, messageCost)
    
    return (
      <div className='row'>
        <div className='col-md-6'>
          <TextInputContainer maxLength={pricingDetails.maxLength} />
          {previewText &&
            <AutomationTextMessagePreviewContainer />
          }
        </div>
        <div className='col-md-6'>
          <AutomationTextMessageDetailsContainer pricingDetails={pricingDetails} />
        </div>
      </div>
    )
  }
}

ContentView.propTypes = {
  previewText: PropTypes.string,
  text: PropTypes.string.isRequired,
  messageCost: PropTypes.number.isRequired
}

export default ContentView
