import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import alert from 'modules/components/alert'

import SettingsView from '../components/SettingsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import loadBulkEmail from '../../loadBulkEmail'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state),
    errors: selectors.getErrors(state),
    loading: selectors.getLoading(state),
    bulkEmail: loadBulkEmail.selectors.getBulkEmail(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateData: actions.updateData,
    update: actions.update,
    showAlertWithConfig: alert.actions.showWithConfig,
    deleteBulkEmail: actions.deleteBulkEmail
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsView))
