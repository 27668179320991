import { combineReducers } from 'redux'
import entryRules from './modules/entryRules'
import showAudience from './modules/showAudience'
import exitRules from './modules/exitRules'

export const reducer = combineReducers({
  [entryRules.constants.NAME]: entryRules.reducer,
  [showAudience.constants.NAME]: showAudience.reducer,
  [exitRules.constants.NAME]: exitRules.reducer
})
