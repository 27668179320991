import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import RecipesListView from "../components/RecipesListView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import create from "../../create";

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    recipes: selectors.getRecipes(state),
    pageDetails: selectors.getPageDetails(state),
    searchTerm: selectors.getSearchTerm(state),
    sortOrder: selectors.getSortOrder(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRecipes: actions.getRecipes,
      createFunnelFromDesign: actions.createFunnelFromRecipe,
      showListingView: create.actions.reset,
      sortSearchUpdated: actions.sortSearchUpdated
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecipesListView)
);
