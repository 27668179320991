import { request, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import mergeTags from "modules/stores/mergeTags";
import locations from "modules/stores/locations";
import billing from "modules/stores/billing";

import tags from "modules/stores/tags";
import trials from "modules/stores/trials";

import session from "modules/stores/session";

function startLoading() {
  return {
    type: actionTypes.LOADING_STARTED
  };
}

function stopLoading() {
  return {
    type: actionTypes.LOADING_STOPPED
  };
}

export function loadInitialStateForCurrentUser() {
  return dispatch =>
    Promise.all([
      dispatch(startLoading()),
      dispatch(getUserCompanyDetails()),
      dispatch(locations.actions.loadInitialLocations()),
      dispatch(getUserRoles()),
      dispatch(billing.actions.loadBillingDataForUser()),
      dispatch(tags.actions.getTagOptions()),
      dispatch(trials.actions.getTrials(1))
    ]).then(() => {
      dispatch(stopLoading());
    });
}

// GET USER COMPANY
const getUserCompanyDetails = () => {
  return dispatch => {
    function onFailure(errors) {
      dispatch(session.actions.endSession("Your Session has expired"));
    }

    function onSuccess(payload) {
      if (payload.company) {
        const company = payload.company;
        dispatch(getUserCompanySuccess(company));
        dispatch(mergeTags.actions.fetchMergeTags());
        dispatch(getSalesFunnelSuccess(company.current_sales_funnel));
        dispatch(setRegions(company.regions));
      }
    }

    return request("user_company", GET, null, onSuccess, onFailure);
  };
};

export function getUserCompanySuccess(company) {
  return {
    type: actionTypes.COMPANY_DATA_FETCH_SUCESS,
    company
  };
}

// GET SALES Funnel
export function getSalesFunnelSuccess(salesFunnel) {
  return {
    type: actionTypes.SALES_FUNNEL_FETCH_SUCCESS,
    salesFunnel
  };
}

// GET User Roles Funnel
const getUserRoles = () => {
  return dispatch => {
    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(getUserRolesSuccess(payload.user_roles));
    }

    return request("user_roles", GET, null, onSuccess, onFailure);
  };
};

function getUserRolesSuccess(userRoles) {
  return {
    type: actionTypes.USER_ROLES_FETCH_SUCCESS,
    userRoles
  };
}

// regions
function setRegions(regions) {
  return {
    type: actionTypes.SET_REGIONS,
    regions
  };
}

// SCHEDULES
export const getSchedules = () => {
  return dispatch => {
    dispatch(startLoading());

    function onFailure(errors) {}

    function onSuccess(payload) {
      dispatch(setSchedules(payload.user_location_schedules));
    }

    return request("user_location_schedules", GET, null, onSuccess, onFailure);
  };
};

function setSchedules(schedules) {
  return {
    type: actionTypes.SET_SCHEDULES,
    schedules
  };
}
