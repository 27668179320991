import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SelectTextMessageTemplateModalContainer from '../containers/SelectTextMessageTemplateModalContainer'
import { SELECT_TEMPLATE_MODAL_NAME } from '../constants'

import { ContentBlockView, FormFields } from 'modules/components'
const { TextArea, SelectField } = FormFields


export class TextInputView extends Component {

  state = {
    cursorPosition: 0,
    maxLength: this.props.maxLength
  }

  onChange = (name, value) => {
    this.props.setText(value)
  }

  onSelect = (name, value) => {
    const { text, setText } = this.props
    const { cursorPosition } = this.state
    if (text) {
      const newText = text.slice(0, cursorPosition) + value + text.slice(cursorPosition)
      setText(newText)
    } else {
      setText(value)
    }
  }

  saveBulkSmsClicked = () => {
    const { bulkSms, text, updateSmsContent } = this.props
    updateSmsContent(bulkSms, text)
  }

  updateCusorPosition = (name, cursorPosition) => {
    this.setState({ ...this.state, cursorPosition: cursorPosition })
  }

  openTemplateSelect = () => {
    this.props.showModal(SELECT_TEMPLATE_MODAL_NAME)
  }

  render () {
    const { text, mergeTags, loading, bulkSms } = this.props
    return (
      <ContentBlockView title={'BulkSms Content'} loading={loading} bordered disabled={bulkSms.status === 'scheduled'}>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <SelectField
              id='personalization'
              placeHolderText='Insert Merge tag'
              onChange={this.onSelect}
              name='personalization'
              initialValue={null}
              formFieldStyle={'input-group'}
              icon={'fa fa-user'}
              possibleOptions={mergeTags}
              displayFieldName='title'
              />
          </div>
          <div className='col-md-4 col-xs-6'>
            <button className='btn btn-primary push-20' onClick={this.openTemplateSelect}>
              <i className='fa fa-save' /> Select Template
            </button>
          </div>
        </div>
        <div className='editor'>
          <TextArea
            name={'message'}
            onChange={this.onChange}
            maxLength={this.state.maxLength}
            cursorUpdated={this.updateCusorPosition}
            needsFormGroup={false}
            autoResize
            value={text}
            rows={5} />
        </div>
        <button className='btn btn-primary push-20' onClick={this.saveBulkSmsClicked}>
          <i className='fa fa-save' /> Update Content
        </button>
        <SelectTextMessageTemplateModalContainer />
      </ContentBlockView>
    )
  }
}

TextInputView.propTypes = {
  bulkSms: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string,
  updateSmsContent: PropTypes.func.isRequired,
  setText: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxLength: PropTypes.number.isRequired
}

TextInputView.defaultProps = {
  text: ''
}

export default TextInputView
