import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
import { ContentBlockView } from 'modules/components'

import STRINGS from 'strings'
const graphStrings = STRINGS.ViewBulkEmail.Results.Graph
const statusStrings = STRINGS.ViewBulkSharedStrings.Statuses

export class GraphView extends Component {

  formattedChartData = () => {
    const { formattedStatistics } = this.props
    const { delivered, open, bounce, click, complaint, unsubscribe } = formattedStatistics
    return [delivered.count, open.count, click.count, bounce.count, unsubscribe.count, complaint.count]
  }

  render () {
    const chartData = {
      labels: [
        statusStrings.delivered,
        statusStrings.opened,
        statusStrings.clicked,
        statusStrings.bounced, 
        statusStrings.unsubscribe,
        statusStrings.spamCompliant
      ],
      datasets: [
        {
          label: graphStrings.graphTitle,
          backgroundColor: [
            'rgba(92, 144, 210, 0.5)',
            'rgba(70, 195, 123, 0.5)',
            'rgba(68, 180, 166, 0.5)',
            'rgba(210, 106, 92, 0.5)',
            'rgba(255, 107, 107, 0.5)',
            'rgba(0, 0, 0, 0.5)',
          ],
          borderColor: [
            '#5281BC',
            '#3FAF6E',
            '#44b4a6',
            '#BE6053',
            '#ff6b6b',
            'rgba(0, 0, 0, 1)',
          ],
          borderWidth: 1,
          data: this.formattedChartData()
        }
      ]
    }

    return (
      <ContentBlockView title={graphStrings.panelTitle} bordered>
        <Bar data={chartData} options={{ legend: { display: false } }} />
      </ContentBlockView>
    )
  }
}

GraphView.propTypes = {
  formattedStatistics: PropTypes.object.isRequired
}

export default GraphView
