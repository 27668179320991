import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

import { ContentBlockView } from 'modules/components'

export class ComplianceView extends Component {

  render () {
    const { compliance } = this.props
    return (
      <ContentBlockView>
        <div className='row'>
          <div className='col-xs-1'>
            <i className='fa fa-warning fa-5x text-warning' style={{ marginLeft: '20px' }} />
          </div>
          <div className='col-xs-11'>
            <div>
            <h4>{STRINGS.Templates.Email.ComplianceView['errorTitle']}</h4>
              <p>
                {STRINGS.Templates.Email.ComplianceView['errorBody']}
                  <a href='http://support.gymleads.net/' target='__blank'> {STRINGS.Templates.Email.ComplianceView['knowledgeBaseCTA']}.</a>
              </p>
            </div>
            <ul>
              {compliance.subject_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['subjectOk']}</li>
              }
              {compliance.address_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['addressOk']}</li>
              }
              {compliance.unsubscribe_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['unsubscribeOk']}</li>
              }
              {compliance.company_name_ok === false &&
                <li>{STRINGS.Templates.Email.ComplianceView['companyNameOk']}</li>
              }
            </ul>
          </div>
        </div>
      </ContentBlockView>
    )
  }
}

ComplianceView.propTypes = {
  compliance: PropTypes.object.isRequired,
}

export default ComplianceView
