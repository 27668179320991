import RepliesContainer from "./containers/RepliesContainer";
import * as constants from "./constants";
import * as selectors from "./selectors";
import * as actions from "./actions";
import reducer from "./reducer";

export default {
  RepliesContainer,
  constants,
  selectors,
  reducer,
  actions
};
