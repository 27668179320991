import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import TextInputView from '../components/TextInputView'
import loadTemplate from '../../loadTemplate'

import userData from 'modules/stores/userData'
import * as selectors from '../selectors'
import * as actions from '../actions'
import * as settingsSelectors from '../../settings/selectors'
import * as settingsActions from '../../settings/actions'


function mapStateToProps (state) {
  return {
    settingsData: settingsSelectors.getData(state),
    loading: selectors.getLoading(state),
    template: loadTemplate.selectors.getTemplate(state),
    mergeTags: selectors.mergeTagsForSelect(state),
    company: userData.selectors.getCompany(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateSettingsData: settingsActions.updateData,
    updateTemplate: actions.updateTemplate
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextInputView))
