import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'

const SCHEDULES_ACTION_HANDLERS = {
  [actionTypes.PREFIL_DATA_FOR_SCHEDULE]: (state, action) => {
    const fields = generateFieldsForKeys(action.schedule, fieldNames, payloadKeys, null)
    return {
      ...state,
      data: {
        fields: fields,
        isValid: fieldsAreValid(fields)
      }
    }
  },
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_SELECTED_FUNNEL_STEP_ID]: (state, action) => {
    return { ...state, selectedFunnelStepId: action.selectedFunnelStepId }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  }
}

const payloadKeys = {
  daysAllowed: 'days_allowed',
  beforeAfterBetween: 'before_after_between',
  startTime: 'start_time',
  endTime: 'end_time'
}

function initialState () {
  const fields = generateFieldsForKeys(null, fieldNames, payloadKeys, null)
  return {
    loading: false,
    selectedFunnelStepId: null,
    errors: null,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function (state = initialState(), action) {
  const handler = SCHEDULES_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
