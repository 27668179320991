import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";

import { LeadSquareView } from "modules/components";
import { SHOW_STEP_ADD_LEADS_MODAL_VIEW } from "../../stepAddLeads/constants";
import stepLeads from "../../stepLeads";
import STRINGS from "strings";


const AddLeadToFunnelButton = styled.span`
  margin: 0px 10px 0px 0px;
`;
const ViewLeadsInFunnelButton = styled(AddLeadToFunnelButton)``;

const FooterColumnButtons = styled.span`
  padding: 5px 0px 10px 0px;
  width: 'fit-content';
`;

const FooterColumnLeads = styled.span``;

const FooterColumnSubtitle = styled.span`
  display: 'inline';
`;


export class StepAudiencePreviewView extends Component {
  showLeadsModal = () => {
    const {
      setSelectedStepLeadsStepId,
      automationFunnelStep,
      showModal
    } = this.props;
    setSelectedStepLeadsStepId(automationFunnelStep.id);
    showModal(stepLeads.constants.SHOW_STEP_LEADS_MODAL_VIEW);
  };

  showAddLeadsModal = () => {
    const {
      setSelectedStepAddLeadsStepId,
      automationFunnelStep,
      showModal
    } = this.props;
    setSelectedStepAddLeadsStepId(automationFunnelStep.id);
    showModal(SHOW_STEP_ADD_LEADS_MODAL_VIEW);
  };

  render() {
    const { automationFunnelStep } = this.props;
    const sampleLeads = automationFunnelStep.sample_leads;
    return (
      <div>
        <hr />
        {sampleLeads.length > 0 && (
          <div className="step-leads">
          <div className="row">
            <FooterColumnButtons>
              <div className="col-md-3">
                <AddLeadToFunnelButton>
                  <button className='btn btn-success' onClick={this.showAddLeadsModal}>
                    <i className='fa fa-plus' /> {STRINGS.Funnels.Steps.LeadsList.addLeadToStep}
                  </button>
                </AddLeadToFunnelButton>
                
                <ViewLeadsInFunnelButton>
                  <button className='btn btn-primary' onClick={this.showLeadsModal}>
                    {STRINGS.Funnels.Steps.LeadsList.viewAll}
                  </button>
                </ViewLeadsInFunnelButton>
              </div>
            </FooterColumnButtons>

            <FooterColumnLeads>
              <div className="col-md-8"> 
                <div className="group-users">
                  {sampleLeads.map((lead, i) => (
                    <LeadSquareView lead={lead} key={`lead-${lead.id}`} />
                  ))}
                  <p>
                    <span className="step-leads-count-number">
                      {automationFunnelStep.leads_count}
                    </span>{" "}
                    {STRINGS.Funnels.Steps.LeadsList.leads}
                  </p>
                </div>
              </div>
            </FooterColumnLeads>
          </div>
          </div>
        )}
        {sampleLeads.length === 0 &&
          <React.Fragment>
            <AddLeadToFunnelButton>
              <button className='btn btn-success' onClick={this.showAddLeadsModal}>
                <i className='fa fa-plus' /> {STRINGS.Funnels.Steps.LeadsList.addLeadToStep}
              </button>
            </AddLeadToFunnelButton>
            <FooterColumnSubtitle>
              <h5 style={{ display: 'inline' }}>{STRINGS.Funnels.Steps.LeadsList.noLeads}</h5>
            </FooterColumnSubtitle>
          </React.Fragment>
        }
      </div>
    );
  }
}

StepAudiencePreviewView.propTypes = {
  setSelectedStepAddLeadsStepId: PropTypes.func.isRequired,
  setSelectedStepLeadsStepId: PropTypes.func.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
};

export default StepAudiencePreviewView;
