import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import EditPlainTextTemplateView from '../components/EditPlainTextTemplateView'

import loadTemplate from '../../loadTemplate'
import userData from 'modules/stores/userData'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    template: loadTemplate.selectors.getTemplate(state),
    company: userData.selectors.getCompany(state),
    mergeTags: selectors.getMergeTags(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchMergeTags: actions.fetchMergeTags,
    updateTemplate: actions.updateTemplate
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPlainTextTemplateView))
