import PropTypes from 'prop-types'
import React, { Component } from 'react'

import STRINGS from 'strings'
const statusStrings = STRINGS.ViewBulkSharedStrings.Statuses

const journeySteps = [{
  id: 'pending',
  name: statusStrings.pending
}, {
  id: 'delivered',
  name: statusStrings.delivered
}, {
  id: 'link_clicked',
  name: statusStrings.clicked
}]

export class JourneyCell extends Component {

  nameForEventStep (eventStep) {
    switch (eventStep) {
      case 'accept':
      case 'pending':
        return statusStrings.accepted
      case 'delivery':
      case 'delivered':
        return statusStrings.delivered
      case 'open':
      case 'opened':
        return statusStrings.opened
      case 'click':
      case 'link_clicked':
        return statusStrings.clicked
      case 'bounce':
      case 'drop':
      case 'dropped':
      case 'hard_bounced':
      case 'bounced':
        return statusStrings.bounced
      case 'complaint':
      case 'complained':
        return statusStrings.spamCompliant
      default:
        return statusStrings.unknown

    }
  }

  normalSteps = () => {
    const { record } = this.props
    return journeySteps.map((status, index) => {
      const statusIndex = journeySteps.findIndex(status => status.id === record.status)
      let completeClass = 'disabled'
      if (statusIndex === -1) {
        completeClass = 'failed'
      } else if (statusIndex === index) {
        if (status.id === 'link_clicked') {
          completeClass = 'clicked'
        } else {
          completeClass = 'active'
        }
      } else if (statusIndex > index) {
        completeClass = 'complete'
      } else if (statusIndex < index) {
        completeClass = 'disabled'
      }
      return (
        <div className={'col-xs-2 journey-step ' + completeClass} key={index}>
          <div className='progress'>
            <div className='progress-bar' />
          </div>
            <span className='selectable label label-primary journey-label'>
              {this.nameForEventStep(status.id)}
            </span>
        </div>
      )
    })
  }

  render () {
    const { record } = this.props
    let steps
    switch (record.status) {
      case 'dropped':
      case 'hard_bounced':
      case 'bounced':
        steps = [
          <div className='col-xs-2 journey-step complete' key='1'>
                    <div className='progress'>
                      <div className='progress-bar' />
                    </div>
                    <span className='selectable label label-primary journey-label'>
                      {statusStrings.accepted}
                    </span>
                  </div>,
          <div className='col-xs-2 journey-step failed' key='2'>
                  <div className='progress'>
                    <div className='progress-bar' />
                  </div>
                  <span className='selectable label label-primary journey-label'>
                    {statusStrings.bounced}
                  </span>
                </div>]
        break
      default:
        steps = this.normalSteps()
        break
    }

    return (
      <td>
        <div className='hidden-xs journey row'>
          {steps}
        </div>
        <div className='visible-xs'>
          <span>{this.nameForEventStep(record.status)}</span>
        </div>
      </td>
    )
  }
}

JourneyCell.propTypes = {
  record: PropTypes.object.isRequired
}

export default JourneyCell
