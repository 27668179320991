import * as actionTypes from "./actionTypes";

const USER_DATA_ACTION_HANDLERS = {
  [actionTypes.LOADING_STARTED]: (state, action) => {
    return { ...state, loading: true };
  },
  [actionTypes.LOADING_STOPPED]: (state, action) => {
    return { ...state, loading: false };
  },
  [actionTypes.COMPANY_DATA_FETCH_SUCESS]: (state, action) => {
    return { ...state, company: action.company };
  },
  [actionTypes.SALES_FUNNEL_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      salesFunnel: salesFunnelWithSortedSteps(action.salesFunnel)
    };
  },
  [actionTypes.USER_ROLES_FETCH_SUCCESS]: (state, action) => {
    return { ...state, userRoles: action.userRoles };
  },
  [actionTypes.SET_REGIONS]: (state, action) => {
    return { ...state, regions: action.regions };
  },
  [actionTypes.SET_SCHEDULES]: (state, action) => {
    return { ...state, schedules: action.schedules };
  }
};

const initialState = {
  loading: false,
  regions: [],
  company: {},
  salesFunnel: {},
  userRoles: [],
  schedules: []
};

export default function reducer(state = initialState, action) {
  const handler = USER_DATA_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

function salesFunnelWithSortedSteps(salesFunnelSteps) {
  return {
    ...salesFunnelSteps,
    steps: salesFunnelSteps.steps.sort(function(a, b) {
      return a.step_number - b.step_number;
    })
  };
}
