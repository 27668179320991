import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

// FETCH OPTIONS
export const getTagOptions = () => {
  return dispatch => {
    dispatch(startFetchingOptions())
    function onFailure (errors) {
      dispatch(fetchingOptionsFailed(errors))
    }

    function onSuccess (payload) {
      dispatch(fetchingOptionsSuccess(payload.lead_tags))
    }

    return request('common_tags', GET, null, onSuccess, onFailure)
  }
}

function startFetchingOptions () {
  return {
    type: actionTypes.FETCHING_OPTIONS
  }
}

function fetchingOptionsSuccess (options) {
  return {
    type: actionTypes.FETCHING_OPTIONS_SUCCESS,
    options
  }
}

function fetchingOptionsFailed (errors) {
  return {
    type: actionTypes.FETCHING_OPTIONS_FAILED,
    errors
  }
}