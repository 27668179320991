import PropTypes from 'prop-types'
import React, { Component } from 'react'

import bulkMessaging from 'containers/bulkMessaging'
import alert from 'modules/components/alert'

import checklist from '../../checklist'
import audience from '../../audience'
import content from '../../content'
import settings from '../../settings'

import { ContentBlockView, NavLink, InfoView } from 'modules/components'

import STRINGS from "strings";
const headerStrings = STRINGS.BulkMessaging.BulkEmail.Header;
export class HeaderView extends Component {

  backSelected = () => {
    const { showWithConfig, hasUnsavedChanges } = this.props
    if (hasUnsavedChanges) {
      showWithConfig({
        ...alert.defaultConfig,
        children: headerStrings.unsavedChangesWarning,
        style: 'warning',
        onConfirm: this.goBack
      })
    } else {
      this.goBack()
    }
  }

  goBack = () => {
    this.props.history.push('/secure/' + bulkMessaging.constants.NAME)
  }

  render () {
    const { bulkEmail, currentPath, isLegacy } = this.props
    const baseLink = '/secure/bulkMessaging/editBulkEmail/' + bulkEmail.id
    return (
      <ContentBlockView>
        <div className='row'>
          <div className='col-md-6'>
            <i className='si si-arrow-left fa-2x pull-left selectable'
              style={{ marginTop: '5px', marginRight: '20px' }}
              onClick={this.backSelected} />
            <h2 className='push'>
              {bulkEmail.title || headerStrings.unnamedEmail}
            </h2>
          </div>
          {bulkEmail && bulkEmail.communication_type === 'transactional' &&
            <div className='col-md-6'>
              <InfoView colorThemeClass={'info'}>
                <p>{headerStrings.transactionalEmail}</p>
                <a href='http://support.gymleads.net/gymleads-marketing-app/transactional-vs-marketing-communication'>{headerStrings.learnMore}</a>
              </InfoView>
            </div>
          }
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='block'>
              <ul className='nav nav-tabs nav-tabs-alt'>
                <NavLink
                  to={baseLink + '/' + checklist.constants.NAME}
                  currentPath={currentPath}>
                  <i className='fa fa-list-ol' /> {headerStrings.Menu.checkSend}
                </NavLink>
                {!isLegacy &&
                  <NavLink
                    to={baseLink + '/' + content.constants.NAME}
                    currentPath={currentPath}>
                    <i className='fa fa-newspaper-o' /> {headerStrings.Menu.content}
                  </NavLink>
                }
                {!isLegacy &&
                  <NavLink
                    to={baseLink + '/' + audience.constants.NAME}
                    currentPath={currentPath}>
                    <i className='fa fa-users' /> {headerStrings.Menu.audience}
                  </NavLink>
                }
                <NavLink
                  to={baseLink + '/' + settings.constants.NAME}
                  currentPath={currentPath}>
                  <i className='si si-settings' /> {headerStrings.Menu.settings}
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
      </ContentBlockView>
    )
  }
}

HeaderView.propTypes = {
  bulkEmail: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  showWithConfig: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
  isLegacy: PropTypes.bool.isRequired
}

export default HeaderView
