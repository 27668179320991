import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ChecklistView from '../components/ChecklistView'
import * as selectors from '../selectors'
import * as actions from '../actions'
import loadAutomationFunnel from '../../loadAutomationFunnel'
import userData from 'modules/stores/userData'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    company: userData.selectors.getCompany(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getChecklist: actions.getChecklist
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistView))
