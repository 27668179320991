import { createSelector } from "reselect";
import { NAME } from "./constants";

export const getErrors = state => state.bulkMessaging.bulkEmails[NAME].errors;
export const getLoading = state => state.bulkMessaging.bulkEmails[NAME].loading;
export const getDesigns = state => state.bulkMessaging.bulkEmails[NAME].designs;
export const getTemplates = state =>
  state.bulkMessaging.bulkEmails[NAME].templates;
export const getPageDetails = state =>
  state.bulkMessaging.bulkEmails[NAME].pageDetails;
export const getSearchTerm = state =>
  state.bulkMessaging.bulkEmails[NAME].searchTerm;
export const getSortOrder = state =>
  state.bulkMessaging.bulkEmails[NAME].sortOrder;

export const featuredDesigns = createSelector(
  [getDesigns],
  designs => {
    return designs.filter(design => design.design_type === "featured");
  }
);

export const basicDesigns = createSelector(
  [getDesigns],
  designs => {
    return designs.filter(design => design.design_type === "basic");
  }
);
