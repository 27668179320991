import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import NewGroupModalView from '../components/NewGroupModalView'
import * as actions from '../actions'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createGroup: actions.createGroup
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewGroupModalView))
