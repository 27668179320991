import { NAME } from "./constants";

export const SET_LOADING = NAME + "/SET_LOADING";
export const SET_ERRORS = NAME + "/SET_ERRORS";
export const SET_DESIGNS = NAME + "/SET_DESIGNS";
export const SET_TEMPLATES = NAME + "/SET_TEMPLATES";
export const SET_DESIGNS_SORT_ORDER = NAME + "/SET_DESIGNS_SORT_ORDER";
export const SET_DESIGNS_SEARCH_TERM = NAME + "/SET_DESIGNS_SEARCH_TERM";
export const SET_TEMPLATES_SORT_ORDER = NAME + "/SET_TEMPLATES_SORT_ORDER";
export const SET_TEMPLATES_SEARCH_TERM = NAME + "/SET_TEMPLATES_SEARCH_TERM";
export const RESET_SORT_SEARCH = NAME + "/RESET_SORT_SEARCH";

export const SET_PRIVATE_RECIPE = NAME + "/SET_PRIVATE_RECIPE";

export const PAGE_DETAILS_UPDATED = NAME + "/PAGE_DETAILS_UPDATED";
