import PropTypes from 'prop-types'
import React, { Component } from 'react'

import checklist from '../modules/checklist'
import content from '../modules/content'
import createNewTemplate from '../modules/createNewTemplate'
import loadAutomationTextMessage from '../modules/loadAutomationTextMessage'
import settings from '../modules/settings'

import header from '../modules/header'

export class SmsView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  currentView = () => {
    const { currentView } = this.props
    switch (currentView) {
      case header.constants.CHECKLIST_VIEW:
        return <checklist.ChecklistContainer />
      case header.constants.EDIT_VIEW:
        return <content.ContentContainer />
      case header.constants.SETTINGS_VIEW:
        return <settings.SettingsContainer />
      default:
        return <div />
    }
  }

  render () {
    const { loadingAutomationTextMessage, automationTextMessage } = this.props
    return (
      <div>
        {loadingAutomationTextMessage &&
          <loadAutomationTextMessage.LoadAutomationTextMessageContainer />
        }
        {!loadingAutomationTextMessage && !automationTextMessage &&
          <createNewTemplate.CreateNewTemplateContainer />
        }
        {!loadingAutomationTextMessage && automationTextMessage &&
          this.currentView()
        }
      </div>
    )
  }
}

SmsView.propTypes = {
  loadingAutomationTextMessage: PropTypes.bool.isRequired,
  automationTextMessage: PropTypes.object,
  currentView: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired
}


export default SmsView
