import PropTypes from "prop-types";
import React, { Component } from "react";
import { numberWithCommas } from "modules/helpers/FormatHelpers";

import CountView from "./CountView";
import "./styles.css";

import STRINGS from "strings";
const metricsStrings = STRINGS.ViewBulkSms.Results.Metrics;
const statusStrings = STRINGS.ViewBulkSharedStrings.Statuses;

export class MetricsView extends Component {
  render() {
    const { formattedStatistics } = this.props;
    const { total, delivered, bounce, click, pending } = formattedStatistics;
    return (
      <div className="row">
        <div className="col-sm-6 col-lg-2 col-lg-offset-2">
          <div className="block text-center block-bordered">
            <div className="font-w600 count-type">{metricsStrings.title}</div>
            <div
              className="block-content block-content-full"
              style={{ paddingTop: "10px" }}
            >
              <h4 style={{ paddingBottom: "20px" }}>
                {numberWithCommas(total.count)}
              </h4>
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-sm-4 col-lg-2">
          <CountView
            title={statusStrings.pending}
            percentage={pending.percentage}
            count={pending.count}
            textClass={"info"}
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-lg-2">
          <CountView
            title={statusStrings.delivered}
            percentage={delivered.percentage}
            count={delivered.count}
            textClass={"primary"}
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-lg-2">
          <CountView
            title={statusStrings.clicked}
            percentage={click.percentage}
            count={click.count}
            textClass={"flat"}
          />
        </div>
        <div className="col-xs-6 col-sm-4 col-lg-2">
          <CountView
            title={statusStrings.bounced}
            percentage={bounce.percentage}
            count={bounce.count}
            textClass={"danger"}
          />
        </div>
      </div>
    );
  }
}

MetricsView.propTypes = {
  formattedStatistics: PropTypes.object.isRequired,
};

export default MetricsView;
