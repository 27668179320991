import * as actionTypes from './actionTypes'

export const prefillDataForBulkSms = (bulkSms) => {
  return (dispatch) => {
    if (bulkSms.query_body) {
      const rules = (bulkSms.query_body) ? bulkSms.query_body.rules : []
      dispatch(setCondition(bulkSms.query_body.condition))
      dispatch(setRules(rules))
    }
    dispatch(setSelectedGroup(bulkSms.marketing_group))
  }
}

function setCondition (condition) {
  return {
    type: actionTypes.SET_CONDITION,
    condition
  }
}

function setRules (rules) {
  return {
    type: actionTypes.SET_RULES,
    rules
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setSelectedGroup (selectedGroup) {
  return {
    type: actionTypes.SET_SELECTED_GROUP,
    selectedGroup
  }
}
