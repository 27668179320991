import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

import { ContentBlockView, EmailEditor, ComplianceView } from 'modules/components'

export class ContentView extends Component {

  componentWillMount() {
    this.props.setHasChanges(true)
  }

  saveContentClicked = () => {
    const { automationEmail, automationFunnel, automationFunnelStep, updateEmailContent } = this.props
    window.unlayer.exportHtml(data => {
      const { design, html } = data
      updateEmailContent(automationFunnel.id, automationFunnelStep.id, automationEmail.id, html, design)
    })
  }

  render () {
    const { automationEmail, mergeTags } = this.props
    const { compliance } = automationEmail.checklist
    const emailStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email

    return (
      <div>
        {!compliance.is_valid &&
          <ComplianceView compliance={compliance} />
        }
        <ContentBlockView title={'Edit Email'} bordered style={{ padding: '0px' }}>
          <EmailEditor designJson={automationEmail.design_json} mergeTags={mergeTags} />
          <button className='btn btn-primary push-20-t push-20 push-20-l'
            onClick={this.saveContentClicked}>
            <i className='fa fa-save' /> {emailStrings.saveEmailButton}
          </button>
        </ContentBlockView>
      </div>
    )
  }
}

ContentView.propTypes = {
  loading: PropTypes.bool.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  automationEmail: PropTypes.object.isRequired,
  updateEmailContent: PropTypes.func.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  automationFunnel: PropTypes.object.isRequired,
  automationFunnelStep: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default ContentView
