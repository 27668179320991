import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ContentView from '../components/ContentView'
import * as selectors from '../selectors'
import * as actions from '../actions'
import loadBulkEmail from '../../loadBulkEmail'
import mergeTags from 'modules/stores/mergeTags'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    mergeTags: mergeTags.selectors.getMergeTags(state),
    bulkEmail: loadBulkEmail.selectors.getBulkEmail(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setHasChanges: actions.setHasChanges,
    updateEmailContent: actions.updateEmailContent
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentView))
