import config from "config";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import moment from "moment-timezone";

import STRINGS from "strings";

const EmailReplyPreview = ({ reply, leadId, leadName, timezone }) => {
  const goToLead = () => {
    window.open(
      `${config.mainHost}/Secure/manageLeads?lead_id=${leadId}`,
      "_blank"
    );
  };

  const { email_text, safe_html, email_date } = reply;
  const formattedDate =
    email_date &&
    moment(email_date)
      .tz(timezone)
      .format("Do MMM YYYY, h:mm a");

  return (
    <div>
      <LeadLink onClick={goToLead}>
        <strong>{STRINGS.Global.from}:</strong>
        {` ${leadName}`}
      </LeadLink>
      {formattedDate && (
        <LineListing>
          <strong>{STRINGS.Global.date}:</strong>
          {` ${formattedDate}`}
        </LineListing>
      )}
      <EmailBody>
        {!safe_html && <p>{email_text}</p>}
        {safe_html && (
          <div style={{ pointerEvents: "none" }}>
            <div dangerouslySetInnerHTML={{ __html: safe_html }} />
          </div>
        )}
      </EmailBody>
    </div>
  );
};

EmailReplyPreview.propTypes = {
  reply: PropTypes.PropTypes.object.isRequired,
  leadName: PropTypes.string.isRequired,
  leadId: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired
};

export default EmailReplyPreview;

const LineListing = styled.p`
  margin-bottom: 5px !important;
`;

const LeadLink = styled.p`
  margin-bottom: 5px !important;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #5c90d2;
  }
`;

const EmailBody = styled.div`
  margin-top: 10px !important;
  min-height: 160px;
`;
