import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ContentView from '../components/ContentView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import loadAutomationEmail from '../../loadAutomationEmail'
import loadAutomationFunnel from 'containers/editAutomationFunnel/modules/loadAutomationFunnel'

import mergeTags from 'modules/stores/mergeTags'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    mergeTags: mergeTags.selectors.getMergeTags(state),
    automationEmail: loadAutomationEmail.selectors.getAutomationEmail(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: loadAutomationEmail.selectors.currentFunnelStep(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateEmailContent: actions.updateEmailContent,
    setHasChanges: actions.setHasChanges
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentView))
