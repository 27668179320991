import PropTypes from "prop-types";
import React, { Component } from "react";

import { MultiLeadSourceSelect } from "modules/components";
import Button from "@atlaskit/button";

const SELECT_LEAD_SOURCES_MODAL = "LEAD_SOURCE_ENTRY/SELECT_LEAD_SOURCES_MODAL";

class LeadSourceEntryView extends Component {
  constructor(props) {
    super(props);
    if (props.data) {
      switch (typeof props.data.value) {
        case "number":
          this.state = { value: [props.data.value] };
          break;
        case "object":
          this.state = { value: props.data.value };
          break;
        case "string":
          this.state = {
            value: props.data.value
              .toString()
              .split(",")
              .map(i => parseInt(i, 10))
          };
          break;
        default:
          this.state = { value: [] };
          break;
      }
    } else {
      this.state = {
        value: []
      };
    }
  }

  updateLeadSourceIds = selectedLeadSourceIds => {
    this.setState({ ...this.state, value: selectedLeadSourceIds });
    this.updateWithResult({ value: selectedLeadSourceIds });
  };

  updateWithResult = result => {
    const { id, ruleUpdated } = this.props;
    ruleUpdated(id, result);
  };

  showSelectLeadSourcesModal = () => {
    const { showModal } = this.props;
    showModal(SELECT_LEAD_SOURCES_MODAL);
  };

  buttonStringTitle = () => {
    const { data } = this.props;
    const selectedLength = data ? data.value.length : 0;
    if (selectedLength === 0) {
      return "Select a lead source";
    } else {
      return selectedLength + " selected";
    }
  };

  render() {
    const { readonly } = this.props;
    const title = readonly ? "Lead sources Used" : "Select Lead sources";
    return (
      <div style={{ display: "inline" }}>
        <span style={{ display: "inline-block" }}>
          <Button
            appearance="default"
            onClick={this.showSelectLeadSourcesModal}
          >
            {this.buttonStringTitle()}
          </Button>
        </span>
        <span style={{ display: "inline-block" }}>
          <MultiLeadSourceSelect.MultiLeadSourceSelectModalContainer
            readonly={readonly}
            title={title}
            modalName={SELECT_LEAD_SOURCES_MODAL}
            leadSourceIds={this.state.value}
            onUpdate={this.updateLeadSourceIds}
          />
        </span>
      </div>
    );
  }
}

LeadSourceEntryView.propTypes = {
  id: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  ruleUpdated: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  data: PropTypes.object
};

LeadSourceEntryView.defaultProps = {
  readonly: false
};

export default LeadSourceEntryView;
