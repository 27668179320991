import { apiClient, buildIndexUrl } from "utils/apiClient";

export const fetchLocations = async (page = 1, sort = null, search = null) => {
  let url = buildIndexUrl("locations", page, sort, search);
  try {
    const response = await apiClient().get(url);
    return response.data;
  } catch (error) {
    return error.data;
  }
};

export const getLocation = async (id) => {
  try {
    const response = await apiClient().get(`locations/${id}`);
    return response?.data?.location;
  } catch (error) {
    return error.data;
  }
};
