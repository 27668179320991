import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import TextInputView from '../components/TextInputView'
import loadAutomationTextMessage from '../../loadAutomationTextMessage'
import loadAutomationFunnel from 'containers/editAutomationFunnel/modules/loadAutomationFunnel'

import userData from 'modules/stores/userData'
import mergeTags from 'modules/stores/mergeTags'
import modal from 'modules/stores/modal'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    automationTextMessage: loadAutomationTextMessage.selectors.getAutomationTextMessage(state),
    mergeTags: mergeTags.selectors.mergeTagsForSelect(state),
    text: selectors.getText(state),
    company: userData.selectors.getCompany(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: loadAutomationTextMessage.selectors.currentFunnelStep(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setText: actions.setText,
    showModal: modal.actions.showModal,
    updateSmsContent: actions.updateSmsContent
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextInputView))
