import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import AudienceRecordsView from '../components/AudienceRecordsView'
import * as actions from '../actions'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    audienceRecords: selectors.getAudienceRecords(state),
    loading: selectors.getLoading(state),

  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getAudienceRecordsForBulkSms: actions.getAudienceRecordsForBulkSms
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AudienceRecordsView))
