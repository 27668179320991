import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import SearchForLeadModalView from '../components/SearchForLeadModalView'
import * as actions from '../actions'
import * as selectors from '../selectors'

import loadBulkSms from '../../loadBulkSms'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state),
    bulkSms: loadBulkSms.selectors.getBulkSms(state),
    searchText: selectors.getSearchText(state),
    searchResults: selectors.getSearchResults(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setSearchText: actions.setSearchText,
    setPreviewResult: actions.setPreviewResult,
    searchForLeads: actions.searchForLeads,
    getPreviewHtml: actions.getPreviewHtml,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForLeadModalView))
