import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AlertContainer from 'modules/components/alert/containers/AlertContainer'
import FlagsContainer from 'modules/components/flags/containers/FlagsContainer'

import { PrivateRouteContainer } from 'modules/components'

import home from 'containers/home'
import dashboard from 'containers/dashboard'
import groups from 'containers/groups'
import templates from 'containers/templates'
import automations from 'containers/automations'
import editAutomationFunnel from 'containers/editAutomationFunnel'
import editGroup from 'containers/editGroup'
import editEmailTemplate from 'containers/editEmailTemplate'
import editSmsTemplate from 'containers/editSmsTemplate'
import bulkMessaging from 'containers/bulkMessaging'
import editBulkSms from 'containers/editBulkSms'
import viewBulkSms from 'containers/viewBulkSms'
import editBulkEmail from 'containers/editBulkEmail'
import viewBulkEmail from 'containers/viewBulkEmail'

import * as analyticActions from 'modules/helpers/Analytics'

export class SecureView extends Component {

  componentWillMount () {
    const { loadInitialStateForCurrentUser, clearAttemptedUrl } = this.props
    loadInitialStateForCurrentUser()
    clearAttemptedUrl()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.loadingComplete === false &&
        nextProps.loadingComplete === true) {
      analyticActions.startUserSession()
    }
  }

  render () {
    const { loadingComplete, sessionLoading } = this.props
    const showSecureView = (loadingComplete === true && sessionLoading === false)
    return (
      <div>
        {showSecureView &&
          <div>
            <PrivateRouteContainer exact path="/secure/dashboard" component={dashboard.DashboardContainer} />
            <PrivateRouteContainer exact path="/secure/home" component={home.HomeContainer} />
            <PrivateRouteContainer exact path="/secure/automation" component={automations.AutomationsContainer} />
            <PrivateRouteContainer path="/secure/automation/funnel/:id" component={editAutomationFunnel.EditAutomationFunnelContainer} />
            <PrivateRouteContainer exact path="/secure/groups" component={groups.GroupsContainer} />
            <PrivateRouteContainer path="/secure/groups/:id" component={editGroup.EditGroupContainer} />
            <PrivateRouteContainer exact path="/secure/templates" component={templates.TemplatesContainer} />
            <PrivateRouteContainer path="/secure/templates/email/:id" component={editEmailTemplate.EditTemplateContainer} />
            <PrivateRouteContainer path="/secure/templates/sms/:id" component={editSmsTemplate.EditTemplateContainer} />
            <PrivateRouteContainer exact path={"/secure/bulkMessaging"} component={bulkMessaging.BulkMessagingContainer} />
            <PrivateRouteContainer path={"/secure/bulkMessaging/editBulkSms/:id"} component={editBulkSms.EditBulkSmsContainer} />
            <PrivateRouteContainer path={"/secure/bulkMessaging/viewBulkSms/:id"} component={viewBulkSms.ViewBulkSmsContainer} />
            <PrivateRouteContainer path={"/secure/bulkMessaging/editBulkEmail/:id"} component={editBulkEmail.EditBulkEmailContainer} />
            <PrivateRouteContainer path={"/secure/bulkMessaging/viewBulkEmail/:id"} component={viewBulkEmail.ViewBulkEmailContainer} />
            <AlertContainer />
            <FlagsContainer />
          </div>
        }
        {!showSecureView &&
          <div id='page-loader' style={{ display: 'block' }} />
        }
      </div>
    )
  }
}

SecureView.propTypes = {
  loadingComplete: PropTypes.bool.isRequired,
  sessionLoading: PropTypes.bool.isRequired,
  loadInitialStateForCurrentUser: PropTypes.func.isRequired,
  clearAttemptedUrl: PropTypes.func.isRequired
}

export default SecureView
