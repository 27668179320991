import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import CreateNewTemplateView from '../components/CreateNewTemplateView'
import * as selectors from '../selectors'
import * as actions from '../actions'
import loadAutomationFunnel from 'containers/editAutomationFunnel/modules/loadAutomationFunnel'
import loadAutomationTextMessage from '../../loadAutomationTextMessage'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: loadAutomationTextMessage.selectors.currentFunnelStep(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    create: actions.create
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewTemplateView))
