import PropTypes from "prop-types";
import React, { Component } from "react";
import { SelectLeadSourceTableRow } from "./SelectLeadSourceTableRow";

export class SelectLeadSourceTableView extends Component {
  render() {
    const {
      leadSources,
      readonly,
      selectedLeadSourceIds,
      selectLeadSource,
      deselectLeadSource
    } = this.props;
    const rows = leadSources.map((leadSource, index) => {
      const selectedIndex = selectedLeadSourceIds.findIndex(
        selectedLeadSourceId => leadSource.id === selectedLeadSourceId
      );
      const selected = selectedIndex >= 0;
      return (
        <SelectLeadSourceTableRow
          key={index}
          leadSource={leadSource}
          leadSourceSelected={selected}
          selectLeadSource={selectLeadSource}
          deselectLeadSource={deselectLeadSource}
          readonly={readonly}
        />
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-vcenter table-hover">
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

SelectLeadSourceTableView.propTypes = {
  leadSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLeadSourceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectLeadSource: PropTypes.func.isRequired,
  deselectLeadSource: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired
};

SelectLeadSourceTableView.defaultProps = {
  readonly: false
};

export default SelectLeadSourceTableView;
