import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'
import ActiveLeadTableRow from './ActiveLeadTableRow'

export class CompletedLeadsTableView extends Component {

  render () {
    const { leads } = this.props
    return (
      <table className='table table-hover table-vcenter'>
        <thead>
          <tr>
            <th>{STRINGS.Global.name}</th>
            <th>{STRINGS.Global.status}</th>
            <th>{STRINGS.Global.location}</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead, index) => {
            return <ActiveLeadTableRow lead={lead} key={index} />
          })}
        </tbody>
      </table>

    )
  }
}

CompletedLeadsTableView.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default CompletedLeadsTableView
