import { request, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

import flags from "modules/components/flags";
import modal from "modules/stores/modal";
import loadAutomationTextMessage from "../loadAutomationTextMessage";
import STRINGS from 'strings'
const smsChecklistStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Checklist

export const sendPreview = (
  automationFunnelId,
  automationFunnelStepId,
  automationTextMessageId,
  previewNumber
) => {
  return dispatch => {
    dispatch(setLoading(true));

    const body = {
      send_preview_number: previewNumber
    };

    function onFailure(payload) {
      dispatch(modal.actions.showErrors(payload.errors));
      dispatch(setLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(
        loadAutomationTextMessage.actions.setAutomationTextMessage(
          payload.automation_text_message_template
        )
      );
      dispatch(modal.actions.hideModal());
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: smsChecklistStrings.previewSendingNotification,
          index: 0
        })
      );
    }

    const url =
      "automation_funnels/" +
      automationFunnelId +
      "/automation_funnel_steps/" +
      automationFunnelStepId +
      "/automation_text_message_templates/" +
      automationTextMessageId +
      "/preview";
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

export function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}
