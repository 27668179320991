import { request, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { push } from 'react-router-redux'

import flags from 'modules/components/flags'
import modal from 'modules/stores/modal'
import loadBulkSms from '../loadBulkSms'

import STRINGS from 'strings'
const checklistStrings = STRINGS.BulkMessaging.BulkSms.Checklist

export const sendPreview = (bulkSms, previewNumber) => {
  return dispatch => {
    dispatch(setLoading(true))

    const body = {
      send_preview_number: previewNumber
    }

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadBulkSms.actions.setBulkSms(payload.mass_lead_text_message))
      dispatch(modal.actions.hideModal())
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: checklistStrings.previewSendingNotification,
        index: 0
      }))
    }

    const url = 'mass_lead_text_messages/' + bulkSms.id + '/preview'
    return request(url, POST, body, onSuccess, onFailure)
  }
}

export const startSend = (bulkSms) => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(push('/secure/bulkMessaging/viewBulkSms/' + bulkSms.id + '/results'))
    }

    const url = 'mass_lead_text_messages/' + bulkSms.id + '/send'
    return request(url, POST, null, onSuccess, onFailure)
  }
}

export const cancelScheduledSend = (bulkSms) => {
  return dispatch => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setErrors(payload.errors))
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadBulkSms.actions.setBulkSms(payload.mass_lead_text_message))
      dispatch(modal.actions.hideModal())
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: checklistStrings.cancelScheduleSmsNotification,
        index: 0
      }))
    }

    const url = 'mass_lead_text_messages/' + bulkSms.id + '/cancel_schedule'
    return request(url, POST, null, onSuccess, onFailure)
  }
}

export const scheduleSend = (bulkSms, scheduleTime) => {
  return dispatch => {
    dispatch(modal.actions.startLoading())
    const body = {
      schedule_send_time: scheduleTime
    }

    function onFailure (payload) {
      dispatch(modal.actions.setErrors(payload.errors))
      dispatch(modal.actions.stopLoading())
    }

    function onSuccess (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(loadBulkSms.actions.setBulkSms(payload.mass_lead_text_message))
      dispatch(modal.actions.hideModal())
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: checklistStrings.setScheduleSmsNotification,
        index: 0
      }))
    }

    const url = 'mass_lead_text_messages/' + bulkSms.id + '/schedule'
    return request(url, POST, body, onSuccess, onFailure)
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
