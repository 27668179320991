import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import AutomationTextMessageDetailsView from '../components/AutomationTextMessageDetailsView'
import loadAutomationTextMessage from '../../loadAutomationTextMessage'
import billing from 'modules/stores/billing'
import { getProductName } from 'modules/stores/userData/selectors'

function mapStateToProps (state) {
  const getDefaultSmsCost = billing.selectors.getDefaultSmsCost(state)
  const defaultCost = (getDefaultSmsCost) ? getDefaultSmsCost.marketing_outbound_cost : 0.1
  return {
    automationTextMessage: loadAutomationTextMessage.selectors.getAutomationTextMessage(state),
    messageCost: defaultCost,
    productName: getProductName(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutomationTextMessageDetailsView))
