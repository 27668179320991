import PropTypes from "prop-types";
import React, { Component } from "react";

import settings from "../../settings";

import STRINGS from "strings";
const checklistFromRowStrings =
  STRINGS.BulkMessaging.BulkEmail.Checklist.FromRow;

export class FromRow extends Component {
  goToSendSettings = () => {
    this.props.history.push(settings.constants.NAME);
  };

  appearAsString = () => {
    const { bulkEmail, company } = this.props;
    const fromName = bulkEmail.from_name ? (
      bulkEmail.from_name
    ) : (
      <span>
        {bulkEmail.user.display_name} from {company.user_display_name}
      </span>
    );
    return fromName;
  };

  render() {
    const { bulkEmail, productName } = this.props;
    const icon = "fa fa-check-circle fa-2x pull-left text-success";
    return (
      <div className="checklist-row-top">
        <i className={icon} />
        <h4>{checklistFromRowStrings.from}</h4>
        <p>
          {!bulkEmail.from_address &&
            STRINGS.formatString(
              checklistFromRowStrings.yourUsingProductEmailMessage,
              <b>{productName}</b>
            )}
          {bulkEmail.from_address &&
            STRINGS.formatString(
              checklistFromRowStrings.yourUsingEmailMessage,
              <b>{bulkEmail.from_address}</b>
            )}{" "}
          {STRINGS.formatString(
            checklistFromRowStrings.itWillAppearMessage,
            <b>{this.appearAsString()}</b>
          )}
        </p>
      </div>
    );
  }
}

FromRow.propTypes = {
  history: PropTypes.object.isRequired,
  bulkEmail: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
};

export default FromRow;
