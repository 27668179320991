import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AlertView from '../components/AlertView'

import * as selectors from '../selectors'
import * as actions from '../actions'

const mapStateToProps = (state) => {
  return {
    showing: selectors.getShowing(state),
    config: selectors.getConfig(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setShowing: actions.setShowing
  },
    dispatch)
}

export default connect(mapStateToProps ,mapDispatchToProps)(AlertView)
