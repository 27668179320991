import PropTypes from 'prop-types'
import React, { Component } from 'react'

import PreviewModalContainer from '../containers/PreviewModalContainer'
import { SEND_PREVIEW_MODAL } from '../constants'
import STRINGS from 'strings'

const smsChecklistStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.SMS.Checklist
export class PreviewRow extends Component {

  showPreviewModal = () => {
    const { showModal } = this.props
    showModal(SEND_PREVIEW_MODAL)
  }

  render () {
    const { automationTextMessage } = this.props
    const checklist = automationTextMessage.checklist
    const icon = (!checklist.preview_sent) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        <button
          className='pull-right btn btn-primary'
          disabled={!checklist.ready_to_send_preview}
          onClick={this.showPreviewModal}>
          {smsChecklistStrings.sendPreviewModalButton}
        </button>
        <i className={icon} />
        <h4>{smsChecklistStrings.sendPreviewRowHeadline}</h4>
        {checklist.ready_to_send_preview &&
          <div>
            {automationTextMessage.preview_sent_to &&
              <p>{STRINGS.formatString(smsChecklistStrings.previewSentTo, <b>{automationTextMessage.preview_sent_to}</b>)}.</p>
            }
            {!automationTextMessage.preview_sent_to &&
              <p>{smsChecklistStrings.previewNotSent}</p>
            }
          </div>
        }
        {!checklist.ready_to_send_preview &&
          <p>{smsChecklistStrings.checklistIncomplete}</p>
        }
        <PreviewModalContainer />
      </div>
    )
  }
}

PreviewRow.SubjectRow = {
  automationTextMessage: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
}

export default PreviewRow
