import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { GymLeadsLoader } from 'modules/components'

import STRINGS from "strings";

export class LoadTemplateView extends Component {

  componentDidMount () {
    const { getBulkSms, match } = this.props
    getBulkSms(match.params.id)
  }

  render () {
    return <GymLeadsLoader text={STRINGS.Global.loading} active />
  }
}

LoadTemplateView.propTypes = {
  getBulkSms: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  errors: PropTypes.object
}

export default LoadTemplateView
