import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getErrors = state => state.viewBulkEmail[NAME].errors
export const getLoading = state => state.viewBulkEmail[NAME].loading
export const getBulkEmail = state => state.viewBulkEmail[NAME].bulkEmail
export const getNotFound = state => state.viewBulkEmail[NAME].notFound

export const getChecklist = createSelector(
  [ getBulkEmail ],
  (bulkEmail) => {
    return bulkEmail.checklist
  }
)

export const getDesignJson = createSelector(
  [ getBulkEmail ],
  (bulkEmail) => {
    return bulkEmail.design_json
  }
)
