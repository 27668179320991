import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import CompletedLeadsView from '../components/CompletedLeadsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    joinRows: selectors.getJoinRows(state),
    loading: selectors.getLoading(state),
    pageDetails: selectors.getPageDetails(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    get: actions.getLeads
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompletedLeadsView))
