import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'
import { FormFields, ModalContainer } from 'modules/components'
import { NEW_BULK_SMS_MODAL } from '../constants'

const { TextField } = FormFields
const newSmsModalStrings = STRINGS.BulkMessaging.NewBulkSMSModal

export class NewBulkSmsModalView extends Component {

  state = {
    name: null
  }

  handleSubmit = (event) => {
    const { createBulkSms } = this.props
    event && event.preventDefault()
    createBulkSms(this.state.name)
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value })
  }

  isDisabled = () => {
    return !this.state.name
  }

  render () {
    const { loading } = this.props
    return (
      <ModalContainer
        modalName={NEW_BULK_SMS_MODAL}
        modalTitle={newSmsModalStrings.createNewBulkSMS}
        colorThemeClass={'success'}
        loading={loading}
        loadingText={newSmsModalStrings.creatingBulkSMS}
        showCancelButton
        actionEnabled={!this.isDisabled()}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={
          <span><i className='fa fa-plus' /> {newSmsModalStrings.headline}</span>
        }>
        <p>{newSmsModalStrings.subheadline}</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}
          style={{
            marginLeft: '10px',
            marginRight: '10px'
          }}>
          <TextField
            name='name'
            label=''
            placeholder={newSmsModalStrings.textFieldPlaceholder}
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput} />
        </form>
      </ModalContainer>
    )
  }
}

NewBulkSmsModalView.propTypes = {
  createBulkSms: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default NewBulkSmsModalView
