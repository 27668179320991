import { request, buildIndexUrl, GET, DELETE } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import flags from 'modules/components/flags'
import STRINGS from 'strings'
export const getLeads = (automationFunnelId, pageNumber, search, sort) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setLeads(payload.automation_funnels_completed_leads))
    }

    const baseUrl = 'automation_funnels/' + automationFunnelId + '/automation_funnels_completed_leads'

    const url = buildIndexUrl(baseUrl, pageNumber, sort, search)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export const deleteRecord = (automationFunnelId, joinId) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(removeJoinRowWithId(joinId))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.CompletedLeads.removedLead,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId + '/automation_funnels_completed_leads/' + joinId
    return request(url, DELETE, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setLeads (joinRows) {
  return {
    type: actionTypes.SET_JOIN_ROWS,
    joinRows
  }
}

function removeJoinRowWithId (joinRowId) {
  return {
    type: actionTypes.REMOVE_JOIN_ROW_WITH_ID,
    joinRowId
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}
