import PropTypes from "prop-types";
import React, { Component } from "react";
import "./Fields.css";

export class PasswordField extends Component {
  state = {
    errorMessage: null,
    style: "",
    hidden: true,
    valid: this.props.validator !== null,
  };

  handleChange = (event) => {
    if (!this.props.onChange) return;

    const textValue = event.target.value;
    let style = "";
    const errorMessage = this.errorMessageForValue(textValue);
    const newIsValidValue = errorMessage === null;

    if (!newIsValidValue) {
      style = "has-error";
    }

    this.setState({
      ...this.state,
      errorMessage: errorMessage,
      style: style,
      valid: newIsValidValue,
    });
    this.props.onChange(this.props.name, textValue, newIsValidValue);
  };

  errorMessageForValue = (value) => {
    const { name, validator } = this.props;
    if (!validator) return null;

    let errorMessage = null;
    const validations = validator[name];
    validations.forEach(function (validation) {
      const isValid = validation.rule(value);
      if (!isValid) {
        errorMessage = validation.errorMessage;
      }
    });
    return errorMessage;
  };

  togglePasswordHidden = (event) => {
    event.preventDefault();
    this.setState({ ...this.state, hidden: !this.state.hidden });
  };

  render() {
    const { name, disabled, formFieldStyle, label, placeholder } = this.props;
    const { style, errorMessage, hidden } = this.state;
    const hiddenGliphicon = hidden
      ? "glyphicon-eye-open"
      : "glyphicon glyphicon-eye-close";
    const inputType = hidden ? "password" : "text";
    return (
      <div className={"form-group " + style}>
        <div className={formFieldStyle}>
          <label htmlFor={name}>{label}</label>
          <input
            type={inputType}
            className="form-control"
            id={name}
            ref={name}
            placeholder={placeholder}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <span
            id="hidden-toggle"
            className={"pull-right glyphicon " + hiddenGliphicon}
            onClick={this.togglePasswordHidden}
            style={{ marginTop: "-20px" }}
            aria-hidden="true"
          />
          <span id={name + "-error-message"} className="help-block">
            {errorMessage}
          </span>
        </div>
      </div>
    );
  }
}

PasswordField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  formFieldStyle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  validator: PropTypes.object,
};
