import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectTemplate from "images/SmsNotReady.jpg";
import styled from "styled-components";

import {
  ContentBlockView,
  GymLeadsLoader,
  ImageEmptyView,
  SortSearchView
} from "modules/components";
import TemplatesEmpty from "images/TemplatesEmpty.jpg";

import SelectTemplateRow from "./SelectTemplateRow";
import sortOptions from "../sortOptions";

import { PagesView } from "modules/components";
import STRINGS from "strings";

const PreviewText = styled.p``;

export class TemplatesView extends Component {
  state = {
    selectedTemplate: null
  };

  componentDidMount() {
    const { currentPage } = this.props.pageDetails;
    this.getTemplatesWithPageNumber(currentPage ? currentPage : 1);
  }

  componentWillUnmount() {
    this.props.resetSortSearch();
  }

  getTemplatesWithPageNumber = pageNumber => {
    const { getTemplates, sortOrder, searchTerm } = this.props;
    getTemplates(pageNumber, sortOrder, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getTemplatesWithPageNumber(pageNumber);
  };

  templateSelected = template => {
    this.setState({ selectedTemplate: template });
  };

  createTemplateSelected = () => {
    const { createTemplate, company } = this.props;
    const { selectedTemplate } = this.state;
    createTemplate(selectedTemplate, company.id);
  };

  goBack = () => {
    this.props.showListingView();
  };

  render() {
    const {
      templates,
      loading,
      pageDetails,
      sortSearchUpdated,
      sortOrder,
      searchTerm
    } = this.props;
    const { selectedTemplate } = this.state;

    const templateRows = templates.map((template, index) => {
      const selected = selectedTemplate && selectedTemplate.id === template.id;
      return (
        <SelectTemplateRow
          key={index}
          template={template}
          onClick={this.templateSelected}
          selected={selected}
        />
      );
    });

    return (
      <div>
        <SortSearchView
          sortSearchUpdated={sortSearchUpdated}
          sortColumnWidth={"3"}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          initialSearchTerm={searchTerm}
        />
        <div className="row">
          <div className="col-md-6">
            <ContentBlockView
              title={STRINGS.Templates.TextMessage.Designs["yourTemplates"]}
              bordered
              minHeight={397}
            >
              <GymLeadsLoader
                title={
                  STRINGS.Templates.TextMessage.Designs["loadingTemplates"]
                }
                active={loading}
              />
              {templates.length > 0 && !loading && templateRows}
              {templates.length === 0 && (
                <ImageEmptyView
                  image={TemplatesEmpty}
                  title={STRINGS.Templates.TextMessage.Designs["noTemplates"]}
                  subtitle={
                    STRINGS.Templates.TextMessage.Designs["noTemplatesSubtitle"]
                  }
                />
              )}
            </ContentBlockView>
          </div>
          <div className="col-md-6">
            <ContentBlockView title={"Preview"} bordered minHeight={397}>
              {!selectedTemplate && (
                <ImageEmptyView
                  image={SelectTemplate}
                  title={
                    STRINGS.Templates.TextMessage.Designs["selectTemplate"]
                  }
                />
              )}
              {selectedTemplate && (
                <PreviewText>{selectedTemplate.text}</PreviewText>
              )}
            </ContentBlockView>
          </div>
        </div>
        {templates.length > 0 && !loading && (
          <PagesView pageClicked={this.pageClicked} pageDetails={pageDetails} />
        )}
        <button
          className="btn btn-primary push-20-t push-20"
          onClick={this.goBack}
        >
          <i className="fa fa-angle-left" />{" "}
          {STRINGS.Templates.TextMessage.Designs["backToDesigns"]}
        </button>
        <button
          className="btn btn-success push-20-t push-20 pull-right"
          onClick={this.createDesignSelected}
          disabled={!selectedTemplate}
        >
          <i className="fa fa-plus" /> {STRINGS.Global["create"]}
        </button>
      </div>
    );
  }
}

TemplatesView.propTypes = {
  showListingView: PropTypes.func.isRequired,
  getTemplates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTemplate: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  pageDetails: PropTypes.object.isRequired,
  sortOrder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  resetSortSearch: PropTypes.func.isRequired
};

export default TemplatesView;
