const initialState = {
  loading: false,
  errors: null,
  leads: [],
  selectedFunnelStepId: null,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

export default initialState;
