import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import alert from "modules/components/alert";
import modal from "modules/stores/modal";

import StepRow from "../components/StepRow";
import * as actions from "../actions";
import view from "../../view";
import sms from "../../sms";
import email from "../../email";

import loadAutomationFunnel from "../../../../loadAutomationFunnel";
import triggers from "../../triggers";
import stepLeads from "../../stepLeads";
import stepAddLeads from "../../stepAddLeads";
import schedule from "../../schedule";

function mapStateToProps(state) {
  return {
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteStep: actions.deleteStep,
      showAlertWithConfig: alert.actions.showWithConfig,
      prefillDataForTrigger: triggers.actions.prefillDataForTrigger,
      setSelectedTriggerStepId: triggers.actions.setSelectedFunnelStepId,
      setSelectedStepLeadsStepId: stepLeads.actions.setSelectedFunnelStepId,
      setSelectedStepAddLeadsStepId: stepAddLeads.actions.setSelectedFunnelStepId,
      setView: view.actions.setView,
      setSelectedSmsStepId: sms.actions.setSelectedFunnelStepId,
      setSelectedEmailStepId: email.actions.setSelectedFunnelStepId,
      setSelectedScheduleStepId: schedule.actions.setSelectedFunnelStepId,
      prefillDataForSchedule: schedule.actions.prefillDataForSchedule,
      showModal: modal.actions.showModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StepRow)
);
