import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import editTemplate from '../editTemplate'

import settings from '../settings'

export const get = (templateId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      if (payload.status === 404) {
          dispatch(setNotFound(true))
          return
      }
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setTemplate(payload.text_message_template))
      dispatch(settings.actions.prefillDataForTemplate(payload.text_message_template))
      dispatch(editTemplate.actions.prefillForTemplate(payload.text_message_template))
      if (payload.meta) {
        dispatch(editTemplate.actions.setPreviewText(payload.meta.preview))
      }
      dispatch(setNotFound(false))
      dispatch(setLoading(false))
    }

    const idAsInt = parseInt(templateId, 10)
    if (Number.isInteger(idAsInt)) {
      return request('text_message_templates/' + templateId, GET, null, onSuccess, onFailure)
    } else {
      return request('system_text_message_template/' + templateId, GET, null, onSuccess, onFailure)
    }
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setTemplate (template) {
  return {
    type: actionTypes.SET_TEMPLATE,
    template
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function setNotFound (notFound) {
  return {
    type: actionTypes.SET_NOT_FOUND,
    notFound
  }
}
