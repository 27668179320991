import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";
import { todayDateTimeString } from "modules/helpers/DateHelpers";
import styled from "styled-components";

import STRINGS from "strings";
const detailPanelStrings = STRINGS.ViewBulkSharedStrings.DetailsPanel;

export class DetailsView extends Component {
  render() {
    const { bulkSms, timezone } = this.props;
    return (
      <ContentBlockView title={detailPanelStrings.title} bordered style={{paddingBottom: '15px'}}>
        <LineListing>
          <strong>{detailPanelStrings.authoredBy}:</strong>{" "}
          {bulkSms.user.display_name}
        </LineListing>
        <LineListing>
          <strong>{detailPanelStrings.deliveredAt}:</strong>{" "}
          {todayDateTimeString(bulkSms.send_time, timezone)}
        </LineListing>
        <LineListing>
          <strong>{detailPanelStrings.sentTo}:</strong>{" "}
          {bulkSms.marketing_group && bulkSms.marketing_group.name}
          {!bulkSms.marketing_group && detailPanelStrings.usedCustomFilter}
        </LineListing>
      </ContentBlockView>
    );
  }
}

DetailsView.propTypes = {
  timezone: PropTypes.string.isRequired,
  bulkSms: PropTypes.object.isRequired
};

export default DetailsView;

const LineListing = styled.p`
  margin-bottom: 5px !important;
`;
