import { combineReducers } from 'redux'
import loadAutomationFunnel from './modules/loadAutomationFunnel'
import settings from './modules/settings'
import audience from './modules/audience'
import steps from './modules/steps'
import checklist from './modules/checklist'
import members from './modules/members'

export const reducer = combineReducers({
  [loadAutomationFunnel.constants.NAME]: loadAutomationFunnel.reducer,
  [settings.constants.NAME]: settings.reducer,
  [audience.constants.NAME]: audience.reducer,
  [steps.constants.NAME]: steps.reducer,
  [checklist.constants.NAME]: checklist.reducer,
  [members.constants.NAME]: members.reducer
})
