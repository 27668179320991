import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import modal from 'modules/stores/modal'

import NewBulkEmailModalView from '../components/NewBulkEmailModalView'
import create from '../modules/create'

function mapStateToProps (state) {
  return {
    loading: modal.selectors.getModalLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setNewName: create.actions.setNewName,
    showSelectDesignView: create.actions.showSelectDesignView,
    hideModal: modal.actions.hideModal
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewBulkEmailModalView))
