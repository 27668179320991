import { createSelector } from 'reselect'
import { NAME, SELECT_NEW_FUNNEL_VIEW } from './constants'

export const getNewName = state => state.automations.funnels[NAME].newName
export const getView = state => state.automations.funnels[NAME].view

export const isSettingUpNewFunnel = createSelector(
  [ getView ],
  (view) => {
    return view === SELECT_NEW_FUNNEL_VIEW
  }
)
