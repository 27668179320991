import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './store/store'
import app from 'containers/app'
import './icons/font-awesome/css/font-awesome.min.css'
import './fonts/open-sans/open-sans.css'
import session from 'modules/stores/session'
import { newBugsnagClient } from 'modules/helpers/BugsnagClient'

store.dispatch(session.actions.restoreSession())

const target = document.querySelector('#root')
const bugsnagClient = newBugsnagClient()
var ErrorBoundary = bugsnagClient.getPlugin('react')

render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <app.AppContainer />
        </div>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>,
  target
)
