import { isPresent, isEmail } from 'utils/validation'
import STRINGS from 'strings'
const settingsStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Settings.Validators

export const validator = {
  subject: [{
    rule: isPresent,
    errorMessage: settingsStrings.emailSubject
  }],
  fromAddress: [{
    rule: isEmail,
    errorMessage: settingsStrings.fromAddress
  }]
}
