import { request, GET } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";

export const getTemplates = (templateType, pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setTemplates(payload.email_templates));
    }
    let url =
      "email_templates?template_type=" +
      templateType +
      "&page[number]=" +
      pageNumber +
      "&design_type=user";
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const sortSearchUpdated = (templateType, sort, search) => {
  return async dispatch => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getTemplates(templateType, 1, sort, search));
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setTemplates(templates) {
  return {
    type: actionTypes.SET_TEMPLATES,
    templates
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

export function addTemplate(template) {
  return {
    type: actionTypes.ADD_TEMPLATE,
    template
  };
}

export function removeTemplate(template) {
  return {
    type: actionTypes.REMOVE_TEMPLATE,
    template
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder
  };
}
