import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import LocationEntryView from './LocationEntryView'

import locations from 'modules/stores/locations'
import modal from 'modules/stores/modal'

function mapStateToProps (state) {
  return {
    locations: locations.selectors.getLocations(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationEntryView))
