import { NAME } from "./constants";

export const getErrors = state => state.automations.funnels[NAME].errors;
export const getLoading = state => state.automations.funnels[NAME].loading;
export const getFunnels = state => state.automations.funnels[NAME].funnels;
export const getSearchTerm = state =>
  state.automations.funnels[NAME].searchTerm;
export const getSortOrder = state => state.automations.funnels[NAME].sortOrder;
export const getPageDetails = state =>
  state.automations.funnels[NAME].pageDetails;
