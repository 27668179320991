import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ImageEmptyView, LinkItem } from 'modules/components'

import { ContentBlockView } from 'modules/components'
import LinksEmpty from 'images/LinksEmpty.jpg'

export class LinksView extends Component {

  render () {
    const { links } = this.props
    const linkRows = links.map((link, index) => {
      return (
        <LinkItem
          key={index}
          link={link} />
      )
    })
    return (
        <ContentBlockView title={'Links in template'} bordered>
          {links.length === 0 &&
            <ImageEmptyView
              image={LinksEmpty}
              title={"No links in this template"}
              subtitle={"Add a URL within the body of your template and save. " +
              "We'll shorten and track it for you. Any links we process will be listed here. " +
            "Links must start with a http or https (we highly recommend https)"} />
          }
          {links.length > 0 &&
            linkRows
          }
        </ContentBlockView>
    )
  }
}

LinksView.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  template: PropTypes.object.isRequired,
}

export default LinksView
