import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import DesignsView from '../components/DesignsView'
import * as selectors from '../selectors'
import * as actions from '../actions'

import loadAutomationFunnel from 'containers/editAutomationFunnel/modules/loadAutomationFunnel'
import loadAutomationEmail from '../../loadAutomationEmail'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    basicDesigns: selectors.basicDesigns(state),
    featuredDesigns: selectors.featuredDesigns(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    automationFunnelStep: loadAutomationEmail.selectors.currentFunnelStep(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getDesigns: actions.getDesigns,
    createTemplate: actions.createTemplate
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignsView))
