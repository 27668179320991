import PropTypes from "prop-types";
import React, { Component } from "react";
import { ImageEmptyView, SortSearchView, PagesView } from "modules/components";
import TextMessageTemplatesEmpty from "images/SmsReady.jpg";
import { ModalContainer } from "modules/components";

import TextMessageTemplateRow from "./TextMessageTemplateRow";

export class SelectTextMessageTemplateModalView extends Component {
  state = {
    searchTerm: null
  };

  componentDidMount() {
    this.getTextMessageTemplatesWithPageNumber(1);
  }

  getTextMessageTemplatesWithPageNumber = pageNumber => {
    const { getTextMessageTemplates } = this.props;
    const { searchTerm } = this.state;
    getTextMessageTemplates(pageNumber, null, searchTerm);
  };

  pageClicked = pageNumber => {
    this.getTextMessageTemplatesWithPageNumber(pageNumber);
  };

  sortSearchUpdated = (sort, search) => {
    const { getTextMessageTemplates } = this.props;
    this.setState(
      { searchTerm: search },
      getTextMessageTemplates(1, sort, search)
    );
  };

  textMessageTemplateSelected = textMessageTemplate => {
    const { setSelectedTextMessageTemplate, hideModal } = this.props;
    setSelectedTextMessageTemplate(textMessageTemplate);
    hideModal();
  };

  render() {
    const {
      textMessageTemplates,
      pageDetails,
      selectedTextMessageTemplate,
      modalName
    } = this.props;
    const { searchTerm } = this.state;

    const rows = textMessageTemplates.map((textMessageTemplate, index) => {
      const selected = selectedTextMessageTemplate
        ? selectedTextMessageTemplate.id === textMessageTemplate.id
        : false;
      return (
        <TextMessageTemplateRow
          key={index}
          selected={selected}
          textMessageTemplate={textMessageTemplate}
          selectTemplate={this.textMessageTemplateSelected}
        />
      );
    });

    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={"Select A TextMessageTemplate"}
        colorThemeClass={"primary"}
        showCancelButton
      >
        <SortSearchView
          sortColumnWidth={"4"}
          searchColumnWidth={"8"}
          sortSearchUpdated={this.sortSearchUpdated}
          initialSearchTerm={searchTerm}
        />
        {textMessageTemplates.length === 0 && (
          <ImageEmptyView
            image={TextMessageTemplatesEmpty}
            title={"Can't Find Any TextMessageTemplates"}
            subtitle="Try refining your search, or create some templates if you haven't already."
          />
        )}
        {textMessageTemplates.length > 0 && (
          <div>
            <div className="table-responsive">
              <table className="table table-vcenter table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
            <PagesView
              pageClicked={this.pageClicked}
              pageDetails={pageDetails}
            />
          </div>
        )}
      </ModalContainer>
    );
  }
}

SelectTextMessageTemplateModalView.propTypes = {
  getTextMessageTemplates: PropTypes.func.isRequired,
  selectedTextMessageTemplate: PropTypes.object,
  textMessageTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageDetails: PropTypes.object.isRequired,
  setSelectedTextMessageTemplate: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default SelectTextMessageTemplateModalView;
