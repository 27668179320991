import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import PreviewModalView from '../components/PreviewModalView'
import * as actions from '../actions'

import loadBulkSms from '../../loadBulkSms'
import session from 'modules/stores/session'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state),
    bulkSms: loadBulkSms.selectors.getBulkSms(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    sendPreview: actions.sendPreview
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewModalView))
