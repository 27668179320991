import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

const audienceStrings = STRINGS.Funnels.EditAutomationFunnel.Checklist.To

export class ToRow extends Component {

  goToAudience = () => {
    this.props.history.push('audience')
  }

  render () {
    const { automationFunnel } = this.props
    const { checklist, marketing_group, entry_rules } = automationFunnel
    const icon = (checklist.audience_ready === false) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        <button
          className='pull-right btn btn-primary'
          onClick={this.goToAudience}>
          {audienceStrings.setAudience}
        </button>
        <i className={icon} />
        <h4>To</h4>
        {!marketing_group && !entry_rules &&
          <p>
            {audienceStrings.setAudienceMessage}
          </p>
        }
        {marketing_group &&
          <div>
            <p>{STRINGS.formatString(audienceStrings.marketingGroup, <strong>{marketing_group.name}</strong>)}</p>
          </div>
        }
        {entry_rules &&
          <div>
            <p>{audienceStrings.setAudienceMessage}</p>
          </div>
        }
      </div>
    )
  }
}

ToRow.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default ToRow
