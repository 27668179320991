import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

import { ContentBlockView, ImageEmptyView } from 'modules/components'

import EmailReady from 'images/EmailReady.jpg'
import EmailNotReady from 'images/EmailNotReady.jpg'

export class ReadyView extends Component {

  render () {
    const readyStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Checklist.Ready
    const { automationEmail, loading } = this.props
    const checklist = automationEmail.checklist
    return (
      <ContentBlockView bordered loading={loading}>
        {checklist.ready_to_send &&
          <ImageEmptyView
            title={readyStrings.emailIsReady}
            subtitle={readyStrings.emailIsReadyMessage}
            image={EmailReady} />
        }
        {!checklist.ready_to_send &&
          <ImageEmptyView
            title={readyStrings.emailNotReady}
            subtitle={readyStrings.emailNotReadyMessage}
            image={EmailNotReady} />
        }
      </ContentBlockView>
    )
  }
}

ReadyView.SubjectRow = {
  automationEmail: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

export default ReadyView
