import React, { Component } from 'react'
import { InfoView } from 'modules/components'

class HelpView extends Component {

  render () {
    const title = 'Automation rules'
    const detail = 'Setup rules for when leads should be added or removed from this group. ' +
    `${this.props.productName} will automatically add leads into the group if they match the below rules.`
    return (
      <InfoView title={title} detail={detail} colorThemeClass={'info'} />
    )
  }
}

export default HelpView
