import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import alert from 'modules/components/alert'

import ScheduleModal from '../components/ScheduleModal'

import * as actions from '../actions'
import * as selectors from '../selectors'
import modal from 'modules/stores/modal'

import loadAutomationFunnel from '../../../../loadAutomationFunnel'

function mapStateToProps (state) {
  return {
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    selectedFunnelStep: selectors.selectedFunnelStep(state),
    data: selectors.getData(state),
    loading: selectors.getLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateSchedule: actions.updateSchedule,
    hideModal: modal.actions.hideModal,
    createNewSchedule: actions.createNewSchedule,
    deleteSchedule: actions.deleteSchedule,
    showAlertWithConfig: alert.actions.showWithConfig,
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleModal)
