import PropTypes from "prop-types";
import React, { Component } from "react";
import { ModalContainer } from "modules/components";
import * as arrayUtils from "utils/arrayUtils";

import MutliTagSelectView from "./MultiTagSelectView";

export class MultiTagSelectModal extends Component {
  state = {
    selectedTagIds: this.props.tagIds
  };

  componentWillReceiveProps(nextProps) {
    const oldTagIds = this.props.tagIds;
    const newTagIds = nextProps.tagIds;

    if (!oldTagIds || !newTagIds) {
      return;
    }

    if (arrayUtils.arraysEqual(oldTagIds, newTagIds)) {
      return;
    }
    this.setState({ selectedTagIds: newTagIds });
  }

  selectTag = tag => { 
    const newSelectedTagIds = [
      ...this.state.selectedTagIds,
      tag.id
    ];
    this.setState({
      ...this.state,
      selectedTagIds: newSelectedTagIds
    });
  };

  deselectTag = tag => {
    const index = this.state.selectedTagIds.findIndex(
      tagId => parseInt(tagId, 10) === parseInt(tag.id, 10)
    );
    const newSelectedTagIds = [
      ...this.state.selectedTagIds.slice(0, index),
      ...this.state.selectedTagIds.slice(index + 1)
    ];
    this.setState({
      ...this.state,
      selectedTagIds: newSelectedTagIds
    });
  };

  updateClubIds = () => {
    this.props.onUpdate(this.state.selectedTagIds);
    this.cleanAndHide();
  };

  cleanAndHide = () => {
    this.setState({ selectedTagIds: [] });
    this.props.hideModal();
  };

  render() {
    const { modalName, readonly, buttonTitle } = this.props;
    const disabled = this.state.selectedTagIds.length === 0;

    return (
      <ModalContainer
        modalName={modalName}
        modalTitle={"Select tag"}
        colorThemeClass={"primary"}
        showCancelButton
        readonly={readonly}
        actionEnabled={!disabled}
        actionButtonClicked={this.updateClubIds}
        actionButtonElement={
          <span>
            <i className="fa fa-building-o" /> {buttonTitle}
          </span>
        }
      >
        <MutliTagSelectView
          {...this.props}
          readonly={readonly}
          selectedTagIds={this.state.selectedTagIds}
          selectTag={this.selectTag}
          deselectTag={this.deselectTag}
        />
      </ModalContainer>
    );
  }
}

MultiTagSelectModal.propTypes = {
  getTags: PropTypes.func.isRequired,
  tagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onUpdate: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  excludedTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalName: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  buttonTitle: PropTypes.string
};

MultiTagSelectModal.defaultProps = {
  buttonTitle: "Filter by selected",
  readonly: false
};

export default MultiTagSelectModal;
