import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getRules = state => state.editGroup.rules[NAME].rules
export const getCondition = state => state.editGroup.rules[NAME].condition
export const getEvent = state => state.editGroup.rules[NAME].event
export const getEntryIsAutomatic = state => state.editGroup.rules[NAME].entryIsAutomatic
export const getHasChanges = state => state.editGroup.rules[NAME].hasChanges

export const getValidRules = createSelector(
  [ getRules ],
  (rules) => {
    return rules.filter(rule => rule.data !== null)
  }
)
