import { NAME } from "./constants";

export const LOADING_STARTED = NAME + "/INITIAL_USER_DATA_STARTED_LOADING";
export const LOADING_STOPPED = NAME + "/INITIAL_USER_DATA_STOPED_LOADING";

export const COMPANY_DATA_FETCH_SUCESS = NAME + "/COMPANY_DATA_FETCH_SUCESS";
export const USER_ROLES_FETCH_SUCCESS = NAME + "/USER_ROLES_FETCH_SUCCESS";
export const SALES_FUNNEL_FETCH_SUCCESS = NAME + "/SALES_FUNNEL_FETCH_SUCCESS";

export const SET_REGIONS = NAME + "/SET_REGIONS";

export const SET_SCHEDULES = NAME + "/SET_SCHEDULES";
export const SET_SMS_ACCOUNTS = NAME + "/SET_SMS_ACCOUNTS";
