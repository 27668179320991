import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import BulkSmsView from '../components/BulkSmsView'

function mapStateToProps (state) {
  return {

  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkSmsView))
