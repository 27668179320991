import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'


import header from '../../header'

export class SubjectRow extends Component {

  editSubject = () => {
    this.props.setView(header.constants.SETTINGS_VIEW)
  }

  render () {
    const subjectStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Checklist.Subject
    const { automationEmail } = this.props
    const checklist = automationEmail.checklist
    const icon = (!checklist.subject_ready) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        <button
          className='pull-right btn btn-primary'
          onClick={this.editSubject}>
          {subjectStrings.editButton}
        </button>
        <i className={icon} />
        <h4>{subjectStrings.subject}</h4>
        {checklist.subject_ready &&
          <p>{automationEmail.subject}</p>
        }
        {!checklist.subject_ready &&
          <p>{subjectStrings.setSubject}</p>
        }
      </div>
    )
  }
}

SubjectRow.SubjectRow = {
  automationEmail: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
}

export default SubjectRow
