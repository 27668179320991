import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import StepsView from "../components/StepsView";
import triggers from "../modules/triggers";
import view from "../modules/view";
import stepLeads from "../modules/stepLeads";
import stepAddLeads from "../modules/stepAddLeads";
import loadAutomationFunnel from "../../loadAutomationFunnel";

function mapStateToProps(state) {
  return {
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state),
    selectedStepLeadsId: stepLeads.selectors.getSelectedFunnelStepId(state),
    selectedStepAddLeadsId: stepAddLeads.selectors.getSelectedFunnelStepId(state),
    currentView: view.selectors.getView(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTriggerOptions: triggers.actions.getTriggerOptions
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StepsView)
);
