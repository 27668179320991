import { request, buildIndexUrl, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getTrials = (pageNumber, searchTerm = null) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setTrials(payload.trials))
    }

    const url = buildIndexUrl('trials', pageNumber, null, searchTerm)
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setTrials (trials) {
  return {
    type: actionTypes.SET_TRIALS,
    trials
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}
