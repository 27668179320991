import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { QuickInfoBlock } from 'modules/components'

export class SummaryView extends Component {

  render () {
    const { automationFunnel } = this.props
    const currentLeadsCount = automationFunnel.current_leads_count
    const finishedLeadsCount = automationFunnel.completed_leads_count
    return (
      <div className='row'>
        <div className='col-xs-6 col-sm-4'>
          <QuickInfoBlock title={'Number of Steps'} number={automationFunnel.steps_count}
            loading={false} />
        </div>
        <div className='col-xs-6 col-sm-4'>
          <QuickInfoBlock title={'Active Leads'} number={currentLeadsCount}
            loading={false} />
        </div>
        <div className='col-xs-6 col-sm-4'>
          <QuickInfoBlock title={'Completed Leads'} number={finishedLeadsCount}
            loading={false} />
        </div>
      </div>
    )
  }
}

SummaryView.propTypes = {
  automationFunnel: PropTypes.object.isRequired
}

export default SummaryView
