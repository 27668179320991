import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import alert from 'modules/components/alert'

import CompletedLeadTableRow from '../components/CompletedLeadTableRow'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {

  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    deleteRecord: actions.deleteRecord,
    showAlertWithConfig: alert.actions.showWithConfig
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompletedLeadTableRow))
