import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields, ModalContainer } from 'modules/components'
import { NEW_GROUP_MODAL } from '../constants'

const { TextField } = FormFields

export class NewGroupModalView extends Component {

  state = {
    name: null
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    this.props.createGroup(this.state.name)
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value })
  }

  isDisabled = () => {
    return !this.state.name
  }

  render () {
    const { loading } = this.props
    return (
      <ModalContainer
        modalName={NEW_GROUP_MODAL}
        modalTitle={'Create a New Group'}
        colorThemeClass={'success'}
        loading={loading}
        loadingText={'Creating Group'}
        showCancelButton
        actionEnabled={!this.isDisabled()}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={
          <span><i className='fa fa-plus' /> Create group</span>
        }>
        <p>Just give your group a name and you'll be on your way! You can always change the name later.</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}
          style={{
            marginLeft: '15px',
            marginRight: '15px'
          }}>
          <TextField
            name='name'
            label=''
            placeholder='New members'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput} />
        </form>
      </ModalContainer>
    )
  }
}

NewGroupModalView.propTypes = {
  createGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default NewGroupModalView
