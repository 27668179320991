import conditions from './modules/conditions'
import showAudience from './modules/showAudience'

export const prefillDataForAutomationFunnel = (automationFunnel) => {
  return (dispatch) => {
    dispatch(conditions.actions.prefillDataForAutomationFunnel(automationFunnel))
  }
}

export const reset = () => {
  return (dispatch) => {
    dispatch(conditions.actions.reset())
    dispatch(showAudience.actions.reset())
  }
}
