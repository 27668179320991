import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './styles.css'

import { ContentBlockView, FormFields } from 'modules/components'
const { TextArea, SelectField } = FormFields

export class TextInputView extends Component {

  state = {
    cursorPosition: 0,
    maxLength: this.props.maxLength
  }

  onChange = (name, value) => {
    this.props.setText(value)
  }

  onSelect = (name, value) => {
    const { text, setText } = this.props
    const { cursorPosition } = this.state
    if (text) {
      const newText = text.slice(0, cursorPosition) + value + text.slice(cursorPosition)
      setText(newText)
    } else {
      setText(value)
    }
  }

  saveDesignClicked = () => {
    const { template, text, company, updateTemplate } = this.props
    updateTemplate(template, text, company.id)
  }

  updateCusorPosition = (name, cursorPosition) => {
    this.setState({ ...this.state, cursorPosition: cursorPosition })
  }

  render () {
    const { text, mergeTags, loading } = this.props
    return (
      <ContentBlockView title={'Template content'} loading={loading} bordered>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <SelectField
              id='personalization'
              placeHolderText='Insert Merge tag'
              onChange={this.onSelect}
              name='personalization'
              initialValue={null}
              formFieldStyle={'input-group'}
              icon={'fa fa-user'}
              possibleOptions={mergeTags}
              displayFieldName='title'
              />
          </div>
        </div>
        <div className='editor'>
          <TextArea
            name={'message'}
            onChange={this.onChange}
            maxLength={this.state.maxLength}
            cursorUpdated={this.updateCusorPosition}
            needsFormGroup={false}
            autoResize
            value={text}
            rows={5} />
        </div>
        <button className='btn btn-primary push-20' onClick={this.saveDesignClicked}>
          <i className='fa fa-save' /> Save template
        </button>
      </ContentBlockView>
    )
  }
}

TextInputView.propTypes = {
  template: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  setText: PropTypes.func.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxLength: PropTypes.number.isRequired
}

export default TextInputView
