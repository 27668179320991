import { request, GET, POST } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import { push } from "react-router-redux";

import listing from "../listing";
import create from "../create";

import STRINGS from "strings";

export const getLegacyRecipes = () => {
  return dispatch => {
    dispatch(setLegacyRecipesLoading(true));
    function onFailure(payload) {
      dispatch(setLegacyRecipesLoading(false));
    }

    function onSuccess(payload) {
      dispatch(setLegacyRecipesLoading(false));
      dispatch(setLegacyRecipes(payload.funnel_recipes));
    }

    return request("funnel_recipes", GET, null, onSuccess, onFailure);
  };
};

export const getRecipes = (pageNumber, sort, search) => {
  return dispatch => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setRecipes(payload.automation_funnel_recipes));
    }

    let url = "automation_funnel_recipes?page[number]=" + pageNumber;
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }

    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const sortSearchUpdated = (sort, search) => {
  return async dispatch => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getRecipes(1, sort, search));
  };
};

export const createFunnel = (name, recipieId) => {
  return dispatch => {
    dispatch(setLoading(true));
    const automationBody = {
      automation_funnel: {
        name: name
      },
      recipe: recipieId
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      const funnel = payload.automation_funnel;
      dispatch(listing.actions.addFunnel(funnel));
      const id = funnel.id;
      dispatch(push("automation/funnel/" + id + "/checklist"));
      dispatch(create.actions.reset());
    }

    return request(
      "automation_funnels",
      POST,
      automationBody,
      onSuccess,
      onFailure
    );
  };
};

export const createFunnelFromRecipe = (recipeId, shareCode = null) => {
  return dispatch => {
    dispatch(setLoading(true));
    const body = {
      automation_funnel_recipe_id: recipeId,
      share_code: shareCode
    };

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      const funnel = payload.automation_funnel;
      const id = funnel.id;
      dispatch(push("automation/funnel/" + id + "/checklist"));
      dispatch(create.actions.reset());
    }

    const url = "automation_funnel/create_from_recipe";
    return request(url, POST, body, onSuccess, onFailure);
  };
};

export const getPrivateRecipe = shareCode => {
  return dispatch => {
    dispatch(setLoading(true));
    dispatch(setErrors(null));

    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(
        setErrors({
          not_found: STRINGS.Templates.noShareCodeFound
        })
      );
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      if (payload) {
        dispatch(setPrivateRecipe(payload.automation_funnel_recipe));
      } else {
        dispatch(
          setErrors({
            not_found: STRINGS.Templates.noShareCodeFound
          })
        );
      }
    }

    const url = `automation_funnel_recipes/${shareCode}`;
    return request(url, GET, null, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setRecipes(recipes) {
  return {
    type: actionTypes.SET_RECIPES,
    recipes
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function setLegacyRecipes(legacyRecipes) {
  return {
    type: actionTypes.SET_LEGACY_RECIPES,
    legacyRecipes
  };
}

function setLegacyRecipesLoading(loadingLegacyRecipes) {
  return {
    type: actionTypes.SET_LEGACY_RECIPES_LOADING,
    loadingLegacyRecipes
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

export function setPrivateRecipe(privateRecipe) {
  return {
    type: actionTypes.SET_PRIVATE_RECIPE,
    privateRecipe
  };
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder
  };
}
