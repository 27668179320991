import * as actionTypes from "./actionTypes";
import flags from "modules/components/flags";
import STRINGS from "strings";

import * as automationFunnelStepsLeadApi from "modules/api/automationFunnelStepsLead";

export const getLeadsForFunnelStep = (
  funnel,
  funnelStep,
  page,
  sort,
  search
) => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      const response = await automationFunnelStepsLeadApi.fetchLeadsForFunnelStep(
        funnel,
        funnelStep,
        page,
        sort,
        search
      );
      dispatch(setLoading(false));
      dispatch(setPageDetails(response.meta));
      dispatch(setLeads(response.automation_funnel_steps_leads));
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(setErrors(error.response.data.errors));
      }
      dispatch(setLoading(false));
    }
  };
};

export const addLeadToStep = (funnel, funnelStep, leadId) => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      await automationFunnelStepsLeadApi.addLeadToStep(
        funnel,
        funnelStep,
        leadId
      );
      dispatch(setLoading(false));
      dispatch(addLeadToStepWithId(leadId));
      dispatch(
        flags.actions.showFlag({
          appearance: "success",
          title: STRINGS.Funnels.Steps.LeadsList.leadAddSuccess,
          index: 0
        })
      );
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(setErrors(error.response.data.errors));
      }
      dispatch(setLoading(false));
    }
  };
};

export function setSelectedFunnelStepId(selectedFunnelStepId) {
  return {
    type: actionTypes.SET_SELECTED_FUNNEL_STEP_ID,
    selectedFunnelStepId
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}

export function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  };
}

function setPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  };
}

function setLeads(leads) {
  return {
    type: actionTypes.SET_LEADS,
    leads
  };
}

function addLeadToStepWithId(leadId) {
  return {
    type: actionTypes.ADD_LEAD_TO_STEP_WITH_ID,
    leadId
  };
}
