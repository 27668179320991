import PropTypes from "prop-types";
import React, { Component } from "react";

import bulkMessaging from "containers/bulkMessaging";

import replies from "../../replies";
import results from "../../results";
import resultsBreakdown from "../../resultsBreakdown";
import content from "../../content";
import audience from "../../audience";

import { ContentBlockView, NavLink } from "modules/components";

import STRINGS from "strings";
const menuStrings = STRINGS.ViewBulkEmail.Header.Menu;

export class HeaderView extends Component {
  backSelected = () => {
    this.props.history.push("/secure/" + bulkMessaging.constants.NAME);
  };

  render() {
    const { bulkEmail, currentPath } = this.props;
    const baseLink = "/secure/bulkMessaging/viewBulkEmail/" + bulkEmail.id;
    return (
      <ContentBlockView>
        <div className="row">
          <div className="col-md-6">
            <i
              className="si si-arrow-left fa-2x pull-left selectable"
              style={{ marginTop: "5px", marginRight: "20px" }}
              onClick={this.backSelected}
            />
            <h2 className="push">
              {bulkEmail.title || menuStrings.titleUnnamedEmail}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="block">
              <ul className="nav nav-tabs nav-tabs-alt">
                <NavLink
                  to={baseLink + "/" + results.constants.NAME}
                  currentPath={currentPath}
                >
                  <i className="fa fa-list-ol" /> {menuStrings.results}
                </NavLink>
                <NavLink
                  to={baseLink + "/" + content.constants.NAME}
                  currentPath={currentPath}
                >
                  <i className="fa fa-newspaper-o" /> {menuStrings.content}
                </NavLink>
                <NavLink
                  to={baseLink + "/" + resultsBreakdown.constants.NAME}
                  currentPath={currentPath}
                >
                  <i className="fa fa-users" /> {menuStrings.resultsBreakdown}
                </NavLink>
                <NavLink
                  to={baseLink + "/" + replies.constants.NAME}
                  currentPath={currentPath}
                >
                  <i className="fa fa-mail-reply" /> {menuStrings.replies}
                </NavLink>
                <NavLink
                  to={baseLink + "/" + audience.constants.NAME}
                  currentPath={currentPath}
                >
                  <i className="fa fa-envelope" /> {menuStrings.audience}
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
      </ContentBlockView>
    );
  }
}

HeaderView.propTypes = {
  bulkEmail: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setAlertShowing: PropTypes.func.isRequired,
  setAlertConfig: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired
};

export default HeaderView;
