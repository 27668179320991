import { combineReducers } from 'redux'
import loadBulkEmail from './modules/loadBulkEmail'
import checklist from './modules/checklist'
import audience from './modules/audience'
import content from './modules/content'
import settings from './modules/settings'

export const reducer = combineReducers({
  [loadBulkEmail.constants.NAME]: loadBulkEmail.reducer,
  [checklist.constants.NAME]: checklist.reducer,
  [audience.constants.NAME]: audience.reducer,
  [content.constants.NAME]: content.reducer,
  [settings.constants.NAME]: settings.reducer
})
