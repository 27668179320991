import { request, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import STRINGS from 'strings'

import flags from 'modules/components/flags'
import loadAutomationEmail from '../loadAutomationEmail'

export const updateEmailContent = (automationFunnelId, automationFunnelStepId, automationEmailId, html, json) => {
  return dispatch => {
    dispatch(setLoading(true))
    const body = {
      automation_email_template: {
        html: html,
        design_json: json
      }
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setHasChanges(false))
      dispatch(loadAutomationEmail.actions.setAutomationEmail(payload.automation_email_template))
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.emailUpdatedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + automationFunnelId +
    '/automation_funnel_steps/' + automationFunnelStepId +
    '/automation_email_templates/' + automationEmailId
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

export function setHasChanges (hasChanges) {
  return {
    type: actionTypes.SET_HAS_CHANGES,
    hasChanges
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
