import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PrivateRouteContainer, ImageEmptyView } from 'modules/components'
import STRINGS from 'strings'
import loadAutomationFunnel from '../modules/loadAutomationFunnel'
import settings from '../modules/settings'
import audience from '../modules/audience'
import steps from '../modules/steps'
import checklist from '../modules/checklist'
import members from '../modules/members'

import FunnelsEmpty from 'images/AutomationsEmpty.jpg'
const emptyFunnelStrings = STRINGS.Funnels.EditAutomationFunnel.FunnelEmpty

export class EditAutomationFunnelView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  backSelected = () => {
    this.props.history.push('/secure/automation')
  }

  render () {
    const { automationFunnel, header, notFound } = this.props
    return (
      <div className='content'>
        {automationFunnel &&
          <div>
            {header}
            <div className='row'>
              <div className='col-sm-12'>
                <PrivateRouteContainer exact path="/secure/automation/funnel/:id/checklist" component={checklist.ChecklistContainer} />
                <PrivateRouteContainer exact path="/secure/automation/funnel/:id/members" component={members.MembersContainer} />
                <PrivateRouteContainer exact path="/secure/automation/funnel/:id/steps" component={steps.StepsContainer} />
                <PrivateRouteContainer exact path="/secure/automation/funnel/:id/settings" component={settings.SettingsContainer} />
                <PrivateRouteContainer exact path="/secure/automation/funnel/:id/audience" component={audience.AudienceContainer} />
              </div>
            </div>
          </div>
        }
        {!automationFunnel && !notFound &&
          <loadAutomationFunnel.LoadAutomationFunnelContainer />
        }
        {!automationFunnel && notFound &&
          <ImageEmptyView
            image={FunnelsEmpty}
            title={emptyFunnelStrings.title}
            subtitle={emptyFunnelStrings.subtitle}
            buttonText={emptyFunnelStrings.buttonText}
            buttonSelected={this.backSelected} />
        }
      </div>
    )
  }
}

EditAutomationFunnelView.propTypes = {
  automationFunnel: PropTypes.object,
  reset: PropTypes.func.isRequired,
  notFound: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  header: PropTypes.element.isRequired
}

export default EditAutomationFunnelView
