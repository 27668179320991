import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormFields, ModalContainer } from 'modules/components'
import { NEW_EMAIL_TEMPLATE_MODAL } from '../constants'

import filters from '../modules/filters'
import { modalStringsForTemplateType } from '../helpers'

const { TextField } = FormFields

export class NewEmailTemplateModalView extends Component {

  state = {
    name: null,
    emailType: null
  }

  handleSubmit = (event) => {
    const { templateType } = this.props
    event && event.preventDefault()
    switch (templateType) {
      case filters.constants.HTML_TEMPLATES:
        this.createHtmlTemplate()
        break
      case filters.constants.QUICK_SEND_TEMPLATES:
        this.createQuickSendTemplate()
        break
      default:
        break
    }
  }

  createHtmlTemplate = () => {
    const { showSelectDesignView, setNewName, hideModal } = this.props
    setNewName(this.state.name)
    showSelectDesignView()
    hideModal()
  }

  createQuickSendTemplate = () => {
    const { createQuickSendTemplate, company } = this.props
    createQuickSendTemplate(this.state.name, company.id)
  }

  handleInput = (name, value, isValid) => {
    this.setState({ ...this.state, [name]: value })
  }

  isDisabled = () => {
    return !this.state.name
  }

  render () {
    const { loading, templateType } = this.props
    const strings = modalStringsForTemplateType(templateType)
    return (
      <ModalContainer
        modalName={NEW_EMAIL_TEMPLATE_MODAL}
        modalTitle={strings.title}
        colorThemeClass={'success'}
        loading={loading}
        loadingText={'Creating Email template'}
        showCancelButton
        actionEnabled={!this.isDisabled()}
        actionButtonClicked={this.handleSubmit}
        actionButtonElement={
          <span><i className='fa fa-plus' /> {strings.button}</span>
        }>
        <p>{strings.detail}</p>
        <form className='form-horizontal push-30-t' onSubmit={this.handleSubmit}
          style={{
            marginLeft: '10px',
            marginRight: '10px'
          }}>
          <TextField
            name='name'
            label=''
            placeholder='New signup email'
            formFieldStyle='form-material form-material-primary'
            onChange={this.handleInput} />
        </form>
      </ModalContainer>
    )
  }
}

NewEmailTemplateModalView.propTypes = {
  setNewName: PropTypes.func.isRequired,
  showSelectDesignView: PropTypes.func.isRequired,
  templateType: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  createQuickSendTemplate: PropTypes.func.isRequired
}

export default NewEmailTemplateModalView
