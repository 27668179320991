import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import BulkSmsListingView from "../components/BulkSmsListingView";
import session from "modules/stores/session";
import * as selectors from "../selectors";
import * as actions from "../actions";
import filters from "../../filters";

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    bulkSmss: selectors.getBulkSmss(state),
    pageDetails: selectors.getPageDetails(state),
    status: filters.selectors.getSelection(state),
    searchTerm: selectors.getSearchTerm(state),
    sortOrder: selectors.getSortOrder(state),
    selectedLocations: selectors.getSelectedLocations(state),
    userPermissionRuleSet: session.selectors.getUserPermissions(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBulkSms: actions.getBulkSms,
      sortSearchUpdated: actions.sortSearchUpdated,
      selectedLocationsUpdated: actions.selectedLocationsUpdated
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BulkSmsListingView)
);
