import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import alert from "modules/components/alert";

import SettingsView from "../components/SettingsView";
import * as selectors from "../selectors";
import * as actions from "../actions";

import loadTemplate from "../../loadTemplate";
import userData from "modules/stores/userData";
import modal from "modules/stores/modal";

function mapStateToProps(state) {
  return {
    data: selectors.getData(state),
    loading: selectors.getLoading(state),
    template: loadTemplate.selectors.getTemplate(state),
    company: userData.selectors.getCompany(state),
    locationIds: selectors.getLocationids(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateData: actions.updateData,
      update: actions.update,
      showAlertWithConfig: alert.actions.showWithConfig,
      showModal: modal.actions.showModal,
      setLocationIds: actions.setLocationIds,
      deleteTemplate: actions.deleteTemplate
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsView)
);
