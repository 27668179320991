import { request, GET, POST, paramStringWithName } from "utils/apiUtils";
import * as actionTypes from "./actionTypes";
import modal from "modules/stores/modal";
import { push } from "react-router-redux";

import editBulkSms from "containers/editBulkSms";

export const getBulkSms = (status, pageNumber, sort, search, locations) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    function onFailure(payload) {
      dispatch(setLoading(false));
      dispatch(setErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(setLoading(false));
      dispatch(loadPageDetails(payload.meta));
      dispatch(setBulkSmss(payload.mass_lead_text_messages));
    }

    let statusString;
    if (Array.isArray(status)) statusString = status.join("&status[]=");
    else statusString = status;

    let url =
      "mass_lead_text_messages?status[]=" +
      statusString +
      "&page[number]=" +
      pageNumber;
    if (sort) {
      url = url + "&sort=" + sort;
    }
    if (search) {
      url = url + "&search=" + search;
    }
    const locationsString = paramStringWithName("location_ids", locations);
    if (locationsString) {
      url = url + "&" + locationsString;
    }
    return request(url, GET, null, onSuccess, onFailure);
  };
};

export const sortSearchUpdated = (status, sort, search, locations) => {
  return async (dispatch) => {
    dispatch(setSortOrder(sort));
    dispatch(setSearchTerm(search));
    dispatch(getBulkSms(status, 1, sort, search, locations));
  };
};

export const selectedLocationsUpdated = (status, sort, search, locations) => {
  return async (dispatch) => {
    dispatch(setSelectedLocations(locations));
    dispatch(getBulkSms(status, 1, sort, search, locations));
  };
};

export const createBulkSms = (name) => {
  return (dispatch) => {
    const body = {
      mass_lead_text_message: {
        title: name,
        is_legacy: false,
      },
    };

    dispatch(modal.actions.startLoading());
    function onFailure(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.showErrors(payload.errors));
    }

    function onSuccess(payload) {
      dispatch(modal.actions.stopLoading());
      dispatch(modal.actions.hideModal());
      const bulkSms = payload.mass_lead_text_message;
      dispatch(addBulkSms(bulkSms));
      const id = bulkSms.id;
      dispatch(
        push(
          "/Secure/bulkMessaging/" +
            editBulkSms.constants.NAME +
            "/" +
            id +
            "/checklist"
        )
      );
    }
    return request("mass_lead_text_messages", POST, body, onSuccess, onFailure);
  };
};

function setLoading(loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

function setBulkSmss(bulkSmss) {
  return {
    type: actionTypes.SET_BULK_SMSS,
    bulkSmss,
  };
}

function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
}

export function addBulkSms(bulkSms) {
  return {
    type: actionTypes.ADD_BULK_SMS,
    bulkSms,
  };
}

export function removeBulkSms(bulkSms) {
  return {
    type: actionTypes.REMOVE_BULK_SMS,
    bulkSms,
  };
}

function loadPageDetails(pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails,
  };
}

function setSearchTerm(searchTerm) {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchTerm,
  };
}

function setSortOrder(sortOrder) {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder,
  };
}

function setSelectedLocations(selectedLocations) {
  return {
    type: actionTypes.SET_SELECTED_LOCATIONS,
    selectedLocations,
  };
}
