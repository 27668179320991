import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ChecklistView from '../components/ChecklistView'

import loadAutomationTextMessage from '../../loadAutomationTextMessage'
import header from '../../header'

import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    automationTextMessage: loadAutomationTextMessage.selectors.getAutomationTextMessage(state),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setView: header.actions.setView
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistView))
