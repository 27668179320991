import { createSelector } from 'reselect'

import settings from '../settings'
import editTemplate from '../editTemplate'

export const hasUnsavedChanges = createSelector(
  [ settings.selectors.getHasChanges,
    editTemplate.selectors.getHasChanges],
  (settingsHaveChanges, contentHasChanges ) => {
    if (settingsHaveChanges) { return true }
    if (contentHasChanges) { return true }
    return false
  }
)
