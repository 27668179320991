import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SystemTemplateRow from './SystemTemplateRow'

import editHtmlTemplate from 'containers/editEmailTemplate/modules/editHtmlTemplate'

export class SystemTemplateListingView extends Component {

  editSystemTemplate = (id) => {
    const url = '/secure/templates/email/' + id + '/' + editHtmlTemplate.constants.NAME
    this.props.history.push(url)
  }

  render () {
    const { templates } = this.props

      const templateRows = templates.map((template, index) => {
        return (
          <SystemTemplateRow
            key={template.id}
            id={template.id}
            title={template.title}
            detail={template.detail}
            onSelect={this.editSystemTemplate} />
        )
      })

    return (
      <div className='row'>
        <div className='col-xs-12'>
          {templateRows}
        </div>
      </div>
    )
  }
}

SystemTemplateListingView.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  templateType: PropTypes.string.isRequired,
}

export default SystemTemplateListingView
