import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";
import { todayDateTimeString } from "modules/helpers/DateHelpers";
import {
  formatPercentage,
  numberWithCommas
} from "modules/helpers/FormatHelpers";
import STRINGS from "strings";
import styled from "styled-components";

const detailPanelStrings = STRINGS.ViewBulkSharedStrings.DetailsPanel;

export class DetailsView extends Component {
  render() {
    const { bulkEmail, timezone, formattedStatistics } = this.props;
    const { unsubscribe, reply } = formattedStatistics;
    return (
      <ContentBlockView
        title={detailPanelStrings.title}
        bordered
        style={{ paddingBottom: "15px" }}
      >
        <LineListing>
          <strong>{detailPanelStrings.authoredBy}:</strong>{" "}
          {bulkEmail.user.display_name}
        </LineListing>
        <LineListing>
          <strong>{detailPanelStrings.deliveredAt}:</strong>{" "}
          {todayDateTimeString(bulkEmail.send_time, timezone)}
        </LineListing>
        <LineListing>
          <strong>{detailPanelStrings.sentTo}:</strong>{" "}
          {bulkEmail.marketing_group && bulkEmail.marketing_group.name}
          {!bulkEmail.marketing_group && detailPanelStrings.usedCustomFilter}
        </LineListing>
        <LineListing>
          <strong>{detailPanelStrings.subject}:</strong> {bulkEmail.subject}
        </LineListing>
        <LineListing>
          <strong>{detailPanelStrings.unsubscribes}:</strong>
          {` ${numberWithCommas(unsubscribe.count)} (% ${formatPercentage(
            unsubscribe.percentage
          )})`}
        </LineListing>
        <LineListing>
          <strong>{detailPanelStrings.replies}:</strong>
          {` ${numberWithCommas(reply.count)} (% ${formatPercentage(
            reply.percentage
          )})`}
        </LineListing>
      </ContentBlockView>
    );
  }
}

DetailsView.propTypes = {
  timezone: PropTypes.string.isRequired,
  bulkEmail: PropTypes.object.isRequired,
  formattedStatistics: PropTypes.object.isRequired
};

export default DetailsView;

const LineListing = styled.p`
  margin-bottom: 5px !important;
`;
