import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import BulkMessagingView from '../components/BulkMessagingView'

import modal from 'modules/stores/modal'
import selection from '../modules/selection'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  return {
    selection: selection.selectors.getSelection(state),
    shouldHideHeader: selectors.shouldHideHeader(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showModal: modal.actions.showModal,
    setSelection: selection.actions.setSelection
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkMessagingView))
