import PropTypes from 'prop-types'
import React, { Component } from 'react'

import audience from '../../audience'

export class ToRow extends Component {

  goToAudience = () => {
    this.props.history.push(audience.constants.NAME)
  }

  render () {
    const { bulkEmail } = this.props
    const checklist = bulkEmail.checklist
    const toOk = (checklist.audience_ready && checklist.threshold_ok)
    const icon = (!toOk) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        {bulkEmail.status !== 'scheduled' &&
          <button
            className='pull-right btn btn-primary'
            onClick={this.goToAudience}>
            Set your audience
          </button>
        }
        <i className={icon} />
        <h4>To</h4>
        {bulkEmail.estimated_lead_count === 0 &&
          <div>
            Who should the email go to?
          </div>
        }
        {bulkEmail.estimated_lead_count > 0 &&
          <div>
            {bulkEmail.marketing_group &&
              <div>Sending to the <strong>{bulkEmail.marketing_group.name}</strong>{' '}
              group. (<strong>{bulkEmail.estimated_lead_count}</strong> people)</div>
            }
            {!bulkEmail.marketing_group &&
              <div>Sending to leads matching a <strong>Custom Filter</strong>{' '}
              (<strong>{bulkEmail.estimated_lead_count} people</strong>)</div>
            }
          </div>
        }
        {checklist.threshold_ok === false &&
          <div>You have included too many recipiants in this email.{' '}
            Try making the audience more specific. <a href='http://support.gymleads.net/pricing-privacy-and-terms/sending-reputation-and-limits'>Learn more about sending limits</a></div>
        }
        {bulkEmail.is_opt_in &&
          <div>
            <strong>
              This email includes an opt in link. Leasd that have already recieved a bulk email or SMS with an opt in link will be excluded.{' '}
              <a href='http://support.gymleads.net/pricing-privacy-and-terms/sending-reputation-and-limits'>Learn more about opt in.</a>
            </strong>
          </div>
        }
      </div>
    )
  }
}

ToRow.propTypes = {
  bulkEmail: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default ToRow
