import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'
import header from '../../header'

export class FromRow extends Component {

  goToSendSettings = () => {
    this.props.setView(header.constants.SETTINGS_VIEW)
  }

  appearAsString = () => {
    const { user, company, automationEmail } = this.props
    const fromName = (automationEmail.from_name) ? automationEmail.from_name :
      <span>{user.display_name} from {company.user_display_name}</span>
    return fromName
  }

  render () {
    const fromRowStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Email.Checklist.From
    const { automationEmail } = this.props
    const icon = 'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row-top'>
        <button
          className='pull-right btn btn-primary'
          onClick={this.goToSendSettings}>
          {fromRowStrings.editButton}
        </button>
        <i className={icon} />
        <h4>{fromRowStrings.from}</h4>
        <p>
          {!automationEmail.from_address &&
            <span>{STRINGS.formatString(fromRowStrings.emailUsed, <b>{this.props.productName}</b>)}</span>
          }
          {automationEmail.from_address &&
            <span>{STRINGS.formatString(fromRowStrings.emailUsed, <b>{automationEmail.from_address}</b>)}</span>
          }
          {STRINGS.formatString(fromRowStrings.emailUsed, <b>{this.appearAsString()}</b>)}
        </p>
      </div>
    )
  }
}

FromRow.propTypes = {
  user: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
  automationEmail: PropTypes.object.isRequired,
  productName: PropTypes.string,
  company: PropTypes.object.isRequired
}

export default FromRow
