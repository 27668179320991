import * as actionTypes from './actionTypes'
import { request, GET } from 'utils/apiUtils'

export const getStats = (groupId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setGrowthData(payload.growth_data))
      dispatch(setNewestUsers(payload.newest_users))
      dispatch(setLoading(false))
    }

    return request('marketing_groups/' + groupId + '/stats', GET, null, onSuccess, onFailure)
  }
}

function setGrowthData (growthData) {
  return {
    type: actionTypes.SET_GROWTH_DATA,
    growthData
  }
}

function setNewestUsers (newestUsers) {
  return {
    type: actionTypes.SET_NEWEST_USERS,
    newestUsers
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
