import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'
import moment from 'moment-timezone'

export class GroupItem extends Component {

  rowSelected = (e) => {
    const { group, onSelect } = this.props
    onSelect(group)
  }

  render () {
    const { group, timezone } = this.props
    return (
      <ContentBlockView>
        <div className='row selectable'
          onClick={this.rowSelected}>
          <div className='col-xs-6 push-10'>
            <h5>{group.name}</h5>
            <p>Created {moment(group.created_at).tz(timezone).format('Do MMM YYYY, h:mm a')}</p>
          </div>
          <div className='col-xs-6 push-10'>
            <p><span style={{ fontWeight: 'bold'}}>{group.lead_count}</span> leads</p>
          </div>
        </div>
      </ContentBlockView>
    )
  }
}

GroupItem.propTypes = {
  group: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default GroupItem
