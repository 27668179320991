import * as actionTypes from './actionTypes'

const LOADK_JOURNEY_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_AUTOMATION_EMAIL]: (state, action) => {
    return { ...state, automationEmail: action.automationEmail }
  },
  [actionTypes.SET_SELECTED_FUNNEL_STEP_ID]: (state, action) => {
    return { ...state, selectedFunnelStepId: action.selectedFunnelStepId }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  automationEmail: null,
  selectedFunnelStepId: null,
  loading: true,
  errors: null
}

export default function (state = initialState, action) {
  const handler = LOADK_JOURNEY_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
