import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const get = (bulkSmsId, pageNumber, search, statuses) => {
  return dispatch => {
    dispatch(setLoading(true))
    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(loadPageDetails(payload.meta))
      dispatch(setRecords(payload.leads_mass_lead_text_messages))
    }

    let url = 'mass_lead_text_messages/' + bulkSmsId + '/leads_mass_lead_text_messages?page[number]=' + pageNumber
    if (search) { url = url + '&search=' + search }
    statuses && statuses.forEach(function (status) {
      url = url + '&status[]=' + status
    })
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setRecords (records) {
  return {
    type: actionTypes.SET_RECORDS,
    records
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

function loadPageDetails (pageDetails) {
  return {
    type: actionTypes.PAGE_DETAILS_UPDATED,
    pageDetails
  }
}
