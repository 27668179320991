import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import EditTemplateView from '../components/EditTemplateView'
import billing from 'modules/stores/billing'
import * as selectors from '../selectors'

function mapStateToProps (state) {
  const getDefaultSmsCost = billing.selectors.getDefaultSmsCost(state)
  const defaultCost = (getDefaultSmsCost) ? getDefaultSmsCost.marketing_outbound_cost : 0.1
  return {
    text: selectors.getText(state),
    messageCost: defaultCost,
    previewText: selectors.getPreviewText(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTemplateView))
