import React, { Component } from 'react'
import { ImageEmptyView } from 'modules/components'
import DashboardEmpty from 'images/DashboardEmpty.jpg'

export class DashboardView extends Component {

  render () {
    return (
      <div className='content content-boxed'>
        <ImageEmptyView
          image={DashboardEmpty}
          title={"Dashboard coming soon"}
          subtitle="Quick and helpful stats for your marketing, coming soon!" />
      </div>
    )
  }
}

export default DashboardView
