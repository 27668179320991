import { NAME } from "./constants";

export const getErrors = state => state.templates.smsTemplates[NAME].errors;
export const getLoading = state => state.templates.smsTemplates[NAME].loading;
export const getDesigns = state => state.templates.smsTemplates[NAME].designs;
export const getTemplates = state =>
  state.templates.smsTemplates[NAME].templates;
export const getPageDetails = state =>
  state.templates.smsTemplates[NAME].pageDetails;
export const getPrivateRecipe = state =>
  state.templates.smsTemplates[NAME].privateRecipe;
export const getDesignsSearchTerm = state =>
  state.templates.smsTemplates[NAME].designsSearchTerm;
export const getDesignsSortOrder = state =>
  state.templates.smsTemplates[NAME].designsSortOrder;
export const getTemplatesSearchTerm = state =>
  state.templates.smsTemplates[NAME].templatesSearchTerm;
export const getTemplatesSortOrder = state =>
  state.templates.smsTemplates[NAME].templatesSortOrder;
