import PropTypes from "prop-types";
import React, { Component } from "react";

import { MultiLocationSelect } from "modules/components";
import { LocationButtonView } from "./LocationButtonView";

const SELECT_LOCATIONS_MODAL = "LOCATION_ENTRY/SELECT_LOCATIONS_MODAL";

class LocationEntryView extends Component {
  constructor(props) {
    super(props);
    if (props.data) {
      switch (typeof props.data.value) {
        case "number":
          this.state = { value: [props.data.value] };
          break;
        case "object":
          this.state = { value: props.data.value };
          break;
        case "string":
          this.state = {
            value: props.data.value
              .toString()
              .split(",")
              .map((i) => parseInt(i, 10)),
          };
          break;
        default:
          this.state = { value: [] };
          break;
      }
    } else {
      this.state = {
        value: [],
      };
    }
  }

  updateLocationIds = (selectedLocationIds) => {
    this.setState({ ...this.state, value: selectedLocationIds });
    this.updateWithResult({ value: selectedLocationIds });
  };

  updateWithResult = (result) => {
    const { id, ruleUpdated } = this.props;
    ruleUpdated(id, result);
  };

  showSelectLocationsModal = () => {
    const { showModal } = this.props;
    showModal(SELECT_LOCATIONS_MODAL);
  };

  buttonStringTitle = () => {
    const { locations, data } = this.props;
    const selectedLength = data ? data.value.length : 0;
    if (selectedLength === 0) {
      return "Select a location";
    } else if (selectedLength === 1) {
      const index = locations.findIndex(
        (location) => location.id === data.value[0]
      );
      if (index < 0) {
        return "A location you don't have access to";
      }
      return locations[index].display_name;
    } else {
      return selectedLength + " selected";
    }
  };

  render() {
    const { readonly, showClearSelectionButton } = this.props;
    const title = readonly ? "Locations Used" : "Select Locations";
    return (
      <div style={{ display: "inline" }}>
        <LocationButtonView
          locations={this.props.locations}
          data={this.props.data}
          onClick={this.showSelectLocationsModal}
        />
        {/* <span style={{ display: "inline-block" }}>
          <Button appearance="default" onClick={this.showSelectLocationsModal}>
            {this.buttonStringTitle()}
          </Button>
        </span> */}
        <span style={{ display: "inline-block" }}>
          <MultiLocationSelect.MultiLocationSelectModalContainer
            readonly={readonly}
            title={title}
            modalName={SELECT_LOCATIONS_MODAL}
            locationIds={this.state.value}
            onUpdate={this.updateLocationIds}
            showClearSelectionButton={showClearSelectionButton}
          />
        </span>
      </div>
    );
  }
}

LocationEntryView.propTypes = {
  id: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  ruleUpdated: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  data: PropTypes.object,
  showClearSelectionButton: PropTypes.bool,
};

LocationEntryView.defaultProps = {
  readonly: false,
  showClearSelectionButton: false,
};

export default LocationEntryView;
