import moment from 'moment-timezone'

import { pluralizationHelper } from 'modules/helpers/PluralizationHelpers'

const REFERENCE = moment()
const TODAY = REFERENCE.clone().startOf('day')
const FIVE_MINUTES_MINUTES_AGO = REFERENCE.clone().subtract(5, 'minute')
const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day')
const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day')

export function getSafeUTCDateAsStringFromDate (date) {
  if (!moment(date).isValid()) {
    return null
  }

  if (moment.isMoment(date)) {
    return date.format()
  } else {
    return moment(date).format()
  }
}

export function isToday (momentDate) {
  return momentDate.isSame(TODAY, 'd')
}

export function isYesterday (momentDate) {
  return momentDate.isSame(YESTERDAY, 'd')
}

export function isWithinAWeek (momentDate) {
  return momentDate.isAfter(A_WEEK_OLD)
}

export function isInPast (momentDate) {
  return momentDate.isBefore(REFERENCE)
}

export function isWithin5Minutes (momentDate) {
  return (momentDate.isBefore(FIVE_MINUTES_MINUTES_AGO) === true &&
    momentDate.isAfter(FIVE_MINUTES_MINUTES_AGO) === false)
}

export function isAfter5Minutes (momentDate) {
  return momentDate.isAfter(FIVE_MINUTES_MINUTES_AGO)
}

export function isTwoWeeksOrMore (momentDate) {
  return !isWithinAWeek(momentDate)
}

export function colorForDate (momentDate, timezone) {
  let now = moment().tz(timezone)
  const fiveMinutesBefore = momentDate.clone().subtract(5, 'minute')
  const fiveMinutesLater = momentDate.clone().add(5, 'minute')

  if (now.isBefore(fiveMinutesBefore)) { return null }
  if (now.isBefore(fiveMinutesLater)) { return 'text-warning' }
  return 'text-danger'
}

export function todayDateTimeString (datetime, timezone) {
  const createdMoment = moment(datetime).tz(timezone)
  const format = (isToday(createdMoment)) ? 'h:mm a' : 'dddd MMMM Do, h:mm a'
  const sentOnDateString = createdMoment.format(format)
  const todayString = (isToday(createdMoment)) ? 'Today at' : ''
  return todayString + ' ' + sentOnDateString
}

export function daysAgoString (lead, shorthand) {
  if (!lead.last_contact) {
    return (shorthand) ? 'Never' : 'Never contacted'
  }

  const today = moment().startOf('day')
  const lastContactMoment = moment(lead.last_contact).startOf('day')
  const days = Math.round((today - lastContactMoment) / 86400000)

  if (days === 0) {
    return (shorthand) ? 'Today' : 'Contacted today'
  }

  let daysString = days
  if (!shorthand) {
    return 'Last contacted ' + pluralizationHelper('days', days) + ' ago'
  }
  return daysString
}
