import * as actionTypes from './actionTypes'
import { HTML_TEMPLATES } from './constants'

const TEMPLATE_FILTERS_ACTION_HANDLERS = {
  [actionTypes.SET_SELECTION]: (state, action) => {
    return { ...state, selection: action.selection }
  }
}

const initialState = {
  selection: HTML_TEMPLATES,
}

export default function (state = initialState, action) {
  const handler = TEMPLATE_FILTERS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
