import * as actionTypes from './actionTypes'

const EDIT_FUNNEL_EMAIL_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_HAS_CHANGES]: (state, action) => {
    return { ...state, hasChanges: action.hasChanges }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState
  }
}

const initialState = {
  loading: false,
  hasChanges: false,
  errors: null
}

export default function (state = initialState, action) {
  const handler = EDIT_FUNNEL_EMAIL_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
