import { createSelector } from 'reselect'
import loadBulkSms from '../loadBulkSms'
import { NAME } from './constants'

export const getErrors = state => state.viewBulkSms[NAME].errors
export const getLoading = state => state.viewBulkSms[NAME].loading

export const formattedStatistics = createSelector(
  [ loadBulkSms.selectors.getBulkSms ],
  (bulkSms) => {
    return bulkSms.full_results
  }
)
