import PropTypes from 'prop-types'
import React, { Component } from 'react'

import PreviewModalContainer from '../containers/PreviewModalContainer'
import { SEND_PREVIEW_MODAL } from '../constants'

import STRINGS from 'strings'
const previewRowStrings = STRINGS.BulkMessaging.BulkSms.Checklist.PreviewRow

export class PreviewRow extends Component {

  showPreviewModal = () => {
    const { showModal } = this.props
    showModal(SEND_PREVIEW_MODAL)
  }

  render () {
    const { bulkSms } = this.props
    const checklist = bulkSms.checklist
    const icon = (!checklist.preview_sent) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        {bulkSms.status !== 'scheduled' &&
          <button
            className='pull-right btn btn-primary'
            disabled={!checklist.ready_to_send_preview}
            onClick={this.showPreviewModal}>
            {previewRowStrings.sendPreviewButton}
          </button>
        }
        <i className={icon} />
        <h4>{previewRowStrings.headeline}</h4>
        {checklist.ready_to_send_preview &&
          <div>
            {bulkSms.preview_sent_to &&
              <p>{`${previewRowStrings.previewSentTo} ${bulkSms.preview_sent_to}`}.</p>
            }
            {!bulkSms.preview_sent_to &&
              <p>{previewRowStrings.sendAPreview}</p>
            }
          </div>
        }
        {!checklist.ready_to_send_preview &&
          <p>{previewRowStrings.previewRowInvalidMessage}</p>
        }
        <PreviewModalContainer />
      </div>
    )
  }
}

PreviewRow.SubjectRow = {
  bulkSms: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
}

export default PreviewRow
