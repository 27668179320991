import { isPresent, isNumber, isPositiveNumber } from "utils/validation";

export const validator = {
  secondsToWait: [
    {
      rule: isPresent,
      errorMessage: "How long should we wait (enter 0 for no delay)",
    },
    {
      rule: isNumber,
      errorMessage: "This should be a numerical value",
    },
  ],
};
export const editTriggerValueValidator = {
  valueId: [
    {
      rule: isPositiveNumber,
      errorMessage: "This should be a positive numerical value",
    },
  ],
};
