import { combineReducers } from 'redux'
import loadBulkSms from './modules/loadBulkSms'
import checklist from './modules/checklist'
import audience from './modules/audience'
import content from './modules/content'
import settings from './modules/settings'

export const reducer = combineReducers({
  [loadBulkSms.constants.NAME]: loadBulkSms.reducer,
  [checklist.constants.NAME]: checklist.reducer,
  [audience.constants.NAME]: audience.reducer,
  [content.constants.NAME]: content.reducer,
  [settings.constants.NAME]: settings.reducer
})
