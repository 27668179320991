import PropTypes from 'prop-types'
import React, { Component } from 'react'

import alert from 'modules/components/alert'

import settings from '../../settings'
import links from '../../links'
import editHtmlTemplate from '../../editHtmlTemplate'
import editPlainTextTemplate from '../../editPlainTextTemplate'

import { ContentBlockView, NavLink, InfoView } from 'modules/components'

export class HeaderView extends Component {

  backSelected = () => {
    const { showWithConfig } = this.props
    showWithConfig({
      ...alert.defaultConfig,
      children: 'You will lose any unsaved changes',
      style: 'warning',
      onConfirm: this.goBack
    })
  }

  goBack = () => {
    this.props.history.push('/secure/templates')
  }

  helperText = () => {
    const { template } = this.props
    if (!template.system_template_name) { return }
    let detail
    switch (template.system_template_name) {
      case 'QuickEmail':
        detail = "This template is used for the 'quick email' template on the sales platform."
        break
      case 'OptInEmail':
        detail = "This template is used for opting leads in."
        break
      default:
        detail = "Unkown template."
        break
    }
    return detail + '\n System templates cannot have their settings changed.'
  }

  render () {
    const { template, currentPath } = this.props
    const baseLink = '/secure/templates/email/' + template.id
    const helperText = this.helperText()
    return (
      <ContentBlockView>
        <div className='row'>
          <div className='col-md-6'>
            <i className='si si-arrow-left fa-2x pull-left selectable'
              style={{ marginTop: '5px', marginRight: '20px' }}
              onClick={this.backSelected} />
            <h2 className='push'>
              {template.title}
            </h2>
          </div>
        </div>
        {helperText &&
          <InfoView colorThemeClass={'info'} title={'This is a system template'} detail={helperText} />
        }
        {template.design_type === 'user' &&
          <div className='row'>
            <div className='col-xs-12'>
              <div className='block'>
                <ul className='nav nav-tabs nav-tabs-alt'>
                  {template.template_type === 'html' &&
                    <NavLink
                      to={baseLink + '/' + editHtmlTemplate.constants.NAME}
                      currentPath={currentPath}>
                      <i className='si si-pencil' /> Edit
                    </NavLink>
                  }
                  {template.template_type === 'quick_send' &&
                    <NavLink
                      to={baseLink + '/' + editPlainTextTemplate.constants.NAME}
                      currentPath={currentPath}>
                      <i className='si si-pencil' /> Edit
                    </NavLink>
                  }
                  {template.template_type === 'html' &&
                    <NavLink
                      to={baseLink + '/' + links.constants.NAME}
                      currentPath={currentPath}>
                      <i className='si si-link' /> Links
                    </NavLink>
                  }
                  <NavLink
                    to={baseLink + '/' + settings.constants.NAME}
                    currentPath={currentPath}>
                    <i className='si si-settings' /> Settings
                  </NavLink>
                </ul>
              </div>
            </div>
          </div>
        }
      </ContentBlockView>
    )
  }
}

HeaderView.propTypes = {
  template: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  showWithConfig: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
}

export default HeaderView
