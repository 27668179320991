import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'

import conditions from '../modules/conditions'
import audienceRecords from '../modules/audienceRecords'

export class AudienceView extends Component {

  render () {
    const { bulkEmail } = this.props
    return (
      <div>
        <ContentBlockView title={'Audience Breakdown'} bordered disabled={bulkEmail.status === 'scheduled'}>
          <div className='row' style={{ marginLeft: '-20px', marginRight: '-20px' }}>
            <div className='col-xs-4 options-container-left'>
              <conditions.ConditionsContainer bulkEmail={bulkEmail} />
            </div>
            <div className='col-xs-8'>
              <audienceRecords.AudienceRecordsContainer />
            </div>
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

AudienceView.propTypes = {
  bulkEmail: PropTypes.object.isRequired
}

export default AudienceView
