import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PrivateRouteContainer, ImageEmptyView } from 'modules/components'

import loadBulkEmail from '../modules/loadBulkEmail'
import checklist from '../modules/checklist'
import settings from '../modules/settings'
import content from '../modules/content'
import audience from '../modules/audience'
import header from '../modules/header'

import emptyImage from 'images/TemplatesEmpty.jpg'

import STRINGS from 'strings'
const bulkEmailStrings = STRINGS.BulkMessaging.BulkEmail.Header

export class EditTemplateView extends Component {

  componentWillUnmount() {
    this.props.reset()
  }

  backSelected = () => {
    this.props.history.push('/secure/bulkMessaging')
  }

  render () {
    const { bulkEmail, notFound } = this.props
    return (
      <div className='content'>
        {bulkEmail &&
          <div>
            <header.HeaderContainer />
            {bulkEmail.status === 'scheduled' &&
              <div className='alert alert-warning'>
                <p>
                  <i className='fa fa-2x fa-clock-o push-5-r' />
                  {bulkEmailStrings.cantEditDescription}
                </p>
              </div>
            }
            <PrivateRouteContainer exact path={"/secure/bulkMessaging/editBulkEmail/:id/" + checklist.constants.NAME} component={checklist.ChecklistContainer} />
            <PrivateRouteContainer exact path={"/secure/bulkMessaging/editBulkEmail/:id/" + content.constants.NAME} component={content.ContentContainer} />
            <PrivateRouteContainer exact path={"/secure/bulkMessaging/editBulkEmail/:id/" + audience.constants.NAME} component={audience.AudienceContainer} />
            <PrivateRouteContainer exact path={"/secure/bulkMessaging/editBulkEmail/:id/" + settings.constants.NAME} component={settings.SettingsContainer}  />
          </div>
        }
        {!bulkEmail && !notFound &&
          <loadBulkEmail.LoadBulkEmailContainer />
        }
        {!bulkEmail && notFound &&
          <ImageEmptyView
            image={emptyImage}
            title={bulkEmailStrings.emailNotFoundTitle}
            subtitle={bulkEmailStrings.emailNotFoundSubtitle}
            buttonText={bulkEmailStrings.emailNotFoundButton}
            buttonSelected={this.backSelected} />
        }
      </div>
    )
  }
}

EditTemplateView.propTypes = {
  bulkEmail: PropTypes.object,
  notFound: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired
}

export default EditTemplateView
