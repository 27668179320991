import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView, SimpleImageEmptyView } from 'modules/components'

import LeadsEmpty from 'images/LeadsEmpty.jpg'

export class NewestMembersView extends Component {

  render () {
    const { users } = this.props
    return (
      <ContentBlockView title={'Newest members'} bordered minHeight={400}>
        {users &&
          <div />
        }
        {!users &&
          <SimpleImageEmptyView
            image={LeadsEmpty}
            title={'No leads yet'}
            subtitle={'We\'ll show you the newest leads for this group, when they are in the group'}
            height={200}
           />
        }
      </ContentBlockView>
    )
  }
}

NewestMembersView.propTypes = {
  newestUsers: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default NewestMembersView
