import { NAME } from './constants'

export const SET_RULES = NAME + '/SET_RULES'
export const SET_CONDITION = NAME + '/SET_CONDITION'

export const RESET = NAME + '/RESET'

export const ADD_RULE = NAME + '/ADD_RULE'
export const UPDATE_RULE = NAME + '/UPDATE_RULE'
export const REMOVE_RULE = NAME + '/REMOVE_RULE'

export const SET_EVENT = NAME + '/SET_EVENT'
export const SET_ENTRY_IS_AUTOMATIC = NAME + "/SET_ENTRY_IS_AUTOMATIC"

export const SET_HAS_CHANGES = NAME + "/SET_HAS_CHANGES"
