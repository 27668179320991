import * as actionTypes from "./actionTypes";
import SortOptions from "./sortOptions";

const SELECT_DESIGN_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading };
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors };
  },
  [actionTypes.SET_DESIGNS]: (state, action) => {
    return { ...state, designs: action.designs };
  },
  [actionTypes.SET_TEMPLATES]: (state, action) => {
    return { ...state, templates: action.templates };
  },
  [actionTypes.SET_DESIGNS_SORT_ORDER]: (state, action) => {
    return { ...state, designsSortOrder: action.sortOrder };
  },
  [actionTypes.SET_DESIGNS_SEARCH_TERM]: (state, action) => {
    return { ...state, designsSearchTerm: action.searchTerm };
  },
  [actionTypes.SET_TEMPLATES_SORT_ORDER]: (state, action) => {
    return { ...state, templatesSortOrder: action.sortOrder };
  },
  [actionTypes.SET_TEMPLATES_SEARCH_TERM]: (state, action) => {
    return { ...state, templatesSearchTerm: action.searchTerm };
  },
  [actionTypes.RESET_SORT_SEARCH]: state => {
    return {
      ...state,
      designsSortOrder: initialState.designsSortOrder,
      designsSearchTerm: initialState.designsSearchTerm,
      templatesSortOrder: initialState.templatesSortOrder,
      templatesSearchTerm: initialState.templatesSearchTerm
    };
  },
  [actionTypes.SET_PRIVATE_RECIPE]: (state, action) => {
    return { ...state, privateRecipe: action.privateRecipe };
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return {
      ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    };
  }
};

const initialState = {
  loading: false,
  errors: null,
  privateRecipe: null,
  templates: [],
  designs: [],
  designsSearchTerm: null,
  designsSortOrder: SortOptions[3].id,
  templatesSearchTerm: null,
  templatesSortOrder: SortOptions[3].id,
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
};

export default function(state = initialState, action) {
  const handler = SELECT_DESIGN_ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
