import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class GroupRow extends Component {

  rowSelected = (event) => {
    const { group, selectGroup } = this.props
    selectGroup(group)
  }

  render () {
    const { group, selected } = this.props
    const className = (selected) ? 'selected' : 'selectable'
    return (
      <tr className={className} onClick={() => this.rowSelected(group)}>
        <td>{group.name}</td>
        <td className='text-center'>{group.lead_count}</td>
      </tr>
    )
  }
}

GroupRow.propTypes = {
  group: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectGroup: PropTypes.func.isRequired
}


export default GroupRow
