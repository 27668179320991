import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import BulkMessageListingView from "../components/BulkMessageListingView";
import session from "modules/stores/session";
import * as selectors from "../selectors";
import * as actions from "../actions";
import filters from "../../filters";

function mapStateToProps(state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    bulkEmails: selectors.getBulkEmails(state),
    pageDetails: selectors.getPageDetails(state),
    status: filters.selectors.getSelection(state),
    searchTerm: selectors.getSearchTerm(state),
    sortOrder: selectors.getSortOrder(state),
    selectedLocations: selectors.getSelectedLocations(state),
    userPermissionRuleSet: session.selectors.getUserPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBulkEmails: actions.getBulkEmails,
      sortSearchUpdated: actions.sortSearchUpdated,
      selectedLocationsUpdated: actions.selectedLocationsUpdated,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BulkMessageListingView)
);
