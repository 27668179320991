import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ModalContainer, ImageEmptyView } from 'modules/components'
import { UPDATE_SCHEDULE_MODAL_VIEW } from '../constants'
import alert from 'modules/components/alert'

import ScheduleEmpty from 'images/ScheduleEmpty.jpg'

import EditScheduleFormContainer from '../containers/EditScheduleFormContainer'
import store from 'store/store'

import STRINGS from 'strings'
const scheduleStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Schedule

const Provider = require('react-redux').Provider

export class ScheduleModal extends Component {

  updateSelected = () => {
    const { automationFunnel, selectedFunnelStep, updateSchedule, data } = this.props
    const schedule = selectedFunnelStep.automation_funnel_step_schedule
    updateSchedule(automationFunnel.id, selectedFunnelStep.id, schedule.id, data)
  }

  createNewSchedule = () => {
    const{ automationFunnel, selectedFunnelStep, createNewSchedule } = this.props
    createNewSchedule(automationFunnel.id, selectedFunnelStep.id)
  }

  deleteScheduleConfirmed = () => {
    const{ automationFunnel, selectedFunnelStep, deleteSchedule } = this.props
    const schedule = selectedFunnelStep.automation_funnel_step_schedule
    deleteSchedule(automationFunnel.id, selectedFunnelStep.id, schedule.id)
  }

  deleteScheduleSelected = () => {
    const { showAlertWithConfig } = this.props
    const alertConfig = {
      ...alert.defaultConfig,
      children: scheduleStrings.removeScheduleWarning,
      style: 'danger',
      onConfirm: this.deleteScheduleConfirmed
    }
    showAlertWithConfig(alertConfig)
  }

  buttonEnabled = () => {
    const daysAllowed = this.props.data.fields.daysAllowed.value
    if (daysAllowed && daysAllowed.length > 0) {
      return true
    } else {
      return false
    }
  }

  render () {
    const { selectedFunnelStep, loading } = this.props
    const updateButton = (selectedFunnelStep && selectedFunnelStep.automation_funnel_step_schedule) ? <span>Update</span> : null
    return (
      <div>
        {selectedFunnelStep &&
          <ModalContainer
            modalName={UPDATE_SCHEDULE_MODAL_VIEW}
            modalTitle={`${scheduleStrings.setScheduleFor} ${selectedFunnelStep.name}`}
            colorThemeClass={'primary'}
            showCancelButton
            width='x-large'
            actionEnabled={this.buttonEnabled()}
            actionButtonClicked={this.updateSelected}
            actionButtonElement={updateButton}>
            {!selectedFunnelStep.automation_funnel_step_schedule &&
              <ImageEmptyView
                image={ScheduleEmpty}
                title={scheduleStrings.noSchedule}
                subtitle={scheduleStrings.noScheduleMessage}
                buttonText={scheduleStrings.setUpSchedule}
                loading={loading}
                buttonSelected={this.createNewSchedule}
                colorThemeClass={'success'} />
            }
            {selectedFunnelStep.automation_funnel_step_schedule &&
              <div className='push-100'>
                <Provider store={store}>
                  <EditScheduleFormContainer />
                </Provider>
                <div className='row push-20-t'>
                  <div className='col-xs-12'>
                    <button className='btn btn-danger push' onClick={this.deleteScheduleSelected}>
                      <i className='fa fa-cross' /> {scheduleStrings.deleteSchedule}
                    </button>
                  </div>
                </div>
              </div>
            }
          </ModalContainer>
        }
      </div>
    )
  }
}

ScheduleModal.propTypes = {
  automationFunnel: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedFunnelStep: PropTypes.object,
  createNewSchedule: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateSchedule: PropTypes.func.isRequired,
  deleteSchedule: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
}

export default ScheduleModal
