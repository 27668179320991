import { request, POST, PUT, DELETE } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { fieldNames } from './constants'
import flags from 'modules/components/flags'
import modal from 'modules/stores/modal'

import { formDataFromData } from 'utils/formHelpers'

import listing from '../listing'

import STRINGS from 'strings'
const scheduleStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Schedule

export function prefillDataForSchedule (schedule) {
  return {
    type: actionTypes.PREFIL_DATA_FOR_SCHEDULE,
    schedule
  }
}

export const createNewSchedule = (funnelId, stepId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading())

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(listing.actions.setScheduleForStepId(stepId, payload.automation_funnel_step_schedule))
      dispatch(prefillDataForSchedule(payload.automation_funnel_step_schedule))
      dispatch(modal.actions.stopLoading())
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: scheduleStrings.scheduleCreatedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + funnelId +
    '/automation_funnel_steps/' + stepId +
    '/automation_funnel_step_schedules'
    return request(url, POST, null, onSuccess, onFailure)
  }
}

export const updateSchedule = (funnelId, stepId, scheduleId, formData) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading())
    const body = {
      automation_funnel_step_schedule: formDataFromData(formData, fieldNames)
    }

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(listing.actions.setScheduleForStepId(stepId, payload.automation_funnel_step_schedule))
      dispatch(modal.actions.stopLoading())
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: scheduleStrings.scheduleUpdatedNotification,
        index: 0
      }))
      dispatch(modal.actions.hideModal())
    }

    const url = 'automation_funnels/' + funnelId +
    '/automation_funnel_steps/' + stepId +
    '/automation_funnel_step_schedules/' + scheduleId
    return request(url, PUT, body, onSuccess, onFailure)
  }
}

export const deleteSchedule = (funnelId, stepId, scheduleId) => {
  return (dispatch) => {
    dispatch(modal.actions.startLoading())

    function onFailure (payload) {
      dispatch(modal.actions.stopLoading())
      dispatch(modal.actions.showErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(listing.actions.setScheduleForStepId(stepId, null))
      dispatch(prefillDataForSchedule(null))
      dispatch(modal.actions.stopLoading())
      dispatch(flags.actions.showFlag({
        appearance: 'success',
        title: scheduleStrings.scheduleRemovedNotification,
        index: 0
      }))
    }

    const url = 'automation_funnels/' + funnelId +
    '/automation_funnel_steps/' + stepId +
    '/automation_funnel_step_schedules/' + scheduleId
    return request(url, DELETE, null, onSuccess, onFailure)
  }
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setSelectedFunnelStepId (selectedFunnelStepId) {
  return {
    type: actionTypes.SET_SELECTED_FUNNEL_STEP_ID,
    selectedFunnelStepId
  }
}
