import PropTypes from "prop-types";
import React, { Component } from "react";
import alert from "modules/components/alert";
import { SCHEDULE_SEND_MODAL } from "../constants";

import { SimpleImageEmptyView } from "modules/components";
import ScheduleModalContainer from "../containers/ScheduleModalContainer";

import SmsReady from "images/SmsReady.jpg";

import STRINGS from "strings";
const draftSendViewStrings =
  STRINGS.BulkMessaging.BulkSms.Checklist.DraftSendView;

export class DraftSendView extends Component {
  showSendWarning = () => {
    const { showAlertWithConfig, bulkSms } = this.props;
    const message = STRINGS.formatString(
      draftSendViewStrings.sendButtonConfirmation,
      bulkSms.total_leads_count
    );
    const alertConfig = {
      ...alert.defaultConfig,
      children: message,
      onConfirm: this.sendSms,
      style: "warning",
    };
    showAlertWithConfig(alertConfig);
  };

  showScheduleModal = () => {
    this.props.showModal(SCHEDULE_SEND_MODAL);
  };

  sendSms = () => {
    const { startSend, bulkSms } = this.props;
    startSend(bulkSms);
  };

  render() {
    const { bulkSms } = this.props;
    return (
      <div>
        <SimpleImageEmptyView
          image={SmsReady}
          title={""}
          subtitle={`${draftSendViewStrings.checklistCompleteSubtitle1} ${bulkSms.total_leads_count} ${draftSendViewStrings.checklistCompleteSubtitle2}`}
        />
        <div className="row">
          <div className={"col-sm-6 col-sm-offset-3 text-center text-success"}>
            <button
              className={
                "btn btn-minw btn-rounded btn-noborder btn-success push-5"
              }
              onClick={this.showSendWarning}
            >
              {draftSendViewStrings.sendSmsNowButton}
            </button>
          </div>
          <div className={"col-sm-6 col-sm-offset-3 text-center push-5"}>
            <p style={{ marginBottom: "0px" }}>Or</p>
          </div>
          <div
            className={"col-sm-6 col-sm-offset-3 text-center text-primary"}
            style={{ marginBottom: "20px" }}
          >
            <button
              className={
                "btn btn-minw btn-rounded btn-noborder btn-primary push-5"
              }
              onClick={this.showScheduleModal}
            >
              {draftSendViewStrings.scheduleLaterButton}
            </button>
          </div>
        </div>
        <ScheduleModalContainer
          title={draftSendViewStrings.scheduleModalTitle}
          buttonTitle={draftSendViewStrings.scheduleModalButton}
          color={"success"}
          modalName={SCHEDULE_SEND_MODAL}
        />
      </div>
    );
  }
}

DraftSendView.SubjectRow = {
  bulkSms: PropTypes.object.isRequired,
  startSend: PropTypes.func.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default DraftSendView;
