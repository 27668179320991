import { createSelector } from 'reselect'
import { NAME } from './constants'
import * as listingSelectors from '../listing/selectors'

export const getLoading = state => state.editAutomationFunnel.steps[NAME].loading
export const getSelectedFunnelStepId = state => state.editAutomationFunnel.steps[NAME].selectedFunnelStepId
export const getData = state => state.editAutomationFunnel.steps[NAME].data
export const getErrors = state => state.editAutomationFunnel.steps[NAME].errors

export const selectedFunnelStep = createSelector(
  [ getSelectedFunnelStepId, listingSelectors.getSteps ],
  (selectedFunnelStepId, steps) => {
    if (!selectedFunnelStepId) { return null }
    const index = steps.findIndex(step => parseInt((step.id), 10) === parseInt((selectedFunnelStepId), 10))
    return steps[index]
  }
)
