import { NAME } from "./constants";

export const getErrors = state => state.bulkMessaging.bulkEmails[NAME].errors;
export const getLoading = state => state.bulkMessaging.bulkEmails[NAME].loading;
export const getBulkEmails = state =>
  state.bulkMessaging.bulkEmails[NAME].bulkEmails;
export const getPageDetails = state =>
  state.bulkMessaging.bulkEmails[NAME].pageDetails;
export const getSearchTerm = state =>
  state.bulkMessaging.bulkEmails[NAME].searchTerm;
export const getSortOrder = state =>
  state.bulkMessaging.bulkEmails[NAME].sortOrder;
export const getSelectedLocations = state =>
  state.bulkMessaging.bulkEmails[NAME].selectedLocations;
