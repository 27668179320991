import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentBlockView } from "modules/components";

import image from "images/plain_email.png";

import moment from "moment-timezone";

import { RowContainer, PreviewImageContainer } from "./styles";

export class TemplateRow extends Component {
  rowSelected = e => {
    const { template, onSelect } = this.props;
    onSelect(template);
  };

  render() {
    const { template, timezone, showCreatedBy } = this.props;
    const createdBy = (template.user && template.user.display_name) || null;

    return (
      <ContentBlockView>
        <RowContainer onClick={this.rowSelected}>
          <PreviewImageContainer>
            <img
              src={image}
              style={{
                width: "70px",
                maxHeight: "88px",
                marginBottom: "20px"
              }}
              alt="email preview"
            />
          </PreviewImageContainer>
          <div>
            <h5>{template.title}</h5>
            <p>
              Last updated{" "}
              {moment(template.updated_at)
                .tz(timezone)
                .format("Do MMM YYYY, h:mm a")}
              <br />
              {showCreatedBy && createdBy && `Created by ${createdBy}`}
            </p>
          </div>
        </RowContainer>
      </ContentBlockView>
    );
  }
}

TemplateRow.propTypes = {
  template: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  showCreatedBy: PropTypes.bool.isRequired
};

export default TemplateRow;
