import * as actionTypes from './actionTypes'

const COMPLETED_LEADS_ACTION_HANDLERS = {
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_JOIN_ROWS]: (state, action) => {
    return { ...state, joinRows: action.joinRows }
  },
  [actionTypes.REMOVE_JOIN_ROW_WITH_ID]: (state, action) => {
    const index = state.joinRows.findIndex(joinRow => parseInt((joinRow.id), 10) === parseInt((action.joinRowId), 10))
    const newJoinRows = [
      ...state.joinRows.slice(0, index),
      ...state.joinRows.slice(index + 1)
    ]
    return { ...state, joinRows: newJoinRows }
  },
  [actionTypes.PAGE_DETAILS_UPDATED]: (state, action) => {
    return { ...state,
      pageDetails: {
        pageCount: action.pageDetails.total_pages,
        currentPage: action.pageDetails.current_page,
        totalLeads: action.pageDetails.total_count
      }
    }
  },
  [actionTypes.RESET]: (state, action) => {
      return initialState
  }
}

const initialState = {
  loading: false,
  errors: null,
  joinRows: [],
  pageDetails: {
    currentPage: 1,
    pageCount: 0,
    total: 0
  }
}

export default function reducer (state = initialState, action) {
  const handler = COMPLETED_LEADS_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
