import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'

import alert from 'modules/components/alert'

import entryRules from '../modules/entryRules'
import exitRules from '../modules/exitRules'
import showAudience from '../modules/showAudience'
import './styles.css'

import HelpView from './HelpView'

class RulesView extends Component {

  componentDidMount () {
    const { setAlertConfig } = this.props
    const alertConfig = {
      ...alert.defaultConfig,
      children: 'This will update the rules for this group. All leads that match the rules will be added immedieately and leads will be added automatically. Existing members won\'t be affected.',
      style: 'warning',
      onConfirm: this.updateGroup
    }
    setAlertConfig(alertConfig)
  }

  saveClicked = () => {
    this.props.setAlertShowing(true)
  }

  updateGroup = () => {
    const { group, updateBody, update } = this.props
    update(group.id, updateBody)
  }

  render () {
    const { group, productName } = this.props
    return (
      <div>
        <HelpView productName={productName}/>
        <ContentBlockView title='Automation rules' bordered>
          <div className='row' style={{ marginLeft: '-20px', marginRight: '-20px' }}>
            <div className='col-xs-4 options-container-left'>
              <entryRules.EntryRulesContainer group={group} />
            </div>
            <div className='col-xs-4'>
              <showAudience.ShowAudienceContainer />
            </div>
            <div className='col-xs-4 options-container-right'>
              <exitRules.ExitRulesContainer group={group} />
            </div>
          </div>
        </ContentBlockView>
        <div className='row'>
          <div className='col-xs-6 push-20'>
            <button className='btn btn-primary' onClick={this.saveClicked}>
              <i className='fa fa-save' /> Update member rules
            </button>
          </div>
        </div>
      </div>
    )
  }
}

RulesView.propTypes = {
  group: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  updateBody: PropTypes.object.isRequired,
  setAlertShowing: PropTypes.func.isRequired,
  setAlertConfig: PropTypes.func.isRequired,
  productName: PropTypes.string,
  rules: PropTypes.object,
}

export default RulesView
