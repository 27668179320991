import PropTypes from "prop-types";
import React from "react";

import LeadRow from "./LeadRow";
import STRINGS from "strings";

function LeadsTableView({ leads, addLead }) {
  return (
    <table className="table table-hover table-vcenter">
      <thead>
        <tr>
          <th>{STRINGS.Global.name}</th>
          <th>{STRINGS.Global.email}</th>
          <th>{STRINGS.Global.number}</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {leads.map((lead, index) => {
          return <LeadRow lead={lead} key={index} addLead={addLead} />;
        })}
      </tbody>
    </table>
  );
}

LeadsTableView.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.object).isRequired,
  addLead: PropTypes.func.isRequired
};

export default LeadsTableView;
