import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import alert from 'modules/components/alert'

import StartView from '../components/StartView'
import * as selectors from '../selectors'
import * as actions from '../actions'
import loadAutomationFunnel from '../../loadAutomationFunnel'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    automationFunnel: loadAutomationFunnel.selectors.getAutomationFunnel(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    startAutomation: actions.startAutomation,
    showAlertWithConfig: alert.actions.showWithConfig,
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartView))
