import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LeadsEmpty from 'images/LeadsEmpty.jpg'

import equal from 'deep-equal'

import { SimpleImageEmptyView, GymLeadsLoader, LeadSquareView } from 'modules/components'
import './styles.css'

class ShowAudienceView extends Component {

  componentDidMount () {
    const { getPreviewLeads, entryRulesJson } = this.props
    getPreviewLeads(entryRulesJson)
  }

  componentWillReceiveProps (nextProps) {
    if (equal(nextProps.entryRulesJson, this.props.entryRulesJson) === false) {
      nextProps.getPreviewLeads(nextProps.entryRulesJson)
    }
  }

  render () {
    const { previewLeads, loading, totalCount } = this.props
    return (
      <div>
        <GymLeadsLoader active={loading} />
        {!loading && previewLeads.length === 0 &&
          <SimpleImageEmptyView
            image={LeadsEmpty}
            height={100}
            title={'No leads selected'}
            subtitle={'Select some rules on how leads should enter this group'} />
        }
        {!loading && previewLeads.length > 0 &&
          <div className='audience'>
            <p className='audience-header'>Leads who currently match filters: </p>
            <div className='group-users'>
              {previewLeads.map((lead, i) => (
                <LeadSquareView lead={lead} key={i} />
              ))}
            </div>
            <p><span className='audience-count-number'>{totalCount}</span> leads</p>
          </div>
        }
      </div>
    )
  }
}

ShowAudienceView.propTypes = {
  previewLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  getPreviewLeads: PropTypes.func.isRequired,
  entryRulesJson: PropTypes.object.isRequired
}

ShowAudienceView.defaultProps = {
  count: 0
}

export default ShowAudienceView
