import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { generateNewFormState } from 'utils/formHelpers'

import DaySelect from './DaySelect'
import SendTimeSelect from './SendTimeSelect'

import DropdownMenu, {
  DropdownItem,
} from '@atlaskit/dropdown-menu'

import STRINGS from 'strings'
const scheduleStrings = STRINGS.Funnels.EditAutomationFunnel.ActiveFunnel.Steps.Schedule

const sendOptions = [{
  id: 'anytime',
  display: 'anytime'
}, {
  id: 'before',
  display: 'before'
}, {
  id: 'after',
  display: 'after'
}, {
  id: 'between',
  display: 'between'
}]

export class EditScheduleFormView extends Component {

  handleChange = (fieldName, value, isValid) => {
    const newData = generateNewFormState(this.props.data, fieldName, value, isValid)
    this.props.updateData(newData)
  }

  sendSelected = (option) => {
    const newData = generateNewFormState(this.props.data, 'beforeAfterBetween', option, true)
    this.props.updateData(newData)
  }

  daysUpdated = (newDays) => {
    const newData = generateNewFormState(this.props.data, 'daysAllowed', newDays, true)
    this.props.updateData(newData)
  }

  render () {
    const { data, updateData, timezone } = this.props
    const dayString = data.fields.daysAllowed.value
    const beforeAfterBetween = data.fields.beforeAfterBetween
    return (
      <div>
        <div className='row push-20'>
          <div className='col-xs-12'>
            <h5>{scheduleStrings.whichDaysToSend}</h5>
            <DaySelect dayString={dayString} daysUpdated={this.daysUpdated} />
          </div>
        </div>
        <div className='row'>
          <div className={'col-md-12 col-xs-12'} style={{ display: 'inline' }}>
            <h5>{scheduleStrings.whichTimesToSend}</h5>
            Send{' '}
            <div style={{ display: 'inline-block' }}>
              <DropdownMenu
                trigger={<span>{beforeAfterBetween.value}</span>}
                triggerType="button">
                {sendOptions.map((option, i) => (
                  <DropdownItem key={i} id={option.id} onClick={()=> this.sendSelected(option.id)}>
                    {option.display}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </div>
            <SendTimeSelect updateData={updateData} data={data} timezone={timezone} />
          </div>
        </div>
      </div>
    )
  }
}

EditScheduleFormView.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
}

export default EditScheduleFormView
