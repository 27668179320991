import PropTypes from "prop-types";
import React, { Component } from "react";
import alert from "modules/components/alert";

import { SimpleImageEmptyView } from "modules/components";
import { EDIT_SCHEDULE_SEND_MODAL } from "../constants";
import ScheduleModalContainer from "../containers/ScheduleModalContainer";

import { isToday } from "modules/helpers/DateHelpers";
import moment from "moment-timezone";

import EmailReady from "images/EmailReady.jpg";

import STRINGS from "strings";
const checklistScheduleStrings =
  STRINGS.BulkMessaging.BulkEmail.Checklist.ScheduleSend;

export class ScheduledSendView extends Component {
  showWarning = () => {
    const { showAlertWithConfig } = this.props;
    const message = checklistScheduleStrings.cancelWarningMessage;
    const alertConfig = {
      ...alert.defaultConfig,
      children: message,
      onConfirm: this.cancelSend,
      style: "warning",
    };
    showAlertWithConfig(alertConfig);
  };

  cancelSend = () => {
    const { cancelScheduledSend, bulkEmail } = this.props;
    cancelScheduledSend(bulkEmail);
  };

  showScheduleModal = () => {
    this.props.showModal(EDIT_SCHEDULE_SEND_MODAL);
  };

  timeAway = () => {
    const { bulkEmail, timezone } = this.props;
    const dueMoment = moment(bulkEmail.schedule_send_time).tz(timezone);
    const hoursDuration = dueMoment.diff(moment().tz(timezone), "hours");
    const days = Math.floor(hoursDuration / 24);
    const hours = Math.floor(hoursDuration % 24);
    if (days === 0 && hours < 1) {
      return "Sending this hour";
    }
    const dayLabel = days === 1 ? "day" : "days";
    const hourLabel = hours === 1 ? "hour" : "hours";
    if (days < 1) {
      return `${checklistScheduleStrings.sendingIn} ${hours} ${hourLabel}`;
    } else {
      return `${checklistScheduleStrings.sendingIn} ${days} ${dayLabel} ${STRINGS.Global.and} ${hours} ${hourLabel}`;
    }
  };

  atTimeString = () => {
    const { bulkEmail, timezone } = this.props;
    const dueMoment = moment(bulkEmail.schedule_send_time).tz(timezone);
    const format = isToday(dueMoment) ? "h:mm a" : "dddd MMMM Do, h:mm a";
    const dueDateString = dueMoment.format(format);
    const timeString = isToday(dueMoment) ? "today at" : "at";
    return timeString + " " + dueDateString;
  };

  render() {
    const { bulkEmail, timezone } = this.props;

    return (
      <div>
        <SimpleImageEmptyView
          image={EmailReady}
          title={this.timeAway()}
          subtitle={`${checklistScheduleStrings.sendToPeople} ${
            bulkEmail.estimated_lead_count
          } ${checklistScheduleStrings.people} ${this.atTimeString()} ${
            checklistScheduleStrings.inTimezone
          } ${timezone}.`}
        />
        <div className="row">
          <div className={"col-sm-6 col-sm-offset-3 text-center text-danger"}>
            <button
              className={
                "btn btn-minw btn-rounded btn-noborder btn-danger push-5"
              }
              onClick={this.showWarning}
            >
              {checklistScheduleStrings.cancelScheduleSendButton}
            </button>
          </div>
          <div className={"col-sm-6 col-sm-offset-3 text-center push-5"}>
            <p style={{ marginBottom: "0px" }}>{STRINGS.Global.or}</p>
          </div>
          <div
            className={"col-sm-6 col-sm-offset-3 text-center text-primary"}
            style={{ marginBottom: "20px" }}
          >
            <button
              className={
                "btn btn-minw btn-rounded btn-noborder btn-primary push-5"
              }
              onClick={this.showScheduleModal}
            >
              {checklistScheduleStrings.changeSendTimeButton}
            </button>
          </div>
        </div>
        <ScheduleModalContainer
          title={checklistScheduleStrings.scheduleModalTitle}
          buttonTitle={checklistScheduleStrings.scheduleModalButton}
          color={"primary"}
          modalName={EDIT_SCHEDULE_SEND_MODAL}
        />
      </div>
    );
  }
}

ScheduledSendView.SubjectRow = {
  cancelScheduledSend: PropTypes.func.isRequired,
  bulkEmail: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  showAlertWithConfig: PropTypes.func.isRequired,
};

export default ScheduledSendView;
