import PropTypes from 'prop-types'
import React, { Component } from 'react'

import RecordRow from './RecordRow'
import STRINGS from 'strings'
const globalStrings = STRINGS.Global

export class RecordsTableView extends Component {

  render () {
    const { records } = this.props
    return (
      <table className='table table-hover table-vcenter'>
        <thead>
          <tr>
            <th>{globalStrings.Name}</th>
            <th>{globalStrings.number}</th>
            <th style={{ width: '75%' }}>{globalStrings.status}</th>
          </tr>
        </thead>
        <tbody>
          {records.map((record) => {
            return <RecordRow record={record} key={record.id} />
          })}
        </tbody>
      </table>

    )
  }
}

RecordsTableView.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default RecordsTableView
