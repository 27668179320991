const initialState = {
  loading: false,
  errors: null,
  initialLoadComplete: false,
  locations: [],
  isMultiLocationAccount: false,
  defaultLocation: null,
  pageDetails: {
    pageCount: 0,
    currentPage: 1,
    totalCount: 0
  }
};

export default initialState;
