import content from './modules/content'
import loadBulkSms from './modules/loadBulkSms'
import audience from './modules/audience'
import checklist from './modules/checklist'

export const reset = () => {
  return (dispatch) => {
    dispatch(loadBulkSms.actions.reset())
    dispatch(content.actions.reset())
    dispatch(audience.actions.reset())
    dispatch(checklist.actions.reset())
  }
}
