import session from "./session";
import userData from "./userData";
import modal from "./modal";
import locations from "./locations";
import billing from "./billing";
import trials from "./trials";
import mergeTags from "./mergeTags";
import tags from "./tags";
import excludedTags from "./excludedTags"
import deals from "./deals";
import leadSources from "./leadSources";
import heardAboutMethods from "./heardAboutMethods";
import leadGoals from "./leadGoals";
import leadLostReasons from "./leadLostReasons";

export default {
  session,
  userData,
  modal,
  tags,
  excludedTags,
  mergeTags,
  deals,
  billing,
  trials,
  locations,
  leadSources,
  heardAboutMethods,
  leadGoals,
  leadLostReasons
};
