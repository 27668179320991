import { createSelector } from 'reselect'

import settings from '../settings'
import exitRules from '../rules/modules/exitRules'
import entryRules from '../rules/modules/entryRules'

export const hasUnsavedChanges = createSelector(
  [ settings.selectors.getHasChanges,
    exitRules.selectors.getHasChanges,
    entryRules.selectors.getHasChanges ],
  (settingsHaveChanges, exitRulesHaveChanges, entryRulesHaveChanges ) => {
    if (settingsHaveChanges) { return true }
    if (exitRulesHaveChanges) { return true }
    if (entryRulesHaveChanges) { return true }
    return false
  }
)
