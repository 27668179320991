import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class InfoView extends Component {

  render () {
    const { colorThemeClass, title, detail, children } = this.props
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className={'alert alert-' + colorThemeClass + ' alert-dismissable'}>
            {title &&
              <h3 className='font-w300 push-15'>{title}</h3>
            }
            {detail &&
              <p>
                {detail}
              </p>
            }
            {children}
          </div>
        </div>
      </div>
    )
  }
}

InfoView.propTypes = {
  colorThemeClass: PropTypes.string.isRequired,
  title: PropTypes.string,
  detail: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ])
}

export default InfoView
