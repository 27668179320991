import PropTypes from 'prop-types'
import React, { Component } from 'react'

import audience from '../../audience'

import STRINGS from 'strings'
const toRowStrings = STRINGS.BulkMessaging.BulkSms.Checklist.ToRow

export class ToRow extends Component {

  goToAudience = () => {
    this.props.history.push(audience.constants.NAME)
  }

  render () {
    const { bulkSms } = this.props
    const checklist = bulkSms.checklist
    const icon = (!checklist.audience_ready) ? 'fa fa-circle-thin fa-2x pull-left' :
    'fa fa-check-circle fa-2x pull-left text-success'
    return (
      <div className='checklist-row'>
        {bulkSms.status !== 'scheduled' &&
          <button
            className='pull-right btn btn-primary'
            onClick={this.goToAudience}>
            {toRowStrings.setAudienceButton}
          </button>
        }
        <i className={icon} />
        <h4>{toRowStrings.headline}</h4>
        {bulkSms.total_leads_count === 0 &&
          <p>
            {toRowStrings.subtitle}
          </p>
        }
        {bulkSms.total_leads_count > 0 &&
          <div>
            {bulkSms.marketing_group &&
              <p>{STRINGS.formatString(toRowStrings.sendToMarketingGroupMesage, <strong>{bulkSms.marketing_group.name}</strong>, <strong>{bulkSms.total_leads_count}</strong>)}</p>
            }
            {!bulkSms.marketing_group &&
              <p>{STRINGS.formatString(toRowStrings.sendToCustomFilterMesage, <strong>{toRowStrings.customFilter}</strong>, <strong>{bulkSms.total_leads_count}</strong>)}
              </p>
            }
          </div>
        }
        {bulkSms.is_opt_in &&
          <div>
            <strong>
              {toRowStrings.optInMessage}
              <a href='http://support.gymleads.net/gymleads-marketing-app/compliance/enhanced-privacy'
              target='_blank' rel='noopener noreferrer'>{toRowStrings.learnMoreLink}</a>
            </strong>
          </div>
        }
      </div>
    )
  }
}

ToRow.propTypes = {
  bulkSms: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default ToRow
