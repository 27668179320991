import content from './modules/content'
import loadBulkEmail from './modules/loadBulkEmail'
import settings from './modules/settings'

export const reset = () => {
  return (dispatch) => {
    dispatch(loadBulkEmail.actions.reset())
    dispatch(content.actions.reset())
    dispatch(settings.actions.reset())
  }
}
