import PropTypes from "prop-types";
import React, { Component } from "react";

import LeadRow from "./LeadRow";
import STRINGS from "strings";

export class LeadsTableView extends Component {
  render() {
    const { stepsLeads, onRemoveSelected } = this.props;
    return (
      <table className="table table-hover table-vcenter">
        <thead>
          <tr>
            <th>{STRINGS.Global.name}</th>
            <th>{STRINGS.Global.email}</th>
            <th>{STRINGS.Global.number}</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {stepsLeads.map((stepsLead, index) => {
            return (
              <LeadRow
                stepsLead={stepsLead}
                key={index}
                onRemoveSelected={onRemoveSelected}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
}

LeadsTableView.propTypes = {
  stepsLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemoveSelected: PropTypes.func.isRequired
};

export default LeadsTableView;
