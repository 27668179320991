import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import ContentView from '../components/ContentView'
import * as selectors from '../selectors'
import * as actions from '../actions'
import loadBulkSms from '../../loadBulkSms'
import mergeTags from 'modules/stores/mergeTags'
import billing from 'modules/stores/billing'

function mapStateToProps (state) {
  const getDefaultSmsCost = billing.selectors.getDefaultSmsCost(state)
  const defaultCost = (getDefaultSmsCost) ? getDefaultSmsCost.marketing_outbound_cost : 0.1
  return {
    text: selectors.getText(state),
    messageCost: defaultCost,
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    previewText: selectors.getPreviewText(state),
    mergeTags: mergeTags.selectors.getMergeTags(state),
    bulkSms: loadBulkSms.selectors.getBulkSms(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateSmsContent: actions.updateSmsContent
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentView))
