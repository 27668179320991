import PropTypes from "prop-types";
import React, { Component } from "react";

import SelectViewMenu from "modules/components/selectViewMenu";

import "./styles.css";
import RecipesListContainer from "../containers/RecipesListContainer";
import ShareCodeContainer from "../containers/ShareCodeContainer";
import STRINGS from "strings";

const SYSTEM_DESIGNS = "SYSTEM_DESIGNS";
const SHARE_CODE = "SHARE_CODE";

const menuItems = [
  {
    id: SYSTEM_DESIGNS,
    name: STRINGS.Groups.Designs.designs
  },
  {
    id: SHARE_CODE,
    name: STRINGS.Templates.shareTitle
  }
];

export class SelectDesignView extends Component {
  state = {
    view: SYSTEM_DESIGNS
  };

  setView = view => {
    this.setState({ ...this.state, view: view });
  };

  render() {
    const { view } = this.state;
    return (
      <div>
        <div>
          <h3 className="push-10">{STRINGS.Automations.Designs.title}</h3>
          <SelectViewMenu
            menuItems={menuItems}
            currentView={view}
            setView={this.setView}
          />
          {view === SYSTEM_DESIGNS && <RecipesListContainer />}
          {view === SHARE_CODE && <ShareCodeContainer />}
        </div>
      </div>
    );
  }
}

SelectDesignView.propTypes = {
  loading: PropTypes.bool.isRequired,
  legacyRecipesLoading: PropTypes.bool.isRequired,
  createFunnel: PropTypes.func.isRequired,
  legacyRecipies: PropTypes.arrayOf(PropTypes.object).isRequired,
  getLegacyRecipes: PropTypes.func.isRequired,
  showListingView: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default SelectDesignView;
