import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

import audience from '../audience'

export const get = (bulkSmsId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      if (payload.status === 404) {
          dispatch(setNotFound(true))
          return
      }
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setBulkSms(payload.mass_lead_text_message))
      dispatch(audience.actions.prefillDataForBulkSms(payload.mass_lead_text_message))
      dispatch(setNotFound(false))
      dispatch(setLoading(false))
    }

    return request('mass_lead_text_messages/' + bulkSmsId, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setBulkSms (bulkSms) {
  return {
    type: actionTypes.SET_BULK_SMS,
    bulkSms
  }
}

function setErrors (errors) {
  return {
    type: actionTypes.SET_LOADING,
    errors
  }
}

function setNotFound (notFound) {
  return {
    type: actionTypes.SET_NOT_FOUND,
    notFound
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
