import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ContentBlockView, EmailEditor, ComplianceView } from 'modules/components'
import STRINGS from "strings";
const contentStrings = STRINGS.BulkMessaging.BulkEmail.Content;

export class ContentView extends Component {

  componentWillMount() {
    this.props.setHasChanges(true)
  }

  saveContentClicked = () => {
    const { bulkEmail, updateEmailContent } = this.props
    window.unlayer.exportHtml(data => {
      const { design, html } = data
      updateEmailContent(bulkEmail, html, design)
    })
  }

  render () {
    const { bulkEmail, mergeTags } = this.props
    const { compliance } = bulkEmail.checklist

    return (
      <div>
        {!compliance.is_valid &&
          <ComplianceView compliance={compliance} />
        }
        <ContentBlockView
          title={'Edit Email'}
          bordered
          style={{ padding: '0px' }}
          disabled={bulkEmail.status === 'scheduled'}>
          <EmailEditor designJson={bulkEmail.design_json} mergeTags={mergeTags} />
          <button className='btn btn-primary push-20-t push-20 push-20-l'
            onClick={this.saveContentClicked}>
            <i className='fa fa-save' /> {contentStrings.saveButton}
          </button>
        </ContentBlockView>
      </div>
    )
  }
}

ContentView.propTypes = {
  loading: PropTypes.bool.isRequired,
  bulkEmail: PropTypes.object.isRequired,
  updateEmailContent: PropTypes.func.isRequired,
  mergeTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  setHasChanges: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default ContentView
