import PropTypes from 'prop-types'
import React, { Component } from 'react'
import 'styles/loaders.min.css'

export class GymLeadsLoader extends Component {

  render () {
    const { text, active } = this.props
    const visibility = (!active) ? 'hidden' : 'visible'
    const height = (active) ? '100%' : '0px'
    return (
      <div style={{ width: '100%', visibility: visibility, height: height }}>
        <div className='loader-inner ball-pulse' style={{ display: 'table', margin: '0 auto' }}>
          <div style={{ backgroundColor: '#5c90d2' }} />
          <div style={{ backgroundColor: '#5c90d2' }} />
          <div style={{ backgroundColor: '#5c90d2' }} />
        </div>
        {text &&
          <p className='text-center'>{text}</p>
        }
      </div>
    )
  }
}

GymLeadsLoader.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool.isRequired
}

export default GymLeadsLoader
